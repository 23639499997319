import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/settings.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import customSelectStyles from "../SelectStyle";
import countries from "../../../utils/countries";
import { regexDecimalPoint, withdrawDecimalPoint, withdrawNetwork } from "../../../utils/config";

const method = [
    // { label: 'fiat', value: 'FIAT_WITHDRAW' },
    { label: 'crypto', value: 'CRYPTO_WITHDRAW' },
]

const Withdrawal = (props) => {
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);

    const [loadingOtp, setLoadingOtp] = useState(false);
    const [loading, setLoading] = useState(false);

    const [withdrawInfo, setWithdrawInfo] = useState({
        wallet_balance: 0,
    });
    const [receiveAmountInfo, setReceiveAmountInfo] = useState('')
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        withdraw_type: 'CRYPTO_WITHDRAW',
        bank_name: '',
        bank_account_name: '',
        bank_account_number: '',
        bank_swift_code: '',
        bank_country: '',
        bank_address: '',
        coin_name: 'USDT',
        chain: '',
        address: '',
        email_otp: '',
        amount: '',
        transaction_password: '',
    });
    let errorsObj = {
        withdraw_type: '',
        bank_name: '',
        bank_account_name: '',
        bank_account_number: '',
        bank_swift_code: '',
        bank_country: '',
        bank_address: '',
        coin_name: '',
        chain: '',
        address: '',
        email_otp: '',
        amount: '',
        transaction_password: '',
    };
    const [errors, setErrors] = useState(errorsObj);
    const [countdownSecond, setCountdownSecond] = useState(0);
    const [networkFee, setNetworkFee] = useState(0);
    useEffect(() => {
        getFee()
        getWalletBalance()
        return () => { };
    }, [props.auth.auth.access_token]);

    useEffect(() => {
        if (countdownSecond > 0) {
            setTimeout(() => {
                setCountdownSecond(countdownSecond - 1);
            }, 1000);
        }
        return () => { };
    }, [countdownSecond]);

    const getFee = () => {
        fetch(api.get_network_fee, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setNetworkFee(responseJson.data)
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log(translate(props.lang, "Error"), responseJson.message);
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getWalletBalance = () => {
        fetch(api.get_wallet_balance, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setWithdrawInfo(responseJson.data)
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log(translate(props.lang, "Error"), responseJson.message);
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const requestCode = () => {
        setLoadingOtp(true);
        let data = {
            "email": props.auth.profile.email,
            "send_otp": true, // optional // if dont have this param default will send otp
            "type": "withdraw"
        }
        fetch(api.request_email_otp, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingOtp(false);
                if (responseJson.status === "success") {
                    setCountdownSecond(60);
                    swal(translate(props.lang, "Success"), translate(props.lang, "Verification code has sent to").replace('$email', props.auth.profile.email), "success");
                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleOptionSelect = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        if (fieldName === 'amount') {
            if (fieldValue) {
                if (fieldValue !== "-") {
                    if (regexDecimalPoint.test(fieldValue)) {
                        newFormData[fieldName] = fieldValue;
                    }
                }
            } else {
                newFormData[fieldName] = '';
            }
        } else {
            newFormData[fieldName] = fieldValue;
        }
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        var onlyNumber = /^\d+(\.\d+)?$/;
        if (!formData.amount) {
            errorMsg.amount = "This field is required.";
            check = false
        } else {
            if (!onlyNumber.test(formData.amount)) {
                errorMsg.amount = 'Only Numbers are allowed in this field';
                check = false;
            } else {
                if (formData.amount <= 0) {
                    errorMsg.amount = "Amount must greater than 0.";
                    check = false
                } else if (formData.amount > Number(withdrawInfo.bonusBalance)) {
                    errorMsg.amount = "Insufficient balance";
                    check = false
                }
            }
        }
        if (!formData.email_otp) {
            errorMsg.email_otp = "This field is required.";
            check = false
        }

        if (!formData.transaction_password) {
            errorMsg.transaction_password = "This field is required.";
            check = false
        }
        if (formData.withdraw_type === 'CRYPTO_WITHDRAW') {
            if (!formData.address) {
                errorMsg.address = "This field is required.";
                check = false
            }
            if (!formData.coin_name) {
                errorMsg.coin_name = "This field is required.";
                check = false
            }
            if (!formData.chain) {
                errorMsg.chain = "This field is required.";
                check = false
            }
        }
        else {
            if (!formData.bank_account_name) {
                errorMsg.bank_account_name = "This field is required.";
                check = false
            }
            if (!formData.bank_name) {
                errorMsg.bank_name = "This field is required.";
                check = false
            }
            if (!formData.bank_swift_code) {
                errorMsg.bank_swift_code = "This field is required.";
                check = false
            }
            if (!formData.bank_country) {
                errorMsg.bank_country = "This field is required.";
                check = false
            }
            if (!formData.bank_address) {
                errorMsg.bank_address = "This field is required.";
                check = false
            }
            if (!formData.bank_account_number) {
                errorMsg.bank_account_number = "This field is required.";
                check = false
            }
        }
        setErrors(errorMsg);
        // if (check) {
            // setLoading(true);
            // let newFormData;
            // if (formData.withdraw_type === 'CRYPTO_WITHDRAW') {
            //     newFormData = {
            //         coin_name: formData.coin_name, // optional if crypto_withdraw cannot be empty
            //         address: formData.address, // optional if crypto_withdraw cannot be empty
            //         chain: formData.chain, // optional if crypto_withdraw cannot be empty
            //         amount: Number(formData.amount),
            //         transaction_password: formData.transaction_password,
            //         withdraw_type: formData.withdraw_type, // CRYPTO_WITHDRAW / FIAT_WITHDRAW
            //         email_otp: formData.email_otp,
            //     }
            // }
            // else {
            //     newFormData = {
            //         bank_name: formData.bank_name, // optional if fiat_withdraw cannot be empty
            //         bank_account_name: formData.bank_account_name, // optional if fiat_withdraw cannot be empty
            //         bank_account_number: formData.bank_account_number, // optional if fiat_withdraw cannot be empty
            //         bank_swift_code: formData.bank_swift_code, // optional if fiat_withdraw cannot be empty
            //         bank_country: formData.bank_country, // optional if fiat_withdraw cannot be empty
            //         bank_address: formData.bank_address, // optional if fiat_withdraw cannot be empty
            //         amount: Number(formData.amount),
            //         transaction_password: formData.transaction_password,
            //         withdraw_type: formData.withdraw_type, // CRYPTO_WITHDRAW / FIAT_WITHDRAW
            //         email_otp: formData.email_otp,
            //     }
            // }
            // fetch(api.withdraw, {
            //     method: "POST",
            //     headers: new Headers({
            //         "Content-Type": "application/json",
            //         "Accept-Language": props.lang.toLowerCase(),
            //         "Authorization": "Bearer " + props.auth.auth.access_token
            //     }),
            //     body: JSON.stringify(newFormData)
            // })
            //     .then(response => response.json())
            //     .then(responseJson => {
            //         setLoading(false);
            //         if (responseJson.status === "success") {
            //             setReceiveAmountInfo('');
            //             swal(translate(props.lang, "Success"), responseJson.message, "success");
            //             setFormData({ ...errorsObj })
            //             history.push('/wallet/transaction-history');
            //         } else {
            //             if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
            //                 dispatch(logout(props.history, 'login'));
            //             }
            //             swal(translate(props.lang, "Error"), responseJson.message, "error");
            //         }
            //     }).catch(error => {
            //         console.error("error", error);
            //     });
        // }
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0">
                    <Col lg={12} md={12} sm={12} xs={12} className="mt-3 px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="px-0 mb-3">
                                    <Row className="justify-content-center align-items-center mx-0 px-0 mb-2">
                                        <Col lg={6} md={6} xs={12} sm={12} className="text-md-start text-center mb-3 mb-md-0">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img src={require('../../../images/icon2/withdrawal.svg').default} className="balanceIcon" alt="icon-wallet" />
                                                </div>
                                                <div className="ps-3">
                                                    <div className="text-center text-md-start">
                                                        <span className="font-20 font-weight-400 text-white">{translate(props.lang, "Withdrawal From Wallet")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} xs={12} sm={12}>
                                            <div className="balanceBox d-flex w-100 justify-content-between px-3 px-lg-4 py-3">
                                                <div>
                                                    <span className="font-24 text-white font-weight-500 line-height-18 break-all-word break-white-space">USD</span>
                                                </div>
                                                <div>
                                                    <span className="font-24 text-white font-weight-500 line-height-18 break-all-word break-white-space">{Number(withdrawInfo.wallet_balance).toLocaleString('en-US', digitFormat)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                    <Row className="justify-content-center align-items-center mx-0 px-0">
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                            <div className="text-start">
                                                <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1  ${errors.withdraw_type ? 'errorBorder' : ''}`}>
                                                    <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Withdraw Method")} <span className="errorTextRed">*</span></span>
                                                    <Select
                                                        options={method.length > 0 ? method.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                                        placeholder={translate(props.lang, "Select withdrawal method")}
                                                        className="input-transparent w-100 text-capitalize px-0"
                                                        styles={customSelectStyles}
                                                        name="withdraw_type"
                                                        isSearchable={false}
                                                        value={formData.withdraw_type ? method.filter(item => item.value === formData.withdraw_type).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : ''}
                                                        onChange={(e) => handleOptionSelect(e, 'withdraw_type')}
                                                    />
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.withdraw_type)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        {formData.withdraw_type === 'CRYPTO_WITHDRAW' ? (
                                            <>
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-2 mb-1 ${errors.coin_name ? 'errorBorder' : ''}`}>
                                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Coin")} <span className="errorTextRed">*</span></span>
                                                            <div className={`input-group`}>
                                                                <Input
                                                                    type={'text'}
                                                                    name="withdrawType"
                                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                                    value={"USDT | TetherUS"}
                                                                    autoComplete="off"
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ms-4">
                                                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.coin_name)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1  ${errors.chain ? 'errorBorder' : ''}`}>
                                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Network")} <span className="errorTextRed">*</span></span>
                                                            <Select
                                                                options={withdrawNetwork.length > 0 ? withdrawNetwork.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                                                placeholder={translate(props.lang, "Select Network")}
                                                                className="input-transparent w-100 text-capitalize px-0"
                                                                styles={customSelectStyles}
                                                                name="chain"
                                                                isSearchable={false}
                                                                value={formData.chain ? withdrawNetwork.filter(item => item.value === formData.chain).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : ''}
                                                                onChange={(e) => handleOptionSelect(e, 'chain')}
                                                            />
                                                        </div>
                                                        <div className="ms-4">
                                                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.chain)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-2 mb-1 ${errors.address ? 'errorBorder' : ''}`}>
                                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Wallet Address")}(USDT) <span className="errorTextRed">*</span></span>
                                                            <div className={`input-group`}>
                                                                <Input
                                                                    type={'text'}
                                                                    name="address"
                                                                    placeholder={translate(props.lang, "Your Wallet Address")}
                                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                                    value={formData.address}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChange(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ms-4">
                                                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.address)}</span>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </>
                                        ) : (<>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1 ${errors.bank_country ? 'errorBorder' : ''}`}>
                                                        <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Country")} <span className="errorTextRed">*</span></span>
                                                        <Select
                                                            options={
                                                                countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: props.lang === 'zh_cn' ? item.chinese_simplified : props.lang === 'zh_tw' ? item.chinese_traditional : item.name })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                                            }
                                                            placeholder={translate(props.lang, "Select Bank Country")}
                                                            className="input-transparent w-100 text-capitalize"
                                                            styles={customSelectStyles}
                                                            value={
                                                                countries.filter(item => item.name == formData.bank_country).length > 0 ?
                                                                    (countries.filter(item => item.name == formData.bank_country).map((item, i) => ({ key: i, value: item.name, label: props.lang === 'zh_cn' ? item.chinese_simplified : props.lang === 'zh_tw' ? item.chinese_traditional : item.name })))
                                                                    : null
                                                            }
                                                            name="bank_country"
                                                            isSearchable
                                                            onChange={(e) => handleOptionSelect(e, 'bank_country')}
                                                        />
                                                    </div>
                                                    <div className="ms-4">
                                                        <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.bank_country)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-2 mb-1 ${errors.bank_name ? 'errorBorder' : ''}`}>
                                                        <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Name")} <span className="errorTextRed">*</span></span>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={'text'}
                                                                name="bank_name"
                                                                placeholder={translate(props.lang, "Your Bank Name")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={formData.bank_name}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ms-4">
                                                        <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.bank_name)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-2 mb-1 ${errors.bank_account_name ? 'errorBorder' : ''}`}>
                                                        <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Holder Name")} <span className="errorTextRed">*</span></span>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={'text'}
                                                                name="bank_account_name"
                                                                placeholder={translate(props.lang, "Your Bank Account Holder Name")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={formData.bank_account_name}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ms-4">
                                                        <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.bank_account_name)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-2 mb-1 ${errors.bank_account_number ? 'errorBorder' : ''}`}>
                                                        <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Number")} <span className="errorTextRed">*</span></span>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={'text'}
                                                                name="bank_account_number"
                                                                placeholder={translate(props.lang, "Your Bank Account Number")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={formData.bank_account_number}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ms-4">
                                                        <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.bank_account_number)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-2 mb-1 ${errors.bank_address ? 'errorBorder' : ''}`}>
                                                        <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Address")} <span className="errorTextRed">*</span></span>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={'text'}
                                                                name="bank_address"
                                                                placeholder={translate(props.lang, "Your Bank Address")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={formData.bank_address}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ms-4">
                                                        <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.bank_address)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-2 mb-1 ${errors.bank_swift_code ? 'errorBorder' : ''}`}>
                                                        <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Swift Code")} <span className="errorTextRed">*</span></span>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={"text"}
                                                                name="bank_swift_code"
                                                                placeholder={translate(props.lang, "Your Bank Swift Code")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={formData.bank_swift_code}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ms-4">
                                                        <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.bank_swift_code)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </>)}

                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                            <div className="text-start">
                                                <div className={`groupLabel-input py-2 mb-1 ${errors.amount ? 'errorBorder' : ''}`}>
                                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Withdraw Amount")} <span className="errorTextRed">*</span></span>
                                                    <div className={`input-group`}>
                                                        <Input
                                                            type='text'
                                                            onWheel={event => event.currentTarget.blur()}
                                                            name="amount"
                                                            placeholder={translate(props.lang, "Your Withdraw Amount")}
                                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                                            value={formData.amount}
                                                            autoComplete="off"
                                                            autoCorrect={'off'}
                                                            autoSave='off'
                                                            aria-autocomplete='off'
                                                            spellCheck={"false"}
                                                            id="withdraw_amount"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.amount)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} xs={12} sm={12} className="mb-2">
                                            <div className="text-start">
                                                <div className={`groupLabel-input py-2 mb-1 ${errors.transaction_password ? 'errorBorder' : ''}`}>
                                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Transaction Password")} <span className="errorTextRed">*</span></span>
                                                    <div className={`input-group`}>
                                                        <Input
                                                            type={showPassword ? "text" : "password"}
                                                            name="transaction_password"
                                                            placeholder={translate(props.lang, "Your Transaction Password")}
                                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                                            value={formData.transaction_password}
                                                            autoComplete="new-password"
                                                            aria-autocomplete="none"
                                                            id="transaction_password"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                        <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword ? (
                                                                <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                                            ) : (
                                                                <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.transaction_password)}</span>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={6} md={6} xs={12} sm={12} className="mb-2">
                                            <div className="text-start">
                                                <div className={`groupLabel-input px-md-4 px-3 py-2 mb-1 ${errors.email_otp ? 'errorBorder' : ''}`}>
                                                    <div className='d-flex justify-content-between align-items-center w-100'>
                                                        <div className="w-75">
                                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Verification Code")} <span className="errorTextRed">*</span></span>
                                                            <div className={`input-group `}>
                                                                <Input
                                                                    type="text"
                                                                    name="email_otp"
                                                                    placeholder={translate(props.lang, "Your Email Verification Code")}
                                                                    className="form-control input-transparent py-1"
                                                                    value={formData.email_otp}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChange(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {countdownSecond === 0 ? (
                                                                <button className="requestCodeBtn px-lg-3 px-2 py-3 cursor-pointer" disabled={loadingOtp} onClick={() => requestCode()}>
                                                                    <span className={`font-weight-600 font-16 btnText text-nowrap`}>{loadingOtp ? (<FaSpinner className="fa-spin" />) : translate(props.lang, "Send")}</span>
                                                                </button>
                                                            ) : (
                                                                <button className="requestCodeBtn px-lg-3 px-2 py-3 cursor-pointer" disabled>
                                                                    <span className={`font-weight-600 font-16 btnText text-nowrap`}>{countdownSecond}</span>
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.email_otp)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} xs={12} sm={12} className="mt-2">
                                            {formData.withdraw_type === "CRYPTO_WITHDRAW" ? (
                                                <div className="card-custom-bg">
                                                    <span className="font-13 text-white font-weight-400">{translate(props.lang, "Receive Amount")}</span>
                                                    <div className="mt-3">
                                                        <span className="font-30 text-white font-weight-400">{formData.amount ? Number(formData.amount - networkFee).toLocaleString('en-US',  { maximumFractionDigits: withdrawDecimalPoint, minimumFractionDigits: withdrawDecimalPoint}) : 0} </span><span className="font-13 page-title-color font-weight-500"></span>
                                                    </div>
                                                    <div className="mt-3">
                                                        <span className="font-13 text-white font-weight-400">{translate(props.lang, "Network Fee:")}{networkFee ? networkFee : 0}</span>
                                                    </div>
                                                </div>
                                            ) : formData.withdraw_type === "fiat" ? (
                                                <div className="card-custom-bg">
                                                    <span className="font-13 text-white font-weight-400">{translate(props.lang, "Receive Amount")}</span>
                                                    <div className="mt-3">
                                                        <span className="font-30 text-white font-weight-400">{formData.amount ? Number(formData.amount).toLocaleString('en-US',  { maximumFractionDigits: withdrawDecimalPoint, minimumFractionDigits: withdrawDecimalPoint}) : 0} </span>
                                                    </div>
                                                    <div className="mt-3">
                                                        <span className="font-13 text-white font-weight-400">{translate(props.lang, "Exchange Rate:")}{receiveAmountInfo ? receiveAmountInfo.fiatExchangeRate : 0}</span>
                                                    </div>
                                                    <div className="mt-3">
                                                        <span className="font-13 text-white font-weight-400">{translate(props.lang, "Charge Fee:")}{receiveAmountInfo ? receiveAmountInfo.fiatWithdrawFee : 0}</span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </Col>
                                        <Col lg={12} md={12} xs={12} sm={12}>
                                            <button className="primary-btn px-4 py-2 text-center mx-auto w-100 mt-4" disabled={loading} onClick={() => submitForm()}>
                                                <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Submit")}</span>
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Withdrawal);
