import Dashboard from "../jsx/page/Dashboard/Dashboard";
import Investment from "../jsx/page/Investment/Investment";
import Profile from "../jsx/page/Profile/Profile";
import WalletOverview from "../jsx/page/Wallet/Overview";
import Deposit from "../jsx/page/Wallet/Deposit";
import Withdrawal from "../jsx/page/Wallet/Withdrawal";
import TransactionHistory from "../jsx/page/Wallet/TransactionHistory";
import BonusHistoryDetails from "../jsx/page/Wallet/BonusHistoryDetails";
import TeamOverview from "../jsx/page/Team/Overview";
import Performance from "../jsx/page/Team/Performance";
import Referral from "../jsx/page/Team/Referral";
import TeamInvestment from "../jsx/page/Team/TeamInvestment";
import SettingOverview from "../jsx/page/Setting/Overview";
import KYC from "../jsx/page/Setting/KYC";
import ChangePassword from "../jsx/page/Setting/ChangePassword";
import ChangeTransactionPassword from "../jsx/page/Setting/ChangeTransactionPassword";
import Notification from "../jsx/page/Notification/Notification";
import CryptoDeposit from "../jsx/page/Wallet/CryptoDeposit";
import FiatDeposit from "../jsx/page/Wallet/FiatDeposit";
import BankInfo from "../jsx/page/Wallet/BankInfo";
import WithdrawalMethod from "../jsx/page/Wallet/WithdrawalMethod";
import Test from "../jsx/page/Test";
import HedgePosition from "../jsx/page/Hedge/HedgePosition";
import HedgePositionDetails from "../jsx/page/Hedge/HedgePositionDetails";
import AnnouncementList from "../jsx/page/Announcement/AnnouncementList";
import Transfer from "../jsx/page/Wallet/Transfer";

const routes = [
    { url: "/", component: Dashboard, name: "Dashboard" },
    { url: "/dashboard", component: Dashboard, name: "Dashboard" },
    { url: "/hedgedAccount", component: HedgePosition, name: "Hedged Position" },
    { url: "/hedgedAccount/:id", component: HedgePositionDetails, name: "Hedged Position Details" },
    { url: "/announcement", component: AnnouncementList, name: "Announcement" },
    { url: "/investment", component: Investment, name: "Investment" },
    { url: "/wallet/overview", component: WalletOverview, name: "Wallet Overview" },
    { url: "/wallet/deposit", component: Deposit, name: "Deposit" },
    { url: "/wallet/deposit/crypto", component: CryptoDeposit, name: "Fund Your Wallet" },
    { url: "/wallet/deposit/fiat", component: FiatDeposit, name: "Fund Your Wallet" },
    { url: "/wallet/withdrawal", component: Withdrawal, name: "Withdrawal" },
    { url: "/wallet/transaction-history", component: TransactionHistory, name: "Transaction History" },
    { url: "/wallet/bonus-history-details", component: BonusHistoryDetails, name: "Bonus History Details" },
    { url: "/wallet/bankInfo", component: BankInfo, name: "Bank Info" },
    { url: "/wallet/bankInfo/withdraw-method", component: WithdrawalMethod, name: "Bank Info" },
    { url: "/wallet/transfer", component: Transfer, name: "Internal Transfer" },
    { url: "/team/overview", component: TeamOverview, name: "Team Overview" },
    { url: "/team/performance", component: Performance, name: "Performance" },
    { url: "/team/team-investment", component: TeamInvestment, name: "Team Investment" },
    { url: "/team/referral", component: Referral, name: "Referral" },
    { url: "/profile", component: Profile, name: "Profile" },
    { url: "/settings/overview", component: SettingOverview, name: "Security" },
    { url: "/settings/kyc", component: KYC, name: "KYC" },
    { url: "/settings/change-password", component: ChangePassword, name: "Change Password" },
    { url: "/settings/change-transaction-password", component: ChangeTransactionPassword, name: "Forget Transaction Password" },
    { url: "/notification", component: Notification, name: "Notification" },
    // { url: "/test", component: Test, name: "Notification" },
];

export default routes;