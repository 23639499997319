import { connect, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import { api } from "../../../utils/api";
import { logout } from "../../../store/actions/AuthActions";
import LoadingV3 from "../../components/Loading/LoadingV3";
import { copyText } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import "../../../css/team.scss";

const Referral = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProfile();
    }, [props.auth.auth.access_token]);

    const getProfile = () => {
        setLoading(true);
        fetch(api.get_profile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    if (responseJson.data) {
                        dispatch({
                            type: 'UPDATE_PROFILE',
                            profile: responseJson.data
                        });
                    }
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const downloadImage = () => {
        const svg = document.getElementById("referralQR");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");

            const downloadLink = document.createElement("a");
            downloadLink.download = "referralQR";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };

        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }


    return (
        <>
            <div className='pb-5 team'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            {loading ? (
                                <LoadingV3 theme='light' />
                            ) : (
                                <Row className='mx-0 px-0 align-items-start justify-content-center'>
                                    <Col lg={12} md={12} xs={12} sm={12} className="d-block d-md-none text-center">
                                        <div className="d-flex w-100 justify-content-center">
                                            <div className="referral-qr-container w-50 h-100 p-2 mb-4" onClick={() => downloadImage()}>
                                                <QRCode
                                                    id="referralQR"
                                                    size={256}
                                                    style={{ height: "auto", width: "100%" }}
                                                    value={props.auth.profile.referralCode ? window.location.origin + '/register?referral=' + props.auth.profile.referralCode : ''}
                                                    viewBox={`0 0 256 256`}
                                                    bgColor="#ffffff"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={10} md={9} sm={12} xs={12} className="px-0">
                                        <Row className="align-items-center mx-0 px-0 h-100">
                                            <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                <Row className="align-items-center mx-0 px-0 h-100">
                                                    <Col lg={6} md={6} sm={8} xs={8} className="d-flex align-items-center">
                                                        <div className="me-2">
                                                            <img src={require('../../../images/icon2/qrcode.svg').default} alt="icon-wallet" className="img-fluid" />
                                                        </div>
                                                        <span className="font-weight-600 font-22 text-white line-height-20 text-capitalize">{translate(props.lang, "share my QR code")}</span>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={4} xs={4}>
                                                        <div className="d-flex w-100 justify-content-end">
                                                            <div className="copyBox py-2 text-center d-inline-block px-3" onClick={() => copyText(props.auth.profile.referral_code ? window.location.origin + '/register?referral=' + props.auth.profile.referral_code : '', props.lang)}>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="text-black font-weight-500 font-16">{translate(props.lang, "Copy")}</span>
                                                                    <img src={require('../../../images/icon2/arrow-black.svg').default} alt="icon-wallet" className="ms-1 img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="py-3">
                                                <div className="borderLine"></div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <span className="font-weight-600 font-20 text-center text-white break-all-word lh-base overflow-wrap-anywhere">{props.auth.profile.referral_code ? window.location.origin + '/register?referral=' + props.auth.profile.referral_code : ''}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={2} md={3} sm={12} xs={12} className="d-none d-md-block">
                                        <div className="referral-qr-container w-100 h-100 p-2" onClick={() => downloadImage()}>
                                            <QRCode
                                                id="referralQR"
                                                size={256}
                                                style={{ height: "auto", width: "100%" }}
                                                value={props.auth.profile.referralCode ? window.location.origin + '/register?referral=' + props.auth.profile.referralCode : ''}
                                                viewBox={`0 0 256 256`}
                                                bgColor="#ffffff"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Referral);