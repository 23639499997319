const ZH_CN = {
    "Welcome Back": "欢迎回来",
    "Don’t have an account yet?": "还没有账户？",
    "Sign up now": "立即注册",
    "Email Address": "邮箱",
    "Enter Email": "请输入邮箱",
    "Password": "密码",
    "Enter Transaction Password": "输入交易密码",
    "Remember me": "记住账号",
    "Forgot Password?": "重置登录密码？",
    "Login": "登录",
    "This field is required.": "必填项。",
    "Forgot Password": '重置登录密码',
    "Already have an account?": "已有账户？",
    "Back To Login": "登录",
    "Verification Code": "邮箱验证码",
    "Your Verification Code": "输入邮箱验证码",
    "Send": "发送",
    "Confirm Password": '确认密码',
    "Confirm Your Password": '请输入登录密码',
    "Submit": "提交",
    "By clicking 'Submit' button, you agree to our Privacy and Terms of Use.": "单击“提交”按钮，即表示您同意我们的隐私和使用条款。",
    "Invalid Email Format": "邮箱格式错误",
    "Password must at least 6 characters": "登录密码必须至少为6个字符",
    "Confirm password need to same with password": "确认密码与登录密码不一致",
    "Verification code has sent to": "验证码已发送至您的 $email 邮箱。请检查您的邮箱或垃圾邮箱",
    "Create Personal Account": "请准确填写您的注册信息",
    "Login now": "登录",
    "Full Name": "姓名",
    "Your Full Name": "请输入您的身份证姓名/护照姓名",
    "Country": "国家",
    "Select Country": "选择国家",
    "No countries available": "没国家可选",
    "Transaction Password" : "交易密码",
    "Your Transaction Password" : "输入交易密码",
    "Referral Code (optional)": "邀请代码（可选）",
    "Create Account Now": "注册",
    "Register Successful": "您已完成注册",
    "Success": "成功",
    "Error": "请重试",
    "Your Referral Code": "请输入邀请码" ,
    "Dashboard": "首页",
    "Investment": "资产",
    "Wallet": "钱包",
    "Wallet Overview": "财务记录",
    "Deposit": "存款",
    "Withdrawal": "提款",
    "Bank Info": "提款信息",
    "Transaction History": "存取记录",
    "Bonus History Details": "返佣记录",
    "Team": "团队",
    "Team Overview": "晋级详情",
    "Team Investment": "团队详情",
    "Performance": "数据总览",
    "Referral": "邀请链接",
    "Profile": "我的信息",
    "Settings": "设置",
    "KYC": "身份认证",
    "Security": "密码",
    "Fund Your Wallet": "账户存款",
    "Change Password": '修改登录密码',
    "Forget Transaction Password": '忘记交易密码',
    "Notification": "通知",
    "Hi,": "你好，",
    "Stop Trading Account": '停止交易MT5账号',
    "Attention": "请注意!",
    "Are you sure you want to stop trading?": "您确定要停止交易吗？",
    "Yes, I am sure!": "是的，我确定！",
    "Cancel": "取消",
    "New password must at least 6 characters": '新登录密码必须至少包含6个字符',
    "Confirm password does not match the new password": '确认密码与新登录密码不一致',
    "New login password change successful": "已修改新登录密码",
    'Back': "返回",
    "Old Password": "原密码",
    "New Password": '新登录密码',
    "Your Old Password": '输入原密码',
    "Your New Password": "输入新登录密码",
    "Confirm New Password": '确认新登录密码',
    "Confirm Your New Password": "输入新登录密码",
    "Update": "更新",
    "New Transaction Password": "新交易密码",
    "Your new transaction password": '输入新交易密码',
    "Confirm New Transaction Password": '确认新交易密码',
    "Confirm your new confirm transaction password": "输入新交易密码",
    "New transaction password change successful": '已修改新交易密码',
    "Transaction password must at least 6 characters": '交易密码必须至少为6个字符',
    "Confirm password does not match the new transaction password": "确认密码与新交易密码不一致",
    "Logout": "退出登录",
    "Referral Code": "邀请码",
    "Referral Link": "邀请链接",
    "Close": "关闭",
    "Copied": "已复制",
    "Oops! No data found...": "未找到数据",
    "Loading Data": "加载数据中",

    "Male" : "男性",
    "Female" : "女性",
    // "Not Verified": "未审核",
    "Only Numbers are allowed in this field": '此项只允许输入数字',
    "Image size cannot exceed 2mb": '图片大小不能超过2MB',
    "Only allow format jpg, png or jpeg": "只允许 jpg, png, jpeg 格式",
    "Gender": "性别",
    "Select Gender": "选择性别",
    "IC / passport no.": '身份证/护照号码',
    "Your IC / Passport No.": '输入您的身份证/护照号码',
    "Date of Birth": '生日',
    "Your Date of Birth": '输入您的生日',
    "Phone Number": '手机号',
    "Your phone number": '输入您的手机号',
    "Address": "地址",
    "Your address": '输入您的地址',
    "Joining Date": '注册时间',
    "KYC Status": '认证状态',
    "Remark": "备注",
    "Upload IC/ Passport front": "上传身份证/护照正面",
    "Upload IC/ Passport Back": "上传身份证/护照背面",
    "Upload Photo With Holding IC/Passport": "上传本人手持身份证/护照的照片",
    "APPROVED": "已批准",
    "REVIEW": "审核中",
    "REJECTED": "已拒绝",
    "PENDING": "待审核",
    "Congratulations, your account has been successfully created.": "恭喜，您的帐户已成功创建。",
    "Continue": '继续',
    "Profile successful updated": '个人资料已成功更新',
    "ID": "ID",
    "Upline": "我的上属",
    "My Referral Code": "我的邀请码",
    "Edit" : "更改",
    "MT5 Username": "MT5 账号",
    "MT5 Password": "MT5密码",
    "share my QR code": '分享我的邀请链接',
    "My Rank": "我的等级",
    "Active Group Member": "活跃人数",
    "Total Group Member": "累计人数",
    "Ranking Bonus": "平级奖",
    "Lot Rebate Bonus": "点差返佣",
    "Profit Sharing Bonus": "盈利分润",
    "Direct Referral Members": "我的直属人数",
    "Direct Referral Investment Amount": "我的直属业绩",
    "Total Group Investment Amount": "我的团队业绩",
    "Personal Sales": "我的直推业绩",
    "Group Sales": "我的团队业绩",
    "Self Deposit": "我的MT5余额",
    "Ranking": "等级晋级方式",
    "Wallet Balance:": "钱包余额：",
    "Total Member": "累计人数",
    "Total Active Member": "活跃人数",
    "Total Sales": "团队业绩",
    "Personal Deposit": "MT5余额",
    "Type Of Bonus": "佣金类型",
    "Amount": "金额",
    "Date & Time": "时间",
    "View More": "查看更多",
    "Wallet Transaction History": "财务记录",
    "Description": "描述",
    "Type": "资产类型",
    "Action": "操作",
    "Transaction Hash" : "交易明细",
    "TXID": 'TXID',
    "Status": "状态",
    "MT5 Balance": "MT5 余额",
    "Crypto Deposit": '加密货币存款',
    "Seamlessly Add Cryptocurrency To Your Account And Start Trading Effortlessly.": '为了您的资金安全，请认真核对充值地址信息。',
    "Fiat Deposit": '法币存款',
    "Conveniently Deposit Funds Using Your Preferred Fiat Currency, Ensuring A Smooth And Hassle-Free Experience.": "为了您的资金安全，请认真核对转款信息",
    "Coin": "币种 ",
    "Network": "网络",
    "Select Network": "选择网络",
    'Deposit Address': "充值地址",
    "Wallet Address": "钱包地址",
    "Minimum Deposit": "最少充币数量",
    "Please Select Network": "请选择网络",
    "Total Invested": "我的总资产",
    "Total Profit/Loss": "累计交易收益",
    "Investment Details": "订单详情",
    "Order ID": "订单号",
    "Price": "价格",
    "Trading Pair": "产品名称",
    "Profit/Loss %": "盈利/亏损",
    "Lot Size": "手数",
    "Price Enter": "入场价格",
    "Price Close": "出场价格",
    "Announcement": "公告",
    "No Announcement For Now": "暂无公告",
    "Group Sales Performance": "我的团队业绩",
    "Sales": "业绩",
    "Read More": "了解更多",
    "My MT5 Info": "我的MT5登录",
    "Wallet Total Balance": "钱包",
    "Trading Profit": "交易收益",
    "Total Investment": "MT5余额",
    "Total Bonus": "累计返佣收益",
    "My QR Referral Code": "我的二维码&邀请链接",
    "Transfer": "划转",
    "From": "从",
    "To": "至",
    "Transfer Amount": "划转金额",
    "Balance Available:": "可转金额：",
    "Your Transfer Amount": '输入划转金额',
    "Successful": "成功",
    "Unsuccessful": "未成功",
    "Insufficient balance": "余额不足",
    "Add New Withdrawal Method" : "添加新的提款方式",
    "Withdrawal Method Available" : "可用提款方式",
    "Invalid email or password": "无效的邮箱或密码",
    "Fiat Withdrawal": '法币提款',
    "Crypto Withdrawal": "加密货币提款",
    "Bank Country": "银行所在国家",
    "Bank Account Holder Name": "银行账户持有人姓名",
    "Bank Name": "银行名称",
    "Bank Account Number": "银行账号",
    "Bank Address": "银行地址",
    "Bank Swift Code": "银行Swift码",
    "Select Your Bank country": "选择您的银行所在国家/地区",
    "Select Your Bank Name" : "选择您的银行名称",
    "Your Bank Account Holder Name": "输入您的姓名",
    "Your Bank Account Number": "输入您的银行账号",
    "Your Bank Address" : "输入银行地址",
    "Your Bank Swift Code": "输入银行Swift码",
    "Your Bank Name": "输入银行名称",
    "Your remark": "输入备注",
    "Your Wallet Address": "输入您的钱包地址",
    "Select Your Network" : "选择您的网络",
    "Save" : "保存",    
    "Select Bank Name": "选择银行名称",
    "No network available": "没网络可选",
    "Withdrawal From Wallet": "可提款余额",
    "Email Verification Code": "邮箱验证码 ",
    "Your Email Verification Code": "输入邮箱验证码",
    "Withdraw Amount": "提款金额",
    "Your Withdraw Amount": "输入提款金额",
    "Select Type": "选择类型",
    "No option available": '没有选项可选',
    "Select bank transfer": '选择银行转账',
    "Great News! You have the option to resume trading. Do you want to resume trading?": "好消息！您可以选择恢复交易。",
    "Click here": "点击这里",
    "to reopen trading activity.": "重新恢复交易。",
    "Receive Amount" : "到账金额",
    "Network Fee:" : "网络费用 ：",
    "Exchange Rate:" : "汇率 ：",
    "Charge Fee:" : "收费 ：",
    "Merchant" : "商家",
    "Select Merchant" : "选择商家",
    "Order Number": "订单号",
    "Your Order Number": "输入订单号",
    "Payment Currency": "支付货币",
    "Select Payment Currency": "选择支付货币",
    "No currency available": "没货币可选",
    "Deposit Amount": "存款金额",
    "Your Deposit Amount": "输入存款金额",
    "Minimum to maximum transaction is 1000-4000 USD": "最低至最高存款金额为1000至4000 USD",
    "Remove Withdrawal Method": "删除提款方式",
    "Withdrawal Type": "提款类型",
    "fiat withdraw": '法币提款',
    "crypto withdraw": "加密货币提款",
    "Delete" : "删除",
    "Balance Available": "可转余额",
    "Amount must greater than 0.": "数额必须大于0。",
    "Remark:": "备注：",
    "Amount must only within 1000 - 4000": "数额必须在 1,000 - 4,000 之间",
    "View Details": "查看详情",
    "Details": '详情',
    "Investment Package": "跟单策略",
    "Select Your Package": "选择跟单策略",
    "Monthly Return": "交易收益(月)",
    "Minimum investment amount is": '最低投资额为',
    "Maximum investment amount is": "最高投资金额为",
    "Purchase Package Details": "仓位详情",
    "Purchase Datetime": "购买时间",
    "Cumulative Investment Days": '累计跟单天数',
    "Investment Allocation": "仓位金额",
    "Surrender": "终止",
    "Successfully surrender": "成功终止配套",
    "active": "已激活",
    "Account Type": "提款方式",
    "Select Account Type": "选择提款方式",
    "No account type available": "没类别可选",
    "Days": "天",
    "Processing Fee": "手续费",
    "Invalid withdraw amount": '提款数额无效',
    "Withdraw": "提款",
    "MT5 Account": "MT5账号",
    "Select MT5 Account": "选择MT5账号",
    "All MT5 Account": '全部MT5账号',
    "Account": "账号",
    "MT5 Account 1 Username": 'MT5账号 1',
    "MT5 Account 2 Username": "MT5账号 2",
    "MT5 Account 3 Username": "MT5账号 3",
    "MT5 Account 4 Username": "MT5账号 4",
    "MT5 Account 1 Password": 'MT5账号 1 密码',
    "MT5 Account 2 Password": 'MT5账号 2 密码',
    "MT5 Account 3 Password": 'MT5账号 3 密码',
    "MT5 Account 4 Password": 'MT5账号 4 密码',
    "Payment Amount": '付款金额',
    "Your Payment Amount": '您的付款数额',
    "Convert": "兑换",
    "Exchange Rate": "汇率 : ",
    "Your IP does not support the operation": "你的所在IP不支持操作",
    "to": "至",
    "inactive": "不活跃",
    "Account withdrawal type": "提款方式",
    "Select withdrawal type": "选择提款方式",
    "MT5 Login ID": "MT5账号",
    "completed": "完成",
    "Transfer1" : "划转",
    "Copy" : "复制",
    "Confirm" : "确认",
    "MT5 Login ID1" : "MT5登录",
    "Select Bank Country" : "选择银行所在国家",
    "Change Transaction Password" : "修改交易密码",
    "Login Password" : "登录密码",
    "Enter Login Password" : "请输入登录密码",
    "Confirm Login Password" : "确认登录密码",
    "Enter Verification Code" : "请输入邮箱验证码",
    "Enter Transaction Password" : "请输入交易密码",
    "Confirm login password and login password are not same" : "确认密码与登录密码不一致",
    "Assets Type": "资产类型",
    "Trend Trading Strtegies" : "趋势交易策略",
    "Trading Details" : "交易详情",
    "Prudent" : "稳健",
    "Short Trade" : "短线",
    "Runtime" : "运行天数",
    "Total Copiers" : "累计跟单人数",
    "AUM (USD)" : "跟单规模（USD）",
    "Profit/Loss (Month)" : "收益率（月）",
    "Minimum Copy Amount (USD)" : "最低跟单金额（USD）",
    "Follow Orders" : "跟单",
    "Support Resistance Strategies" : "支撑阻力交易策略",
    "Candlestick Patterns Strategies" : "蜡烛图形态交易策略",
    "Trend trading is very obvious, which is to seize the trend of the day and create profits as much as possible. The short-term trend trading strategy is very simple. If you see a foreign exchange currency pair rebounding three times on the same trend line, you can predict a strong trend and find the direction to trade in that trend." : "趋势交易很明显，就是抓住当天的趋势，然后尽可能的创造盈利。短线趋势交易策略很简单，如果看到外汇货币对在同一个趋势线回弹了3次，那么可以预测将出现较强趋势，并寻找在该趋势的方向进行交易。",
    "Win Trades" : "总盈利笔数",
    "Lose Trades" : "总亏损笔数",
    "Max Drawdown" : "最大回撤",
    "Trading Pairs" : "交易对",
    "Support/resistance trading is the best short-term trading method, with a focus on identifying key price points on the chart and then conducting breakthrough trading. The risk management of this strategy is also very direct, which is to set stop loss at key price points." : "支撑位／阻力位交易是最佳的短线交易方法，重点就是识别图表上的关键价位，然后进行突破交易。这种策略的风险管理也很直接，就是在关键价位上下设置止损。",
    "It is traded in the form of a candle chart, with time frames locked to 1-minute, 5-minute, and 15 minute charts. We need to find the inverted candle pattern on the chart as a signal to trade. Similarly, every stop loss is very important, and a good stop loss point is to reverse the candlestick on the opposite side of your trade." : "它是通过蜡烛图形态来交易，时间框我们锁定1分钟、5分钟和15分钟图。我们需要在图表上寻找反转蜡烛图形态，以此作为信号去交易。同样的，每一次止损都非常重要，好的止损点就是反转烛台与你交易相反的那一侧。",
    "Ranking1" : "晋级方式",
    "Wallet Deposit" : "存款",
    "Enter Verification Code1" : "请输入邮箱验证码",
    "Your Full Name1": "输入您的身份证姓名/护照姓名",
    "Enter New Login Password" : "请输入新登录密码",
    "New Login Password" : "新登录密码",
    "Confirm New Login Password" : "确认新登录密码",
    "MT5 PROFIT" : "MT5盈利",
    "In" : "转入",
    "Lot Rebate" : "点差返佣",
    "2.50 from test0@gmail.com" : "2.50 从 test0@gmail.com",
    "Hedged Position" : "对冲仓",
    "Start" : "开始",
    "End" : "截止",
    "Source from MT5 account 1" : "来源 MT5 账号 1",
    "Rebate User" : "返佣用户",
    "Details" : "详情",
    "Old Transaction Password" : "原交易密码",
    "Enter Old Transaction Password" : "输入原交易密码",
    "Coming Soon" : "即将推出",
    "Enter Total Commission" : "输入总佣金",
    "Active" : "未认证",
    "Pending Verify" : "审核中",
    "Rejected" : "已拒绝",
    "Verified" : "已认证",
    "Please Try Again": "请重试",
    "Not Verified": "未认证",
    "My MT5 Balance": "我的MT5余额",
    "Contract Commission": '合约佣金',
    "Select Transaction Type": "选择交易类型",
    "Bonus": '返佣',
    "Withdraw Method": "提款方式",
    "crypto": "加密货币",
    "fiat" : "法币",
    "Pending1" : "待审核",
    "Approved" : "已批准",
    "Rejected": "已拒绝",
    "Active1" : "活跃",
    "wallet_active": "成功",
    "wallet_pending": "待处理",
    "wallet_approved": "已批准",
    "wallet_rejected": "已拒绝",
    "WITHDRAW": "提款",
    "DEPOSIT_CRM": "钱包充值",
    "DEPOSIT_MT5": "MT5充值",
    "Request wallet withdraw": '钱包提款',
    "Submit Amount": '提款金额',
    "Fee": "手续费",
    "Receive Amount": "到账金额",
    "Doc No.": "订单号",
    "Result Per Page": "每页结果",
    "Contract Period": "合约期",
    "Select Contract Period": '选择合约期',
    "Topup": "加仓",
    "Topup Amount": '加仓金额',
    "Select Topup Amount": '选择加仓金额',
    "Enter Topup Amount": "输入加仓金额",
    "Extra Profit": '额外盈利',
    "Old password must at least 6 characters": "登录密码必须至少包含6个字符",
    "New password must at least 6 characters": "新登录密码必须至少包含6个字符",
    "Old transaction password must at least 6 characters": "交易密码必须至少包含6个字符",
    "The page you were looking for is not found!": "找不到您要查找的页面！",
    "Back To Login": "返回登录",
    "Select Bonus Type": "选择佣金类型",
    "Same Rank Bonus": "平级奖",
    "Early Bird Bonus": "早鸟收益",
    "VIP Bonus": "VIP收益",
    "Early Bird Bonus (Purchaser 1.25%)": "早鸟收益（购买者 1.25%）",
    "Early Bird Bonus (Referral 1.25%)": "早鸟收益（分享者 1.25%）",
    "TRANSFER_FROM_MT5": "MT5提款",
    "Withdraw MT5": "MT5提款",
    "Penalty Fee": "违约金",
    "Capital withdrawal only allowed on Saturdays and Sundays.": "本金提款只开放在星期六下午3点至星期日晚上11点59分。",
    "Notice": "通知",
    "Period Extra Profit": "合约期收益",
    "Password has reset successfully": "已重置新登录密码",
    "Adjust": "调整",
    "week": "每周",
    "month": "每月",
    "My Total Assets": "我的总资产",
    "Sales": "业绩",
    "More": "更多",
    "Login ID": "账号",
    "Let’s get you all set up so you can start your first onboarding experience !": '让我们为您完成所有设置，以便您可以开始您的首次入职体验！',
    "Log in to your account so you can continue building & editing your onboarding flows !": "登录到您的帐户，以便您可以继续构建和编辑您的入职流程！",
    "Posted at": "发布于",
    "Rank": "级别",
    "VIP Bonus (Purchaser 0.75%)": "VIP收益 （购买者 0.75%）",
    "VIP Bonus (Referral 0.75%)": "VIP收益 （分享者 0.75%）",
    "Hide Password": "隐藏密码",
    "Show Password": "显示密码",
    "January": "一月",
    "February": "二月",
    "March": "三月",
    "April": "四月",
    "May": "五月",
    "June": "六月",
    "July": "七月",
    "August": "八月",
    "September": "九月",
    "October": "十月",
    "November": "十一月",
    "December": "十二月",
    "Sun": "日",
    "Mon": "一",
    "Tue": "二",
    "Wed": "三",
    "Thu": "四",
    "Fri": "五",
    "Sat": "六",
    "Wallet Withdraw": "钱包提款",
    "Start Date": "开始日期",
    "Period Extra Profit": "合约期收益",
    "Early Bird": "早鸟配套",
    "VIP": "VIP配套",
    "Early Bird/VIP": "早鸟配套/VIP配套",
    "Package": "配套",
    "Total Earning Bonus": "总收益"  ,
    "Remaining Period": "剩余周期",
    "deleted": "已停止",
    "Txn Hash": "交易哈希",
    "Internal Transfer": "转账",
    "Wallet Balance": "钱包余额",
    "TRANSFER": "转账",
    "MT5 Account $index Username": "MT5账号 $index",
    "MT5 Account $index Password": "MT5账号 $index 密码",
    "Renew Contract": "续约",
    "New Contract Period": "新合约期",
    "Please note": "请注意",
    "The minimum deposit amount is 15 USDT. If the deposit is less than 15 USDT, System wallet will not be able to received funds or display the balance. Please make sure minimum deposit 15 USDT.": "最低充值金额为<span class='font-weight-600 text-red'>15 USDT</span>。如果充值金额低于<span class='font-weight-600 text-red'>15 USDT</span>，系统钱包将无法成功到账或显示余额，请务必按照最低<span class='font-weight-600 text-red'>15 USDT</span>进行充值。",
}

export default ZH_CN;