import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import "../../../css/auth3.scss";
import { translate } from '../../../utils/translate';

function Error404(props) {
    return (
        <>
            <div className='login min-vh-100'>
                <div className={`authincation d-flex flex-column flex-lg-row flex-column-fluid h-100  pt-lg-0`}>
                    <div className="w-100 min-vh-100 d-flex align-items-center justify-content-center">
                        <Container className='h-100'>
                            <Row className="h-100 align-items-center justify-content-center pt-2 mx-0 px-0">
                                <Col lg={10} md={12} sm={12} xs={12}>
                                    <Row className='px-0 mx-0 align-items-center justify-content-center'>
                                        <Col lg={10} md={6} sm={12} xs={12} className='mb-md-5 mb-3'>
                                            <div className='text-center'>
                                                <img src={require('../../../images/logo/logo-icon.png').default} alt="logo" className='logoSize' />
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className='px-3 py-5 p-md-5'>
                                        <div className="form-input-content text-center error-page">
                                            <h3 className="error-text font-weight-bold text-white">404</h3>
                                            <h5 className='text-white'>
                                                <i className="fa fa-exclamation-triangle text-white" />{" "}
                                                {translate(props.lang, "The page you were looking for is not found!")}
                                            </h5>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center mt-4'>
                                            <Link to="/login" className="primary-btn round px-5 py-2 text-center mx-auto">
                                                <span className={`btnText`}>{translate(props.lang, "Back To Login")}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    };
};
export default withRouter(connect(mapStateToProps)(Error404));
