import React, { useEffect, useState } from 'react'
import { Col, Row, Input } from 'reactstrap';
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import LoadingV3 from '../../components/Loading/LoadingV3';
import { translate } from '../../../utils/translate';
import { FaSpinner } from 'react-icons/fa';
import { connect, useDispatch } from 'react-redux';
import { api } from '../../../utils/api';
import swal from 'sweetalert';
import customSelectStyles from '../SelectStyle';
import { logout } from '../../../store/actions/AuthActions';

const Wallet = (props) => {
    const dispatch = useDispatch();
    let errorsObj = { transaction_password: '', package_id: '', lock_period_id: '', customer_mt5_id: '', type: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [loading, setLoading] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [transferModal, setTransferModal] = useState(false);
    const [productList, setProductList] = useState([]);
    const [lockPeriod, setLockPeriod] = useState([]);
    const [mt5Accounts, setMt5Accounts] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        transaction_password: '',
        package_id: '',
        lock_period_id: '',
        type: 'create',
        customer_mt5_id: '',
    });

    useEffect(() => {
        getMt5Account();
    }, [props.auth.auth.access_token, props.lang])

    useEffect(() => {
        fetch(api.get_lock_period, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setLockPeriod(responseJson.data);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log(translate(props.lang, "Dashboard"), responseJson.message);
                }
            }).catch(error => {
                console.error("error", error);
            });
    }, [props.auth.auth.access_token, props.lang])

    useEffect(() => {
        fetch(api.get_package, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setProductList(responseJson.data);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log(translate(props.lang, "Dashboard"), responseJson.message);
                }
            }).catch(error => {
                console.error("error", error);
            });
    }, [props.auth.auth.access_token, props.lang])

    const getMt5Account = () => {
        fetch(api.get_profile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setMt5Accounts(responseJson.data.mt5_accounts);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log(translate(props.lang, "Dashboard"), responseJson.message);
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const closeTransferModal = () => {
        setFormData({
            transaction_password: '',
            package_id: '',
            lock_period_id: '',
            type: 'create',
            customer_mt5_id: '',
        });
        setErrors(errorsObj)
        setTransferModal(false)
    }

    const changeTab = (type) => {
        let newFormData = {
            transaction_password: '',
            package_id: '',
            lock_period_id: '',
            type: type,
            customer_mt5_id: '',
        };
        setErrors(errorsObj)
        setFormData(newFormData);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        let newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData)
    }

    const submitForm = () => {
        let check = true;
        const errorMsg = { ...errorsObj };
        let newFormData = { ...formData }
        if (!formData.package_id) {
            errorMsg.package_id = 'This field is required.';
            check = false;
        }

        if (!formData.transaction_password) {
            errorMsg.transaction_password = 'This field is required.';
            check = false;
        }
        if (!formData.lock_period_id) {
            errorMsg.lock_period_id = 'This field is required.';
            check = false;
        }
        if (formData.type === 'topup') {
            if (!formData.customer_mt5_id) {
                errorMsg.customer_mt5_id = 'This field is required.';
                check = false;
            }
        }

        setErrors(errorMsg);
        if (check) {
            // if (newFormData.type === 'create') {
            //     delete newFormData.customer_mt5_id;
            // }
            // setLoading(true)
            // fetch(api.transfer, {
            //     method: "POST",
            //     headers: new Headers({
            //         "Content-Type": "application/json",
            //         "Accept-Language": props.lang,
            //         "Authorization": "Bearer " + props.auth.auth.access_token
            //     }),
            //     body: JSON.stringify(newFormData)
            // })
            //     .then(response => response.json())
            //     .then(responseJson => {
            //         setLoading(false);
            //         if (responseJson.status === "success") {
            //             props.setRefresh(true);
            //             getMt5Account();
            //             setTransferModal(false);
            //             setSuccessModal(true);
            //         } else {
            //             if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
            //                 dispatch(logout(props.history, 'login'));
            //             }
            //             swal(translate(props.lang, "Error"), responseJson.message, "error");
            //         }
            //     }).catch(error => {
            //         console.error("error", error);
            //     });
        }
    }

    const closeSuccessModal = () => {
        setFormData({
            transaction_password: '',
            package_id: '',
            lock_period_id: '',
            type: 'create',
            customer_mt5_id: '',
        })
        setErrors(errorsObj)
        setSuccessModal(false);
    }

    const getOptionLabel = (option) => (
        option.value ? (
            <div className="d-flex w-100 align-items-center">
                <div className='text-end pe-1' style={{ minWidth: props.lang.toLowerCase() === 'en' ? '60px' : '45px' }}>
                    <span className="text-capitalize">{option.label}</span>
                </div>
                <div>
                    <span className="text-capitalize">({translate(props.lang, "Extra Profit")} {option.commission_rate}%)</span>
                </div>
            </div>
        ) : (
            <div className="text-center">
                <span className="mt-2 text-capitalize">{option.label}</span>
            </div>
        )
    );


    return (
        <>
            <div className="tableBgContainer h-100">
                <div className='p-3'>
                    <Row className='align-items-center mx-0 px-0'>
                        <Col lg={8} md={8} sm={8} xs={8} className='px-0'>
                            <div>
                                <img src={require('../../../images/icon2/wallet.svg').default} className="img-fluid iconSize" alt="wallet-balance" />
                                <span className="font-weight-600 font-22 text-white ms-3 ms-md-4">{translate(props.lang, "Wallet Total Balance")}</span>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={4} xs={4} className='pe-0'>
                            <div className='text-end cursor-pointer' onClick={() => setTransferModal(true)}>
                                <div className="transferBox py-2 px-2 text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <span className="text-black font-weight-500 font-16">{translate(props.lang, "Transfer")}</span>
                                        <img src={require('../../../images/icon2/arrow-black.svg').default} alt="icon-wallet" className="ms-1 img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='w-100 mt-3 borderLine'></div>
                    <div className='w-100 mt-3'>
                        {props.loading ? (
                            <LoadingV3 theme='light' />
                        ) :
                            <>
                                <div className="w-100 align-items-center">
                                    <span className="font-weight-600 font-32 text-white">{props.boxCurrency ? props.boxCurrency : ''}&nbsp;</span>
                                </div>
                                {/* <div className="text-start w-100 align-items-center mt-1">
                                    <span className="font-weight-600 font-24 text-white">{props.walletBalance}</span>
                                </div> */}
                                <div className="text-end w-100 align-items-center">
                                    <span className="font-weight-600 font-40 text-white">{props.walletBalance}</span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Modal className="fade modalColorBg" show={transferModal} centered >
                <Modal.Header className="border-0 pt-4 pb-2 px-4 px-md-5">
                    <div className="modalHeader d-flex w-100 align-items-center">
                        <div className="w-75">
                        </div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeTransferModal()} >
                            <img src={require('../../../images/icon2/modal-close.svg').default} alt="close" />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-4 px-md-5">
                    <div className='bgWhite'>
                        <div className="tab-container d-flex w-100 justify-content-start mb-3">
                            <div className={`tab-item w-50 h-100 text-center px-4 py-3 buy ${formData.type === 'create' ? 'active' : ""}`} onClick={() => changeTab('create')}>
                                <span className="font-weight-600 font-16 text-capitalize">{translate(props.lang, "Transfer")}</span>
                            </div>
                            <div className={`tab-item w-50 h-100 text-center px-4 py-3 sell ${formData.type === 'topup' ? 'active' : ""}`} onClick={() => changeTab('topup')}>
                                <span className="font-weight-600 font-16 text-capitalize">{translate(props.lang, "Topup")}</span>
                            </div>
                        </div>
                        <div className="mb-2 ms-2">
                            <div className='d-flex w-100 align-items-center justify-content-between'>
                                <span className="font-weight-500 font-18 op8 line-height-26 text-white d-block">{translate(props.lang, "From")}</span>
                                <span className="font-weight-700 font-20  line-height-30 text-white d-block">{translate(props.lang, "Wallet")}</span>
                            </div>
                        </div>
                        <div className="mb-2 ms-2">
                            <div className='d-flex w-100 align-items-center justify-content-between'>
                                <span className="font-weight-500 font-18 op8 line-height-26 text-white d-block">{translate(props.lang, "To")}</span>
                                <span className="font-weight-700 font-20  line-height-30 text-white d-block">{translate(props.lang, "MT5 Balance")}</span>
                            </div>
                        </div>
                        {formData.type === 'create' ? (
                            <>
                                <div className="mb-3">
                                    <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1 ${errors.package_id ? 'errorBorder' : ''}`}>
                                        <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, 'Investment Package')} <span className="errorTextRed">*</span></span>
                                        <div className={`filter-input-group`}>
                                            <Select
                                                options={
                                                    productList.length > 0 ? productList.map((item, i) => ({ key: i, value: item.package_id, label: item.is_without_hedge ? item.mt5_total.toLocaleString('en-US') : item.total.toLocaleString('en-US') })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                                }
                                                placeholder={translate(props.lang, 'Select Your Package')}
                                                className="input-transparent w-100 text-capitalize px-0"
                                                styles={customSelectStyles}
                                                isSearchable={false}
                                                value={
                                                    formData.package_id ? productList.filter(item => item.package_id === formData.package_id).map((item) => ({ value: item.package_id, label: item.is_without_hedge ? item.mt5_total.toLocaleString('en-US') : item.total.toLocaleString('en-US') })) : ''
                                                }
                                                name="package_id"
                                                onChange={(e) => handleOptionSelected(e, 'package_id')}
                                            />
                                        </div>
                                    </div>
                                    <div className='ms-3 ms-md-4'>
                                        <span className={`font-weight-400 font-14 text-white d-block`}>{translate(props.lang, "Balance Available:")} {props.walletBalance}</span>
                                        <span className={`font-weight-400 font-14 errorTextRed ${errors.package_id ? 'mt-2' : ''}`}>{translate(props.lang, errors.package_id)}</span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className={`groupLabel-input py-2 mb-1`}>
                                        <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Monthly Return")}</span>
                                        <div className={`input-group`}>
                                            <Input
                                                type={'text'}
                                                className="form-control input-transparent py-1 px-md-4 px-3 text-lowercase"
                                                value={formData.package_id ? productList.filter(item => item.package_id === formData.package_id).length > 0 ? productList.filter(item => item.package_id === formData.package_id)[0].low_roi + `% ${translate(props.lang, "To")} ` + productList.filter(item => item.package_id === formData.package_id)[0].high_roi + "%" : '-' : '-'}
                                                autoComplete="off"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="mb-3">
                                    <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1 ${errors.package_id ? 'errorBorder' : ''}`}>
                                        <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, 'MT5 Account')} <span className="errorTextRed">*</span></span>
                                        <div className={`filter-input-group`}>
                                            <Select
                                                options={
                                                    mt5Accounts.length > 0 ? mt5Accounts.map((item, i) => ({ key: i, value: item.customer_mt5_id, label: `${translate(props.lang, "MT5 Account")} ${item.mt5_group_id} - ${item.mt5_login_id} ( ${translate(props.lang, "MT5 Balance")} : ${Number(item.mt5_balance).toLocaleString()} USD )` })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                                }
                                                placeholder={translate(props.lang, 'Select MT5 Account')}
                                                className="input-transparent w-100 text-capitalize px-0"
                                                styles={customSelectStyles}
                                                isSearchable={false}
                                                value={
                                                    formData.customer_mt5_id ? mt5Accounts.filter(item => item.customer_mt5_id === formData.customer_mt5_id).map((item) => ({ value: item.customer_mt5_id, label: `${translate(props.lang, "MT5 Account")} - ${item.mt5_login_id} ${item.mt5_group_id} ( ${translate(props.lang, "MT5 Balance")} : ${Number(item.mt5_balance).toLocaleString()} USD )` })) : ''
                                                }
                                                name="customer_mt5_id"
                                                onChange={(e) => handleOptionSelected(e, 'customer_mt5_id')}
                                            />
                                        </div>
                                    </div>
                                    <div className='ms-3 ms-md-4'>
                                        <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.package_id)}</span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1 ${errors.package_id ? 'errorBorder' : ''}`}>
                                        <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, 'Topup Amount')} <span className="errorTextRed">*</span></span>
                                        <div className={`filter-input-group`}>
                                            <Select
                                                options={
                                                    productList.map((item, i) => ({ key: i, value: item.package_id, label: item.is_without_hedge ? item.mt5_total.toLocaleString('en-US') : item.total.toLocaleString('en-US') }))
                                                }
                                                placeholder={translate(props.lang, 'Select Topup Amount')}
                                                className="input-transparent w-100 px-0"
                                                styles={customSelectStyles}
                                                isSearchable={false}
                                                value={
                                                    formData.package_id ? productList.filter(item => item.package_id === formData.package_id).map((item) => ({ value: item.package_id, label: item.is_without_hedge ? item.mt5_total.toLocaleString('en-US') : item.total.toLocaleString('en-US') })) : ''
                                                }
                                                name="package_id"
                                                onChange={(e) => handleOptionSelected(e, 'package_id')}
                                            />
                                        </div>
                                    </div>
                                    <div className='ms-3 ms-md-4'>
                                        <span className={`font-weight-400 font-14 text-white d-block`}>{translate(props.lang, "Balance Available:")} {props.walletBalance}</span>
                                        <span className="font-weight-400 font-14 errorTextRed mt-2">{translate(props.lang, errors.package_id)}</span>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="mb-3">
                            <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1 ${errors.lock_period_id ? 'errorBorder' : ''}`}>
                                <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, 'Contract Period')} <span className="errorTextRed">*</span></span>
                                <div className={`filter-input-group`}>
                                    <Select
                                        options={
                                            lockPeriod.length > 0 ? lockPeriod.map((item, i) => ({ key: i, commission_rate: item.commission_rate, value: item.lock_period_id, label: item.lock_days + " " + translate(props.lang, "Days") })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                        }
                                        placeholder={translate(props.lang, 'Select Contract Period')}
                                        className="input-transparent w-100 px-0"
                                        styles={customSelectStyles}
                                        isSearchable={false}
                                        getOptionLabel={getOptionLabel}
                                        value={
                                            formData.lock_period_id ? lockPeriod.filter(item => item.lock_period_id === formData.lock_period_id).map((item) => ({ commission_rate: item.commission_rate, value: item.lock_period_id, label: item.lock_days + " " + translate(props.lang, "Days") })) : ''
                                        }
                                        name="lock_period_id"
                                        onChange={(e) => handleOptionSelected(e, 'lock_period_id')}
                                    />
                                </div>
                            </div>
                            <div className='ms-3 ms-md-4'>
                                <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.lock_period_id)}</span>
                            </div>
                        </div>

                        <div className="mb-2">
                            <div className={`groupLabel-input py-2 mb-1 ${errors.transaction_password ? 'errorBorder' : ''}`}>
                                <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Transaction Password")} <span className='text-red'>*</span></span>
                                <div className={`input-group`}>
                                    <Input
                                        type={showPassword ? "text" : "password"}
                                        name="transaction_password"
                                        placeholder={translate(props.lang, "Enter Transaction Password")}
                                        className="form-control input-transparent py-1 px-md-4 px-3"
                                        value={formData.transaction_password}
                                        autoComplete='new-password'
                                        aria-autocomplete='none'
                                        id="new-password"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                    <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? (
                                            <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                        ) : (
                                            <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='ms-3 ms-md-4'>
                                <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.transaction_password)}</span>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <button className="primary-btn px-4 py-2 text-center w-100" disabled={loading} onClick={() => submitForm()}>
                                <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Confirm")}</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* successModal  */}
            <Modal className="fade modalColorBg" show={successModal} centered>
                <Modal.Body className="border-0 px-4 py-5">
                    <Row className="justify-content-center align-items-center mx-0 px-0">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <div className="text-center mb-4">
                                <img src={require('../../../images/icon2/transferSuccess.svg').default} className='img-fluid' alt="success" />
                            </div>
                            <div className="text-center mb-3">
                                <span className="font-weight-600 font-30 text-white line-height-24 d-block mt-3 text-capitalize">{translate(props.lang, "Successful")}</span>
                            </div>
                            <div className="mb-3 text-center">
                                <span className='d-block mt-2 font-30 font-weight-600 text-capitalize text-white'>
                                    {formData.package_id ? productList.filter(item => item.package_id === formData.package_id).length > 0 ? 
                                        productList.filter(item => item.package_id === formData.package_id)[0].is_without_hedge ? productList.filter(item => item.package_id === formData.package_id)[0].mt5_total.toLocaleString('en-US') : productList.filter(item => item.package_id === formData.package_id)[0].total.toLocaleString('en-US') : '-' : '-'}</span>
                            </div>
                            <div className="mb-3">
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <span className="font-weight-400 font-16 op6 line-height-26 text-white d-block">{translate(props.lang, "From")}</span>
                                    <span className="font-weight-600 font-18  line-height-30 text-white d-block">{translate(props.lang, "Wallet")}</span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <span className="font-weight-400 font-16 op6 line-height-26 text-white d-block">{translate(props.lang, "To")}</span>
                                    <span className="font-weight-600 font-18  line-height-30 text-white d-block">{translate(props.lang, "MT5 Balance")}</span>
                                </div>
                            </div>
                            <div className="mt-3">
                                <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => closeSuccessModal()}>
                                    <span className={`btnText`}>{translate(props.lang, "Close")}</span>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Wallet);