import { connect } from "react-redux";
import { translate } from "../../../utils/translate";

const Error404 = (props) => {
    return (
        <div className='pt-4 pt-lg-0 pt-md-0'>
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center ">
                    <div className="col-md-5">
                        <div className="form-input-content text-center error-page">
                            <h3 className="error-text font-weight-bold text-white">404</h3>
                            <h5 className="text-white">
                                <i className="fa fa-exclamation-triangle" />{" "}
                                {translate(props.lang, "The page you were looking for is not found!")}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Error404);
