import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/settings.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import customSelectStyles from "../SelectStyle";
import countries from "../../../utils/countries";
import { regexDecimalPoint, restrictTransferEmail, withdrawDecimalPoint, withdrawNetwork } from "../../../utils/config";

const Transfer = (props) => {
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showContent, setShowContent] = useState(false);

    const [walletInfo, setWalletInfo] = useState({
        wallet_balance: 0,
    });
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: '',
        transaction_password: '',
        amount: '',
    });
    let errorsObj = {
        email: '',
        transaction_password: '',
        amount: '',
    };
    const [errors, setErrors] = useState(errorsObj);
    useEffect(() => {
        getWalletBalance()
        return () => { };
    }, [props.auth.auth.access_token]);

    useEffect(()=>{
        if(restrictTransferEmail.includes(props.auth.profile.email)){
            setShowContent(false); 
            window.location.href = "/dashboard";
        } else {
            setShowContent(true); 
        }
    },[])

    const getWalletBalance = () => {
        fetch(api.get_wallet_balance, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setWalletInfo(responseJson.data)
                } else {
                    if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log(translate(props.lang, "Error"), responseJson.message);
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        if (fieldName === 'amount') {
            if (fieldValue) {
                if (fieldValue !== "-") {
                    if (regexDecimalPoint.test(fieldValue)) {
                        newFormData[fieldName] = fieldValue;
                    }
                }
            } else {
                newFormData[fieldName] = '';
            }
        } else {
            newFormData[fieldName] = fieldValue;
        }
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        var onlyNumber = /^\d+(\.\d+)?$/;
        let newFormData = { ...formData };
        if (!formData.amount) {
            errorMsg.amount = "This field is required.";
            check = false
        } else {
            if (!onlyNumber.test(formData.amount)) {
                errorMsg.amount = 'Only Numbers are allowed in this field';
                check = false;
            } else {
                if (formData.amount <= 0) {
                    errorMsg.amount = "Amount must greater than 0.";
                    check = false
                } else if (formData.amount > Number(walletInfo.wallet_balance)) {
                    errorMsg.amount = "Insufficient balance";
                    check = false
                } else {
                    newFormData.amount = Number(formData.amount)
                }
            }
        }
        if (!formData.email) {
            errorMsg.email = "This field is required.";
            check = false
        }

        if (!formData.transaction_password) {
            errorMsg.transaction_password = "This field is required.";
            check = false
        }
        setErrors(errorMsg);
        if (check) {
            // setLoading(true);
           
            // fetch(api.transfer_fund, {
            //     method: "POST",
            //     headers: new Headers({
            //         "Content-Type": "application/json",
            //         "Accept-Language": props.lang.toLowerCase(),
            //         "Authorization": "Bearer " + props.auth.auth.access_token
            //     }),
            //     body: JSON.stringify(newFormData)
            // })
            //     .then(response => response.json())
            //     .then(responseJson => {
            //         setLoading(false);
            //         if (responseJson.status === "success") {
            //             swal(translate(props.lang, "Success"), responseJson.message, "success");
            //             setFormData({ ...errorsObj })
            //             history.push('/wallet/transaction-history');
            //         } else {
            //             if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
            //                 dispatch(logout(props.history, 'login'));
            //             }
            //             swal(translate(props.lang, "Error"), responseJson.message, "error");
            //         }
            //     }).catch(error => {
            //         console.error("error", error);
            //     });
        }
    }

    return (
        <>
            {showContent ? (
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0">
                    <Col lg={12} md={12} sm={12} xs={12} className="mt-3 px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="px-0 mb-3">
                                    <Row className="justify-content-center align-items-center mx-0 px-0 mb-2">
                                        <Col lg={6} md={6} xs={12} sm={12} className="text-md-start text-center mb-3 mb-md-0">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img src={require('../../../images/icon2/withdrawal.svg').default} className="balanceIcon" alt="icon-wallet" />
                                                </div>
                                                <div className="ps-3">
                                                    <div className="text-center text-md-start">
                                                        <span className="font-20 font-weight-400 text-white">{translate(props.lang, "Wallet Balance")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} xs={12} sm={12}>
                                            <div className="balanceBox d-flex w-100 justify-content-between px-3 px-lg-4 py-3">
                                                <div>
                                                    <span className="font-24 text-white font-weight-500 line-height-18 break-all-word break-white-space">USD</span>
                                                </div>
                                                <div>
                                                    <span className="font-24 text-white font-weight-500 line-height-18 break-all-word break-white-space">{Number(walletInfo.wallet_balance).toLocaleString('en-US', digitFormat)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                    <Row className="justify-content-center align-items-center mx-0 px-0">
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                            <div className="text-start">
                                                <div className={`groupLabel-input py-2 mb-1 ${errors.email ? 'errorBorder' : ''}`}>
                                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Address")}<span className="errorTextRed">*</span></span>
                                                    <div className={`input-group`}>
                                                        <Input
                                                            type={'text'}
                                                            name="email"
                                                            placeholder={translate(props.lang, "Enter Email")}
                                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                                            value={formData.email}
                                                            autoComplete="off"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.email)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                            <div className="text-start">
                                                <div className={`groupLabel-input py-2 mb-1 ${errors.amount ? 'errorBorder' : ''}`}>
                                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Transfer Amount")} <span className="errorTextRed">*</span></span>
                                                    <div className={`input-group`}>
                                                        <Input
                                                            type='text'
                                                            onWheel={event => event.currentTarget.blur()}
                                                            name="amount"
                                                            placeholder={translate(props.lang, "Your Transfer Amount")}
                                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                                            value={formData.amount}
                                                            autoComplete="off"
                                                            autoCorrect={'off'}
                                                            autoSave='off'
                                                            aria-autocomplete='off'
                                                            spellCheck={"false"}
                                                            id="transfer_amount"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.amount)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                            <div className="text-start">
                                                <div className={`groupLabel-input py-2 mb-1 ${errors.transaction_password ? 'errorBorder' : ''}`}>
                                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Transaction Password")} <span className="errorTextRed">*</span></span>
                                                    <div className={`input-group`}>
                                                        <Input
                                                            type={showPassword ? "text" : "password"}
                                                            name="transaction_password"
                                                            placeholder={translate(props.lang, "Your Transaction Password")}
                                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                                            value={formData.transaction_password}
                                                            autoComplete="new-password"
                                                            aria-autocomplete="none"
                                                            id="transaction_password"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                        <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword ? (
                                                                <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                                            ) : (
                                                                <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.transaction_password)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} xs={12} sm={12}>
                                            <button className="primary-btn px-4 py-2 text-center mx-auto w-100 mt-4" disabled={loading} onClick={() => submitForm()}>
                                                <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Submit")}</span>
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            ) : null}
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Transfer);
