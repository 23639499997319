//kyc_status - active, kyc_status - approved/rejected/pending/''
//status - verified

import { connect, useDispatch } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import Select from "react-select";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { FaSpinner } from "react-icons/fa";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import imageCompression from 'browser-image-compression';
import customSelectStyles from "../SelectStyle";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
import { logout } from "../../../store/actions/AuthActions";
import "../../../css/settings.scss";
import countries from "../../../utils/countries";
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import zhTWLocale from 'date-fns/locale/zh-TW';

const localeMap = {
    en: enLocale,
    zh_cn: zhCNLocale,
    zh_tw: zhTWLocale,
};

const buttonTextMap = {
    en: { clear: 'Clear', cancel: 'Cancel', ok: 'OK' },
    zh_cn: { clear: '清除', cancel: '取消', ok: '确定' },
    zh_tw: { clear: '清除', cancel: '取消', ok: '確定' },
};

const KYC = (props) => {
    const dispatch = useDispatch();
    let errorsObj = {
        name: '',
        gender: '',
        phoneNo: '',
        identityNo: '',
        address: '',
        country: '',
        front: '',
        back: '',
        holdingIC: '',
        dateOfBirth: '',
    };
    const [errors, setErrors] = useState(errorsObj);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [countryOption, setCountryOption] = useState([]);
    const [locale, setLocale] = useState('en');
    const [img, setImg] = useState({
        front: null,
        back: null,
        holdingIC: null
    });
    const [imgPreview, setImagePreview] = useState({
        front: null,
        back: null,
        holdingIC: null
    });
    const [formData, setFormData] = useState({
        name: '',
        gender: '',
        phoneNo: '',
        identityNo: '',
        address: '',
        country: '',
        front: '',
        back: '',
        holdingIC: '',
        dateOfBirth: null,
    })
    const [kycInfo, setKycInfo] = useState({
        status: '',
        referral_code: '',
        joiningDate: '',
        remark: '',
        kyc_status: ''
    });

    useEffect(() => {
        getKyc();
    }, [props.auth.auth.access_token]);

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    const getKyc = () => {
        setLoadingData(true);
        fetch(api.get_profile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(async responseJson => {
                setLoadingData(false);
                if (responseJson.status === "success") {
                    if (responseJson.data) {
                        setKycInfo({ ...responseJson.data })
                        let newFormData = { ...formData };
                        newFormData.name = responseJson.data.full_name;
                        newFormData.identityNo = responseJson.data.id_number;
                        newFormData.country = responseJson.data.country;
                        newFormData.address = responseJson.data.address;
                        newFormData.dateOfBirth = responseJson.data.date_of_birth ? responseJson.data.date_of_birth : '';
                        newFormData.phoneNo = responseJson.data.telephone;
                        if (responseJson.data.kyc_status !== "Rejected") {
                            newFormData.front = responseJson.data.attachment.front_ic_value;
                            newFormData.back = responseJson.data.attachment.back_ic_value;
                            newFormData.holdingIC = responseJson.data.attachment.holding_attachment_value;

                            let newImgPreview = { ...imgPreview };
                            newImgPreview.front = responseJson.data.attachment.front_ic;
                            newImgPreview.back = responseJson.data.attachment.back_ic;
                            newImgPreview.holdingIC = responseJson.data.attachment.holding_attachment;
                            setImagePreview(newImgPreview);

                        }
                        setFormData({ ...newFormData });
                    }
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        var regPhone = /^[\d -]+$/;

        if (!formData.name) {
            errorMsg.name = 'This field is required.';
            check = false;
        }

        if (!formData.identityNo) {
            errorMsg.identityNo = 'This field is required.';
            check = false;
        }

        if (!formData.dateOfBirth) {
            errorMsg.dateOfBirth = 'This field is required.';
            check = false;
        }

        if (!formData.phoneNo) {
            errorMsg.phoneNo = 'This field is required.';
            check = false;
        } else {
            if (!regPhone.test(formData.phoneNo)) {
                errorMsg.phoneNo = 'Only Numbers are allowed in this field';
                check = false;
            }
        }

        if (!formData.country) {
            errorMsg.country = 'This field is required.';
            check = false;
        }

        if (!formData.address) {
            errorMsg.address = 'This field is required.';
            check = false;
        }
        if (!img.front) {
            if (!formData.front) {
                errorMsg.front = "This field is required.";
                check = false
            }
        }
        if (!img.back) {
            if (!formData.back) {
                errorMsg.back = "This field is required.";
                check = false
            }
        }
        if (!img.holdingIC) {
            if (!formData.holdingIC) {
                errorMsg.holdingIC = "This field is required.";
                check = false
            }
        }
        setErrors(errorMsg)
        if (check) {
            setLoading(true);
            let newFormData = { ...formData };
            newFormData = {
                full_name: formData.name,
                telephone: formData.phoneNo,
                id_number: formData.identityNo,
                address: formData.address,
                country: formData.country,
                front_ic: formData.front,
                back_ic: formData.back,
                holding_attachment: formData.holdingIC,
                date_of_birth: formData.dateOfBirth,
            }
            if (!img.front && !img.back && !img.holdingIC) {
                proceedUpdateKYC(newFormData);
            } else {
                let uploadPromises = [];
                let popUpMsg = [];
                Object.keys(img).map((item) => {
                    if (img[item]) {
                        let uploadPromise = new Promise((resolve, reject) => {

                            let form = new FormData();
                            form.append("attachment", img[item]);
                            let url = "";
                            if (item === 'front') {
                                url = api.upload + '/upload_front_ic';
                            } else if (item === 'back') {
                                url = api.upload + '/upload_back_ic';
                            } else {
                                url = api.upload + '/upload_holding_attachment';
                            }
                            fetch(url, {
                                method: 'POST',
                                headers: {
                                    "Accept-Language": props.lang.toLowerCase(),
                                    "Authorization": "Bearer " + props.auth.auth.access_token
                                },
                                body: form,
                            }).then(response => response.json())
                                .then(responseJson => {
                                    if (responseJson.status === 'success') {
                                        resolve('done')
                                        if (item === 'front') {
                                            newFormData.front_ic = responseJson.data.file_path;
                                        } else if (item === 'back') {
                                            newFormData.back_ic = responseJson.data.file_path;
                                        } else {
                                            newFormData.holding_attachment = responseJson.data.file_path;
                                        }
                                    } else {
                                        reject('reject');
                                        popUpMsg.push(item + ' upload fail.');
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                });

                        })
                        uploadPromises.push(uploadPromise);
                    }
                })
                Promise.all(uploadPromises).then(() => {
                    proceedUpdateKYC(newFormData)
                }, (error) => {
                    swal(translate(props.lang, "Error"), popUpMsg.join('\n'), "error");
                })
            }
        }
    }

    const proceedUpdateKYC = (data) => {
        setLoading(true);
        fetch(api.submit_kyc, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if (responseJson.status === "success") {
                    swal(translate(props.lang, "Success"), responseJson.message, { icon: "success" });
                    getKyc();
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    if (responseJson.data && typeof responseJson.data.error === 'object') {
                        let apiErrorMsg = { ...errorsObj };
                        let popupMessage = [];
                        Object.keys(responseJson.data.error).map((key) => {
                            apiErrorMsg[key] = responseJson.data.error[key].join(',');
                            popupMessage.push(responseJson.data.error[key].join('\n'))
                        })
                        setErrors(apiErrorMsg);
                        swal(translate(props.lang, "Error"), popupMessage.join('\n'), "error");
                    } else {
                        swal(translate(props.lang, "Error"), responseJson.message, "error");
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const uploadImage = async (e, type) => {
        if (e.target.files[0]) {
            let size = (e.target.files[0].size / 1024 / 1024).toFixed(2);
            if (size < 1) {
                const newFormData = { ...img };
                const newImg = { ...imgPreview };
                newFormData[type] = e.target.files[0];
                newImg[type] = URL.createObjectURL(e.target.files[0]);
                setImg(newFormData);
                setImagePreview(newImg);
            } else {
                const options = {
                    maxSizeMB: 1,
                    useWebWorker: true,
                    maxWidthOrHeight: 1024,
                }
                try {
                    const compressedFile = await imageCompression(e.target.files[0], options);
                    var file = new File([compressedFile], compressedFile.name, { lastModified: Math.floor(Date.now() / 1000) });
                    const newFormData = { ...img };
                    const newImg = { ...imgPreview };
                    newFormData[type] = file;
                    newImg[type] = URL.createObjectURL(file);
                    setImg(newFormData);
                    setImagePreview(newImg);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    const handleDateChange = (e, type) => {
        const newFormData = { ...formData };
        newFormData[type] = moment(e).format('YYYY-MM-DD');
        setFormData(newFormData);
    }

    return (
        <>
            <div className='settings pb-5'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-4">
                            {/* <div className={`whiteContainer ${errors.country || errors.contact ? 'py-5' : 'pt-5 pb-4'}`}> */}
                            <Row className="align-items-center justify-content-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-2 mb-1 ${errors.name ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Full Name")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="name"
                                                    placeholder={translate(props.lang, "Your Full Name1")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.name}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                    disabled={kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? false : true : true}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.name)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-2 mb-1 ${errors.identityNo ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "IC / passport no.")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="identityNo"
                                                    placeholder={translate(props.lang, "Your IC / Passport No.")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.identityNo}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                    disabled={kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? false : true : true}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.identityNo)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-2 mb-1 ${errors.dateOfBirth ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Date of Birth")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                                    <DatePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="yyyy-MM-dd"
                                                        disableFuture
                                                        className="form-control input-transparent py-1 px-md-4 px-3 date text-black"
                                                        placeholder={translate(props.lang, "Your Date of Birth")}
                                                        value={formData.dateOfBirth ? new Date(formData.dateOfBirth) : null}
                                                        onChange={(date) => handleDateChange(date, 'dateOfBirth')}
                                                        disabled={kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? false : true : true}
                                                        clearLabel={buttonTextMap[locale].clear}
                                                        cancelLabel={buttonTextMap[locale].cancel}
                                                        okLabel={buttonTextMap[locale].ok}
                                                        views={['year', 'month', 'date']}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.dateOfBirth)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1 ${errors.country ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Country")} <span className='text-red'>*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={
                                                        countries.length > 0 ? countries.map((item, i) => ({ value: item.name, label: props.lang === "en" ? item.name : props.lang === "zh_cn" ? item.chinese_simplified : item.chinese_traditional })) : [{ value: '', label: translate(props.lang, "No countries available") }]
                                                    }
                                                    placeholder={translate(props.lang, "Select Country")}
                                                    className="input-transparent w-100 text-capitalize px-0"
                                                    styles={customSelectStyles}
                                                    value={
                                                        countries.filter(item => item.name == formData.country).length > 0 ?
                                                            (countries.filter(item => item.name == formData.country).map((item, i) => ({ value: item.name, label: props.lang === "en" ? item.name : props.lang === "zh_cn" ? item.chinese_simplified : item.chinese_traditional })))
                                                            : null
                                                    }
                                                    name="country"
                                                    menuPortalTarget={document.body}
                                                    onChange={(e) => handleOptionSelected(e, 'country')}
                                                    isDisabled={kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? false : true : true}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.country)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-2 mb-1 ${errors.phoneNo ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Phone Number")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="phoneNo"
                                                    placeholder={translate(props.lang, "Your phone number")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.phoneNo}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                    disabled={kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? false : true : true}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.phoneNo)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-2 ${errors.address ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Address")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="address"
                                                    placeholder={translate(props.lang, "Your address")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.address}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                    disabled={kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? false : true : true}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.address)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={4} md={12} sm={12} xs={12} className="mb-3 mb-lg-0">
                                    <div className="w-100 py-4 kycBox">
                                        <Row className="px-0 mx-0">
                                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2 mb-md-0 text-md-start text-center">
                                                <span className="font-weight-500 font-20 line-height-18 text-white d-block text-capitalize">{translate(props.lang, "Joining Date")}</span>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="text-md-end text-center">
                                                <span className="font-weight-700 font-20 line-height-18 text-white d-block">{kycInfo ? kycInfo.date_added ? kycInfo.date_added : "-" : "-"}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={4} md={12} sm={12} xs={12} className="mb-3 mb-lg-0">
                                    <div className="w-100 py-4 kycBox">
                                        <Row className="px-0 mx-0">
                                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2 mb-md-0 text-md-start text-center">
                                                <span className="font-weight-500 font-20 line-height-18 text-white d-block text-capitalize">{translate(props.lang, "Referral Code")}</span>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="text-md-end text-center">
                                                <span className="font-weight-700 font-20 line-height-18 text-white d-block">{kycInfo ? kycInfo.referral_code ? kycInfo.referral_code : '-' : "-"}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={4} md={12} sm={12} xs={12} className="">
                                    <div className="w-100 py-4 kycBox">
                                        <Row className="px-0 mx-0 align-items-center">
                                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2 mb-md-0 text-md-start text-center">
                                                <span className="font-weight-500 font-20 line-height-18 text-white d-block text-capitalize">{translate(props.lang, "KYC Status")}</span>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="text-md-end text-center">
                                                {/* <span className={`font-weight-700 font-20 line-height-18 text-white d-block status ${kycInfo && kycInfo.kyc_status.toLowerCase()} ${kycInfo && kycInfo.status.toLowerCase() === 'active' ? 'unverified' : kycInfo.status.toLowerCase()}`}>
                                                    <svg width="10" height="10" className="me-2" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="10" height="10" rx="5" fill={kycInfo.kyc_status === "Approved" ? '#25C6C6' : kycInfo.kyc_status === 'Pending' ? '#DAA621' : kycInfo.kyc_status === 'Rejected' ? '#C10808' : kycInfo.status === 'Verified' ? '#00DA3C' : '#6D6D6D'} />
                                                    </svg>
                                                    {kycInfo.kyc_status ? translate(props.lang, kycInfo.kyc_status.toUpperCase()) : kycInfo.status === 'Verified' ? translate(props.lang, 'Verified') : translate(props.lang, 'Not Verified')}
                                                </span> */}
                                                <span className={`font-weight-700 font-20 line-height-18 px-2 py-1 d-inline-block text-capitalize statusBox ${kycInfo && kycInfo.kyc_status.toLowerCase()} ${kycInfo && kycInfo.status.toLowerCase() === 'active' ? 'unverified' : kycInfo.status.toLowerCase()}`}>
                                                    {kycInfo.kyc_status ? translate(props.lang, kycInfo.kyc_status.toUpperCase()) : kycInfo.status === 'Verified' ? translate(props.lang, 'Verified') : translate(props.lang, 'Not Verified')}
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={`tableBgContainer mt-3 ${kycInfo && kycInfo.kyc_remark ? 'd-block' : 'd-none'}`}>
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="py-4 px-4">
                                        <div className="text-start text-md-start">
                                            <span className="font-weight-500 font-20 line-height-18 text-white text-capitalize">{translate(props.lang, "Remark:")}  </span>
                                            <span className="font-weight-500 font-20 line-height-18 text-white">{kycInfo && kycInfo.kyc_remark ? kycInfo.kyc_remark : "-"}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {kycInfo.status !== "Verified" && (
                            <Row className="mx-0 px-0 mb-3 mt-3">
                                <Col lg={4} md={6} sm={12} xs={12} className="mb-3 px-1 pb-3">
                                    <div className={`tableBgContainer h-100  ${errors.front ? 'redBorder' : ""}`}>
                                        <label htmlFor="front" className={`d-flex align-items-center justify-content-center h-100 w-100`}>
                                            {imgPreview.front ? (
                                                <div className="h-100 d-flex flex-column justify-content-between w-100 align-items-center">
                                                    <div className="text-center pt-3 my-auto">
                                                        <img src={imgPreview.front} className="img-fluid doc-preview" alt="document" />
                                                    </div>
                                                    <div className="text-center mt-3 pb-3">
                                                        <span className="font-weight-600 font-16 text-white text-capitalize line-height-22">{translate(props.lang, "Upload IC/ Passport front")}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="py-5">
                                                    <div className="text-center">
                                                        <img src={require('../../../images/icon2/icfront.svg').default} className="img-fluid" />
                                                    </div>
                                                    <div className="text-center mt-3">
                                                        <span className="font-weight-600 font-16 text-white text-capitalize line-height-22">{translate(props.lang, "Upload IC/ Passport front")}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </label>
                                        {kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? (
                                            <input type='file' id="front" className="d-none" onChange={(e) => uploadImage(e, "front")} />
                                        ) : null : null}
                                    </div>
                                    <div className="mx-3 mt-1">
                                        <span className="errorTextRed">{translate(props.lang, errors.front)}</span>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className="mb-3 px-1 pb-3">
                                    <div className={`tableBgContainer h-100  ${errors.back ? 'redBorder' : ""}`}>
                                        <label htmlFor="back" className={`d-flex align-items-center justify-content-center h-100 w-100`}>
                                            {imgPreview.back ? (
                                                <div className="h-100 d-flex flex-column justify-content-between w-100 align-items-center">
                                                    <div className="text-center pt-3 my-auto">
                                                        <img src={imgPreview.back} className="img-fluid doc-preview" alt="document" />
                                                    </div>
                                                    <div className="text-center mt-3 pb-3">
                                                        <span className="font-weight-600 font-16 text-white text-capitalize line-height-22">{translate(props.lang, "Upload IC/ Passport Back")}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="py-5">
                                                    <div className="text-center">
                                                        <img src={require('../../../images/icon2/icBack.svg').default} className="img-fluid" />
                                                    </div>
                                                    <div className="text-center mt-3">
                                                        <span className="font-weight-600 font-16 text-white text-capitalize line-height-22">{translate(props.lang, "Upload IC/ Passport Back")}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </label>
                                        {kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? (
                                            <input type='file' id="back" className="d-none" onChange={(e) => uploadImage(e, "back")} />
                                        ) : null : null}
                                    </div>
                                    <div className="mx-3 mt-1">
                                        <span className="errorTextRed">{translate(props.lang, errors.back)}</span>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className="mb-3 px-1 pb-3">
                                    <div className={`tableBgContainer h-100  ${errors.holdingIC ? 'redBorder' : ""}`}>
                                        <label htmlFor="holdingIC" className={`d-flex align-items-center justify-content-center h-100 w-100`}>
                                            {imgPreview.holdingIC ? (
                                                <div className="h-100 d-flex flex-column justify-content-between w-100 align-items-center">
                                                    <div className="text-center pt-3 my-auto">
                                                        <img src={imgPreview.holdingIC} className="img-fluid doc-preview" alt="document" />
                                                    </div>
                                                    <div className="text-center mt-3 pb-3">
                                                        <span className="font-weight-600 font-16 text-white text-capitalize line-height-22">{translate(props.lang, "Upload Photo With Holding IC/Passport")}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="py-5">
                                                    <div className="text-center">
                                                        <img src={require('../../../images/icon2/holdIc.svg').default} className="img-fluid" />
                                                    </div>
                                                    <div className="text-center mt-3">
                                                        <span className="font-weight-600 font-16 text-white text-capitalize line-height-22">{translate(props.lang, "Upload Photo With Holding IC/Passport")}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </label>
                                        {kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? (
                                            <input type='file' id="holdingIC" className="d-none" onChange={(e) => uploadImage(e, "holdingIC")} />
                                        ) : null : null}
                                    </div>
                                    <div className="mx-3 mt-1">
                                        <span className="errorTextRed">{translate(props.lang, errors.holdingIC)}</span>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                    {loadingData ? null : (
                        kycInfo.status.toLowerCase() === "active" ? kycInfo.kyc_status.toLowerCase() === '' || kycInfo.kyc_status.toLowerCase() === 'rejected' ? (
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-5">
                                <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => submitForm()} disabled={loading} >
                                    <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Submit")}</span>
                                </button>
                            </Col>
                        ) : null : null
                    )}
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(KYC);