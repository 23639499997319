import React, { useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

/// Css
import "../css/custom1.scss";
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import routes from "../utils/route";
import Nav from "./layouts/nav";

import { ThemeContext } from "../context/ThemeContext";
import { logout } from "../store/actions/AuthActions";
import { api } from "../utils/api";
import Error404 from "./page/Common/Error404";

const Markup = (props) => {
  const dispatch = useDispatch();
  const { menuToggle } = useContext(ThemeContext);
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  useEffect(() => {
    getProfile();
  }, [props.auth.auth.access_token])

  const getProfile = () => {
    fetch(api.get_profile, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": "EN",
        "Authorization": "Bearer " + props.auth.auth.access_token
      }),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "success") {
          dispatch({
            type: 'UPDATE_PROFILE',
            profile: responseJson.data
          });
        } else {
          if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
            dispatch(logout(props.history, 'login'));
          }
        }
      }).catch(error => {
        console.error("error", error);
      });
  }

  return (
    <>
      <div id={`${!pagePath ? "main-wrapper" : ""}`} className={`${!pagePath ? "show" : "vh-100"}  ${menuToggle ? "menu-toggle" : ""}`}>
        {!pagePath && <Nav history={props.history} location={props.location} />}
        <div className={`${!pagePath ? "content-body" : ""}`} id="contentBody">
          <div className={`overflow-x-auto`}>
            <div className={`${!pagePath ? "container-fluid" : ""}`} style={{ minHeight: window.screen.height - 60 }}>
              <Switch>
                {routes.map((item, i) => (
                  <Route
                    key={i}
                    path={item.url}
                    exact
                    component={item.component}
                  />
                ))}
                <Route component={Error404} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default withRouter(connect(mapStateToProps)(Markup));
