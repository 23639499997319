import { connect } from 'react-redux';
import "../../../css/loading.scss";
import { translate } from "../../../utils/translate";


const Loadingv3 = (props) => {
    return (
        <div className={`${props.theme === 'dark' ? 'loading4' : 'loading4'}`}>
            <div className="preloader-container py-4">
                <div>
                    <div className="progress-1"></div>
                    <div className="mt-2 text-center">
                        <span className={`font-weight-400 font-16 ${props.theme === 'dark' ? 'primary-text' : 'text-white'}`}>{translate(props.lang, "Loading Data")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        errorMessage: auth.errorMessage,
        successMessage: auth.successMessage,
        showLoading: auth.showLoading,
        lang: i18n.lang
    }
};

export default connect(mapStateToProps)(Loadingv3);