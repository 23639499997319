import { connect, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { useState, useEffect } from "react";
import "../../../css/tutorial.scss";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/api";
import { logout } from "../../../store/actions/AuthActions";
import { translate } from "../../../utils/translate";

const HedgePosition = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [info, setInfo] = useState(null);
    const [loadingAnnouncement, setLoadingAnnouncement] = useState(true);

    useEffect(() => {
        getInfo();
    }, [id])

    const getInfo = () => {
        setLoadingAnnouncement(true)
        fetch(api.customer_get_announcement_details + `/${id}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingAnnouncement(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setInfo(responseJson.data)
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className="bg-transparent tutorial pb-5">
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="align-items-center px-0 mx-0 justify-content-md-between">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex align-items-center w-100">
                                        <Link to="/hedgedAccount" className="cursor-pointer">
                                            <div className="d-flex align-items-center">
                                                <img src={require('../../../images/icon2/backBtn.svg').default} className="img-fluid back-btn" alt="back" />
                                            </div>
                                        </Link>
                                        <div className="ms-3">
                                            <span className="font-weight-600 font-22 line-height-26 text-white">{info ? info.title ? info.title : '' : ''}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="px-0 mx-0 justify-content-center align-items-center mt-4">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    {info && info.attachment_video ? (
                                        <div className="hedge-video">
                                            <video autoPlay playsInline controls preload="auto" className="responsive-video" poster={info.attachment_image}>
                                                <source type="video/mp4" src={info.attachment_video} />
                                                Your browser does not support HTML5 video.
                                            </video>
                                        </div>
                                    ) : (
                                        info && info.attachment_image ? (
                                            <div className="mt-3">
                                                <img src={info.attachment_image} className="img-fluid" alt="" />
                                            </div>
                                        ) : null
                                    )}

                                    {/* {info && info.attachment_image ? (
                                        <div className="mt-3">
                                            <img src={info.attachment_image} className="img-fluid" alt="" />
                                        </div>
                                    ) : null} */}
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="my-3">
                                    <div className="mt-4">
                                        <span className="font-weight-400 font-16 op6 text-white letter-spacing-03p wordBreak">{translate(props.lang, "Posted at")} {info ? info.date_added ? info.date_added : '' : ''}</span>
                                    </div>
                                    <div className="mt-4">
                                        <span className="font-weight-400 font-22 text-white lh-sm letter-spacing-03p wordBreak break-white-space">{info ? info.description ? info.description : '' : ''}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(HedgePosition);