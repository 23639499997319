//only when user submit kyc and status is review or rejected only can edit
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/profile.scss";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
import LoadingV3 from '../../components/Loading/LoadingV3';
import countries from "../../../utils/countries";
import Select from "react-select";
import customSelectStyles from "../SelectStyle";
import { logout } from "../../../store/actions/AuthActions";
import { FaSpinner } from "react-icons/fa";
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import zhTWLocale from 'date-fns/locale/zh-TW';

const localeMap = {
    en: enLocale,
    zh_cn: zhCNLocale,
    zh_tw: zhTWLocale,
};

const buttonTextMap = {
    en: { clear: 'Clear', cancel: 'Cancel', ok: 'OK' },
    zh_cn: { clear: '清除', cancel: '取消', ok: '确定' },
    zh_tw: { clear: '清除', cancel: '取消', ok: '確定' },
};

const Profile = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const errorObj = { country: '', date_of_birth: '', telephone: '', address: '' };
    const [errors, setErrors] = useState(errorObj);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [editable, setEditable] = useState(false);
    const [locale, setLocale] = useState('en');
    const [profile, setProfile] = useState({
        kyc_status: '',
        status: '',
        mt5_accounts: []
    });
    const [formData, setFormData] = useState({
        country: '',
        date_of_birth: '',
        telephone: '',
        address: '',
    });

    useEffect(() => {
        getProfile();
    }, [props.auth.auth.access_token])

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const getProfile = () => {
        setLoadingProfile(true);
        fetch(api.get_profile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingProfile(false);
                }, 1000);

                if (responseJson.status === "success") {
                    if (responseJson.data) {
                        dispatch({
                            type: 'UPDATE_PROFILE',
                            profile: responseJson.data
                        });
                        setProfile(responseJson.data);
                        let newFormData = { ...formData };
                        newFormData.country = responseJson.data.country;
                        newFormData.date_of_birth = responseJson.data.date_of_birth;
                        newFormData.telephone = responseJson.data.telephone;
                        newFormData.address = responseJson.data.address;
                        setFormData(newFormData);
                    }
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const updateProfile = () => {
        let check = true;
        let errorMsg = { ...errorObj }
        if (!formData.address) {
            check = false;
            errorMsg.address = "This field is required.";
        }
        if (!formData.date_of_birth) {
            check = false;
            errorMsg.date_of_birth = "This field is required.";
        }
        if (!formData.telephone) {
            check = false;
            errorMsg.telephone = "This field is required.";
        }
        if (!formData.country) {
            check = false;
            errorMsg.country = "This field is required.";
        }
        setErrors(errorMsg)
        if (check) {
            setLoading(true);

            fetch(api.edit_profile, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if (responseJson.status === "success") {
                        setEditable(false);
                        swal(translate(props.lang, "Success"), translate(props.lang, "Profile successful updated"), "success");
                        getProfile();
                    } else {
                        if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        swal(translate(props.lang, "Error"), responseJson.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const handleDateChange = (e, type) => {
        const newFormData = { ...formData };
        newFormData[type] = moment(e).format('YYYY-MM-DD');
        setFormData(newFormData);
    }


    return (
        <>
            <div className='pb-5 profile'>
                <Row className="mx-0 px-0">
                    <Col lg={12} md={12} sm={12} xs={12} className="mt-3 px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className='mx-0 px-0 align-items-md-stretch mt-3'>
                                <Col lg={3} md={3} sm={12} xs={12} className="mb-3 mb-md-0 px-0">
                                    <Row className='justify-content-center align-items-center px-0 mx-0'>
                                        {loadingProfile ? (
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <LoadingV3 theme='light' />
                                            </Col>
                                        ) : (
                                            <>
                                                <Col lg={5} md={8} sm={5} xs={5} className="text-center">
                                                    {props.auth.profile.rank_image ? (
                                                        <img src={props.auth.profile.rank_image} className="img-fluid" alt="profile-img" />
                                                    ) : (
                                                        <img src={require('../../../images/avatar/profile.svg').default} className="img-fluid" alt="profile-img" />
                                                    )}
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12} className="mt-3 mt-md-4 text-center">
                                                    <span className="font-weight-600 font-18 line-height-27 text-white break-all-word text-capitalize">{profile ? profile.full_name ? profile.full_name : profile.nick_name : "-"}</span>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Col>
                                <Col lg={1} md={1} sm={12} xs={12} className="d-none d-md-block px-0">
                                    <div className="verticalBorderLine op4"></div>
                                </Col>
                                <Col lg={8} md={8} sm={12} xs={12} className="px-0">
                                    <div className="h-100">
                                        {loadingProfile && !profile ? (<LoadingV3 theme='light' />) : (
                                            <Row className='mx-0 px-0 justify-content-start'>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Joining Date")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.date_added ? profile.date_added : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize break-all-word">{translate(props.lang, "Full Name")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1 break-all-word text-capitalize">{profile ? profile.full_name ? profile.full_name : profile.nick_name : '-'}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize break-all-word">{translate(props.lang, "Email Address")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1 break-all-word">{profile && profile.email ? profile.email : "-"}</span>
                                                </Col>

                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    {editable ? (
                                                        <>
                                                            <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Phone Number")}</span>
                                                            <div className={`groupLabel-input py-2 mb-1 mt-1 ${errors.telephone ? 'errorBorder' : ''}`}>
                                                                <Input
                                                                    type={'text'}
                                                                    name="telephone"
                                                                    placeholder={translate(props.lang, "Your phone number")}
                                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                                    value={formData.telephone}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChange(e)}
                                                                />
                                                            </div>
                                                            <div className="ms-4">
                                                                <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.telephone)}</span>
                                                            </div>
                                                        </>
                                                    ) :
                                                        <>
                                                            <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Phone Number")}</span>
                                                            <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.telephone ? profile.telephone : "-"}</span>
                                                        </>
                                                    }
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    {editable ? (
                                                        <>
                                                            <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Date of Birth")}</span>
                                                            <div className={`groupLabel-input py-2 mb-1 mt-1 ${errors.date_of_birth ? 'errorBorder' : ''}`}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                                                    <DatePicker
                                                                        autoOk
                                                                        label=""
                                                                        clearable
                                                                        format="yyyy-MM-dd"
                                                                        disableFuture
                                                                        className="form-control input-transparent py-1 px-md-4 px-3"
                                                                        placeholder={translate(props.lang, "Your Date of Birth")}
                                                                        value={formData.date_of_birth ? formData.date_of_birth : null}
                                                                        onChange={(date) => handleDateChange(date, 'date_of_birth')}
                                                                        clearLabel={buttonTextMap[locale].clear}
                                                                        cancelLabel={buttonTextMap[locale].cancel}
                                                                        okLabel={buttonTextMap[locale].ok}
                                                                        views={['year', 'month', 'date']}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                            <div className="ms-4">
                                                                <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.date_of_birth)}</span>
                                                            </div>
                                                        </>
                                                    ) :
                                                        <>
                                                            <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Date of Birth")}</span>
                                                            <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.date_of_birth ? profile.date_of_birth : "-"}</span>
                                                        </>
                                                    }
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Country")}</span>
                                                    {editable ? (
                                                        <>
                                                            <div className={`groupLabel-select py-1 px-md-4 px-3 mb-1 mt-1 ${errors.country ? 'errorBorder' : ''}`}>
                                                                <Select
                                                                    options={
                                                                        countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: props.lang === "en" ? item.name.toLowerCase() : props.lang === "zh_cn" ? item.chinese_simplified : item.chinese_traditional })) : [{ value: '', label: translate(props.lang, "No countries available") }]
                                                                    }
                                                                    placeholder={translate(props.lang, "Select Country")}
                                                                    className="input-transparent w-100 text-capitalize px-0"
                                                                    styles={customSelectStyles}
                                                                    value={
                                                                        countries.filter(item => item.name == formData.country).length > 0 ?
                                                                            (countries.filter(item => item.name == formData.country).map((item, i) => ({ key: i, value: item.name, label: props.lang === "en" ? item.name.toLowerCase() : props.lang === "zh_cn" ? item.chinese_simplified : item.chinese_traditional })))
                                                                            : [{ value: '', label: '-' }]
                                                                    }
                                                                    onChange={(e) => handleOptionSelected(e, 'country')}
                                                                    name="country"
                                                                    classNamePrefix="customSelect"
                                                                />
                                                            </div>
                                                            <div className="ms-4">
                                                                <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.country)}</span>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1 text-capitalize">
                                                            {profile && profile.country ?
                                                                countries.filter(item => item.name == profile.country).length > 0 ?
                                                                    props.lang.toLowerCase() === "zh_cn" ? countries.filter(item => item.name == profile.country)[0].chinese_simplified :
                                                                        props.lang.toLowerCase() === 'zh_tw' ? countries.filter(item => item.name == profile.country)[0].chinese_traditional :
                                                                            countries.filter(item => item.name == profile.country)[0].name.toLowerCase()
                                                                    : '-'
                                                                : "-"}
                                                        </span>
                                                    )}
                                                </Col>

                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    {editable ? (
                                                        <>
                                                            <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Address")}</span>
                                                            <div className={`groupLabel-input py-2 mb-1 mt-1 ${errors.address ? 'errorBorder' : ''}`}>
                                                                <Input
                                                                    type={'text'}
                                                                    name="address"
                                                                    placeholder={translate(props.lang, "Your address")}
                                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                                    value={formData.address}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChange(e)}
                                                                />
                                                            </div>
                                                            <div className="ms-4">
                                                                <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.address)}</span>
                                                            </div>
                                                        </>
                                                    ) :
                                                        <>
                                                            <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Address")}</span>
                                                            <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.address ? profile.address : "-"}</span>
                                                        </>
                                                    }

                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "Upline")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.upline_name ? profile.upline_name : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "My Referral Code")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.referral_code ? profile.referral_code : "-"}</span>
                                                </Col>
                                                {
                                                    Array.from({ length: 4 }, (_, i) => i).map((item, i) => (
                                                        profile && profile.mt5_accounts.length > 0 ? (
                                                            profile.mt5_accounts.filter(item => item.mt5_group_id === (i + 1)).length > 0 ? (
                                                                profile.mt5_accounts.filter(item => item.mt5_group_id === (i + 1)).map((acc) => (
                                                                    <>
                                                                        <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                                            <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account $index Username").replace('$index', i + 1)}</span>
                                                                            <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{acc.mt5_login_id}</span>
                                                                        </Col>
                                                                        <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                                            <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account $index Password").replace('$index', i + 1)}</span>
                                                                            <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{acc.mt5_login_password}</span>
                                                                        </Col>
                                                                    </>
                                                                ))
                                                            ) : (
                                                                <>
                                                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                                        <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account $index Username").replace('$index', i + 1)}</span>
                                                                        <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">-</span>
                                                                    </Col>
                                                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                                        <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account $index Password").replace('$index', i + 1)}</span>
                                                                        <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">-</span>
                                                                    </Col>
                                                                </>
                                                            )
                                                        ) : (
                                                            <>
                                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account $index Username").replace('$index', i + 1)}</span>
                                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">-</span>
                                                                </Col>
                                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account $index Password").replace('$index', i + 1)}</span>
                                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">-</span>
                                                                </Col>
                                                            </>
                                                        )

                                                    ))
                                                }
                                                {/* <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account 1 Username")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.mt5_accounts.length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 1).length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 1)[0].mt5_login_id ? profile.mt5_accounts.filter(item => item.mt5_group_id === 1)[0].mt5_login_id : '-' : "-" : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account 1 Password")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.mt5_accounts.length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 1).length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 1)[0].mt5_login_password ? profile.mt5_accounts.filter(item => item.mt5_group_id === 1)[0].mt5_login_password : '-' : "-" : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account 2 Username")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.mt5_accounts.length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 2).length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 2)[0].mt5_login_id ? profile.mt5_accounts.filter(item => item.mt5_group_id === 2)[0].mt5_login_id : '-' : "-" : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account 2 Password")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.mt5_accounts.length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 2).length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 2)[0].mt5_login_password ? profile.mt5_accounts.filter(item => item.mt5_group_id === 2)[0].mt5_login_password : '-' : "-" : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account 3 Username")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.mt5_accounts.length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 3).length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 3)[0].mt5_login_id ? profile.mt5_accounts.filter(item => item.mt5_group_id === 3)[0].mt5_login_id : '-' : "-" : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account 3 Password")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.mt5_accounts.length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 3).length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 3)[0].mt5_login_password ? profile.mt5_accounts.filter(item => item.mt5_group_id === 3)[0].mt5_login_password : '-' : "-" : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account 4 Username")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.mt5_accounts.length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 4).length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 4)[0].mt5_login_id ? profile.mt5_accounts.filter(item => item.mt5_group_id === 4)[0].mt5_login_id : '-' : "-" : "-"}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "MT5 Account 4 Password")}</span>
                                                    <span className="font-weight-600 font-18 line-height-18 text-white d-block mt-1">{profile && profile.mt5_accounts.length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 4).length > 0 ? profile.mt5_accounts.filter(item => item.mt5_group_id === 4)[0].mt5_login_password ? profile.mt5_accounts.filter(item => item.mt5_group_id === 4)[0].mt5_login_password : '-' : "-" : "-"}</span>
                                                </Col>  */}
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                                    <span className="font-weight-400 font-16 line-height-18 text-white op6 d-block text-capitalize">{translate(props.lang, "KYC Status")}</span>
                                                    <span className={`font-weight-600 font-18 line-height-18 px-2 py-1 d-inline-block mt-1 text-capitalize statusBox ${profile && profile.kyc_status.toLowerCase()} ${profile && profile.status.toLowerCase() === 'active' ? 'unverified' : profile.status.toLowerCase()}`}>
                                                        {profile && profile.kyc_status === "Approved" ? translate(props.lang, "Verified") :
                                                            profile.kyc_status === 'Pending' ? translate(props.lang, profile.kyc_status.toUpperCase()) : profile.kyc_status === 'Rejected' ? translate(props.lang, profile.kyc_status.toUpperCase()) : profile.status === "Verified" ? translate(props.lang, "Verified") : translate(props.lang, "Not Verified")}
                                                    </span>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                    <Row className='align-items-center justify-content-start mx-0 px-0 pt-4 pt-md-0'>
                                                        <Col lg={6} md={6} sm={6} xs={6}>
                                                            <div>
                                                                {!editable ? (
                                                                    <button className="primary-btn py-2 text-center w-100" onClick={() => setEditable(true)} >
                                                                        <span className={`btnText`}>{translate(props.lang, "Edit")}</span>
                                                                    </button>
                                                                ) :
                                                                    <button className="primary-btn py-2 text-center w-100" onClick={() => updateProfile()} disabled={loading}>
                                                                        <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Update")}</span>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} md={6} sm={6} xs={6}>
                                                            {editable ? (
                                                                <button className="cancel-btn py-2 text-center w-100" onClick={() => setEditable(false)}>
                                                                    <span className="font-weight-600 font-20 line-height-30 text-white">{translate(props.lang, "Cancel")}</span>
                                                                </button>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    {/* {profile && (profile.status || profile.kyc_Status) ? (
                                                        profile.status === 'Active' || profile.kyc_Status === 'Rejected' ? (
                                                            <Row className='align-items-center justify-content-start mx-0 px-0 pt-4 pt-md-0'>
                                                                {profile && (profile.status || profile.kyc_Status) ? (
                                                                    profile.status === 'Active' || profile.kyc_Status === 'Rejected' ? (
                                                                        <Col lg={6} md={6} sm={6} xs={6}>
                                                                            <div>
                                                                                {!editable ? (
                                                                                    <button className="primary-btn py-2 text-center w-100" onClick={() => setEditable(true)} >
                                                                                        <span className={`btnText`}>{translate(props.lang, "Edit")}</span>
                                                                                    </button>
                                                                                ) :
                                                                                    <button className="primary-btn py-2 text-center w-100" onClick={() => updateProfile()} disabled={loading}>
                                                                                        <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Update")}</span>
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    ) : null
                                                                ) : null}
                                                                <Col lg={6} md={6} sm={6} xs={6}>
                                                                    {editable ? (
                                                                        <button className="cancel-btn py-2 text-center w-100" onClick={() => setEditable(false)}>
                                                                            <span className="font-weight-600 font-20 line-height-30 text-white">{translate(props.lang, "Cancel")}</span>
                                                                        </button>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                        ) : null) : null} */}
                                                </Col>

                                            </Row>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Profile);