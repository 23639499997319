import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import i18nReducer from './reducers/i18nReducer';
import themeReducer from './reducers/ThemeReducer';
const middleware = applyMiddleware(thunk);

const persistConfig = {
    key: 'rcfx', // key is required
    storage, // define which storage to use
    // whitelist: ['auth'], // only auth will be persisted
};


const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    auth: AuthReducer,
    i18n: i18nReducer,
    theme: themeReducer
});
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(persistedReducer,  composeEnhancers(middleware));
export const persistor = persistStore(store);

// export const store = createStore(reducers,  composeEnhancers(middleware));
