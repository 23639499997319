import { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/settings.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let errorObj = {
        // current_password: null,
        new_password: null,
        confirm_password: null,
        email_otp: null
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [countdownSecond, setCountdownSecond] = useState(0);
    const [loadingOtp, setLoadingOtp] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        // current_password: '',
        email_otp: '',
        new_password: '',
        confirm_password: '',
    })

    useEffect(() => {
        if (countdownSecond > 0) {
            setTimeout(() => {
                setCountdownSecond(countdownSecond - 1);
            }, 1000);
        }
        return () => { };
    }, [countdownSecond]);

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        // if (!formData.current_password) {
        //     errorMsg.current_password = "This field is required.";
        //     check = false;
        // } else {
        //     if (formData.current_password.length < 6) {
        //         errorMsg.current_password = "Old password must at least 6 characters";
        //         check = false;
        //     }
        // }
        if (!formData.email_otp) {
            errorMsg.email_otp = "This field is required.";
            check = false;
        }
        if (!formData.new_password) {
            errorMsg.new_password = "This field is required.";
            check = false;
        } else {
            if (formData.new_password.length < 6) {
                errorMsg.new_password = "New password must at least 6 characters";
                check = false;
            }
        }
        if (!formData.confirm_password) {
            errorMsg.confirm_password = "This field is required.";
            check = false;
        }
        if (formData.new_password && formData.confirm_password) {
            if (formData.confirm_password !== formData.new_password) {
                errorMsg.confirm_password = "Confirm password does not match the new password";
                check = false;
            }
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.edit_password, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === "success") {
                        swal(translate(props.lang, "Success"), translate(props.lang, "New login password change successful"), "success");
                        history.push('/settings/overview')
                    } else {
                        if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        if (responseJson.data && typeof responseJson.data.error === 'object') {
                            let apiErrorMsg = { ...errorObj };
                            let popupMessage = [];
                            Object.keys(responseJson.data.error).map((key) => {
                                apiErrorMsg[key] = responseJson.data.error[key];
                                popupMessage.push(responseJson.data.error[key])
                            })
                            setErrors(apiErrorMsg);
                            swal(translate(props.lang, "Error"), popupMessage.join('\n'), "error");
                        } else {
                            swal(translate(props.lang, "Error"), responseJson.message, "error");
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const requestCode = () => {
        setLoadingOtp(true);
        let data = {
            "email": props.auth.profile.email,
            "send_otp": true, // optional // if dont have this param default will send otp
            "type": "change_password"
        }
        fetch(api.request_email_otp, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingOtp(false);
                if (responseJson.status === "success") {
                    setCountdownSecond(60);
                    swal(translate(props.lang, "Success"), translate(props.lang, "Verification code has sent to").replace('$email', props.auth.profile.email), "success");
                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className='settings pb-5'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex align-items-center w-100 mb-4">
                                        <Link to="/settings/overview" className="cursor-pointer">
                                            <div className="d-flex align-items-center">
                                                <img src={require('../../../images/icon2/backBtn.svg').default} className="img-fluid back-btn" alt="back" />
                                            </div>
                                        </Link>
                                        <div className="ms-3">
                                            <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Change Password")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-2 mb-1 ${errors.new_password ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "New Password")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={showNewPassword ? "text" : "password"}
                                                    name="new_password"
                                                    placeholder={translate(props.lang, "Your New Password")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.new_password}
                                                    autoComplete='new-password'
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                                <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowNewPassword(!showNewPassword)}>
                                                    {showNewPassword ? (
                                                        <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                                    ) : (
                                                        <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.new_password)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-2 mb-1 ${errors.confirm_password ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Confirm New Password")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    name="confirm_password"
                                                    placeholder={translate(props.lang, "Confirm Your New Password")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.confirm_password}
                                                    autoComplete='new-password'
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                                <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                    {
                                                        showConfirmPassword ? (
                                                            <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                                        ) : (
                                                            <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.confirm_password)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input px-md-4 px-3 py-2 mb-1 ${errors.email_otp ? 'errorBorder' : ''}`}>
                                            <div className='d-flex justify-content-between align-items-center w-100'>
                                                <div className="w-75">
                                                    <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Verification Code")} <span className="errorTextRed">*</span></span>
                                                    <div className={`input-group `}>
                                                        <Input
                                                            type="text"
                                                            name="email_otp"
                                                            placeholder={translate(props.lang, "Your Email Verification Code")}
                                                            className="form-control input-transparent py-1"
                                                            value={formData.email_otp}
                                                            autoComplete="off"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    {countdownSecond === 0 ? (
                                                        <button className="requestCodeBtn px-lg-3 px-2 py-3 cursor-pointer" disabled={loadingOtp} onClick={() => requestCode()}>
                                                            <span className={`font-weight-600 font-16 text-black text-nowrap`}>{loadingOtp ? (<FaSpinner className="fa-spin" />) : translate(props.lang, "Send")}</span>
                                                        </button>
                                                    ) : (
                                                        <button className="requestCodeBtn px-lg-3 px-2 py-3 cursor-pointer" disabled>
                                                            <span className={`font-weight-600 font-16 text-btnText text-nowrap`}>{countdownSecond}</span>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.email_otp)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                                    <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => submitForm()} disabled={loading}>
                                        <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Confirm")}</span>
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ChangePassword);