// Member Portal 显示组织图的话只能看到3代以内。
// 举例：我是A ，A推B ，B推C ，C推D ，我要能看到B，C，D 的图

import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Input, Row } from 'reactstrap';
import { translate } from "../../../utils/translate.js";
import "../../../css/team.scss";
import "../../../css/tree3.scss"
import { api } from "../../../utils/api.js";
import { digitFormat } from "../../../utils/function.js";
import { logout } from "../../../store/actions/AuthActions.js";
import Loadingv3 from "../../components/Loading/LoadingV3.js";
import { Col } from "react-bootstrap";

const TeamInvestment = (props) => {
  const dispatch = useDispatch();
  const [parent, setParent] = useState([]);
  const [loading, setLoading] = useState(true);
  const treeRef = useRef(null);
  const [teamData, setTeamData] = useState([]);
  const [checkedItem, setCheckedItem] = useState([]);
  const showUnlimitedUserList = ['']; // member_uuid that can see more than 3 level downline

  useEffect(() => {
    getTreeData();
  }, [props.auth.auth.access_token]);

  const getTreeData = () => {
    setLoading(true);
    fetch(api.team_detail + `/${props.auth.profile.customer_id}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": props.lang.toLowerCase(),
        "Authorization": "Bearer " + props.auth.auth.access_token
      }),
    })
      .then(response => response.json())
      .then(responseJson => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        if (responseJson.status === 'success') {
          let tempTree = [{ ...responseJson.data.self_results, children: responseJson.data.downline_results }]
          setCheckedItem([responseJson.data.self_results.customer_id]);
          setParent([responseJson.data.self_results])
          setTeamData(tempTree)
        } else {
          if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
            dispatch(logout(props.history, 'login'));
          }
        }
      }).catch(error => {
        console.error("error", error);
      });
  }

  const getChildren = (member, level) => {
    fetch(api.team_detail + `/${member.customer_id}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": props.lang.toLowerCase(),
        "Authorization": "Bearer " + props.auth.auth.access_token
      }),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'success') {
          if (responseJson.data.downline_results.length > 0) {
            findArrayByUsername(teamData, responseJson.data.downline_results, member.customer_id)
          }
        } else {
          if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
            dispatch(logout(props.history, 'login'));
          }
          console.log('error', responseJson.message)
        }
      }).catch(error => {
        console.error("error", error);
      });
  }

  const findArrayByUsername = (mainArr, newArr, uid) => {
    let newGroupMember = findById(mainArr, newArr, uid);
    setParent([...newGroupMember]);
  }

  function findById(array, newArray, id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].customer_id === id) {
        if (array[i].children) {
          if (array[i].children.length !== newArray.length) {
            array[i].children = newArray;
            break;
          } else {
            break;
          }
        } else {
          array[i].children = newArray;
          break;
        }

      } else {
        if (array[i].children && array[i].children.length > 0) {
          findById(array[i].children, newArray, id);
        }
      }
    }
    return array;
  }

  const renderSingleView = (item, level) => {
    return (
      <div className="border-tree-column mt-2 w-100">
        <div className="checkbox-area" onClick={() => onChangeCheckbox(item, level)}></div>
        <Row className="justify-content-start align-items-start mx-0 h-100 mx-0 px-0 py-3 py-md-4 w-100">
          <Col lg={3} md={3} xs={3} sm={3}>
            <div className="d-flex align-items-center px-2">
              <div className="text-center w-100">
                <div className="">
                  <span className="font-22 font-weight-600 text-white break-all-word break-white-space">{item.full_name ? item.full_name : item.nick_name}</span>
                </div>
                <div className="mt-3">
                  <span className="font-16 font-weight-600 text-white overflow-wrap-anywhere break-white-space">{translate(props.lang, "Wallet Balance:")} {item.wallet_balance ? Number(item.wallet_balance).toLocaleString('en-US', digitFormat) : '0.00'}</span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} xs={9} sm={9} className="second-column">
            <Row className="px-0 mx-0">
              <Col lg={3} md={3} sm={3} xs={3}>
                <div className="text-center">
                  <div>
                    <span className="font-22 font-weight-600 text-white break-all-word overflow-wrap-anywhere break-white-space">{item.total_member.toLocaleString('en-US')}</span>
                  </div>
                  <div className="mt-3 text-overflow-ellipsis">
                    <span className="font-16 font-weight-600 text-white text-capitalize break-all-word overflow-wrap-anywhere break-white-space">{translate(props.lang, "Total Member")}</span>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                <div className="text-center">
                  <div>
                    <span className="font-22 font-weight-600 text-white break-all-word overflow-wrap-anywhere break-white-space">{item.total_active_member.toLocaleString('en-US')}</span>
                  </div>
                  <div className="mt-3 text-overflow-ellipsis">
                    <span className="font-16 font-weight-600 text-white text-capitalize break-all-word overflow-wrap-anywhere break-white-space">{translate(props.lang, "Total Active Member")}</span>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                <div className="text-center">
                  <div>
                    <span className="font-22 font-weight-600 text-white break-all-word overflow-wrap-anywhere break-white-space">{Number(item.total_sales).toLocaleString('en-US', digitFormat)}</span>
                  </div>
                  <div className="mt-3 text-overflow-ellipsis">
                    <span className="font-16 font-weight-600 text-white text-capitalize break-all-word overflow-wrap-anywhere break-white-space">{translate(props.lang, "Total Sales")}</span>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                <div className="text-center px-2">
                  <div>
                    <span className="font-22 font-weight-600 text-white break-all-word overflow-wrap-anywhere break-white-space">{Number(item.personal_deposit).toLocaleString('en-US', digitFormat)}</span>
                  </div>

                  <div className="mt-3 text-overflow-ellipsis">
                    <span className="font-16 font-weight-600 text-white text-capitalize break-all-word overflow-wrap-anywhere break-white-space">{translate(props.lang, "Personal Deposit")}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  const renderChildNode = (item, level) => {
    let checkLevel1 = false; //level < 1
    let checkLevel2 = false; //level < 2
    if (level < 2) {
      checkLevel1 = true;
    }
    if (showUnlimitedUserList.includes(props.auth.auth.customer_id)) {
      checkLevel2 = true;
      checkLevel1 = true
    } else {
      if (level < 3) {
        checkLevel2 = true;
      }
    }

    if (item.is_have_downline > 0 && checkLevel2) {
      if (item.children && item.children.length > 0) {
        return (
          <ul className="pure-tree">
            {item.children.map((child, i) => (
              <li key={i} className={`child-node ${child.is_have_downline > 0 && checkLevel2 ? 'has-children' : ''}`}>
                {child.is_have_downline > 0 && checkLevel1 ? (
                  <Input type="checkbox" className="noBorder" id={child.customer_id} value={child.customer_id} checked={checkedItem.includes(child.customer_id)} onChange={() => onChangeCheckbox(child, level)} />
                ) : null}
                <div className="single-view" onClick={() => onChangeCheckbox(child, level)}>
                  {renderSingleView(child, level)}
                </div>

                {checkedItem.includes(child.customer_id) ? renderChildNode(child, level + 1) : null}
              </li>
            ))}
          </ul>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const onChangeCheckbox = (member, level) => {
    // let formatItem = formatObject(member);
    let formatItem = member;
    let arr = checkedItem;
    let checkLevel2 = false; //level < 3 
    if (showUnlimitedUserList.includes(props.auth.auth.customer_id)) {
      checkLevel2 = true;
    } else {
      if (level < 3) {
        checkLevel2 = true;
      }
    }
    if (formatItem.is_have_downline > 0 && checkLevel2) {
      if (arr.includes(formatItem.customer_id)) {
        //hide child
        let newArr = arr.filter(item => item !== formatItem.customer_id)
        setCheckedItem(newArr);
      } else {
        //show child
        getChildren(formatItem, level);
        setCheckedItem(arr.concat(formatItem.customer_id));
      }
    }
  }

  return (
    <>
      <div className="teaminvestment pb-5 px-1 px-lg-0 ms-2" id="tree">
        {loading ? (
          <Loadingv3 theme="light" />
        ) : (
          teamData.length > 0 ? (
            <ul id="compositions-list" className="pure-tree main-tree mt-3" ref={treeRef} >
              <li>
                {teamData[0].is_have_downline ? (
                  <Input type="checkbox" className="noBorder" id={teamData[0].customer_id} value={teamData[0].customer_id} checked={checkedItem.includes(teamData[0].customer_id)} onClick={() => onChangeCheckbox(teamData[0], 0)} />
                ) : null}
                <div className="single-view" onClick={() => onChangeCheckbox(teamData[0], 0)}>
                  {renderSingleView(teamData[0], 0)}
                </div>
                {checkedItem.includes(teamData[0].customer_id) ? renderChildNode(teamData[0], 0) : null}
              </li>
            </ul>
          ) : null
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
};

export default connect(mapStateToProps)(TeamInvestment);
