
/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useLocation, withRouter } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { translate } from "../../../utils/translate";
import "../../../css/sidebar2.scss";
import SidebarMenu from "../../../utils/menu";
import { restrictTransferEmail } from "../../../utils/config";


class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new Metismenu(this.$el);
    }
    componentWillUnmount() {
    }
    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

const SideBar = (props) => {
    const {
        iconHover,
        sidebarposition,
        headerposition,
        sidebarLayout,
    } = useContext(ThemeContext);
    const [path, setPath] = useState("/");
    const location = useLocation();
    const dispatch = useDispatch();
    const [renderMenuLoading, setRenderMenuLoading] = useState(true);
    useEffect(() => {
        var btn = document.querySelector(".nav-control");
        var navBackdrop = document.querySelector(".navigation-backdrop-control");
        var aaa = document.querySelector("#main-wrapper");
        // function toggleFunc() {
        //   return aaa.classList.toggle("menu-toggle");
        // }
        btn.addEventListener("click", () => toggleMenuFunc());
        navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setRenderMenuLoading(false);
        }, 500);
    }, [])

    const toggleMenuFunc = (forceClose = false) => {
        var body = document.querySelector("body");
        var aaa = document.querySelector("#main-wrapper");
        var aaabackdrop = document.querySelector(".navigation-backdrop-control");
        // console.log(aaa.classList.contains("menu-toggle"), 'check');
        if (forceClose) {
            aaabackdrop.classList.add('d-none');
            aaa.classList.remove("menu-toggle");
            // body.classList.remove("fixedPosition");
            return;
        }
        if (aaa.classList.contains("menu-toggle")) {
            aaabackdrop.classList.add('d-none');
            aaa.classList.remove("menu-toggle");
            // body.classList.remove("fixedPosition");
            return;
        } else {
            aaabackdrop.classList.remove('d-none');
            aaa.classList.add("menu-toggle");
            // body.classList.remove("fixedPosition");
            return
        }
    }

    let scrollPosition = useScrollPosition();
    /// Path
    useEffect(() => {
        setPath(location.pathname);
    }, [location]);

    const closeMenu = (hasChildren) => {
        if (!hasChildren) {
            // var element = document.getElementById("main-wrapper");
            // element.classList.remove("menu-toggle");
            if (document.querySelector('body').getAttribute("data-sidebar-style") === 'overlay') {
                document.querySelector(".nav-control").click();
            }
        }
    }

    const checkPath = (allPath, currentPath) => {
        let check = false;
        if (currentPath !== '/') {
            allPath.map((item) => {
                if (currentPath.includes(item) && item !== '/') {
                    check = true;
                    return check;
                }
            })
        }
        return check;
    }

    return (
        <div
            className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
                sidebarLayout.value === "horizontal" &&
                headerposition.value === "static"
                ? scrollPosition > 120
                    ? "fixed"
                    : ""
                : ""
                }`}
        >

            <PerfectScrollbar className="deznav-scroll">
                <div className="mobileLogo mb-md-0 mx-auto" style={{ height: '5.6rem' }}>
                    <div className="d-flex align-items-center w-100 h-100 justify-content-center">
                        <img src={require('../../../images/logo/logo-sidebar-white.png').default} className="img-fluid" alt="logo" />
                        {/* <img src={require('../../../images/logo/logoWhite.svg').default} className="img-fluid" alt="logo" /> */}
                    </div>
                </div>
                {renderMenuLoading ? null : (
                    <MM className="metismenu" id="menu">
                        {SidebarMenu.map((parent, i) => (
                            <li key={i} className={`${parent.display.includes('mobile') ? 'd-block d-lg-none d-md-none' : ''} mx-4 px-0 mb-3 mb-md-2 w-100 mx-auto ${parent.allPath.includes(path) ? "mm-active mm-parent-active" : checkPath(parent.allPath, path) ? "mm-active mm-parent-active" : "main-menu"}`}>
                                <Link className={`ai-icon w-80 px-3 px-md-3 text-capitalize mx-auto py-2`} to={parent.pathName === "#" ? "#" : parent.pathName} onClick={() => closeMenu(parent.children.length > 0 ? true : false)}>
                                    <div className="d-flex align-items-center justify-content-between w-100 py-md-2 py-0">
                                        <div className="d-flex align-items-center w-100 text-start">
                                            <img src={parent.inactiveImg} className="icon-inactive" alt={parent.menu} />
                                            <img src={parent.activeImg} className="icon-active" alt={parent.menu} />
                                            <div className="ms-2">
                                                <span className={`nav-text parent font-weight-600 font-16 text-capitalize`}> {translate(props.lang, parent.menu)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                {parent.children.length > 0 ? (
                                    <ul className={`submenu ${parent.allPath.includes(path) ? "mm-show" : checkPath(parent.allPath, path) ? "mm-show" : "mm-collapse"}`}>
                                        {parent.children.map((child, key) => (
                                            child.menu === 'Internal Transfer' ? (
                                                restrictTransferEmail.includes(props.auth.profile.email) ? null : (
                                                    <li key={key} className={`${child.allPath.includes(path) ? "menu-active" : checkPath(child.allPath, path) ? "menu-active" : "menu-inactive"}`}>
                                                        <Link to={child.pathName} className="children-nav d-flex align-items-center w-100" onClick={() => closeMenu(false)}>
                                                            {child.allPath.includes(path) ? (
                                                                <img src={require('../../../images/icon2/menuArrow.svg').default} className="me-2 arrow" alt="arrow" />
                                                            ) : null}
                                                            <span className={`font-weight-400 font-16 text-capitalize`}>{translate(props.lang, child.menu)}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            ) : (
                                                <li key={key} className={`${child.allPath.includes(path) ? "menu-active" : checkPath(child.allPath, path) ? "menu-active" : "menu-inactive"}`}>
                                                    <Link to={child.pathName} className="children-nav d-flex align-items-center w-100" onClick={() => closeMenu(false)}>
                                                        {child.allPath.includes(path) ? (
                                                            <img src={require('../../../images/icon2/menuArrow.svg').default} className="me-2 arrow" alt="arrow" />
                                                        ) : null}
                                                        <span className={`font-weight-400 font-16 text-capitalize`}>{translate(props.lang, child.menu)}</span>
                                                    </Link>
                                                </li>
                                            )
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        ))
                        }
                    </MM>
                )}
            </PerfectScrollbar>
        </div>
    );
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(SideBar));