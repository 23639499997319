import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    UPDATE_PROFILE,
    UPDATE_PERMISSION_ACCESS
} from '../actions/AuthActions';

const initialState = {
    auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
    },
    access: [],
    profile: {
        auto_rank_id: '',
        manual_rank_id: '',
        referral_code: null,
        kyc_remark: '',
        kyc_status: null,
        id_number: '',
        email: '',
        date_of_birth: '',
        attachment: null,
        address: '',
        full_name: '',
        upline_name: '',
        upline_id: '',
        telephone: '',
        nick_name: '',
        rank_image: '',
        rank_name: ''
    },
    kyc: '',
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
            },
            profile: {
                auto_rank_id: '',
                manual_rank_id: '',
                referral_code: null,
                kyc_remark: '',
                kyc_status: null,
                id_number: '',
                email: '',
                date_of_birth: '',
                attachment: null,
                address: '',
                full_name: '',
                upline_name: '',
                upline_id: '',
                telephone: '',
                nick_name: '',
                rank_image: '',
                rank_name: ''
            },
            showLoading: false,
        };
    }
    if (action.type === UPDATE_PERMISSION_ACCESS) {
        return {
            ...state,
            access: action.access,
        };
    }
    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === UPDATE_PROFILE) {
        return {
            ...state,
            profile: action.profile,
            errorMessage: '',
            successMessage: 'Update Profile Successful',
            showLoading: false,
        };
    }
    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}

    
