import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate"
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
// import LoadingData from "../../components/Loading/Loadingv3";
import "../../../css/notification.scss";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { Col, Row } from "reactstrap";
import LoadingV3 from "../../components/Loading/LoadingV3";
import NotFound from "../Common/NotFound";

const AnnouncementList = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [productPerPage, setProductPerpage] = useState(10);
    const [announcementModal, setAnnouncementModal] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [loadingData, setLoadingData] = useState(true);
    const [newsModal, setNewsModal] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const [totalRecord, setTotalRecord] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [firstLoad, setFirstLoad] = useState(true);


    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            getList();
            setFirstLoad(false);
        }
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            setLoadingData(true)
            setPageNumber(1);
            getList(1);
        }
    }, [productPerPage, props.lang]);

    const getList = (page = pageNumber) => {
        setLoadingData(true);
        let url = `${api.customer_get_announcements}?page=${page}&limit=${productPerPage}&type=announcement`;
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingData(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setResult(responseJson.data.results);
                    var pageCount = Math.ceil(responseJson.data.total_results / productPerPage)
                    setPageCount(pageCount);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const openNewsModal = (news) => {
        fetch(api.updateNotification + '?id=' + news.id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    // getUnreadNotiCount();
                    // getList();
                    setSelectedNews(news);
                    setNewsModal(true);
                    getList();
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const closeNewsModal = () => {
        setSelectedNews(null);
        setNewsModal(false);
    }

    function openAnnouncementModal(item) {
        setSelectedAnnouncement(item);
        setAnnouncementModal(true);
    }

    return (
        <>
            <div className="notification pb-5 mt-3">
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="align-items-center px-0 mx-0 justify-content-md-between">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex align-items-center w-100">
                                        <Link to="/dashboard" className="cursor-pointer">
                                            <div className="d-flex align-items-center">
                                                <img src={require('../../../images/icon2/backBtn.svg').default} className="img-fluid back-btn" alt="back" />
                                            </div>
                                        </Link>
                                        <div className="ms-3">
                                            <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Announcement")}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="px-0 mx-0 justify-content-center align-items-center mt-4">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    {loadingData ? (
                                        <div>
                                            <LoadingV3 theme={'light'} />
                                        </div>
                                    ) : (
                                        result.length > 0 ? (
                                            result.map((item, i) => (
                                                <div key={i} className="cursor-pointer news-border-bottom py-3" onClick={() => openAnnouncementModal(item)}>
                                                    <Row className="justify-content-center">
                                                        <Col lg={3} md={3} xs={12} sm={12}>
                                                            <span className="font-weight-600 font-13 text-white d-block">{item.date_added ? item.date_added : ''}</span>
                                                        </Col>
                                                        <Col lg={9} md={9} sm={12} xs={12} className="mt-2 mt-md-0">
                                                            <span className="d-block font-weight-400 font-13 text-white">{item.title}</span>
                                                            <div className="mt-1">
                                                                <span className="font-weight-400 font-16 text-white op8 lh-sm d-block d-md-none">{item.description.length > 30 ? props.lang === 'en' ? item.description.substring(0, 100) + '...' : item.description.substring(0, 30) + '...' : item.description}</span>
                                                                <span className="font-weight-400 font-16 text-white op8 lh-sm d-none d-md-block">{item.description.length > 30 ? props.lang === 'en' ? item.description.substring(0, 100) + '...' : item.description.substring(0, 30) + '...' : item.description}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="py-5 text-center">
                                                <NotFound />
                                            </div>
                                        )
                                    )}
                                    <div className="pt-4">
                                        {!loadingData && result.length > 0 ? (
                                            <div className="d-flex w-100 justify-content-center">
                                                <div className="mt-3 pagination-container py-3 d-inline-block">
                                                    <ReactPaginate
                                                        previousLabel={"<"}
                                                        nextLabel={">"}
                                                        pageCount={pageCount}
                                                        forcePage={pageNumber - 1}
                                                        onPageChange={changePage}
                                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                                        disabledClassName={"pagination__link--disabled text-muted"}
                                                        activeClassName={"pagination__link--active text-black"}
                                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="fade modalColorBg" size="lg" show={announcementModal} centered>
                <Modal.Header className="border-0 pt-4 px-md-4 px-3">
                    <div className="modalHeader d-flex w-100 align-items-center">
                        <div className="w-75">
                        </div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => setAnnouncementModal(false)} >
                            <img src={require('../../../images/icon2/modal-close.svg').default} alt="close" />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-md-4 px-3 pt-0">
                    <Row className='justify-content-center align-items-center mx-0 px-0'>
                        {/* <Col lg={12} md={12} xs={12} sm={12} className="mb-5 text-center">
                                <span className="font-weight-700 font-30 text-white lh-base">{translate(props.lang, "Announcement")}</span>
                            </Col> */}
                        <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                            <span className="font-weight-700 font-26 lh-base text-white break-all-word d-block">
                                {selectedAnnouncement && selectedAnnouncement.title ? selectedAnnouncement.title : '-'}
                            </span>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12}>
                            <span className="font-weight-400 font-14 lh-base text-white break-all-word d-block">
                                {translate(props.lang, "Posted at")} {selectedAnnouncement && selectedAnnouncement.date_added ? selectedAnnouncement.date_added : ''}
                            </span>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12}>
                            <hr className='announcementPopupLine my-3' />
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                            {selectedAnnouncement ? (
                                <span className="font-weight-400 font-18 line-height-30 text-white d-block wordBreak break-white-space">
                                    {selectedAnnouncement.description ? selectedAnnouncement.description : ''}
                                </span>
                            ) : null}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(AnnouncementList);