import React from 'react'
import LoadingV3 from '../../components/Loading/LoadingV3'
import { Col, Row } from 'reactstrap'

function InfoBoxV2(props) {
    return (
        <>
            {props.twoInrow ? (
                <div className='tableBgContainer'>
                    <Row className="justify-content-center align-items-center mx-0 px-0">
                        <Col lg={6} md={6} sm={12} xs={12} className="border-right-box">
                            <div className='d-flex align-items-center h-100 w-100 py-3 px-0 mx-0'>
                                <div className='w-100'>
                                    <div>
                                        {props.icon ? (
                                            <img src={props.icon} className="img-fluid" alt="icon" />
                                        ) : null}
                                        <span className="font-weight-600 font-18 line-height-26 white-container-title ms-3 ms-md-4">{props.boxLabel}</span>
                                    </div>
                                    {props.loading ? (<LoadingV3 theme="light" />) : (
                                        <div className="textFieldBg w-100 align-items-center px-3 py-2 mt-3">
                                            <span className="font-weight-500 font-24 text-black">{props.boxValue}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="py-0">
                            <div className='d-flex align-items-center h-100 w-100 py-3 px-0 mx-0'>
                                <div className='w-100'>
                                    <div>
                                        {props.icon2 ? (
                                            <img src={props.icon2} className="img-fluid" alt="icon" />
                                        ) : null}
                                        <span className="font-weight-600 font-18 line-height-26 white-container-title ms-3 ms-md-4">{props.boxLabel2}</span>
                                    </div>
                                    {props.loading ? (<LoadingV3 theme="light" />) : (
                                        <div className="textFieldBg w-100 align-items-center px-3 py-2 mt-3">
                                            <span className="font-weight-500 font-24 text-black">{props.boxValue2}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                props.style === 'v2' ? (
                    <div className="tableBgContainer">
                        <div className='p-3'>
                            <div className='d-flex align-items-center'>
                                {props.icon ? (
                                    <img src={props.icon} className="img-fluid iconSize" alt="icon" />
                                ) : null}
                                <span className="font-weight-600 font-22 text-white ms-3 ms-md-4">{props.boxLabel}</span>
                            </div>
                            <div className='w-100 mt-3 borderLine'></div>
                            <div className='w-100 mt-3 position-relative'>
                                {props.loading ? (
                                    <LoadingV3 theme="light" />
                                ) : (
                                    <>
                                        <div className="w-100 align-items-center">
                                            <span className="font-weight-600 font-24 text-white">{props.boxCurrency ? props.boxCurrency : ''}&nbsp;</span>
                                        </div>
                                        <div className="text-start w-100 align-items-center mt-1">
                                            <span className="font-weight-600 font-24 text-white">{props.boxValue}</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="tableBgContainer">
                        <div className='p-3'>
                            <div className='d-flex align-items-center'>
                                {props.icon ? (
                                    <img src={props.icon} className="img-fluid iconSize" alt="icon" />
                                ) : null}
                                <span className="font-weight-600 font-22 text-white ms-3 ms-md-4">{props.boxLabel}</span>
                            </div>
                            <div className='w-100 mt-3 borderLine'></div>
                            <div className='w-100 mt-3 position-relative'>
                                {props.loading ? (
                                    <LoadingV3 theme="light" />
                                ) : (
                                    <>
                                        <div className="w-100 align-items-center">
                                            <span className="font-weight-600 font-32 text-white">{props.boxCurrency ? props.boxCurrency : ''}&nbsp;</span>
                                        </div>
                                        {props.rankIcon ? (
                                            <div className="text-end w-100 align-items-center" style={{ height: '2.5em' }}>
                                                <div className='absolute-rank-icon'>
                                                    <img src={props.rankIcon} className="img-fluid rankIconSize" alt="icon" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="text-end w-100 align-items-center">
                                                <span className="font-weight-600 font-40 text-white">{props.boxValue}</span>
                                            </div>
                                        )}

                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    )
}

export default InfoBoxV2;