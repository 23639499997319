import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { Col, Row, Table } from "reactstrap";
import "../../../css/wallet.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { bonusTypeList, shownItem } from "../../../utils/config";
import { translate } from "../../../utils/translate";
import LoadingV3 from "../../components/Loading/LoadingV3";
import customSelectStyles from "../SelectStyle";
import { digitFormat } from "../../../utils/function";
import NotFound from "../Common/NotFound";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";

const { RangePicker } = DatePicker;

const BonusHistoryDetails = (props) => {
    const dispatch = useDispatch();
    const [productPerpage, setProductPerpage] = useState(12);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [firstLoad, setFirstLoad] = useState(true);
    const [bonusTransactionType, setBonusTransactionType] = useState('');
    const [result, setResult] = useState([]);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const timerRef = useRef(null);
    const [localeSetting, setLocaleSetting] = useState({});

    const changePage = async (event) => {
        setPageNumber(event.selected + 1);
        await getBonusTransaction(event.selected + 1);
    }

    useEffect(()=>{
        setLocaleSetting({
            applyLabel: translate(props.lang, 'Confirm'), // Change "Apply" button text to "Confirm"
            cancelLabel: translate(props.lang, 'Cancel'),
            monthNames:
                [
                    translate(props.lang, 'January'),
                    translate(props.lang, 'February'),
                    translate(props.lang, 'March'),
                    translate(props.lang, 'April'),
                    translate(props.lang, 'May'),
                    translate(props.lang, 'June'),
                    translate(props.lang, 'July'),
                    translate(props.lang, 'August'),
                    translate(props.lang, 'September'),
                    translate(props.lang, 'October'),
                    translate(props.lang, 'November'),
                    translate(props.lang, 'December')
                ],
            daysOfWeek: [
                translate(props.lang, 'Sun'),
                translate(props.lang, 'Mon'),
                translate(props.lang, 'Tue'),
                translate(props.lang, 'Wed'),
                translate(props.lang, 'Thu'),
                translate(props.lang, 'Fri'),
                translate(props.lang, 'Sat'),
            ]
        })
    },[props.lang])

    useEffect(() => {
        if (firstLoad) {
            getBonusTransaction();
            setFirstLoad(false);
        }
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1);
            getBonusTransaction(1);
        }
    }, [from, to, productPerpage, bonusTransactionType]);

    const getBonusTransaction = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_transactions + `?page=${page}&limit=${productPerpage}&order_by=DESC&sort_by=date_added`;

            if (from && to) {
                url += `&date_start=${from}&date_end=${to}`
            }
            if (bonusTransactionType) {
                url += `&transaction_types=${bonusTransactionType}`
            } else {
                let allBonus = bonusTypeList.filter(item => item.label === 'All');
                if (allBonus.length > 0) {
                    url += `&transaction_types=${allBonus[0].value}`
                }
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.results);
                        var pageCount = Math.ceil(responseJson.data.total_results / productPerpage)
                        setPageCount(pageCount);
                    } else {
                        if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        console.log("error", responseJson.message);
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000)
    }

    const handleTimeChange = (e) => {
        if (e) {
            const formattedFromDate = dayjs(e[0]).format("YYYY-MM-DD");
            const formattedToDate = dayjs(e[1]).format("YYYY-MM-DD");
            setFrom(formattedFromDate);
            setTo(formattedToDate);
        } else {
            setFrom("");
            setTo("");
        }
    };

    const clearDateSearch = () => {
        setFrom('');
        setTo('')
    }

    const handleDateChange = (e, picker) => {
        let startDate = moment(picker.startDate).format('YYYY-MM-DD');
        let endDate = moment(picker.endDate).format('YYYY-MM-DD');
        setFrom(startDate ? startDate : '')
        setTo(endDate ? endDate : '')
    };

    const getTypeText = (text) => {
        let newText = props.lang === 'en' ? text.split('(') : text.split('（');
       
        return (
            <>
             <span className="d-md-inline-block d-block pe-md-1">{newText[0]}</span>
             <span className="d-md-inline-block d-block">{props.lang === 'en' ? "(" : "（"}{newText[1]}</span>
            </>
        )
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="align-items-center px-0 mx-0">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex align-items-center w-100 mb-4">
                                        <Link to="/wallet/overview" className="cursor-pointer">
                                            <div className="d-flex align-items-center">
                                                <img src={require('../../../images/icon2/backBtn.svg').default} className="img-fluid  back-btn" alt="back" />
                                            </div>
                                        </Link>
                                        <div className="ms-3">
                                            <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Bonus History Details")}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center px-3">
                                <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-lg-0">
                                    {/* <RangePicker
                                        className="w-100 input-transparent"
                                        placeholder={[translate(props.lang, "Start"), translate(props.lang, "End")]}
                                        onChange={(e) => handleTimeChange(e)}
                                    /> */}
                                    <div className="groupLabel-input date px-2 py-3 py-md-2">
                                        <DateRangePicker
                                            onApply={handleDateChange}
                                            startDate={from}
                                            endDate={to}
                                            initialSettings={{ locale: localeSetting }}
                                            key={JSON.stringify(localeSetting)}
                                        >
                                            <span className="font-16 font-weight-600 text-black h-100">
                                                <input type="text" className="d-none" />
                                                <Row className="justify-content-between px-0 mx-0 align-items-center">
                                                    <Col lg={4} md={4} sm={4} xs={4} className="ps-0 text-center">
                                                        <span className={`font-18 font-weight-600 ${from ? "text-black" : "placeholder-color"}`}>{from ? from : translate(props.lang, "Start")}</span>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2} className="px-0 text-center">
                                                        <img src={require('../../../images/icon2/dateArrow.svg').default} className="img-fluid" />

                                                    </Col>
                                                    <Col lg={4} md={4} sm={4} xs={4} className="pe-0 text-center">
                                                        <span className={`font-18 font-weight-600 ${to ? "text-black" : "placeholder-color"}`}>{to ? to : translate(props.lang, "End")}</span>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2} className="text-end pe-0">
                                                        {from && to ? (
                                                            <div className="d-flex w-100 justify-content-end" onClick={() => clearDateSearch()}>
                                                                <div className="gold-indicatior-container d-flex align-items-center justify-content-end">
                                                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <img src={require('../../../images/icon2/calendar.svg').default} className="img-fluid calendar-icon"/>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </span>
                                        </DateRangePicker>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className="h-100 mb-2 mb-lg-0">
                                    <div className={`groupLabel-select select-placeholder-center w-100 py-1 py-md-0 px-2`}>
                                        <Select
                                            options={bonusTypeList.length > 0 ? bonusTypeList.filter(item => item.label !== 'All').map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                            placeholder={translate(props.lang, "Select Bonus Type")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={customSelectStyles}
                                            value={
                                                bonusTypeList.filter(item => item.value === bonusTransactionType).length > 0 ?
                                                    (bonusTypeList.filter(item => item.value === bonusTransactionType).map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })))
                                                    : null
                                            }
                                            isClearable
                                            isSearchable={false}
                                            onChange={(e) => setBonusTransactionType(e ? e.value : '')}
                                        />
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-lg-0">
                                    <div className={`groupLabel-select select-placeholder-center w-100 py-1 py-md-0 px-2`}>
                                        <Select
                                            options={
                                                shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                            }
                                            placeholder={translate(props.lang, "Result Per Page")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={customSelectStyles}
                                            value={shownItem && shownItem.find(option => option.value === productPerpage)}
                                            name="productPerpage"
                                            isSearchable={false}
                                            onChange={(e) => setProductPerpage(e.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center px-3 mt-4">
                                <div className="tableContainer">
                                    <Table responsive className="w-100 table-list text-center position-relative">
                                        <thead>
                                            <tr>
                                                <th className={`font-weight-600 font-16`} style={{ minWidth: '100px'}}>{translate(props.lang, "Date & Time")}</th>
                                                <th className={`font-weight-600 font-16`}>{translate(props.lang, "Type Of Bonus")}</th>
                                                <th className={`font-weight-600 font-16`}>{translate(props.lang, "Rebate User")}</th>
                                                <th className={`font-weight-600 font-16`}>{translate(props.lang, "Amount")} (USD)</th>
                                            </tr>
                                        </thead>
                                        {loading ? (
                                            <tbody>
                                                <tr role="row">
                                                    <td colSpan={4}>
                                                        <LoadingV3 theme='light' />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {result.length > 0 ?
                                                    result.map((item, i) => (
                                                        <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                             <td className={`font-weight-500 lh-base`} style={{ minWidth: '150px'}}>
                                                                    <span className="d-md-inline-block d-block pe-md-1">{item.date_added.split(" ")[0]}</span>
                                                                    <span className="d-md-inline-block d-block">{item.date_added.split(" ")[1]}</span>
                                                                </td>
                                                                <td className={`font-weight-500 lh-base`}  style={{ minWidth: props.lang === 'en' ? '200px' : '150px'}}>{item.transaction_type === 'EARLY_BIRD_BONUS' || item.transaction_type === "VIP_BONUS" ? getTypeText(item.transaction_type_text) : item.transaction_type_text}</td>
                                                            <td className={`font-weight-500 px-1 description lh-base`}>{item.remark_arr ? JSON.parse(item.remark_arr).length > 1 ? JSON.parse(item.remark_arr)[1] : '-' : '-'}</td>
                                                            <td className={`font-weight-500`}>{item.amount ? Number(item.amount).toLocaleString('en-US', digitFormat) : '-'}</td>
                                                        </tr>
                                                    )) :
                                                    <tr className="py-5 text-center bg-transparent">
                                                        <td colSpan={4}>
                                                            <NotFound />
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        )}
                                    </Table>
                                </div>
                            </Row>
                            <div className="pt-4">
                                {!loading && result.length > 0 ? (
                                    <div className="d-flex w-100 justify-content-center">
                                        <div className="mt-3 pagination-container py-3 d-inline-block">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                forcePage={pageNumber - 1}
                                                containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                                previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                                nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                                disabledClassName={"pagination__link--disabled text-muted"}
                                                activeClassName={"pagination__link--active text-black"}
                                                marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(BonusHistoryDetails);