const shownItem = [
    { label: "12", value: 12 }, 
    { label: "20", value: 20 }, 
    { label: "50", value: 50 }, 
    { label: "100", value: 100 }
]

const langOption = [
    { name: 'English', code: 'en' },
	{ name: '中文 (简体)', code: 'zh_cn' },
	{ name: '中文 (繁體)', code: 'zh_tw' },
]

const bonusTypeList = [
    { label: 'All', value: "LOT_REBATE_BONUS,SAME_RANK_BONUS,EARLY_BIRD_BONUS,VIP_BONUS,CONTRACT_COMMISSION,PERIOD_EXTRA_PROFIT" },
    { label: 'Lot Rebate Bonus', value: 'LOT_REBATE_BONUS' },
    { label: 'Contract Commission', value: 'CONTRACT_COMMISSION' },
    { label: 'Period Extra Profit', value: 'PERIOD_EXTRA_PROFIT' },
    { label: 'Same Rank Bonus', value: 'SAME_RANK_BONUS' },
    { label: 'Early Bird Bonus', value: 'EARLY_BIRD_BONUS' },
    { label: 'VIP Bonus', value: 'VIP_BONUS' },
]

const transactionTypeList = [
    { label: 'Wallet Withdraw', value: 'WITHDRAW' },
    { label: 'DEPOSIT_CRM', value: 'DEPOSIT_CRM' },
    { label: 'TRANSFER_FROM_MT5', value: 'TRANSFER_FROM_MT5' },
    { label: 'DEPOSIT_MT5', value: 'DEPOSIT_MT5' },
    { label: 'TRANSFER', value: 'TRANSFER'},
    { label: 'MT5 PROFIT', value: 'MT5_PROFIT' },
    { label: 'Bonus', value: "LOT_REBATE_BONUS,SAME_RANK_BONUS,EARLY_BIRD_BONUS,VIP_BONUS,CONTRACT_COMMISSION,PERIOD_EXTRA_PROFIT" },
    { label: 'Adjust', value: 'ADJUST' },
]

const specialAccountList = [
    { label: 'Early Bird', value: 'EARLY_BIRD' },
	{ label: 'VIP', value: 'VIP' },
]

const depositNetwork = [
    { label: 'TRC20', value: 'TRON' },
    { label: 'BEP20', value: 'BSC' },
]

const withdrawNetwork = [
    { label: 'TRC20', value: 'TRON' },
    { label: 'BEP20', value: 'BSC' },
]

const regexDecimalPoint = /^[0-9]*(\.[0-9]{0,4})?$/;

const withdrawDecimalPoint = 4;

const restrictTransferEmail = [
    '13142056573@163.com',
    '313299727@qq.com',
    '517297109@qq.com',
]

export { restrictTransferEmail, shownItem, langOption, bonusTypeList, transactionTypeList, specialAccountList, depositNetwork, regexDecimalPoint, withdrawDecimalPoint, withdrawNetwork };