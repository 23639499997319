import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate"
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
// import LoadingData from "../../components/Loading/Loadingv3";
import "../../../css/notification.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { Col, Row } from "reactstrap";

const Notification = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [newsModal, setNewsModal] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const productPerpage = 10;
    const [totalRecord, setTotalRecord] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        getList();
    }, [props.auth.auth.id_token, pageNumber, props.lang])

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
    }

    const getList = () => {
        setLoadingData(true);
        fetch(api.getNotificationList + `?page=${pageNumber - 1}&size=${productPerpage}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingData(false);
                if (responseJson.status=== "success") {
                    setResult(responseJson.data);
                    // setPageCount(responseJson.data.totalPages);
                    // setTotalRecord(responseJson.data.totalElements)
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const openNewsModal = (news) => {
        fetch(api.updateNotification + '?id=' + news.id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status=== "success") {
                    // getUnreadNotiCount();
                    // getList();
                    setSelectedNews(news);
                    setNewsModal(true);
                    getList();
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const closeNewsModal = () => {
        setSelectedNews(null);
        setNewsModal(false);
    }

    return (
        <>
            <div className="notification pb-5 mt-3">
                {loadingData ? (
                    <div>
                        {/* <LoadingData /> */}
                    </div>
                ) : (
                    result.length > 0 ? (
                        result.map((item, i) => ( 
                            //onClick={() => openNewsModal(item)}
                            <div key={i} className="noti-container px-3 py-3 cursor-pointer mb-2">
                                <Row className="justify-content-center">
                                    <Col lg={9} md={9} sm={12} xs={12}>
                                        <div className="d-flex align-items-start w-100">
                                            <div className="mt-1">
                                                {item.read ? (
                                                    <div className="readDot me-2"></div>
                                                ) : (
                                                    <div className="unreadDot me-2"></div>
                                                )}
                                            </div>
                                            <div>
                                                <span className="d-block font-weight-400 font-16 text-grey-1">{item.message}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} xs={12} sm={12}>
                                        <div className="mt-2 text-end">
                                            <span className="font-weight-400 font-12 text-grey-1 d-block">{moment(item.date).format('YYYY-MM-DD hh:mm a')}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    ) : (
                        <div>
                            <span className="font-weight-400 font-12 text-grey-1 d-block">{translate(props.lang, "No Notification")}</span>
                        </div>
                    )
                )}
                {/* {result.length > 0 ? (
                    <div className="d-flex w-100 justify-content-center">
                            <div className="mt-3 pagination-container py-3 d-inline-block">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                            previousLinkClassName={"pagination__previouslink text-grey-2"}
                            nextLinkClassName={"pagination__nextlink text-grey-2"}
                            disabledClassName={"pagination__link--disabled text-muted"}
                            activeClassName={"pagination__link--active text-white"}
                        />
                    </div>
                    </div>
                ) : null} */}
            </div>
            <Modal className="fade notification" show={newsModal} centered>
                <Modal.Body className="border-0">
                    <div className='px-2 pb-4'>
                        <div className="text-start">
                            <span className="font-weight-600 font-18 page-title-color">{selectedNews ? moment(selectedNews.date).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
                        </div>
                        <div className="mt-4">
                            <span className="font-weight-400 font-16 letter-spacing--001em page-title-color">
                                {selectedNews ? selectedNews.message : ''}
                            </span>
                        </div>
                    </div>
                    <div>
                        <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => closeNewsModal()}>
                            <span className={`font-weight-600 font-20 line-height-30 text-white`}>{translate(props.lang, "Close")}</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Notification);