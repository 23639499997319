import { connect } from 'react-redux';
import "../../../css/loadingv2.scss";
import { Col, Row } from 'reactstrap';

const LoadingV2 = (props) => {
    return (
        <div className="bg">
            <Row className='mb-4 mx-0 px-0 align-items-center justify-content-center'>
                <Col lg={2} mb={2} sm={6} xs={6}>
                    <img src={require('../../../images/logo/logo-icon.png').default} alt="logo" className="img-fluid" />
                </Col>
            </Row>
            
            <span className="loader"></span>
        </div>

    )
}
const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
};

export default connect(mapStateToProps)(LoadingV2);