import { connect } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react'
import { Col, Row } from 'reactstrap'
import "../../../css/rankingInfov2.scss";
import { translate } from '../../../utils/translate';
import LoadingV3 from '../../components/Loading/LoadingV3';
import { api } from '../../../utils/api';

const RankingInfo = (props) => {
  const [slide, setSlide] = useState(0);
  const [pagination, setPagination] = useState(1);

  useEffect(() => {
    if (props.ownRank && props.rankList.length > 0) {
      let index = props.rankList.findIndex(item => item.rank_name.toLowerCase() === props.ownRank.toLowerCase());
      if (index !== props.rankList.length - 1) {
        setSlide(index + 1)
      } else {
        setSlide(index)
      }
    }
  }, [props.ownRank, props.rankList])

  const renderMt5Target = (sales, current_sales) => {
    let value1 = current_sales ? current_sales : 0;
    let value2 = sales;

    let width = value1 / value2 * 100;
    if (value1 > value2) {
      width = 100;
    } else {
      width = value1 / value2 * 100;
    }
    width = width.toString() + '%';
    return (
      <>
        <div className='mb-3'>
          <div className='d-flex justify-content-between mb-1'>
            <div className='text-start'>
              <span className='font-weight-400 font-14 rank_desc'>{translate(props.lang, "My MT5 Balance")}</span>
            </div>
            <div className='text-end'>
              <span className='font-weight-400 font-14 rank_desc'>{Number(value1).toLocaleString('en-US')} / {Number(value2).toLocaleString('en-US')}</span>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className="progress text-end">
              <div className="progress-value" style={{ width: width }}></div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderPersonalSales = (sales, current_sales) => {
    let value1 = current_sales ? current_sales : 0;
    let value2 = sales;

    let width = value1 / value2 * 100;
    if (value1 > value2) {
      width = 100;
    } else {
      width = value1 / value2 * 100;
    }

    width = width.toString() + '%';
    return (
      <>
        <div className='mb-3'>
          <div className='d-flex justify-content-between mb-1'>
            <div className='text-start'>
              <span className='font-weight-400 font-14 rank_desc'>{translate(props.lang, "Personal Sales")}</span>
            </div>
            <div className='text-end'>
              <span className='font-weight-400 font-14 rank_desc'>{Number(value1).toLocaleString('en-US')} / {Number(value2).toLocaleString('en-US')}</span>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className="progress text-end">
              <div className="progress-value" style={{ width: width }}></div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderGroupSales = (sales, current_sales) => {
    let value1 = current_sales ? current_sales : 0;
    let value2 = sales;
    let width = value1 / value2 * 100;
    if (value1 > value2) {
      width = 100;
    } else {
      width = value1 / value2 * 100;
    }
    width = width.toString() + '%';
    return (
      <>
        <div className='mb-3'>
          <div className='d-flex justify-content-between mb-1'>
            <div className='text-start'>
              <span className='font-weight-400 font-14 rank_desc'>{translate(props.lang, "Group Sales")}</span>
            </div>
            <div className='text-end'>
              <span className='font-weight-400 font-14 rank_desc'>{Number(value1).toLocaleString('en-US')} / {Number(value2).toLocaleString('en-US')}</span>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className="progress text-end">
              <div className="progress-value" style={{ width: width }}></div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const prevSlide = () => {
    if (slide !== 0) {
      setSlide(slide - 1)
    } else {
      setSlide(props.rankList.length - 1)
    }
  }

  const nextSlide = () => {
    if (slide !== props.rankList.length - 1) {
      setSlide(slide + 1)
    } else {
      setSlide(0)
    }
  }

  const onScroll = (() => {
    var ticking = false;
    var lastScrollLeft = 0;

    return (e) => {
      if (!ticking) {
        window.requestAnimationFrame(function () {
          var documentScrollLeft = e.target.scrollLeft;
          if (lastScrollLeft != documentScrollLeft) {
            if (documentScrollLeft > 250) {
              setPagination(2)
            } else {
              setPagination(1)
            }
          }
          ticking = false;
        });
        ticking = true;
      }
    };
  })();

  return (
    <>
      {props.loading ? (
        <LoadingV3 theme="light" />
      ) : (
        <div className="w-100 position-relative rankinfov2">
          <div className="d-block d-md-none left-arrow" onClick={() => prevSlide()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <g clipPath="url(#clip0_62_18444)">
                <path d="M18.9692 4.19289L17.0408 2.27539L6.32666 13.0004L17.0517 23.7254L18.9692 21.8079L10.1617 13.0004L18.9692 4.19289Z" fill="#BABABA" />
              </g>
              <defs>
                <clipPath id="clip0_62_18444">
                  <rect width="26" height="26" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <Row className="rank-scrolling-wrapper flex-row flex-nowrap px-0 mx-0" onScroll={onScroll}>
            {/* <Row className="px-0 mx-0 mt-4 justify-content-center justify-content-md-start align-items-stretch"> */}
            {props.rankList.length > 0 && props.rankList.map((item, i) => (
              <Col lg={props.lg} md={props.md} sm={12} xs={12} key={i} className={`mb-3 ps-md-0 ${slide === i ? 'd-block' : 'd-none'} d-md-block`}>
                <div className='rankBg2 mx-3 mx-md-0 '>
                  <div className='rankDetailsBg px-2 py-3 h-100'>
                    <Row className='justify-content-center px-0 mx-0'>
                      <Col lg={8} md={8} sm={8} xs={8} className='text-center'>
                        <div className='ranking-container mx-auto'>
                          <img src={item.rank_image} className='img-fluid ranking-icon' alt={item.rank_name} />
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div className='text-center'>
                          <span className="font-weight-600 font-18 line-height-20 text-uppercase rank_name">{item.rank_name}</span>
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12} className='mt-4'>
                        {renderMt5Target(item.mt5_balance_target, item.mt5_balance)}
                        {renderPersonalSales(item.personal_sale_target, item.personal_sales)}
                        {renderGroupSales(item.group_sale_target, item.group_sales)}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <div className="d-block d-md-none right-arrow" onClick={() => nextSlide()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <g clipPath="url(#clip0_62_18444)">
                <path d="M18.9692 4.19289L17.0408 2.27539L6.32666 13.0004L17.0517 23.7254L18.9692 21.8079L10.1617 13.0004L18.9692 4.19289Z" fill="#BABABA" />
              </g>
              <defs>
                <clipPath id="clip0_62_18444">
                  <rect width="26" height="26" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className='d-none d-md-block'>
            <div className='d-flex w-100 align-items-center justify-content-center h-100'>
              <div className={`pagination-dot-rank mx-1 ${pagination === 1 ? 'active' : ''}`}></div>
              <div className={`pagination-dot-rank mx-1 ${pagination === 2 ? 'active' : ''}`}></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(RankingInfo);