
const customSelectStyles = {
    menuPortal: base => ({ ...base }),
    control: (base, state) => ({
        ...base,
        background: "transparent",
        border: "none",
        boxShadow: state.isFocused ? null : null,
        color: "black",
        padding: "0px 0px",
        width: '100%',
        minHeight: '35px',
    }),
    valueContainer: (base) => ({
        ...base,
        paddingLeft: '0px',
        width: '100%',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem'
    }),
    menu: (base) => ({
        ...base,
        marginTop: 0,
        color: "#000000",
        padding: "0px 0px",
        overflow: 'hidden',
        // height: '10em',
        borderRadius: '8px',
        border: "2px solid #244876",
        background: "#FFFFFF",
        backdropFilter: "blur(10px)",
    }),
    menuList: (base) => ({
        ...base,
        padding: "0px 0px",
    }),
    singleValue: (base) => ({
        ...base,
        color: "black",
        padding: "0px 0px",
        alignItems: "center",
        display: "flex",
        fontSize: "1.125em",
        fontWeight: 700,
        overflow: 'visible',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#545454',
        fontWeight: '600'
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "rgba(0, 86, 80, 0.2)" : state.isFocused ? "rgba(0, 86, 80, 0.2)" : "white", // Background color when selected or focused
        color: '#101828',
        fontWeight: "500",
        ':hover': {
            background: "rgba(0, 86, 80, 0.05)", // Hover background color in red
            color: '#101828',
            fontWeight: "500"
        },
    }),
};





export default customSelectStyles;