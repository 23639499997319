import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import './vendor/datatables/css/dataTables.min.css';
import "./css/style.css";
// import Loading from './jsx/components/Loading';
// import Success from './jsx/page/Auth/Success';
// import LoadingV1 from './jsx/components/Loading/LoadingV1';
import LoadingV2 from './jsx/components/Loading/LoadingV2';
import { langOption } from './utils/config';
import { api, region } from './utils/api';
import { logout } from './store/actions/AuthActions';
import Error404 from './jsx/page/Auth/Error404';

const SignUp = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/page/Auth/Register')), 500);
    });
});
const ForgotPassword = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/page/Auth/ForgotPassword')), 500);
    });
});
const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/page/Auth/Login')), 1000);
    });
});

function App(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        let language = localStorage.getItem('rcfx-lang');
        if (language) {
            if(region === 'indo'){
                dispatch({
                    type: "UPDATE_LANGUAGE",
                    lang: 'en',
                });
            } else {
                if (langOption.filter(item => item.code === language).length > 0) {
                    dispatch({
                        type: "UPDATE_LANGUAGE",
                        lang: language,
                    });
                } else {
                    
                    dispatch({
                        type: "UPDATE_LANGUAGE",
                        lang: 'en',
                    });
                }
            }
        } else {
            if(region === 'indo'){
                dispatch({
                    type: "UPDATE_LANGUAGE",
                    lang: 'en',
                });
            }
        }

    }, [dispatch, props.history, location.pathname]);

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        const code = query.get('temp');
        if (code) {
            const tokenDetailsString = localStorage.getItem('rcfx-crm');
            if (tokenDetailsString) {
                let tokenDetails = JSON.parse(tokenDetailsString);
                tokenDetails.access_token = code;
                localStorage.setItem('rcfx-crm', JSON.stringify(tokenDetails));
            } else {
                let json = {
                    access_token: code
                }
                localStorage.setItem('rcfx-crm', JSON.stringify(json));
            }
            getProfile(code);
            checkAutoLogin(dispatch, props.history, location.pathname);
        } else {
            checkAutoLogin(dispatch, props.history, location.pathname);
        }
    }, [])

    useEffect(() => {
        history.listen((location) => {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        });
    }, [history]);

    const getProfile = (token) => {
        fetch(api.get_profile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    if (responseJson.data) {
                        dispatch({
                            type: 'UPDATE_PROFILE',
                            profile: responseJson.data
                        });
                    }
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/register' component={SignUp} />
            <Route path='/forgot-password' component={ForgotPassword} />
            {/* <Route path='/success' component={Success} /> */}
            <Route component={Error404} />
        </Switch>
    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={<LoadingV2 />} >
                    {routes}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));

