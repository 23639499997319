import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import "../../../css/team.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from '../../../utils/function';
import { translate } from "../../../utils/translate";
import InfoBox from '../Common/InfoBox';
import RankingInfo from '../Common/RankingInfo';
import LoadingV3 from '../../components/Loading/LoadingV3';
import InfoBoxV2 from '../Common/InfoBoxV2';
import { Modal, Table } from 'react-bootstrap';

const TeamOverview = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [rankData, setRankData] = useState([]);
    const [allRank, setAllRank] = useState([]);
    const [loadingRank, setLoadingRank] = useState(true);
    const [teamOverviewInfo, setTeamOverviewInfo] = useState({
        active_user: '',
        group_sales: '',
        rank: '',
        total_user: '',
    });
    const [rankList, setRankList] = useState([]);
    const [rankInfoModal, setRankInfoModal] = useState(false)

    useEffect(() => {
        getTeamOverview();
    }, [props.auth.auth.access_token]);

    useEffect(() => {
        if (allRank.length > 0) {
            if (props.auth.profile.rank_name === 'SSIB') {
                setRankData(allRank);
            } else {
                setRankData(allRank.filter(item => item.status === 1));
            }
        }
    }, [props.auth.profile.rank_name, allRank]);

    useEffect(() => {
        setLoadingRank(true);
        fetch(api.get_rank, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingRank(false);
                }, 1000);
                if (responseJson.status === 'success') {
                    setRankList(responseJson.data)
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }, [])

    const getTeamOverview = () => {
        setLoading(true);
        fetch(api.teamOverview, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setAllRank(responseJson.data.ranks)
                    setTeamOverviewInfo(responseJson.data)
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className='pb-5 team'>
                <Row className="mx-0 px-0 mt-3">
                    {/* <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/myRank.svg').default}
                            boxLabel={translate(props.lang, "My Rank")}
                            boxCurrency={props.auth.profile.rank_name ? props.auth.profile.rank_name : ''}
                            boxValue={``}
                            rankIcon={props.auth.profile.rank_image ? props.auth.profile.rank_image : require('../../../images/avatar/profile.svg').default}
                            loading={loading}
                        />
                    </Col> */}
                    <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <div className="tableBgContainer p-3 h-100">
                            <Row className='px-0 mx-0 align-items-center justify-content-center'>
                                <Col lg={10} md={10} sm={9} xs={9} className='px-0'>
                                    <Row className="align-items-center mx-0 px-0 h-100">
                                        <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                            <div className="d-flex align-items-center w-100">
                                                <div className="me-2">
                                                    <img src={require('../../../images/icon2/myRank.svg').default} alt="icon-rank" className="img-fluid" />
                                                </div>
                                                <span className="font-weight-600 font-22 text-white line-height-20 text-capitalize">{translate(props.lang, "My Rank")}</span>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className="px-0 py-3">
                                            <div className="borderLine"></div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                            <span className="font-weight-600 font-32 text-center text-white break-all-word lh-base overflow-wrap-anywhere">{props.auth.profile.rank_name ? props.auth.profile.rank_name : '-'}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={2} md={2} sm={3} xs={3} className='text-end pe-0'>
                                    {/* {!loading ? ( */}
                                    <img src={props.auth.profile.rank_image ? props.auth.profile.rank_image : require('../../../images/avatar/profileSmall.svg').default} className="img-fluid bigRankIconSize" alt="profile-img" />
                                    {/* ) : null} */}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/groupSales.svg').default}
                            boxLabel={translate(props.lang, "Group Sales")}
                            boxCurrency={`USD`}
                            boxValue={`${teamOverviewInfo && teamOverviewInfo.group_sales ? Number(teamOverviewInfo.group_sales).toLocaleString('en-US', digitFormat) : "0.00"}`}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-center mx-0 px-0">
                    <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/activeUser.svg').default}
                            boxLabel={translate(props.lang, "Active Group Member")}
                            boxCurrency={''}
                            boxValue={teamOverviewInfo.active_user ? Number(teamOverviewInfo.active_user).toLocaleString('en-US') : '0'}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/totalUser.svg').default}
                            boxLabel={translate(props.lang, "Total Group Member")}
                            boxCurrency={''}
                            boxValue={teamOverviewInfo.total_user ? Number(teamOverviewInfo.total_user).toLocaleString('en-US') : '0'}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className='mt-3'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <span className="font-weight-600 font-22 text-white">{translate(props.lang, "Ranking1")}</span>
                            <div className="copyBox py-2 text-center d-inline-block px-3" onClick={() => setRankInfoModal(true)}>
                                <div className="d-flex align-items-center">
                                    <span className="text-black font-weight-500 font-16">{translate(props.lang, "Details")}</span>
                                    <img src={require('../../../images/icon2/arrow-black.svg').default} alt="icon-wallet" className="ms-1 img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className='w-100 my-3 borderLine'></div>
                        <div className='mb-md-3'>
                            <RankingInfo rankList={rankData} loading={loadingRank} lg={3} md={4} ownRank={props.auth.profile.rank_name ? props.auth.profile.rank_name : ''} />
                        </div>
                    </Col>

                </Row>
            </div>
            <Modal className="fade modalInfoBg" size="lg" show={rankInfoModal} centered>
                <Modal.Header className="border-0 py-4">
                    <div className="modalHeader d-flex w-100 align-items-center">
                        <div className="w-75">
                        </div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => setRankInfoModal(false)} >
                            <img src={require('../../../images/icon2/modal-close.svg').default} alt="close" />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-4 pt-0">
                    <Table responsive className="w-100 table-rankinfo text-center position-relative">
                        <thead>
                            <tr>
                                <th className={`font-weight-600 font-18 text-gold-gradient text-center`}>{translate(props.lang, "Rank")}</th>
                                <th className={`font-weight-600 font-18 text-gold-gradient text-center`}>{translate(props.lang, "Lot Rebate Bonus")}(USD)</th>
                                <th className={`font-weight-600 font-18 text-gold-gradient text-center`}>{translate(props.lang, "Contract Commission")}</th>
                                <th className={`font-weight-600 font-18 text-gold-gradient text-center`}>{translate(props.lang, "Same Rank Bonus")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rankList.map((item, i) => (
                                    item.rank_name !== 'SSIB' ? (
                                        <tr key={i}>
                                            <td className={`font-weight-500 font-18 lh-base`}>{item.rank_name}</td>
                                            <td className={`font-weight-500 font-18 lh-base`}>{item.commission_rate ? item.commission_rate : '-'}</td>
                                            <td className={`font-weight-500 font-18 lh-base`}>{item.contract_commission ? item.contract_commission + '%' : '-'}</td>
                                            <td className={`font-weight-500 font-18 lh-base`}>{item.same_rank_rate ? item.same_rank_rate + '%' : '-'}</td>
                                        </tr>
                                    ) : null
                                ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TeamOverview);