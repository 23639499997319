import $ from 'jquery';
import swal from 'sweetalert';
import { translate } from './translate';
import country from './country';
import { url } from './api';

const config = {
    priceDecimal: 8
}

const copyText = (value, lang) => {
    if (value !== '') {
        var $temp = $("<input>");
        $("body").append($temp);
        var dummy = $temp.val(value).select();
        dummy.focus();
        document.execCommand("copy");
        $temp.remove();
    }
    swal('', translate(lang, 'Copied'), "success");
}

const get_starred = (str, number) => {
    if(str){
        let len = str.length;
        let left = number*2;
        let star = "*";
        return str.substr(0, number) + star.repeat(len - left) + str.substr(len - 1, number);
    }else{
        return str;
    }
}

const getCountryFlag = (code) => {
    if(code){
        return country[code].flag;
    }else{
        return null;
    }
}


const replaceImgUrl = (imgPath) => {
    let checkUrl = "http://139.162.53.58:7050";
    if(imgPath.indexOf(checkUrl) > -1){
        imgPath = imgPath.replace(checkUrl, url);
        return imgPath;
    }else{
        return imgPath;
    }
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const digitFormat = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
};

function getCurrentMonthDates() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    // Get the first day of the month
    const firstDay = new Date(year, month, 1);

    // Get the last day of the month
    const lastDay = new Date(year, month + 1, 0);

    const monthDates = [];

    // Loop from the first day to the last day of the month
    for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
        monthDates.push(new Date(day));
    }

    return monthDates;
}

function getCurrentWeekDates() {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // Sunday - Saturday : 0 - 6
    const weekDates = [];

    // Find the start of the week (Sunday)
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDay);

    // Push all days of the current week to the array
    for (let i = 0; i < 7; i++) {
        const weekDate = new Date(startOfWeek);
        weekDate.setDate(startOfWeek.getDate() + i);
        weekDates.push(weekDate);
    }

    return weekDates;
}

const formatTwoDecimal = (num) => {
    let truncatedNumber = Math.floor(num * 100) / 100;
    return Number(truncatedNumber);
}

const truncateToTwoDecimals = (num) => {
    const str = num.toString();
    const decimalIndex = str.indexOf('.');
  
    if (decimalIndex === -1) return str; // No decimal point, return the number as is
  
    return str.substring(0, decimalIndex + 3); // Get the part up to two decimal places
};

export { truncateToTwoDecimals, numberWithCommas, copyText, get_starred, getCountryFlag, replaceImgUrl, config, digitFormat, getCurrentWeekDates, getCurrentMonthDates, formatTwoDecimal };