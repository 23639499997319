var countries = [
    { 'name': 'SINGAPORE', 'code': 'SG', 'chinese_simplified': '新加坡', 'chinese_traditional': '新加坡' },
    { 'name': 'CHINA', 'code': 'CN', 'chinese_simplified': '中国', 'chinese_traditional': '中國' },
    { 'name': 'THAILAND', 'code': 'TH', 'chinese_simplified': '泰国', 'chinese_traditional': '泰國' },
    { 'name': 'JAPAN', 'code': 'JP', 'chinese_simplified': '日本', 'chinese_traditional': '日本' },
    { 'name': 'KOREA, REPUBLIC OF', 'code': 'KR', 'chinese_simplified': '韩国', 'chinese_traditional': '韓國' },
    { 'name': 'VIET NAM', 'code': 'VN', 'chinese_simplified': '越南', 'chinese_traditional': '越南' },
    { 'name': 'INDONESIA', 'code': 'ID', 'chinese_simplified': '印尼', 'chinese_traditional': '印尼' },
    { 'name': 'MALAYSIA', 'code': 'MY', 'chinese_simplified': '马来西亚', 'chinese_traditional': '馬來西亞' },
    { 'name': 'AFGHANISTAN', 'code': 'AF', 'chinese_simplified': '阿富汗', 'chinese_traditional': '阿富汗' },
    { 'name': 'ÅLAND ISLANDS', 'code': 'AX', 'chinese_simplified': '奥兰群岛', 'chinese_traditional': '奧蘭群島' },
    { 'name': 'ALBANIA', 'code': 'AL', 'chinese_simplified': '阿尔巴尼亚', 'chinese_traditional': '阿爾巴尼亞' },
    { 'name': 'ALGERIA', 'code': 'DZ', 'chinese_simplified': '阿尔及利亚', 'chinese_traditional': '阿爾及利亞' },
    { 'name': 'AMERICAN SAMOA', 'code': 'AS', 'chinese_simplified': '美属萨摩亚', 'chinese_traditional': '美屬薩摩亞' },
    { 'name': 'ANDORRA', 'code': 'AD', 'chinese_simplified': '安道尔', 'chinese_traditional': '安道爾' },
    { 'name': 'ANGOLA', 'code': 'AO', 'chinese_simplified': '安哥拉', 'chinese_traditional': '安哥拉' },
    { 'name': 'ANGUILLA', 'code': 'AI', 'chinese_simplified': '安圭拉', 'chinese_traditional': '安圭拉' },
    { 'name': 'ANTARCTICA', 'code': 'AQ', 'chinese_simplified': '南极洲', 'chinese_traditional': '南極洲' },
    { 'name': 'ANTIGUA AND BARBUDA', 'code': 'AG', 'chinese_simplified': '安提瓜和巴布达', 'chinese_traditional': '安提瓜和巴布達' },
    { 'name': 'ARGENTINA', 'code': 'AR', 'chinese_simplified': '阿根廷', 'chinese_traditional': '阿根廷' },
    { 'name': 'ARMENIA', 'code': 'AM', 'chinese_simplified': '亚美尼亚', 'chinese_traditional': '亞美尼亞' },
    { 'name': 'ARUBA', 'code': 'AW', 'chinese_simplified': '阿鲁巴', 'chinese_traditional': '阿魯巴' },
    { 'name': 'AUSTRALIA', 'code': 'AU', 'chinese_simplified': '澳大利亚', 'chinese_traditional': '澳大利亞' },
    { 'name': 'AUSTRIA', 'code': 'AT', 'chinese_simplified': '奥地利', 'chinese_traditional': '奧地利' },
    { 'name': 'AZERBAIJAN', 'code': 'AZ', 'chinese_simplified': '阿塞拜疆', 'chinese_traditional': '阿塞拜疆' },
    { 'name': 'BAHAMAS', 'code': 'BS', 'chinese_simplified': '巴哈马', 'chinese_traditional': '巴哈馬' },
    { 'name': 'BAHRAIN', 'code': 'BH', 'chinese_simplified': '巴林', 'chinese_traditional': '巴林' },
    { 'name': 'BANGLADESH', 'code': 'BD', 'chinese_simplified': '孟加拉国', 'chinese_traditional': '孟加拉國' },
    { 'name': 'BARBADOS', 'code': 'BB', 'chinese_simplified': '巴巴多斯', 'chinese_traditional': '巴巴多斯' },
    { 'name': 'BELARUS', 'code': 'BY', 'chinese_simplified': '白俄罗斯', 'chinese_traditional': '白俄羅斯' },
    { 'name': 'BELGIUM', 'code': 'BE', 'chinese_simplified': '比利时', 'chinese_traditional': '比利時' },
    { 'name': 'BELIZE', 'code': 'BZ', 'chinese_simplified': '伯利兹', 'chinese_traditional': '伯利茲' },
    { 'name': 'BENIN', 'code': 'BJ', 'chinese_simplified': '贝宁', 'chinese_traditional': '貝寧' },
    { 'name': 'BERMUDA', 'code': 'BM', 'chinese_simplified': '百慕大', 'chinese_traditional': '百慕大' },
    { 'name': 'BHUTAN', 'code': 'BT', 'chinese_simplified': '不丹', 'chinese_traditional': '不丹' },
    { 'name': 'BOLIVIA, PLURINATIONAL STATE OF', 'code': 'BO', 'chinese_simplified': '玻利维亚', 'chinese_traditional': '玻利維亞' },
    { 'name': 'BOSNIA AND HERZEGOVINA', 'code': 'BA', 'chinese_simplified': '波斯尼亚和黑塞哥维那', 'chinese_traditional': '波斯尼亞和黑塞哥維那' },
    { 'name': 'BOTSWANA', 'code': 'BW', 'chinese_simplified': '博茨瓦纳', 'chinese_traditional': '博茨瓦納' },
    { 'name': 'BOUVET ISLAND', 'code': 'BV', 'chinese_simplified': '布韦岛', 'chinese_traditional': '布韋島' },
    { 'name': 'BRAZIL', 'code': 'BR', 'chinese_simplified': '巴西', 'chinese_traditional': '巴西' },
    { 'name': 'BRITISH INDIAN OCEAN TERRITORY', 'code': 'IO', 'chinese_simplified': '英属印度洋领地', 'chinese_traditional': '英屬印度洋領地' },
    { 'name': 'BRUNEI DARUSSALAM', 'code': 'BN', 'chinese_simplified': '文莱', 'chinese_traditional': '汶萊' },
    { 'name': 'BULGARIA', 'code': 'BG', 'chinese_simplified': '保加利亚', 'chinese_traditional': '保加利亞' },
    { 'name': 'BURKINA FASO', 'code': 'BF', 'chinese_simplified': '布基纳法索', 'chinese_traditional': '布基納法索' },
    { 'name': 'BURUNDI', 'code': 'BI', 'chinese_simplified': '布隆迪', 'chinese_traditional': '布隆迪' },
    { 'name': 'CAMBODIA', 'code': 'KH', 'chinese_simplified': '柬埔寨', 'chinese_traditional': '柬埔寨' },
    { 'name': 'CAMEROON', 'code': 'CM', 'chinese_simplified': '喀麦隆', 'chinese_traditional': '喀麥隆' },
    { 'name': 'CANADA', 'code': 'CA', 'chinese_simplified': '加拿大', 'chinese_traditional': '加拿大' },
    { 'name': 'CAPE VERDE', 'code': 'CV', 'chinese_simplified': '佛得角', 'chinese_traditional': '佛得角' },
    { 'name': 'CAYMAN ISLANDS', 'code': 'KY', 'chinese_simplified': '开曼群岛', 'chinese_traditional': '開曼群島' },
    { 'name': 'CENTRAL AFRICAN REPUBLIC', 'code': 'CF', 'chinese_simplified': '中非共和国', 'chinese_traditional': '中非共和國' },
    { 'name': 'CHAD', 'code': 'TD', 'chinese_simplified': '乍得', 'chinese_traditional': '乍得' },
    { 'name': 'CHILE', 'code': 'CL', 'chinese_simplified': '智利', 'chinese_traditional': '智利' },
    { 'name': 'CHRISTMAS ISLAND', 'code': 'CX', 'chinese_simplified': '圣诞岛', 'chinese_traditional': '聖誕島' },
    { 'name': 'COCOS (KEELING) ISLANDS', 'code': 'CC', 'chinese_simplified': '科科斯（基林）群岛', 'chinese_traditional': '科科斯（基林）群島' },
    { 'name': 'COLOMBIA', 'code': 'CO', 'chinese_simplified': '哥伦比亚', 'chinese_traditional': '哥倫比亞' },
    { 'name': 'COMOROS', 'code': 'KM', 'chinese_simplified': '科摩罗', 'chinese_traditional': '科摩羅' },
    { 'name': 'CONGO', 'code': 'CG', 'chinese_simplified': '刚果', 'chinese_traditional': '剛果' },
    { 'name': 'CONGO, THE DEMOCRATIC REPUBLIC OF THE', 'code': 'CD', 'chinese_simplified': '刚果民主共和国', 'chinese_traditional': '剛果民主共和國' },
    { 'name': 'COOK ISLANDS', 'code': 'CK', 'chinese_simplified': '库克群岛', 'chinese_traditional': '庫克群島' },
    { 'name': 'COSTA RICA', 'code': 'CR', 'chinese_simplified': '哥斯达黎加', 'chinese_traditional': '哥斯達黎加' },
    { 'name': 'CÔTE D\'IVOIRE', 'code': 'CI', 'chinese_simplified': '科特迪瓦', 'chinese_traditional': '科特迪瓦' },
    { 'name': 'CROATIA', 'code': 'HR', 'chinese_simplified': '克罗地亚', 'chinese_traditional': '克羅地亞' },
    { 'name': 'CUBA', 'code': 'CU', 'chinese_simplified': '古巴', 'chinese_traditional': '古巴' },
    { 'name': 'CURAÇAO', 'code': 'CW', 'chinese_simplified': '库拉索', 'chinese_traditional': '庫拉索' },
    { 'name': 'CYPRUS', 'code': 'CY', 'chinese_simplified': '塞浦路斯', 'chinese_traditional': '塞浦路斯' },
    { 'name': 'CZECH REPUBLIC', 'code': 'CZ', 'chinese_simplified': '捷克共和国', 'chinese_traditional': '捷克共和國' },
    { 'name': 'DENMARK', 'code': 'DK', 'chinese_simplified': '丹麦', 'chinese_traditional': '丹麥' },
    { 'name': 'DJIBOUTI', 'code': 'DJ', 'chinese_simplified': '吉布提', 'chinese_traditional': '吉布提' },
    { 'name': 'DOMINICA', 'code': 'DM', 'chinese_simplified': '多米尼克', 'chinese_traditional': '多米尼克' },
    { 'name': 'DOMINICAN REPUBLIC', 'code': 'DO', 'chinese_simplified': '多米尼加共和国', 'chinese_traditional': '多米尼加共和國' },
    { 'name': 'ECUADOR', 'code': 'EC', 'chinese_simplified': '厄瓜多尔', 'chinese_traditional': '厄瓜多爾' },
    { 'name': 'EGYPT', 'code': 'EG', 'chinese_simplified': '埃及', 'chinese_traditional': '埃及' },
    { 'name': 'EL SALVADOR', 'code': 'SV', 'chinese_simplified': '萨尔瓦多', 'chinese_traditional': '薩爾瓦多' },
    { 'name': 'EQUATORIAL GUINEA', 'code': 'GQ', 'chinese_simplified': '赤道几内亚', 'chinese_traditional': '赤道幾內亞' },
    { 'name': 'ERITREA', 'code': 'ER', 'chinese_simplified': '厄立特里亚', 'chinese_traditional': '厄立特里亞' },
    { 'name': 'ESTONIA', 'code': 'EE', 'chinese_simplified': '爱沙尼亚', 'chinese_traditional': '愛沙尼亞' },
    { 'name': 'ETHIOPIA', 'code': 'ET', 'chinese_simplified': '埃塞俄比亚', 'chinese_traditional': '埃塞俄比亞' },
    { 'name': 'FALKLAND ISLANDS (MALVINAS)', 'code': 'FK', 'chinese_simplified': '福克兰群岛', 'chinese_traditional': '福克蘭群島' },
    { 'name': 'FAROE ISLANDS', 'code': 'FO', 'chinese_simplified': '法罗群岛', 'chinese_traditional': '法羅群島' },
    { 'name': 'FIJI', 'code': 'FJ', 'chinese_simplified': '斐济', 'chinese_traditional': '斐濟' },
    { 'name': 'FINLAND', 'code': 'FI', 'chinese_simplified': '芬兰', 'chinese_traditional': '芬蘭' },
    { 'name': 'FRANCE', 'code': 'FR', 'chinese_simplified': '法国', 'chinese_traditional': '法國' },
    { 'name': 'FRENCH GUIANA', 'code': 'GF', 'chinese_simplified': '法属圭亚那', 'chinese_traditional': '法屬圭亞那' },
    { 'name': 'FRENCH POLYNESIA', 'code': 'PF', 'chinese_simplified': '法属波利尼西亚', 'chinese_traditional': '法屬波利尼西亞' },
    { 'name': 'FRENCH SOUTHERN TERRITORIES', 'code': 'TF', 'chinese_simplified': '法属南部领土', 'chinese_traditional': '法屬南部領土' },
    { 'name': 'GABON', 'code': 'GA', 'chinese_simplified': '加蓬', 'chinese_traditional': '加蓬' },
    { 'name': 'GAMBIA', 'code': 'GM', 'chinese_simplified': '冈比亚', 'chinese_traditional': '岡比亞' },
    { 'name': 'GEORGIA', 'code': 'GE', 'chinese_simplified': '格鲁吉亚', 'chinese_traditional': '格魯吉亞' },
    { 'name': 'GERMANY', 'code': 'DE', 'chinese_simplified': '德国', 'chinese_traditional': '德國' },
    { 'name': 'GHANA', 'code': 'GH', 'chinese_simplified': '加纳', 'chinese_traditional': '加納' },
    { 'name': 'GIBRALTAR', 'code': 'GI', 'chinese_simplified': '直布罗陀', 'chinese_traditional': '直布羅陀' },
    { 'name': 'GREECE', 'code': 'GR', 'chinese_simplified': '希腊', 'chinese_traditional': '希臘' },
    { 'name': 'GREENLAND', 'code': 'GL', 'chinese_simplified': '格陵兰', 'chinese_traditional': '格陵蘭' },
    { 'name': 'GRENADA', 'code': 'GD', 'chinese_simplified': '格林纳达', 'chinese_traditional': '格林納達' },
    { 'name': 'GUADELOUPE', 'code': 'GP', 'chinese_simplified': '瓜德罗普', 'chinese_traditional': '瓜德羅普' },
    { 'name': 'GUAM', 'code': 'GU', 'chinese_simplified': '关岛', 'chinese_traditional': '關島' },
    { 'name': 'GUATEMALA', 'code': 'GT', 'chinese_simplified': '危地马拉', 'chinese_traditional': '危地馬拉' },
    { 'name': 'GUERNSEY', 'code': 'GG', 'chinese_simplified': '根西岛', 'chinese_traditional': '根西島' },
    { 'name': 'GUINEA', 'code': 'GN', 'chinese_simplified': '几内亚', 'chinese_traditional': '幾內亞' },
    { 'name': 'GUINEA-BISSAU', 'code': 'GW', 'chinese_simplified': '几内亚比绍', 'chinese_traditional': '幾內亞比紹' },
    { 'name': 'GUYANA', 'code': 'GY', 'chinese_simplified': '圭亚那', 'chinese_traditional': '圭亞那' },
    { 'name': 'HAITI', 'code': 'HT', 'chinese_simplified': '海地', 'chinese_traditional': '海地' },
    { 'name': 'HEARD ISLAND AND MCDONALD ISLANDS', 'code': 'HM', 'chinese_simplified': '赫德岛和麦克唐纳群岛', 'chinese_traditional': '赫德島和麥克唐納群島' },
    { 'name': 'HOLY SEE (VATICAN CITY STATE)', 'code': 'VA', 'chinese_simplified': '梵蒂冈（梵蒂冈城国）', 'chinese_traditional': '梵蒂岡（梵蒂岡城國）' },
    { 'name': 'HONDURAS', 'code': 'HN', 'chinese_simplified': '洪都拉斯', 'chinese_traditional': '洪都拉斯' },
    { 'name': 'HONG KONG', 'code': 'HK', 'chinese_simplified': '香港', 'chinese_traditional': '香港' },
    { 'name': 'HUNGARY', 'code': 'HU', 'chinese_simplified': '匈牙利', 'chinese_traditional': '匈牙利' },
    { 'name': 'ICELAND', 'code': 'IS', 'chinese_simplified': '冰岛', 'chinese_traditional': '冰島' },
    { 'name': 'INDIA', 'code': 'IN', 'chinese_simplified': '印度', 'chinese_traditional': '印度' },
    // { 'name': 'INDONESIA', 'code': 'ID', 'chinese_simplified': '印度尼西亚', 'chinese_traditional': '印度尼西亞' },
    { 'name': 'IRAN, ISLAMIC REPUBLIC OF', 'code': 'IR', 'chinese_simplified': '伊朗伊斯兰共和国', 'chinese_traditional': '伊朗伊斯蘭共和國' },
    { 'name': 'IRAQ', 'code': 'IQ', 'chinese_simplified': '伊拉克', 'chinese_traditional': '伊拉克' },
    { 'name': 'IRELAND', 'code': 'IE', 'chinese_simplified': '爱尔兰', 'chinese_traditional': '愛爾蘭' },
    { 'name': 'ISLE OF MAN', 'code': 'IM', 'chinese_simplified': '马恩岛', 'chinese_traditional': '馬恩島' },
    { 'name': 'ISRAEL', 'code': 'IL', 'chinese_simplified': '以色列', 'chinese_traditional': '以色列' },
    { 'name': 'ITALY', 'code': 'IT', 'chinese_simplified': '意大利', 'chinese_traditional': '意大利' },
    { 'name': 'JAMAICA', 'code': 'JM', 'chinese_simplified': '牙买加', 'chinese_traditional': '牙買加' },
    // { 'name': 'JAPAN', 'code': 'JP', 'chinese_simplified': '日本', 'chinese_traditional': '日本' },
    { 'name': 'JERSEY', 'code': 'JE', 'chinese_simplified': '泽西岛', 'chinese_traditional': '澤西島' },
    { 'name': 'JORDAN', 'code': 'JO', 'chinese_simplified': '约旦', 'chinese_traditional': '約旦' },
    { 'name': 'KAZAKHSTAN', 'code': 'KZ', 'chinese_simplified': '哈萨克斯坦', 'chinese_traditional': '哈薩克斯坦' },
    { 'name': 'KENYA', 'code': 'KE', 'chinese_simplified': '肯尼亚', 'chinese_traditional': '肯尼亞' },
    { 'name': 'KIRIBATI', 'code': 'KI', 'chinese_simplified': '基里巴斯', 'chinese_traditional': '基里巴斯' },
    { 'name': "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF", 'code': 'KP', 'chinese_simplified': '朝鲜', 'chinese_traditional': '朝鮮' },
    // { 'name': 'KOREA, REPUBLIC OF', 'code': 'KR', 'chinese_simplified': '韩国', 'chinese_traditional': '韓國' },
    { 'name': 'KUWAIT', 'code': 'KW', 'chinese_simplified': '科威特', 'chinese_traditional': '科威特' },
    { 'name': 'KYRGYZSTAN', 'code': 'KG', 'chinese_simplified': '吉尔吉斯斯坦', 'chinese_traditional': '吉爾吉斯斯坦' },
    { 'name': 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC', 'code': 'LA', 'chinese_simplified': '老挝人民民主共和国', 'chinese_traditional': '老撾人民民主共和國' },
    { 'name': 'LATVIA', 'code': 'LV', 'chinese_simplified': '拉脱维亚', 'chinese_traditional': '拉脫維亞' },
    { 'name': 'LEBANON', 'code': 'LB', 'chinese_simplified': '黎巴嫩', 'chinese_traditional': '黎巴嫩' },
    { 'name': 'LESOTHO', 'code': 'LS', 'chinese_simplified': '莱索托', 'chinese_traditional': '萊索托' },
    { 'name': 'LIBERIA', 'code': 'LR', 'chinese_simplified': '利比里亚', 'chinese_traditional': '利比里亞' },
    { 'name': 'LIBYA', 'code': 'LY', 'chinese_simplified': '利比亚', 'chinese_traditional': '利比亞' },
    { 'name': 'LIECHTENSTEIN', 'code': 'LI', 'chinese_simplified': '列支敦士登', 'chinese_traditional': '列支敦士登' },
    { 'name': 'LITHUANIA', 'code': 'LT', 'chinese_simplified': '立陶宛', 'chinese_traditional': '立陶宛' },
    { 'name': 'LUXEMBOURG', 'code': 'LU', 'chinese_simplified': '卢森堡', 'chinese_traditional': '盧森堡' },
    { 'name': 'MACAO', 'code': 'MO', 'chinese_simplified': '澳门', 'chinese_traditional': '澳門' },
    { 'name': 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF', 'code': 'MK', 'chinese_simplified': '马其顿，前南斯拉夫共和国', 'chinese_traditional': '馬其頓，前南斯拉夫共和國' },
    { 'name': 'MADAGASCAR', 'code': 'MG', 'chinese_simplified': '马达加斯加', 'chinese_traditional': '馬達加斯加' },
    { 'name': 'MALAWI', 'code': 'MW', 'chinese_simplified': '马拉维', 'chinese_traditional': '馬拉維' },
    // { 'name': 'MALAYSIA', 'code': 'MY', 'chinese_simplified': '马来西亚', 'chinese_traditional': '馬來西亞' },
    { 'name': 'MALDIVES', 'code': 'MV', 'chinese_simplified': '马尔代夫', 'chinese_traditional': '馬爾代夫' },
    { 'name': 'MALI', 'code': 'ML', 'chinese_simplified': '马里', 'chinese_traditional': '馬里' },
    { 'name': 'MALTA', 'code': 'MT', 'chinese_simplified': '马耳他', 'chinese_traditional': '馬耳他' },
    { 'name': 'MARSHALL ISLANDS', 'code': 'MH', 'chinese_simplified': '马绍尔群岛', 'chinese_traditional': '馬紹爾群島' },
    { 'name': 'MARTINIQUE', 'code': 'MQ', 'chinese_simplified': '马提尼克', 'chinese_traditional': '馬提尼克' },
    { 'name': 'MAURITANIA', 'code': 'MR', 'chinese_simplified': '毛里塔尼亚', 'chinese_traditional': '毛里塔尼亞' },
    { 'name': 'MAURITIUS', 'code': 'MU', 'chinese_simplified': '毛里求斯', 'chinese_traditional': '毛里求斯' },
    { 'name': 'MAYOTTE', 'code': 'YT', 'chinese_simplified': '马约特', 'chinese_traditional': '馬約特' },
    { 'name': 'MEXICO', 'code': 'MX', 'chinese_simplified': '墨西哥', 'chinese_traditional': '墨西哥' },
    { 'name': 'MICRONESIA, FEDERATED STATES OF', 'code': 'FM', 'chinese_simplified': '密克罗尼西亚联邦', 'chinese_traditional': '密克羅尼西亞聯邦' },
    { 'name': 'MOLDOVA, REPUBLIC OF', 'code': 'MD', 'chinese_simplified': '摩尔多瓦共和国', 'chinese_traditional': '摩爾多瓦共和國' },
    { 'name': 'MONACO', 'code': 'MC', 'chinese_simplified': '摩纳哥', 'chinese_traditional': '摩納哥' },
    { 'name': 'MONGOLIA', 'code': 'MN', 'chinese_simplified': '蒙古', 'chinese_traditional': '蒙古' },
    { 'name': 'MONTENEGRO', 'code': 'ME', 'chinese_simplified': '黑山', 'chinese_traditional': '黑山' },
    { 'name': 'MONTSERRAT', 'code': 'MS', 'chinese_simplified': '蒙特塞拉特', 'chinese_traditional': '蒙特塞拉特' },
    { 'name': 'MOROCCO', 'code': 'MA', 'chinese_simplified': '摩洛哥', 'chinese_traditional': '摩洛哥' },
    { 'name': 'MOZAMBIQUE', 'code': 'MZ', 'chinese_simplified': '莫桑比克', 'chinese_traditional': '莫桑比克' },
    { 'name': 'MYANMAR', 'code': 'MM', 'chinese_simplified': '缅甸', 'chinese_traditional': '緬甸' },
    { 'name': 'NAMIBIA', 'code': 'NA', 'chinese_simplified': '纳米比亚', 'chinese_traditional': '納米比亞' },
    { 'name': 'NAURU', 'code': 'NR', 'chinese_simplified': '瑙鲁', 'chinese_traditional': '瑙魯' },
    { 'name': 'NEPAL', 'code': 'NP', 'chinese_simplified': '尼泊尔', 'chinese_traditional': '尼泊爾' },
    { 'name': 'NETHERLANDS', 'code': 'NL', 'chinese_simplified': '荷兰', 'chinese_traditional': '荷蘭' },
    { 'name': 'NEW CALEDONIA', 'code': 'NC', 'chinese_simplified': '新喀里多尼亚', 'chinese_traditional': '新喀里多尼亞' },
    { 'name': 'NEW ZEALAND', 'code': 'NZ', 'chinese_simplified': '新西兰', 'chinese_traditional': '新西蘭' },
    { 'name': 'NICARAGUA', 'code': 'NI', 'chinese_simplified': '尼加拉瓜', 'chinese_traditional': '尼加拉瓜' },
    { 'name': 'NIGER', 'code': 'NE', 'chinese_simplified': '尼日尔', 'chinese_traditional': '尼日爾' },
    { 'name': 'NIGERIA', 'code': 'NG', 'chinese_simplified': '尼日利亚', 'chinese_traditional': '尼日利亞' },
    { 'name': 'NIUE', 'code': 'NU', 'chinese_simplified': '纽埃', 'chinese_traditional': '紐埃' },
    { 'name': 'NORFOLK ISLAND', 'code': 'NF', 'chinese_simplified': '诺福克岛', 'chinese_traditional': '諾福克島' },
    { 'name': 'NORTHERN MARIANA ISLANDS', 'code': 'MP', 'chinese_simplified': '北马里亚纳群岛', 'chinese_traditional': '北馬里亞納群島' },
    { 'name': 'NORWAY', 'code': 'NO', 'chinese_simplified': '挪威', 'chinese_traditional': '挪威' },
    { 'name': 'OMAN', 'code': 'OM', 'chinese_simplified': '阿曼', 'chinese_traditional': '阿曼' },
    { 'name': 'PAKISTAN', 'code': 'PK', 'chinese_simplified': '巴基斯坦', 'chinese_traditional': '巴基斯坦' },
    { 'name': 'PALAU', 'code': 'PW', 'chinese_simplified': '帕劳', 'chinese_traditional': '帕勞' },
    { 'name': 'PALESTINE, STATE OF', 'code': 'PS', 'chinese_simplified': '巴勒斯坦', 'chinese_traditional': '巴勒斯坦' },
    { 'name': 'PANAMA', 'code': 'PA', 'chinese_simplified': '巴拿马', 'chinese_traditional': '巴拿馬' },
    { 'name': 'PAPUA NEW GUINEA', 'code': 'PG', 'chinese_simplified': '巴布亚新几内亚', 'chinese_traditional': '巴布亞新幾內亞' },
    { 'name': 'PARAGUAY', 'code': 'PY', 'chinese_simplified': '巴拉圭', 'chinese_traditional': '巴拉圭' },
    { 'name': 'PERU', 'code': 'PE', 'chinese_simplified': '秘鲁', 'chinese_traditional': '秘魯' },
    { 'name': 'PHILIPPINES', 'code': 'PH', 'chinese_simplified': '菲律宾', 'chinese_traditional': '菲律賓' },
    { 'name': 'PITCAIRN', 'code': 'PN', 'chinese_simplified': '皮特凯恩', 'chinese_traditional': '皮特凱恩' },
    { 'name': 'POLAND', 'code': 'PL', 'chinese_simplified': '波兰', 'chinese_traditional': '波蘭' },
    { 'name': 'PORTUGAL', 'code': 'PT', 'chinese_simplified': '葡萄牙', 'chinese_traditional': '葡萄牙' },
    { 'name': 'PUERTO RICO', 'code': 'PR', 'chinese_simplified': '波多黎各', 'chinese_traditional': '波多黎各' },
    { 'name': 'QATAR', 'code': 'QA', 'chinese_simplified': '卡塔尔', 'chinese_traditional': '卡塔爾' },
    { 'name': 'RÉUNION', 'code': 'RE', 'chinese_simplified': '留尼汪', 'chinese_traditional': '留尼汪' },
    { 'name': 'ROMANIA', 'code': 'RO', 'chinese_simplified': '罗马尼亚', 'chinese_traditional': '羅馬尼亞' },
    { 'name': 'RUSSIAN FEDERATION', 'code': 'RU', 'chinese_simplified': '俄罗斯联邦', 'chinese_traditional': '俄羅斯聯邦' },
    { 'name': 'RWANDA', 'code': 'RW', 'chinese_simplified': '卢旺达', 'chinese_traditional': '盧旺達' },
    { 'name': 'SAINT BARTHÉLEMY', 'code': 'BL', 'chinese_simplified': '圣巴泰勒米', 'chinese_traditional': '聖巴泰勒米' },
    { 'name': 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA', 'code': 'SH', 'chinese_simplified': '圣赫勒拿、阿森松和特里斯坦达库尼亚', 'chinese_traditional': '聖赫勒拿、阿森松和特里斯坦達庫尼亞' },
    { 'name': 'SAINT KITTS AND NEVIS', 'code': 'KN', 'chinese_simplified': '圣基茨和尼维斯', 'chinese_traditional': '聖基茨和尼維斯' },
    { 'name': 'SAINT LUCIA', 'code': 'LC', 'chinese_simplified': '圣卢西亚', 'chinese_traditional': '聖盧西亞' },
    { 'name': 'SAINT MARTIN (FRENCH PART)', 'code': 'MF', 'chinese_simplified': '圣马丁（法国部分）', 'chinese_traditional': '聖馬丁（法國部分）' },
    { 'name': 'SAINT PIERRE AND MIQUELON', 'code': 'PM', 'chinese_simplified': '圣皮埃尔和密克隆', 'chinese_traditional': '聖皮埃爾和密克隆' },
    { 'name': 'SAINT VINCENT AND THE GRENADINES', 'code': 'VC', 'chinese_simplified': '圣文森特和格林纳丁斯', 'chinese_traditional': '聖文森特和格林納丁斯' },
    { 'name': 'SAMOA', 'code': 'WS', 'chinese_simplified': '萨摩亚', 'chinese_traditional': '薩摩亞' },
    { 'name': 'SAN MARINO', 'code': 'SM', 'chinese_simplified': '圣马力诺', 'chinese_traditional': '聖馬力諾' },
    { 'name': 'SAO TOME AND PRINCIPE', 'code': 'ST', 'chinese_simplified': '圣多美和普林西比', 'chinese_traditional': '聖多美和普林西比' },
    { 'name': 'SAUDI ARABIA', 'code': 'SA', 'chinese_simplified': '沙特阿拉伯', 'chinese_traditional': '沙特阿拉伯' },
    { 'name': 'SENEGAL', 'code': 'SN', 'chinese_simplified': '塞内加尔', 'chinese_traditional': '塞內加爾' },
    { 'name': 'SERBIA', 'code': 'RS', 'chinese_simplified': '塞尔维亚', 'chinese_traditional': '塞爾維亞' },
    { 'name': 'SEYCHELLES', 'code': 'SC', 'chinese_simplified': '塞舌尔', 'chinese_traditional': '塞舌爾' },
    { 'name': 'SIERRA LEONE', 'code': 'SL', 'chinese_simplified': '塞拉利昂', 'chinese_traditional': '塞拉利昂' },
    // { 'name': 'SINGAPORE', 'code': 'SG', 'chinese_simplified': '新加坡', 'chinese_traditional': '新加坡' },
    { 'name': 'SINT MAARTEN (DUTCH PART)', 'code': 'SX', 'chinese_simplified': '圣马丁（荷兰部分）', 'chinese_traditional': '聖馬丁（荷蘭部分）' },
    { 'name': 'SLOVAKIA', 'code': 'SK', 'chinese_simplified': '斯洛伐克', 'chinese_traditional': '斯洛伐克' },
    { 'name': 'SLOVENIA', 'code': 'SI', 'chinese_simplified': '斯洛文尼亚', 'chinese_traditional': '斯洛文尼亞' },
    { 'name': 'SOLOMON ISLANDS', 'code': 'SB', 'chinese_simplified': '所罗门群岛', 'chinese_traditional': '所羅門群島' },
    { 'name': 'SOMALIA', 'code': 'SO', 'chinese_simplified': '索马里', 'chinese_traditional': '索馬里' },
    { 'name': 'SOUTH AFRICA', 'code': 'ZA', 'chinese_simplified': '南非', 'chinese_traditional': '南非' },
    { 'name': 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS', 'code': 'GS', 'chinese_simplified': '南乔治亚岛和南桑威奇群岛', 'chinese_traditional': '南喬治亞島和南桑威奇群島' },
    { 'name': 'SOUTH SUDAN', 'code': 'SS', 'chinese_simplified': '南苏丹', 'chinese_traditional': '南蘇丹' },
    { 'name': 'SPAIN', 'code': 'ES', 'chinese_simplified': '西班牙', 'chinese_traditional': '西班牙' },
    { 'name': 'SRI LANKA', 'code': 'LK', 'chinese_simplified': '斯里兰卡', 'chinese_traditional': '斯里蘭卡' },
    { 'name': 'SUDAN', 'code': 'SD', 'chinese_simplified': '苏丹', 'chinese_traditional': '蘇丹' },
    { 'name': 'SURINAME', 'code': 'SR', 'chinese_simplified': '苏里南', 'chinese_traditional': '蘇里南' },
    { 'name': 'SVALBARD AND JAN MAYEN', 'code': 'SJ', 'chinese_simplified': '斯瓦尔巴和扬马延', 'chinese_traditional': '斯瓦爾巴和揚馬延' },
    { 'name': 'SWAZILAND', 'code': 'SZ', 'chinese_simplified': '斯威士兰', 'chinese_traditional': '斯威士蘭' },
    { 'name': 'SWEDEN', 'code': 'SE', 'chinese_simplified': '瑞典', 'chinese_traditional': '瑞典' },
    { 'name': 'SWITZERLAND', 'code': 'CH', 'chinese_simplified': '瑞士', 'chinese_traditional': '瑞士' },
    { 'name': 'SYRIAN ARAB REPUBLIC', 'code': 'SY', 'chinese_simplified': '叙利亚阿拉伯共和国', 'chinese_traditional': '敘利亞阿拉伯共和國' },
    { 'name': 'TAIWAN, PROVINCE OF CHINA', 'code': 'TW', 'chinese_simplified': '台湾，中国省', 'chinese_traditional': '台灣，中國省' },
    { 'name': 'TAJIKISTAN', 'code': 'TJ', 'chinese_simplified': '塔吉克斯坦', 'chinese_traditional': '塔吉克斯坦' },
    { 'name': 'TANZANIA, UNITED REPUBLIC OF', 'code': 'TZ', 'chinese_simplified': '坦桑尼亚联合共和国', 'chinese_traditional': '坦桑尼亞聯合共和國' },
    // { 'name': 'THAILAND', 'code': 'TH', 'chinese_simplified': '泰国', 'chinese_traditional': '泰國' },
    { 'name': 'TIMOR-LESTE', 'code': 'TL', 'chinese_simplified': '东帝汶', 'chinese_traditional': '東帝汶' },
    { 'name': 'TOGO', 'code': 'TG', 'chinese_simplified': '多哥', 'chinese_traditional': '多哥' },
    { 'name': 'TOKELAU', 'code': 'TK', 'chinese_simplified': '托克劳', 'chinese_traditional': '托克勞' },
    { 'name': 'TONGA', 'code': 'TO', 'chinese_simplified': '汤加', 'chinese_traditional': '湯加' },
    { 'name': 'TRINIDAD AND TOBAGO', 'code': 'TT', 'chinese_simplified': '特立尼达和多巴哥', 'chinese_traditional': '特立尼達和多巴哥' },
    { 'name': 'TUNISIA', 'code': 'TN', 'chinese_simplified': '突尼斯', 'chinese_traditional': '突尼斯' },
    { 'name': 'TURKEY', 'code': 'TR', 'chinese_simplified': '土耳其', 'chinese_traditional': '土耳其' },
    { 'name': 'TURKMENISTAN', 'code': 'TM', 'chinese_simplified': '土库曼斯坦', 'chinese_traditional': '土庫曼斯坦' },
    { 'name': 'TURKS AND CAICOS ISLANDS', 'code': 'TC', 'chinese_simplified': '特克斯和凯科斯群岛', 'chinese_traditional': '特克斯和凱科斯群島' },
    { 'name': 'TUVALU', 'code': 'TV', 'chinese_simplified': '图瓦卢', 'chinese_traditional': '圖瓦盧' },
    { 'name': 'UGANDA', 'code': 'UG', 'chinese_simplified': '乌干达', 'chinese_traditional': '烏干達' },
    { 'name': 'UKRAINE', 'code': 'UA', 'chinese_simplified': '乌克兰', 'chinese_traditional': '烏克蘭' },
    { 'name': 'UNITED ARAB EMIRATES', 'code': 'AE', 'chinese_simplified': '阿联酋', 'chinese_traditional': '阿聯酋' },
    { 'name': 'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND', 'code': 'GB', 'chinese_simplified': '大不列颠及北爱尔兰联合王国', 'chinese_traditional': '大不列顛及北愛爾蘭聯合王國' },
    { 'name': 'UNITED STATES OF AMERICA', 'code': 'US', 'chinese_simplified': '美利坚合众国', 'chinese_traditional': '美利堅合眾國' },
    { 'name': 'URUGUAY', 'code': 'UY', 'chinese_simplified': '乌拉圭', 'chinese_traditional': '烏拉圭' },
    { 'name': 'UZBEKISTAN', 'code': 'UZ', 'chinese_simplified': '乌兹别克斯坦', 'chinese_traditional': '烏茲別克斯坦' },
    { 'name': 'VANUATU', 'code': 'VU', 'chinese_simplified': '瓦努阿图', 'chinese_traditional': '瓦努阿圖' },
    { 'name': 'VENEZUELA (BOLIVARIAN REPUBLIC OF)', 'code': 'VE', 'chinese_simplified': '委内瑞拉（玻利瓦尔共和国）', 'chinese_traditional': '委內瑞拉（玻利瓦爾共和國）' },
    // { 'name': 'VIET NAM', 'code': 'VN', 'chinese_simplified': '越南', 'chinese_traditional': '越南' },
    { 'name': 'WESTERN SAHARA', 'code': 'EH', 'chinese_simplified': '西撒哈拉', 'chinese_traditional': '西撒哈拉' },
    { 'name': 'YEMEN', 'code': 'YE', 'chinese_simplified': '也门', 'chinese_traditional': '也門' },
    { 'name': 'ZAMBIA', 'code': 'ZM', 'chinese_simplified': '赞比亚', 'chinese_traditional': '贊比亞' },
    { 'name': 'ZIMBABWE', 'code': 'ZW', 'chinese_simplified': '津巴布韦', 'chinese_traditional': '津巴布韋' },
]

export default countries;