import { connect, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "../../../css/tutorial.scss";
import { translate } from "../../../utils/translate";
import { Link } from "react-router-dom";
import { api } from "../../../utils/api";
import { logout } from "../../../store/actions/AuthActions";
import LoadingV3 from "../../components/Loading/LoadingV3";
import NotFound from "../Common/NotFound";

const HedgePosition = (props) => {
    const dispatch = useDispatch();
    const [productPerPage, setProductPerpage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [announcementList, setAnnouncementList] = useState([]);
    const [loadingAnnouncement, setLoadingAnnouncement] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            getAnnouncement();
            setFirstLoad(false);
        }
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            setLoadingAnnouncement(true)
            setPageNumber(1);
            getAnnouncement(1);
        }
    }, [productPerPage, props.lang]);

    const changePage = async (event) => {
        setPageNumber(event.selected + 1);
        await getAnnouncement(event.selected + 1);
    }

    const getAnnouncement = (page = pageNumber) => {
        let url = `${api.customer_get_announcements}?page=${page}&limit=${productPerPage}&type=hedge`;

        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingAnnouncement(false);
                }, 1000); 
                if (responseJson.status === "success") {
                    setAnnouncementList(responseJson.data.results);
                    var pageCount = Math.ceil(responseJson.data.total_results / productPerPage)
                    setPageCount(pageCount);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className="bg-transparent tutorial pb-5">
                <Row className="px-0 mx-0 justify-content-start align-items-stretch mt-3">
                    {loadingAnnouncement ? (
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <LoadingV3 theme={'light'} />
                        </Col>
                    ) : (
                        announcementList.length > 0 ? (
                            announcementList.map((item, i) => (
                                <Col lg={4} md={6} sm={12} xs={12} key={i} className="mb-3">
                                    <Link to={`/hedgedAccount/${item.announcement_id}`}>
                                        <div className="sub-content p-2 h-100">
                                            <div className="small-img-container">
                                                {item.attachment_image ? (
                                                    <img src={item.attachment_image} className="img-fluid" />
                                                ) : null}
                                                <div className="after"></div>
                                            </div>
                                            <div className="desc-content px-3 py-3">
                                                <div>
                                                    <span className="font-weight-600 font-16 text-white letter-spacing-03p wordBreak">{item.title}</span>
                                                </div>
                                                <div className="mt-2">
                                                    <span className="font-weight-600 font-14 op6 text-white lh-sm letter-spacing-03p break-white-space">{item.description.length > 80 ? item.description.substring(0, 60) + "..." : item.description}</span>
                                                </div>
                                                <div className="mt-2">
                                                    <span className="font-weight-400 font-14 op6 text-white letter-spacing-03p wordBreak">{translate(props.lang, "Posted at")} {item.date_added}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            ))
                        ) : (
                            <div className="py-5 text-center tableBgContainer">
                                <NotFound />
                            </div>
                        )
                    )}
                </Row>
                <div className="pt-4">
                    {!loadingAnnouncement && announcementList.length > 0 ? (
                        <div className="d-flex w-100 justify-content-center">
                            <div className="mt-3 pagination-container py-3 d-inline-block">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    pageCount={pageCount}
                                    onPageChange={(e) => changePage(e)}
                                    forcePage={pageNumber - 1}
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                    marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(HedgePosition);