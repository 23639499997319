let en = null;
let zh_cn = null;
let ja = null;
let zh_tw = null;

export let translate = (lang, key) => {
    switch (lang) {
        case "en":
            if (en === null) {
                en = require("../language/en").default;
            }
            if (en[key]) {
                return en[key];
            } else {
                return key;
            }

        case "zh_cn":
            if (zh_cn === null) {
                zh_cn = require("../language/zh_cn").default;
            }
            if (zh_cn[key]) {
                return zh_cn[key];
            } else {
                return key;
            }
        case "zh_tw":
            if (zh_tw === null) {
                zh_tw = require("../language/zh_tw").default;
            }
            if (zh_tw[key]) {
                return zh_tw[key];
            } else {
                return key;
            }
        case "ja":
            if (ja === null) {
                ja = require("../language/ja").default;
            }
            if (ja[key]) {
                return ja[key];
            } else {
                return key;
            }
        default:
            if (en === null) {
                en = require("../language/en").default;
            }
            if (en[key]) {
                return en[key];
            } else {
                return key;
            }
    }
};