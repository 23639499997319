import { useEffect, useRef, useState } from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import { Modal } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { Col, Input, Row, Table } from "reactstrap";
import swal from "sweetalert";
import "../../../css/investment.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat, formatTwoDecimal, truncateToTwoDecimals } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from "../../components/Loading/LoadingV3";
import customSelectStyles from "../SelectStyle";
import InfoBoxV2 from "../Common/InfoBoxV2";
import NotFound from "../Common/NotFound";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";
import SpecialAccount from "./SpecialAccount";

const Investment = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [investmentSummary, setInvestmentSummary] = useState({
        total_assets: 0,
        total_trade_profit: 0,
        account_details: []
    });
    const [pageNumber, setPageNumber] = useState(1);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [loadingAccount, setLoadingAccount] = useState(true);
    const [lockPeriod, setLockPeriod] = useState([]);
    const [tradingProductPerpage, setTradingProductPerpage] = useState(10);
    const [tradePageCount, setTradePageCount] = useState(0);
    const [tradingPageNumber, setTradingPageNumber] = useState(1);
    const [tradingResult, setTradingResult] = useState([])
    const [tradeFrom, setTradeFrom] = useState("");
    const [tradeTo, setTradeTo] = useState("");
    const [loadingTrading, setLoadingTrading] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingInvestment, setLoadingInvestment] = useState(true);
    const [selectedPackage, setSelectedPackage] = useState('');
    const [selectedPackageInfo, setSelectedPackageInfo] = useState('');
    const [withdrawFee, setWithdrawFee] = useState('');
    const [groupId, setGroupId] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [loadingRenew, setLoadingRenew] = useState(false);
    const [trxPasswordModal, setTrxPasswordModal] = useState(false);
    const [mt5AccountList, setMt5AccountList] = useState([]);
    const [open, setOpen] = useState(false);
    const [renewModal, setRenewModal] = useState(false);
    const [localeSetting, setLocaleSetting] = useState({});
    let errorObj = { transaction_password: '', mt5_transaction_id: '', lock_period_id: '' };
    const [errors, setErrors] = useState(errorObj)
    const [formData, setFormData] = useState({
        mt5_transaction_id: '',
        transaction_password: '',
    });
    const [renewFormData, setRenewFormData] = useState({
        lock_period_id: '',
    });

    const groupIdRef = useRef(groupId);
    const timerRef = useRef(null);
    const timerTradeRef = useRef(null);

    useEffect(() => {
        groupIdRef.current = groupId;
    }, [groupId]);

    useEffect(() => {
        setLocaleSetting({
            applyLabel: translate(props.lang, 'Confirm'), // Change "Apply" button text to "Confirm"
            cancelLabel: translate(props.lang, 'Cancel'),
            monthNames:
                [
                    translate(props.lang, 'January'),
                    translate(props.lang, 'February'),
                    translate(props.lang, 'March'),
                    translate(props.lang, 'April'),
                    translate(props.lang, 'May'),
                    translate(props.lang, 'June'),
                    translate(props.lang, 'July'),
                    translate(props.lang, 'August'),
                    translate(props.lang, 'September'),
                    translate(props.lang, 'October'),
                    translate(props.lang, 'November'),
                    translate(props.lang, 'December')
                ],
            daysOfWeek: [
                translate(props.lang, 'Sun'),
                translate(props.lang, 'Mon'),
                translate(props.lang, 'Tue'),
                translate(props.lang, 'Wed'),
                translate(props.lang, 'Thu'),
                translate(props.lang, 'Fri'),
                translate(props.lang, 'Sat'),
            ]
        })
    }, [props.lang])

    const changeTradePage = async (event) => {
        setTradingPageNumber(event.selected + 1);
        await getTradingHistory(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            getTradingHistory();
            setFirstLoad(false);
        }
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            setTradingPageNumber(1);
            getTradingHistory(1)
        }
    }, [props.lang, tradeFrom, tradeTo, props.auth.auth.access_token, groupId]);

    useEffect(() => {
        getProfile()
    }, [])

    useEffect(async () => {
        getAssets();
    }, [props.lang, pageNumber, from, to, props.auth.auth.access_token]);

    useEffect(() => {
        fetch(api.get_lock_period, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setLockPeriod(responseJson.data);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log(translate(props.lang, "Dashboard"), responseJson.message);
                }
            }).catch(error => {
                console.error("error", error);
            });
    }, [props.auth.auth.access_token, props.lang])

    const getProfile = () => {
        fetch(api.get_profile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": "EN",
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setMt5AccountList(responseJson.data.mt5_accounts)
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getAssets = () => {
        setLoadingAccount(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_assets;
            if (from && to) {
                url += `?account_detail_date_start=${from}&account_detail_date_end=${to}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang,
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoadingInvestment(false);
                        setLoadingAccount(false)
                    }, 1000);
                    if (responseJson.status === "success") {
                        setInvestmentSummary(responseJson.data)
                    } else {
                        if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        console.log('error', responseJson.message)
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000)
    }

    const getTradingHistory = (page = tradingPageNumber) => {
        setLoadingTrading(true);
        clearTimeout(timerTradeRef.current);
        timerTradeRef.current = setTimeout(() => {
            let url = api.get_mt5_deal_details + "?page=" + page + "&limit=" + tradingProductPerpage;
            if (tradeFrom && tradeTo) {
                url += `&date_start=${tradeFrom}&date_end=${tradeTo}`
            }
            if (groupId) {
                url += `&mt5_login=${groupId}`;
            }

            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoadingTrading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setTradingResult(responseJson.data.results);
                        var pageCount = Math.ceil(responseJson.data.total_results / tradingProductPerpage)
                        setTradePageCount(pageCount);
                    } else {
                        if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        console.log("error", responseJson.message);
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000)
    }

    const selectSurrenderPackage = async (item, allowWithdraw) => {
        if (allowWithdraw) {
            // setWithdrawFee(item.penalty_rate);
            // setSelectedPackageInfo(item);
            // setSelectedPackage(item.mt5_transaction_id);
            // setTrxPasswordModal(true);
            // let newFormData = { ...formData };
            // newFormData.mt5_transaction_id = item.mt5_transaction_id;
            // newFormData.amount = Number(item.balance);
            // newFormData.transaction_password = '';
            // setFormData(newFormData);
        } else {
            swal(translate(props.lang, "Notice"), translate(props.lang, "Capital withdrawal only allowed on Saturdays and Sundays."), 'info');
        }
    }

    const closeTrxPasswordModal = () => {
        setTrxPasswordModal(false);
        setSelectedPackage('');
        setErrors(errorObj)
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const confirmTransfer = () => {
        let check = true;
        const errorMsg = { ...errorObj };
        let newFormData = { ...formData };

        if (!formData.transaction_password) {
            errorMsg.transaction_password = 'This field is required.';
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            // delete newFormData.amount;
            // setLoading(true);
            // fetch(api.withdraw_mt5, {
            //     method: "POST",
            //     headers: new Headers({
            //         "Content-Type": "application/json",
            //         "Accept-Language": props.lang,
            //         "Authorization": "Bearer " + props.auth.auth.access_token
            //     }),
            //     body: JSON.stringify(newFormData)
            // })
            //     .then(response => response.json())
            //     .then(responseJson => {
            //         setLoading(false);
            //         if (responseJson.status === "success") {
            //             closeTrxPasswordModal();
            //             setTimeout(() => {
            //                 setSuccessModal(true);
            //             }, 500);
            //         } else {
            //             if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
            //                 dispatch(logout(props.history, 'login'));
            //             }
            //             swal(translate(props.lang, "Error"), responseJson.message, "error");
            //         }
            //     }).catch(error => {
            //         console.error("error", error);
            //     });
        }
    }

    const closeSuccessModal = () => {
        setSuccessModal(false);
        setSelectedPackageInfo(null);
        history.push('/wallet/transaction-history');
        setFormData(errorObj);
    }

    const calculateFee = (amount, fee) => {
        let fee_decimal = parseFloat(fee) / 100;
        let final_amount = amount * (1 - fee_decimal);
        return Number(final_amount).toLocaleString('en-US', digitFormat)
    }

    const clearDateSearch = () => {
        setFrom('');
        setTo('')
    }

    const clearTradeDateSearch = () => {
        setTradeFrom('');
        setTradeTo('')
    }

    const handleDateChange = (e, picker) => {
        let startDate = moment(picker.startDate).format('YYYY-MM-DD');
        let endDate = moment(picker.endDate).format('YYYY-MM-DD');
        setFrom(startDate ? startDate : '')
        setTo(endDate ? endDate : '')
    };

    const handleTradeDateChange = (e, picker) => {
        let startDate = moment(picker.startDate).format('YYYY-MM-DD');
        let endDate = moment(picker.endDate).format('YYYY-MM-DD');
        setTradeFrom(startDate ? startDate : '')
        setTradeTo(endDate ? endDate : '')
    };

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        let newFormData = { ...renewFormData };
        newFormData[type] = value;
        setRenewFormData(newFormData)
    }

    const openRenewModal = (item) => {
        setSelectedPackageInfo(item);
        setSelectedPackage(item.mt5_transaction_id);
        setRenewModal(true);
        setRenewFormData({ lock_period_id: '' })
    }

    const closeRenewModal = () => {
        setSelectedPackageInfo('');
        setSelectedPackage('');
        setRenewModal(false);
        setRenewFormData({ lock_period_id: '' })
    }

    const renewContract = () => {
        let check = true;
        const errorMsg = { ...errorObj };
        let newFormData = { ...renewFormData };

        if (!renewFormData.lock_period_id) {
            errorMsg.lock_period_id = 'This field is required.';
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            // setLoadingRenew(true);
            // fetch(api.renew_mt5_lock_period + `/${selectedPackage}`, {
            //     method: "PUT",
            //     headers: new Headers({
            //         "Content-Type": "application/json",
            //         "Accept-Language": props.lang,
            //         "Authorization": "Bearer " + props.auth.auth.access_token
            //     }),
            //     body: JSON.stringify(newFormData)
            // })
            //     .then(response => response.json())
            //     .then(responseJson => {
            //         setLoadingRenew(false);
            //         if (responseJson.status === "success") {
            //             closeRenewModal();
            //             swal(translate(props.lang, "Success"), responseJson.message, "success");
            //             getAssets();
            //         } else {
            //             if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
            //                 dispatch(logout(props.history, 'login'));
            //             }
            //             swal(translate(props.lang, "Error"), responseJson.message, "error");
            //         }
            //     }).catch(error => {
            //         console.error("error", error);
            //     });
        }
    }

    const getOptionLabel = (option) => (
        option.value ? (
            <div className="d-flex w-100 align-items-center">
                <div className='text-end pe-1' style={{ minWidth: props.lang.toLowerCase() === 'en' ? '60px' : '45px' }}>
                    <span className="text-capitalize">{option.label}</span>
                </div>
                <div>
                    <span className="text-capitalize">({translate(props.lang, "Extra Profit")} {option.commission_rate}%)</span>
                </div>
            </div>
        ) : (
            <div className="text-center">
                <span className="mt-2 text-capitalize">{option.label}</span>
            </div>
        )
    )

    return (
        <>
            <div className='pb-5 investment'>
                <Row className="align-items-center justify-content-center mx-0 px-0 mt-3">
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                        <InfoBoxV2
                            icon={require('../../../images/icon2/totalAssets.svg').default}
                            boxLabel={translate(props.lang, "My Total Assets")}
                            boxCurrency={`USD`}
                            boxValue={`${investmentSummary && investmentSummary.total_assets ? Number(investmentSummary.total_assets).toLocaleString('en-US', digitFormat) : "0.00"}`}
                            loading={loadingInvestment}
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                        <InfoBoxV2
                            icon={require('../../../images/icon2/investProfitLoss.svg').default}
                            boxLabel={translate(props.lang, "Total Profit/Loss")}
                            boxCurrency={`USD`}
                            boxValue={`${investmentSummary && investmentSummary.total_trade_profit ? Number(investmentSummary.total_trade_profit).toLocaleString('en-US', digitFormat) : "0.00"}`}
                            loading={loadingInvestment}
                        />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="align-items-center px-0 mx-0 mt-md-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                    <div className="">
                                        <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Purchase Package Details")}</span>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-md-0">
                                    {/* <RangePicker
                                        className="w-100 mt-0 input-transparent"
                                        placeholder={[
                                            translate(props.lang, "Start"),
                                            translate(props.lang, "End"),
                                        ]}
                                        onChange={(e) => handleTimeChange(e, 'account')}
                                    /> */}
                                    <div className="groupLabel-input date px-2 py-3 py-md-2">
                                        <DateRangePicker
                                            onApply={handleDateChange}
                                            startDate={from}
                                            endDate={to}
                                            initialSettings={{ locale: localeSetting }}
                                            key={JSON.stringify(localeSetting)}
                                        >
                                            <span className="font-16 font-weight-600 text-black">
                                                <Row className="align-items-center justify-content-between px-0 mx-0">
                                                    <Col lg={4} md={4} sm={4} xs={4} className="ps-0 text-center">
                                                        <span className={`font-18 font-weight-600 ${from ? "text-black" : "placeholder-color"}`}>{from ? from : translate(props.lang, "Start")}</span>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2} className="px-0 text-center">
                                                        <img src={require('../../../images/icon2/dateArrow.svg').default} className="img-fluid" />

                                                    </Col>
                                                    <Col lg={4} md={4} sm={4} xs={4} className="pe-0 text-center">
                                                        <span className={`font-18 font-weight-600 ${to ? "text-black" : "placeholder-color"}`}>{to ? to : translate(props.lang, "End")}</span>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2} className="text-end pe-0">
                                                        {from && to ? (
                                                            <div className="d-flex w-100 justify-content-end" onClick={() => clearDateSearch()}>
                                                                <div className="gold-indicatior-container d-flex align-items-center justify-content-end">
                                                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <img src={require('../../../images/icon2/calendar.svg').default} className="img-fluid calendar-icon" />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </span>
                                        </DateRangePicker>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center px-0 mx-0 mt-4">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="tableContainer">
                                        <Table responsive className="w-100 table-list text-center position-relative">
                                            <thead>
                                                <tr>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Purchase Datetime")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Investment Allocation")} (USD)</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Contract Period")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Start Date")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Extra Profit")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Cumulative Investment Days")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "MT5 Account")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "MT5 Login ID1")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Status")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loadingInvestment || loadingAccount ? (
                                                    <tr role="row">
                                                        <td colSpan={10}>
                                                            <LoadingV3 theme='light' />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    investmentSummary.account_details.length > 0 ? (
                                                        investmentSummary.account_details.map((item, i) => (
                                                            <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                                <td className={`font-weight-500 lh-base`} style={{ minWidth: '150px' }}>
                                                                    <span className="d-md-inline-block d-block pe-md-1">{item.date_added.split(" ")[0]}</span>
                                                                    <span className="d-md-inline-block d-block">{item.date_added.split(" ")[1]}</span>
                                                                </td>
                                                                <td className={`font-weight-500 font-16 text-center lh-base`} style={{ minWidth: '100px' }}>{item.balance ? Number(item.balance).toLocaleString('en-US', digitFormat) : '0.00'}</td>
                                                                <td className={`font-weight-500 font-16 text-center lh-base`}>{item.lock_days ? item.lock_days : 0} {translate(props.lang, "Days")}</td>
                                                                <td className={`font-weight-500 font-16 text-center lh-base`} style={{ minWidth: '100px' }}>{item.lock_period_start_date ? item.lock_period_start_date : '-'}</td>
                                                                <td className={`font-weight-500 font-16 text-center lh-base`}>{item.commission_rate ? item.commission_rate : '-'}</td>
                                                                <td className={`font-weight-500 font-16 text-center lh-base`}>{item.copy_days ? item.copy_days : 0} {translate(props.lang, "Days")}</td>
                                                                <td className={`font-weight-500 font-16 text-center lh-base`} style={{ minWidth: props.lang === 'en' ? '150px' : '100px' }}>{translate(props.lang, "MT5 Account")} {item.mt5_group_id ? item.mt5_group_id : '0'}</td>
                                                                <td className={`font-weight-500 font-16 text-center lh-base`}>{item.mt5_login ? item.mt5_login : '-'}</td>
                                                                <td className={`font-weight-500 font-16 text-center text-capitalize`} style={{ minWidth: '100px' }}>
                                                                    <span className={`statusBox px-3 py-1 ${item.status.toLowerCase()}`}>{translate(props.lang, item.status.toLowerCase())}</span>
                                                                </td>

                                                                <td className={`font-weight-500 font-16 text-center`} style={{ minWidth: props.lang.toLowerCase() === 'en' ? '300px' : '150px' }}>
                                                                    <div className="d-flex align-items-center w-100">
                                                                    <button className="deleteBtn px-3 py-1 text-center" disabled={selectedPackage === item.mt5_transaction_id && loading} onClick={() => selectSurrenderPackage(item, item.withdraw_flag)}>
                                                                        <span className={`font-weight-600 font-16 line-height-30`}>{translate(props.lang, "Withdraw")}</span>
                                                                    </button>
                                                                    {item.lock_period_status?.toLowerCase() !== 'active' ? (
                                                                        <button className={`primary-btn rounded px-3 py-1 text-center ms-2`} disabled={selectedPackage === item.mt5_transaction_id && loadingRenew} onClick={() => openRenewModal(item)}>
                                                                            {selectedPackage === item.mt5_transaction_id && loadingRenew ? (<FaSpinner className="fa-spin" />) : (<span className={`font-weight-600 font-16 line-height-30`}>{translate(props.lang, "Renew Contract")}</span>)}
                                                                        </button>
                                                                    ) : null}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr className="py-5 text-center">
                                                            <td colSpan={10}>
                                                                <NotFound />
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <SpecialAccount />
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="align-items-center justify-content-between px-0 mx-0 mt-md-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                    <div className="">
                                        <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Investment Details")}</span>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-md-0">
                                    <div className="groupLabel-input date px-2 py-3 py-md-2">
                                        <DateRangePicker
                                            onApply={handleTradeDateChange}
                                            startDate={tradeFrom}
                                            endDate={tradeTo}
                                            initialSettings={{ locale: localeSetting }}
                                            key={JSON.stringify(localeSetting)}
                                        >
                                            <span className="font-16 font-weight-600 text-black">
                                                <Row className="align-items-center justify-content-between px-0 mx-0">
                                                    <Col lg={4} md={4} sm={4} xs={4} className="ps-0 text-center">
                                                        <span className={`font-18 font-weight-600 ${tradeFrom ? "text-black" : "placeholder-color"}`}>{tradeFrom ? tradeFrom : translate(props.lang, "Start")}</span>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2} className="px-0 text-center">
                                                        <img src={require('../../../images/icon2/dateArrow.svg').default} className="img-fluid" />

                                                    </Col>
                                                    <Col lg={4} md={4} sm={4} xs={4} className="pe-0 text-center">
                                                        <span className={`font-18 font-weight-600 ${to ? "text-black" : "placeholder-color"}`}>{tradeTo ? tradeTo : translate(props.lang, "End")}</span>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2} className="text-end pe-0">
                                                        {tradeFrom && tradeTo ? (
                                                            <div className="d-flex w-100 justify-content-end" onClick={() => clearTradeDateSearch()}>
                                                                <div className="gold-indicatior-container d-flex align-items-center justify-content-end">
                                                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <img src={require('../../../images/icon2/calendar.svg').default} className="calendar-icon" />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </span>
                                        </DateRangePicker>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-md-0">
                                    <div className={`groupLabel-select select-placeholder-center py-1 py-md-0 px-2 mb-1`}>
                                        <Select
                                            // options={Array.from({ length: 4 }, (_, i) => i).map((item, i) => ({ key: i, value: item + 1, label: translate(props.lang, "MT5 Account") + " " + (item + 1) }))}
                                            options={mt5AccountList.map((item, i) => ({ key: i, value: item.mt5_login_id, label: `${translate(props.lang, "MT5 Account")} ${item.mt5_group_id} - ${item.mt5_login_id}` }))}
                                            placeholder={translate(props.lang, "Select MT5 Account")}
                                            className="input-transparent w-100 text-capitalize "
                                            styles={customSelectStyles}
                                            value={
                                                groupId ? mt5AccountList.filter(item => item.mt5_login_id === groupId).map((item, i) => ({ key: i, value: item.mt5_login_id, label: `${translate(props.lang, "MT5 Account")} ${item.mt5_group_id} - ${item.mt5_login_id}` })) : ''
                                            }
                                            // value={groupId ? Array.from({ length: 4 }, (_, i) => i).filter(item => item + 1 === groupId).length > 0 ? Array.from({ length: 4 }, (_, i) => i).filter(item => item + 1 === groupId).map((item, i) => ({ key: i, value: item + 1, label: translate(props.lang, "MT5 Account") + " " + (item + 1) })) : null : null}
                                            isSearchable={false}
                                            isClearable={true}
                                            name="groupId"
                                            onChange={(e) => setGroupId(e ? e.value : '')}
                                        // onChange={(e) => changeGroupId(e ? e.value : '')}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center px-0 mx-0 mt-4">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="tableContainer">
                                        <Table responsive className="w-100 table-list text-center position-relative">
                                            <thead>
                                                <tr>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Date & Time")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Order ID")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Trading Pair")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Lot Size")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Price Enter")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Price Close")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Action")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Profit/Loss %")}</th>
                                                    <th className={`font-weight-600 font-16 text-black-1 text-center`}>{translate(props.lang, "Status")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loadingTrading ? (
                                                    <tr role="row">
                                                        <td colSpan={9}>
                                                            <LoadingV3 theme='light' />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    tradingResult.length > 0 ? (
                                                        tradingResult.map((item, i) => (
                                                            <tr role="row" key={i} className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`}>
                                                                <td className={`font-weight-500 lh-base`} style={{ minWidth: '150px' }}>
                                                                    <span className="d-md-inline-block d-block pe-md-1">{item.date_added.split(" ")[0]}</span>
                                                                    <span className="d-md-inline-block d-block">{item.date_added.split(" ")[1]}</span>
                                                                </td>
                                                                <td className={`font-weight-500 font-16 text-center padding`}>{item.order ? item.order : '-'}</td>
                                                                <td className={`font-weight-500 font-16 text-center padding`}>{item.symbol ? item.symbol : '-'}</td>
                                                                <td className={`font-weight-500 font-16 text-center padding`}>{item.volume ? item.volume : '-'}</td>
                                                                <td className={`font-weight-500 font-16 text-center padding`}>{item.price_position ? item.price_position : 0}</td>
                                                                <td className={`font-weight-500 font-16 text-center padding`}>{item.price ? item.price : 0}</td>
                                                                <td className={`font-weight-500 font-16 text-center padding text-capitalize status`} style={{ minWidth: '100px' }}>{item.action_type}</td>
                                                                <td className={`font-weight-500 font-16 text-center padding`}>
                                                                    <span className={`font-weight-500 font-16 text-center text-capitalize ${item.profit ? item.profit < 0 ? "status failed" : item.profit > 0 ? "status active" : '' : ''}`}>{item.profit ? truncateToTwoDecimals(item.profit) : 0}</span>
                                                                </td>
                                                                <td className={`font-weight-500 font-16 text-center text-capitalize padding`} style={{ minWidth: '100px' }}>{item.status.toLowerCase() === 'active' ? translate(props.lang, "completed") : translate(props.lang, item.status.toLowerCase())}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr className="py-5 text-center bg-transparent">
                                                            <td colSpan={9}>
                                                                <NotFound />
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>

                                        </Table>
                                    </div>

                                    <div className="pt-4">
                                        {!loadingTrading && tradingResult.length > 0 ? (
                                            <div className="d-flex w-100 justify-content-center">
                                                <div className="mt-3 pagination-container py-3 d-inline-block">
                                                    <ReactPaginate
                                                        previousLabel={"<"}
                                                        nextLabel={">"}
                                                        pageCount={tradePageCount}
                                                        onPageChange={(e) => changeTradePage(e)}
                                                        forcePage={tradingPageNumber - 1}
                                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                                        disabledClassName={"pagination__link--disabled text-muted"}
                                                        activeClassName={"pagination__link--active text-white"}
                                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="fade modalColorBg" show={trxPasswordModal} centered>
                <Modal.Header className="border-0 pt-4 pb-2 px-4 px-md-5">
                    <div className="modalHeader d-flex w-100 align-items-center">
                        <div className="w-75">
                        </div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeTrxPasswordModal()}>
                            <img src={require('../../../images/icon2/modal-close.svg').default} alt="close" />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-4 px-md-5">
                    <div className="text-center mb-4">
                        <span className="font-weight-600 font-30 text-white">{translate(props.lang, "Withdraw")}</span>
                    </div>
                    <div className="mb-2">
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <span className="font-weight-500 font-18 op8 line-height-26 text-white d-block">{translate(props.lang, "From")}</span>
                            <span className="font-weight-700 font-20  line-height-30 text-white d-block">{translate(props.lang, "MT5 Balance")}</span>
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <span className="font-weight-500 font-18 op6 line-height-26 text-white d-block">{translate(props.lang, "To")}</span>
                            <span className="font-weight-700 font-20  line-height-30 text-white d-block">{translate(props.lang, "Wallet")}</span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className={`groupLabel-input py-2 mb-1 ${errors.amount ? 'errorBorder' : ''}`}>
                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Withdraw Amount")}</span>
                            <div className={`input-group `}>
                                <Input
                                    type="text"
                                    name="amount"
                                    onWheel={event => event.currentTarget.blur()}
                                    placeholder={translate(props.lang, "Your Withdraw Amount")}
                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                    value={Number(formData.amount).toLocaleString('en-US', digitFormat)}
                                    autoComplete="off"
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='ms-4'>
                            <span className="font-weight-400 font-14 errorTextRed d-block">{translate(props.lang, errors.amount)}</span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className={`groupLabel-input py-2 mb-1`}>
                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Penalty Fee")}</span>
                            <Input
                                type="text"
                                placeholder={translate(props.lang, 'Penalty Fee')}
                                className="form-control input-transparent py-1 px-md-4 px-3"
                                value={withdrawFee}
                                autoComplete="off"
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className={`groupLabel-input py-2 mb-1 ${errors.transaction_password ? 'errorBorder' : ''}`}>
                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Transaction Password")} <span className='text-red'>*</span></span>
                            <div className={`input-group`}>
                                <Input
                                    type="password"
                                    name="transaction_password"
                                    placeholder={translate(props.lang, 'Enter Transaction Password')}
                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                    value={formData.transaction_password}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div className="mx-4">
                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.transaction_password)}</span>
                        </div>
                    </div>
                    <div className="py-3 card-custom-bg mt-3">
                        <span className="font-13 text-white font-weight-400">{translate(props.lang, "Receive Amount")}</span>
                        <div className="mt-3">
                            <span className="font-30 text-white font-weight-400">{calculateFee(formData.amount, withdrawFee)}</span>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => confirmTransfer()} disabled={loading}>
                            <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Submit")}</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="fade modalColorBg" show={successModal} centered>
                <Modal.Body className="border-0 px-4 py-5">
                    <Row className="justify-content-center align-items-center mx-0 px-0">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <div className="text-center mb-4">
                                <img src={require('../../../images/icon2/transferSuccess.svg').default} className='img-fluid' alt="success" />
                            </div>
                            <div className="text-center mb-3">
                                <span className="font-weight-600 font-30 text-white line-height-24 d-block mt-3 text-capitalize">{translate(props.lang, "Successful")}</span>
                            </div>
                            <div className="mb-3 text-center">
                                <span className='d-block mt-2 font-30 font-weight-600 text-capitalize text-white'>{calculateFee(formData.amount, withdrawFee)}</span>
                            </div>
                            <div className="mb-3">
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <span className="font-weight-400 font-16 op6 line-height-26 text-white d-block">{translate(props.lang, "From")}</span>
                                    <span className="font-weight-600 font-18  line-height-30 text-white d-block">{translate(props.lang, "MT5 Balance")}</span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <span className="font-weight-400 font-16 op6 line-height-26 text-white d-block">{translate(props.lang, "To")}</span>
                                    <span className="font-weight-600 font-18  line-height-30 text-white d-block">{translate(props.lang, "Wallet")}</span>
                                </div>
                            </div>
                            <div className="mt-3">
                                <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => closeSuccessModal()}>
                                    <span className={`btnText`}>{translate(props.lang, "Close")}</span>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal className="fade modalColorBg" show={renewModal} centered>
                <Modal.Header className="border-0 pt-4 pb-2 px-4 px-md-5">
                    <div className="modalHeader d-flex w-100 align-items-center">
                        <div className="w-75">
                        </div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeRenewModal()}>
                            <img src={require('../../../images/icon2/modal-close.svg').default} alt="close" />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-4 px-md-5">
                    <div className="text-center mb-5">
                        <span className="font-weight-600 font-30 text-white">{translate(props.lang, "Renew Contract")}</span>
                    </div>
                    <div className="mb-3">
                        <div className={`groupLabel-input py-2 mb-1`}>
                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Investment Allocation")}</span>
                            <Input
                                type="text"
                                placeholder={translate(props.lang, 'Investment Allocation')}
                                className="form-control input-transparent py-1 px-md-4 px-3"
                                value={selectedPackageInfo?.balance ? Number(selectedPackageInfo?.balance).toLocaleString('en-US', digitFormat) : '0.00'}
                                autoComplete="off"
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1 ${errors.lock_period_id ? 'errorBorder' : ''}`}>
                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, 'New Contract Period')} <span className="errorTextRed">*</span></span>
                            <div className={`filter-input-group`}>
                                <Select
                                    options={
                                        lockPeriod.length > 0 ? lockPeriod.map((item, i) => ({ key: i, commission_rate: item.commission_rate, value: item.lock_period_id, label: item.lock_days + " " + translate(props.lang, "Days") })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                    }
                                    placeholder={translate(props.lang, 'Select Contract Period')}
                                    className="input-transparent w-100 px-0"
                                    styles={customSelectStyles}
                                    isSearchable={false}
                                    menuPosition="fixed"
                                    getOptionLabel={getOptionLabel}
                                    value={
                                        renewFormData.lock_period_id ? lockPeriod.filter(item => item.lock_period_id === renewFormData.lock_period_id).map((item) => ({ commission_rate: item.commission_rate, value: item.lock_period_id, label: item.lock_days + " " + translate(props.lang, "Days") })) : ''
                                    }
                                    name="lock_period_id"
                                    onChange={(e) => handleOptionSelected(e, 'lock_period_id')}
                                />
                            </div>
                        </div>
                        <div className='ms-3 ms-md-4'>
                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.lock_period_id)}</span>
                        </div>
                    </div>
                    
                    {/* <div className="mt-3">
                        <div className={`groupLabel-input py-2 mb-1 ${errors.transaction_password ? 'errorBorder' : ''}`}>
                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Transaction Password")} <span className='text-red'>*</span></span>
                            <div className={`input-group`}>
                                <Input
                                    type="password"
                                    name="transaction_password"
                                    placeholder={translate(props.lang, 'Enter Transaction Password')}
                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                    value={formData.transaction_password}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div className="mx-4">
                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.transaction_password)}</span>
                        </div>
                    </div> */}
                    
                    <div className="mt-4">
                        <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => renewContract()} disabled={loadingRenew}>
                            <span className={`btnText`}>{loadingRenew ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Submit")}</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Investment);