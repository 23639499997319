import { connect } from "react-redux";
import { Col, Row, Input } from "reactstrap";
import "../../../css/settings.scss";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Select from "react-select";
import { FaSpinner } from "react-icons/fa";
import { api } from "../../../utils/api";
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import customSelectStyles from "../SelectStyle";
import countries from "../../../utils/countries";

const WithdrawalMethod = (props) => {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState([]);
    const [bankNameList, setBankNameList] = useState([]);
    const [chainOption, setChainOption] = useState([])
    let errorObj = { bank_name: '', bank_account_name: '', bank_account_number: '', bank_swift_code: '', bank_country: '', bank_address: '', coin_name: '', chain: '', address: '' };
    
    const [formData, setFormData] = useState({
        bank_name: '', 
        bank_account_name: '', 
        bank_account_number: '', 
        bank_swift_code: '', 
        bank_country: '', 
        bank_address: '', 
        coin_name: '', 
        chain: '', 
        address: '' 
    });
    const [errors, setErrors] = useState(errorObj);
    const history = useHistory();

    useEffect(() => {
        resetForm();
        return () => { };
    }, [tab])

    // useEffect(() => {
    //     getWithdrawInfo();
    //     getCountry();
    //     getBankName();
    //     return () => { };
    // }, [props.auth.auth.id_token]);

    const getWithdrawInfo = () => {
        fetch(api.withdrawInfo, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status=== "success") {
                    let chain = responseJson.data.bankInfoChainType.sort(function (a, b) {
                        return a.key.localeCompare(b.key);
                    });
                    setChainOption(chain);
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    console.log('error getWithdrawInfo', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getCountry = () => {
        fetch(api.getBankCountry + "?page=0&size=" + 300, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status=== "success") {
                    setCountry(responseJson.data);
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    console.log('error get country', responseJson)
                    // swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getBankName = () => {
        fetch(api.getBankName + "?page=0&size=" + 300, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status=== "success") {
                    setBankNameList(responseJson.data.content);
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    console.log('error get country', responseJson)
                    // swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    function submitWithdrawalCrypto() {
        let check = true;
        const errorMsg = { ...errorObj };

        if (!formData.address) {
            errorMsg.address = 'This field is required.';
            check = false;
        }

        if (!formData.chain) {
            errorMsg.chain = 'This field is required.';
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            let data = {
                address: formData.address,
                chain: formData.chain,
                coin_name: "USDT"
            };

            fetch(api.add_crypto_address, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if (responseJson.status=== "success") {
                        swal(translate(props.lang, 'Success'), responseJson.message, "success");
                        history.push('/wallet/bankInfo');
                    } else {
                        if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        if (responseJson.data && typeof responseJson.data.error === 'object') {
                            let apiErrorMsg = { ...errorObj };
                            let popupMessage = [];
                            Object.keys(responseJson.data.error).map((key) => {
                                apiErrorMsg[key] = responseJson.data.error[key];
                                popupMessage.push(responseJson.data.error[key])
                            })
                            setErrors(apiErrorMsg);
                            swal(translate(props.lang, "Error"), popupMessage.join('\n'), "error");
                        } else {
                            swal(translate(props.lang, "Error"), responseJson.message, "error");
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    function submitWithdrawalFiat() {
        let check = true;
        const errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.bank_country) {
            errorMsg.bank_country = 'This field is required.';
            check = false;
        }

        if (!formData.bank_name) {
            errorMsg.bank_name = 'This field is required.';
            check = false;
        }

        if (!formData.bank_account_name) {
            errorMsg.bank_account_name = 'This field is required.';
            check = false;
        }

        if (!formData.bank_account_number) {
            errorMsg.bank_account_number = 'This field is required.';
            check = false;
        }

        if (!formData.bank_address) {
            errorMsg.bank_address = 'This field is required.';
            check = false;
        }

        if (!formData.bank_swift_code) {
            errorMsg.bank_swift_code = 'This field is required.';
            check = false;
        }

        setErrors(errorMsg);
        if (check) {
            delete newFormData.coin_name;
            delete newFormData.chain;
            delete newFormData.address;

            setLoading(true);
            fetch(api.add_bank, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if (responseJson.status=== "success") {
                        swal(translate(props.lang, 'Success'), responseJson.message, "success");
                        history.push('/wallet/bankInfo');
                    } else {
                        if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        if (responseJson.data && typeof responseJson.data.error === 'object') {
                            let apiErrorMsg = { ...errorObj };
                            let popupMessage = [];
                            Object.keys(responseJson.data.error).map((key) => {
                                apiErrorMsg[key] = responseJson.data.error[key];
                                popupMessage.push(responseJson.data.error[key])
                            })
                            setErrors(apiErrorMsg);
                            swal(translate(props.lang, "Error"), popupMessage.join('\n'), "error");
                        } else {
                            swal(translate(props.lang, "Error"), responseJson.message, "error");
                        }
                        swal(translate(props.lang, "Error"), responseJson.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        setFormData({
            bank_name: '', 
            bank_account_name: '', 
            bank_account_number: '', 
            bank_swift_code: '', 
            bank_country: '', 
            bank_address: '', 
            coin_name: '', 
            chain: '', 
            address: '' 
        })
    }

    return (
        <>
            <div className='pb-5 withdrawmethod'>
                <Link to="/wallet/bankInfo" className="d-inline-block">
                    <div className="d-flex align-items-center">
                        <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                        <span className="font-weight-500 font-20 text-black line-height-39 ms-2">{translate(props.lang, "Back")}</span>
                    </div>
                </Link>
                <div className="tabContainer h-100 px-2 py-2 mt-3">
                    <Row className="justify-content-center align-items-center mx-0 px-0">
                        <Col lg={6} md={6} sm={6} xs={6} className="px-0">
                            <div className={`activeContainer ${tab === 0 ? 'active' : 'inactive'} text-center py-3 py-md-4`} onClick={(e) => setTab(0)}>
                                <span className={`font-weight-600 font-18 withdrawal-method-title ${tab === 0 ? 'active' : 'inactive'} break-white-space line-height-110percent`}>{translate(props.lang, "Fiat Withdrawal")}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="px-0">
                            <div className={`activeContainer ${tab === 1 ? 'active' : 'inactive'} text-center py-3 py-md-4`} onClick={(e) => setTab(1)}>
                                <span className={`font-weight-600 font-18 withdrawal-method-title ${tab === 1 ? 'active' : 'inactive'} break-white-space line-height-110percent`}>{translate(props.lang, "Crypto Withdrawal")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="whiteContainer px-md-5 px-2 py-4 my-3">
                    {tab === 0 ? (
                        <>
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-select px-md-4 px-3 mb-1 ${errors.bank_country ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Country")} <span className="errorTextRed">*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={
                                                        countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: props.lang === 'zh_cn' ? item.chinese_simplified :  props.lang === 'zh_tw' ? item.chinese_traditional : item.name })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                                    }
                                                    placeholder={translate(props.lang, "Select Bank Country")}
                                                    className="input-transparent w-100 text-capitalize"
                                                    styles={customSelectStyles}
                                                    value={
                                                        countries.filter(item => item.name == formData.bank_country).length > 0 ?
                                                            (countries.filter(item => item.name == formData.bank_country).map((item, i) => ({ key: i, value: item.name, label: props.lang === 'zh_cn' ? item.chinese_simplified :  props.lang === 'zh_tw' ? item.chinese_traditional : item.name })))
                                                            : null
                                                    }
                                                    name="bank_country"
                                                    isSearchable
                                                    onChange={(e) => handleOptionSelected(e, 'bank_country')}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.bank_country)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.bank_name ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Name")} <span className="errorTextRed">*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="bank_name"
                                                    placeholder={translate(props.lang, "Your Bank Name")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.bank_name}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.bank_name)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.bank_account_name ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Holder Name")} <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="bank_account_name"
                                                    placeholder={translate(props.lang, "Your Bank Account Holder Name")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.bank_account_name}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.bank_account_name)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.bank_account_number ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Number")} <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="bank_account_number"
                                                    placeholder={translate(props.lang, "Your Bank Account Number")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.bank_account_number}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.bank_account_number)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.bank_address ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Address")} <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="bank_address"
                                                    placeholder={translate(props.lang, "Your Bank Address")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.bank_address}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.bank_address)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.bank_swift_code ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Swift Code")} <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={"text"}
                                                    name="bank_swift_code"
                                                    placeholder={translate(props.lang, "Your Bank Swift Code")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.bank_swift_code}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.bank_swift_code)}</span>
                                    </div>
                                </Col>
                                
                                <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                                    <button className="primary-btn px-4 py-2 text-center  w-100" disabled={loading} onClick={() => submitWithdrawalFiat()}>
                                        <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Save")}</span>
                                    </button>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row className="justify-content-center align-items-center px-0 mx-0">
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.coin_name ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Coin")} <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="withdrawType"
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={"USDT | TetherUS"}
                                                    autoComplete="off"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.coin_name)}</span>
                                    </div>
                                </Col>

                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.address ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Wallet Address")}(USDT) <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="address"
                                                    placeholder={translate(props.lang, "Your Wallet Address")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.address}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.address)}</span>
                                    </div>
                                </Col>

                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    {/* <div className="text-start">
                                        <div className={`groupLabel-select px-md-4 px-3 mb-1  ${errors.chainType ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Network")} <span className="errorTextRed">*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={
                                                        chainOption.length > 0 ? chainOption.map((item, i) => ({ value: item.value, label: item.key, key: i })) : [{ value: '', label: translate(props.lang, "No network available") }]
                                                    }
                                                    placeholder={translate(props.lang, "Select Your Network")}
                                                    className="input-transparent w-100 text-capitalize px-0"
                                                    styles={customSelectStyles}
                                                    value={
                                                        formData.chainType ? chainOption.filter(item => item.value === formData.chainType).map((item) => ({ value: item.value, label: item.key })) : ''
                                                    }
                                                    name="chainType"
                                                    onChange={(e) => handleOptionSelected(e, 'chainType')}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.chainType)}</span>
                                    </div> */}
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.chain ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Network")}<span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="chain"
                                                    placeholder={translate(props.lang, "Network")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.chain}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.chain)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mt-5">
                                    <button className="primary-btn px-4 py-2 text-center mx-auto w-100" disabled={loading} onClick={() => submitWithdrawalCrypto()}>
                                        <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Save")}</span>
                                    </button>
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(WithdrawalMethod);