import React from 'react'
import { connect } from 'react-redux';
import { translate } from '../../../utils/translate';
import { Col, Row } from 'reactstrap';

function NotFound(props) {
    return (
        <>
            <Row className="align-items-center justify-content-center px-0 mx-0">
                <Col lg={2} md={2} sm={6} xs={6}>
                    <img src={require('../../../images/icon2/noResult.png').default} className="img-fluid" alt="no-data" />
                </Col>
            </Row>
            <span className="d-block text-white">
                {translate(props.lang, "Oops! No data found...")}
            </span>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NotFound);