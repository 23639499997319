import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import "../../../css/settings.scss";
import { Link } from "react-router-dom";
import { translate } from "../../../utils/translate";

const SettingOverview = (props) => {
    return (
        <>
            <div className='settings pb-5'>
                <Row className="px-0 mx-0 mt-3">
                    <Col lg={6} md={6} xs={12} sm={12} className="mb-4">
                        <div className="tableBgContainer py-2 py-lg-4">
                            <Link to="/settings/change-password">
                                <div className="card-content px-3 px-lg-4 py-4">
                                    <Row className="align-items-center mx-0 px-0">
                                        <Col lg={2} md={3} sm={2} xs={2} className="px-0">
                                            <img src={require('../../../images/icon2/changePassword.svg').default} alt="password" className="img-fluid" />
                                        </Col>
                                        <Col lg={8} md={7} sm={9} xs={9}>
                                            <span className="font-weight-600 font-22 text-white break-white-space lh-base">{translate(props.lang, "Change Password")}</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={1} xs={1} className="px-0">
                                            <div className="d-flex w-100 h-100 justify-content-end">
                                                <div className="arrow-icon-container d-inline-block text-end w-100 h-100">
                                                    <img src={require('../../../images/icon2/arrow-right.svg').default} className="img-fluid" alt="arrow" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Link>
                        </div>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12} className="mb-4">
                        <div className="tableBgContainer py-2 py-lg-4">
                            <Link to="/settings/change-transaction-password">
                                <div className="card-content px-3 px-lg-4 py-4">
                                    <Row className="align-items-center mx-0 px-0">
                                        <Col lg={2} md={3} sm={2} xs={2} className="px-0">
                                            <img src={require('../../../images/icon2/changeTrxPassword.svg').default} alt="trx-password" className="img-fluid" />
                                        </Col>
                                        <Col lg={8} md={7} sm={9} xs={9}>
                                            <span className="font-weight-600 font-22 text-white break-white-space lh-base">{translate(props.lang, "Change Transaction Password")}</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={1} xs={1} className="px-0">
                                            <div className="d-flex w-100 h-100 justify-content-end">
                                                <div className="arrow-icon-container d-inline-block text-end w-100 h-100">
                                                    <img src={require('../../../images/icon2/arrow-right.svg').default} className="img-fluid" alt="arrow" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(SettingOverview);