import React, { Fragment, useState } from "react";
// import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import Sidebar2 from "./SideBar2";


const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <Fragment>
      <NavHader />
      <Header
        toggle={toggle}
        title={title}
        onClick={() => ClickToAddEvent()}
      />
      <div className="navigation-backdrop-control position-absolute w-100 h-100 bg-semi-transparent d-none">
      </div>
      {/* <SideBar /> */}
      <Sidebar2 />
    </Fragment>
  );
};

export default JobieNav;
