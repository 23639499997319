import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import "../../../css/wallet.scss";
import { Link } from "react-router-dom";
import { translate } from "../../../utils/translate";

const Deposit = (props) => {
    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="px-0 mx-0 mt-3">
                    <Col lg={6} md={12} xs={12} sm={12} className="mb-4">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-4">
                            <Link to="/wallet/deposit/crypto">
                                <Row className="align-items-center mx-0 px-0 h-100">
                                    <Col lg={2} md={2} sm={2} xs={2} className="px-0">
                                        <img src={require('../../../images/icon2/crytodeposit.svg').default} alt="icon-wallet" className="img-fluid" />
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <span className="font-weight-600 font-22 text-white break-white-space line-height-110percent">{translate(props.lang, "Crypto Deposit")}</span>
                                        <div className="mt-2">
                                            <span className="font-weight-400 font-16 text-white break-white-space lh-base">{translate(props.lang, "Seamlessly Add Cryptocurrency To Your Account And Start Trading Effortlessly.")}</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} className="px-0">
                                        <div className="d-flex w-100 h-100 justify-content-end text-end">
                                            <img src={require('../../../images/icon2/arrow-right.svg').default} alt="icon-wallet" className="img-fluid" />
                                        </div>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </Col>
                    <Col lg={6} md={12} xs={12} sm={12} className="mb-4">
                        {/* <div className="whiteContainer px-2 py-4 py-lg-5 h-100">
                            <Link to="/wallet/deposit/fiat">
                                <Row className="align-items-center mx-0 px-0 h-100">
                                    <Col lg={2} md={2} sm={3} xs={3}>
                                        <img src={require('../../../images/wallet/fiatdeposit.svg').default} alt="icon-wallet" className="img-fluid" />
                                    </Col>
                                    <Col lg={9} md={9} sm={7} xs={7}>
                                        <span className="font-weight-600 font-20 white-container-title break-white-space line-height-110percent">{translate(props.lang, "Fiat Deposit")}</span>
                                        <div className="mt-2">
                                            <span className="font-weight-400 font-16 text-grey-1 break-white-space lh-sm">{translate(props.lang, "Conveniently Deposit Funds Using Your Preferred Fiat Currency, Ensuring A Smooth And Hassle-Free Experience.")}</span>
                                        </div>
                                    </Col>
                                    <Col lg={1} md={1} sm={2} xs={2}>
                                        <div className="d-flex w-100 h-100 justify-content-end text-end">
                                            <img src={require('../../../images/icon/blue-arrow.svg').default} alt="icon-wallet" className="img-fluid" />
                                        </div>
                                    </Col>
                                </Row>
                            </Link>
                        </div> */}
                    </Col>
                </Row>

            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Deposit);