import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/settings.scss";
import { logout } from '../../../store/actions/AuthActions';
import { api } from '../../../utils/api';
import { translate } from "../../../utils/translate";
import LoadingV3 from '../../components/Loading/LoadingV3';
import customSelectStyles from "../SelectStyle";
import { digitFormat } from '../../../utils/function';

const currencyOption = [
    { value: "MYR", label: "MYR" },
    { value: "SGD", label: "SGD" },
    { value: "THB", label: "THB" },
    { value: "VND", label: "VND" },
]

const merchantType = [
    { value: "TOP_PAY", label: "TOP_PAY (PromptPay QR)" }
]

const FiatDeposit = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    let errorsObj = {
        merchant: '',
        amount: '',
        orderNo: '',
        paymentAmount: '',
        currency: '',
    };
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState('');
    const [convertInfo, setConvertInfo] = useState(null);
    const [errors, setErrors] = useState(errorsObj);
    const [formData, setFormData] = useState({
        merchant: '',
        amount: '',
        orderNo: '',
        paymentAmount: '',
        currency: 'THB',
    })

    // useEffect(() => {
    //     getMethodInfo();
    //     return () => { };
    // }, []);

    useEffect(() => {
        if (formData.currency && formData.amount) {
            convertCurrency()
        }
    }, [formData.currency])

    const convertCurrency = () => {
        let check = true;
        let errorMsg = { ...errorsObj }

        if (!formData.currency) {
            errorMsg.currency = 'Please Select A Currency';
            check = false;
        }
        if (!formData.amount) {
            errorMsg.amount = 'This field is required.';
            check = false;
        } else {
            if (formData.amount <= 0) {
                errorMsg.amount = 'Amount must greater than 0.';
                check = false;
            }
        }
        setErrors(errorMsg);
        if (check) {
            fetch(api.topPayAddFundInfo + `?choosenPaymentType=${formData.currency}&amount=${formData.amount}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang,
                    "Authorization": "Bearer " + props.auth.auth.id_token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status=== "success") {
                        setConvertInfo(responseJson.data);
                    } else if (responseJson.status === "error") {
                        //dispatch(logout(props.history, 'login'));                    } else {
                        swal(translate(props.lang, "Error"), responseJson.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    // const getMethodInfo = () => {
    //     fetch(api.getFundMethodInfo, {
    //         method: "GET",
    //         headers: new Headers({
    //             "Content-Type": "application/json",
    //             "Accept-Language": props.lang,
    //             "Authorization": "Bearer " + props.auth.auth.id_token
    //         }),
    //     })
    //         .then(response => response.json())
    //         .then(responseJson => {
    //             if (responseJson.status=== "success") {
    //                 setDepositInfo(responseJson.data);
    //             } else if (responseJson.status === "error") {
    //                 //dispatch(logout(props.history, 'login'));    //             } else {
    //                 swal(translate(props.lang, "Error"), responseJson.message, "error");
    //             }

    //         }).catch(error => {
    //             console.error("error", error);
    //         });
    // }

    const getOrderID = (merchantType) => {
        fetch(api.generateOrderNo + `?merchantType=${merchantType}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status=== "success") {
                    let newFormData = { ...formData };
                    newFormData.orderNo = responseJson.data;
                    setFormData(newFormData);
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        if (type === 'merchant') {
            setSelectedMerchant(value);
            getOrderID(value);
        } else {
            if (type === 'currency') {
                setConvertInfo(null)
            }
            let newFormData = { ...formData };
            newFormData[type] = value;
            newFormData.amount = '';
            setFormData(newFormData);
        }

    }

    const handleAddFormChange = async (e) => {
        const { value, name } = e.target;
        let newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
    }

    function submitForm() {
        let check = true;
        let errorMsg = { ...errorsObj }
        var onlyNumber = /^\d+$/;

        if (!selectedMerchant) {
            errorMsg.merchant = 'This field is required.';
            check = false;
        }
        if (!formData.currency) {
            errorMsg.currency = 'This field is required.';
            check = false;
        }
        if (!formData.amount) {
            errorMsg.amount = 'This field is required.';
            check = false;
        } else {
            if (!onlyNumber.test(formData.amount)) {
                errorMsg.depositAmount = 'Only Numbers are allowed in this field';
                check = false;
            } else {
                if (formData.amount <= 0) {
                    errorMsg.amount = 'Amount must greater than 0.';
                    check = false;
                } else {
                    if (formData.amount < 1000 || formData.amount > 4000) {
                        errorMsg.amount = 'Amount must only within 1000 - 4000';
                        check = false;
                    }
                }
            }
        }
        setErrors(errorMsg);
        if (check) {
            // if (formData.currency === 'THB') {
                setLoading(true);
                let data = {
                    "orderNo": formData.orderNo,
                    "tradingAmount": formData.amount,
                }

                fetch(api.topPayAddFund, {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.id_token
                    }),
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);
                        if (responseJson.status === "SUCCESS") {
                            // setLoadingModal(true);
                            window.open(responseJson.data.url);
                            history.push('/wallet/transaction-history')
                        } else {
                            setLoading(false);
                            swal(translate(props.lang, "Error"), responseJson.message, "error");
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            // } else {
            //     swal({
            //         title: translate(props.lang, "Error"),
            //         text: translate(props.lang, "Your IP does not support the operation"),
            //         icon: "error",
            //     }).then((willDelete) => {
            //         if (willDelete) {
            //             // history.push('/dashboard');
            //         }
            //     })
            // }
        }
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="whiteContainer px-md-5 px-2 py-4">
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12} className='mb-4'>
                                    <div className="d-flex align-items-center w-100">
                                        <Link to="/wallet/deposit" className="cursor-pointer">
                                            <div className="d-flex align-items-center">
                                                <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                                            </div>
                                        </Link>
                                        <div className="ms-3">
                                            <span className="font-weight-600 font-22 line-height-26 text-black">{translate(props.lang, "Fiat Deposit")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-select px-md-4 px-3 mb-1 ${errors.merchant ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Merchant")} <span className="errorTextRed">*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={
                                                        merchantType.map((item, i) => ({ value: item.value, label: item.label, key: i }))
                                                    }
                                                    placeholder={translate(props.lang, "Select Merchant")}
                                                    className="input-transparent w-100 text-capitalize "
                                                    styles={customSelectStyles}
                                                    value={
                                                        selectedMerchant ? merchantType.filter(item => item.value === selectedMerchant).map((item, i) => ({ value: item.value, label: item.label, key: i })) : ''
                                                    }
                                                    name="merchant"
                                                    isSearchable={false}
                                                    onChange={(e) => handleOptionSelected(e, 'merchant')}
                                                />
                                            </div>
                                        </div>
                                        <div className='ms-4'>
                                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.merchant)}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupDisabled-input py-1 mb-1`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Order Number")}</span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    placeholder={translate(props.lang, "Your Order Number")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.orderNo}
                                                    autoComplete="off"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='ms-4'>
                                            <span className="font-weight-400 font-14 errorTextRed"></span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-select px-md-4 px-3 mb-1 ${errors.currency ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Currency")} <span className="errorTextRed">*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={
                                                        currencyOption.length > 0 ? currencyOption.map((item, i) => ({ value: item.value, label: item.label, key: i })) : [{ value: '', label: translate(props.lang, "No currency available") }]
                                                    }
                                                    placeholder={translate(props.lang, "Select Payment Currency")}
                                                    className="input-transparent w-100 text-capitalize px-0"
                                                    styles={customSelectStyles}
                                                    value={
                                                        formData.currency ? currencyOption.filter(item => item.value === formData.currency).map((item) => ({ value: item.value, label: item.label })) : ''
                                                    }
                                                    name="currency"
                                                    isSearchable={false}
                                                    onChange={(e) => handleOptionSelected(e, 'currency')}
                                                />
                                            </div>
                                        </div>
                                        <div className='ms-4'>
                                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.currency)}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.amount ? 'errorBorder' : ''}`}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className="w-75">
                                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Deposit Amount")}(USD) <span className="errorTextRed">*</span></span>
                                                    <div className={`input-group `}>
                                                        <Input
                                                            type='number'
                                                            step={0.01}
                                                            onWheel={event => event.currentTarget.blur()}
                                                            name="amount"
                                                            placeholder={translate(props.lang, "Your Deposit Amount")}
                                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                                            value={formData.amount}
                                                            autoComplete="off"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='pe-2 pe-md-3'>
                                                    <div className='d-flex justify-content-end align-items-center w-100 h-100'>
                                                        <button className="requestCodeBtn px-lg-4 px-2 py-3 cursor-pointer w-100" onClick={() => convertCurrency()}>
                                                            <span className={`font-weight-600 font-16 text-white text-nowrap`}>{translate(props.lang, "Convert")}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ms-4">
                                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.amount)}</span>
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.amount ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Deposit Amount")}(USD) <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type='number'
                                                    step={0.01}
                                                    onWheel={event => event.currentTarget.blur()}
                                                    name="amount"
                                                    placeholder={translate(props.lang, "Your Deposit Amount")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.amount}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='ms-4'>
                                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.amount)}</span>
                                        </div>
                                    </div>
                                </Col> */}
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                    <div className="text-start">
                                        <div className={`groupDisabled-input px-md-4 px-3 py-1 mb-1`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Amount")}</span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="paymentAmount"
                                                    placeholder={translate(props.lang, "Your Payment Amount")}
                                                    className="form-control input-transparent py-1"
                                                    value={convertInfo && convertInfo.convertedAmount ? convertInfo.convertedAmount : '-'}
                                                    autoComplete="off"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='ms-4'>
                                            <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.convertedAmount)}</span>
                                        </div>
                                    </div>
                                </Col>
                                {convertInfo && convertInfo.exchange_rate ? (
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                        <div className="text-start depositAddressBg py-3 px-md-4 px-3">
                                            <div>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-1">{translate(props.lang, "Exchange Rate")}</span>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-1">{convertInfo.exchange_rate}</span>
                                            </div>
                                        </div>
                                    </Col>
                                ) : null}
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                    <div className='ms-4'>
                                        <span className="font-weight-600 font-14 text-red line-height-24">*{translate(props.lang, "Minimum to maximum transaction is 1000-4000 USD")}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className='mt-3'>
                                    <button className="primary-btn px-4 py-2 text-center mx-auto w-100" disabled={loading} onClick={() => submitForm()}>
                                        <span className={`btnText`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Confirm")}</span>
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="fade loadingModal" show={loadingModal} size="xs" centered >
                <Modal.Body className="border-0 p-0">
                    <div className="text-center py-3">
                        <LoadingV3 theme="light" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(FiatDeposit);