import moment from "moment";
import { useEffect, useState } from 'react';
import { Modal, Table } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import QRCode from "react-qr-code";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Col, Row } from "reactstrap";
import "../../../css/dashboard.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { copyText, digitFormat, getCurrentMonthDates, getCurrentWeekDates } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from '../../components/Loading/LoadingV3';
import Wallet from "./Wallet";
import customSelectStyles from "../SelectStyle";
import InfoBoxV2 from "../Common/InfoBoxV2";
import RankingInfo from "../Common/RankingInfo";
import { useHistory } from "react-router-dom";

const filterType = [
    { label: 'week', value: 'week' },
    { label: 'month', value: 'month' },
]

const Dashboard = (props) => {
    const history = useHistory();
    var options = {
        legend: {
            labels: {
                usePointStyle: true,
                fontColor: "black"
            }
        },
        scales: {
            yAxes: [{
                // ticks: {
                //    fontColor: "#000063",
                //    callback: function (value) {
                //       return value + '%';
                //    }
                // },
                ticks: {
                    fontColor: "#ffffff",
                    min: 0,
                    // max: 10000,

                    // stepSize: 10000, // <----- This prop sets the stepSize
                    callback: function (value) {
                        return Number(value).toLocaleString('en-US');
                    }
                }

            }],
            xAxes: [{
                ticks: {
                    fontColor: "#ffffff",
                }

            }]
        },
    };
    const [dashboardInfo, setDashboardInfo] = useState({
        wallet_balance: '',
        mt5_balance: '',
        hedge_balance: '',
        total_trade_profit: '',
        total_commission_profit: '',
        mt5_accounts: [],
    });
    const dispatch = useDispatch();
    const [loadingDashboard, setLoadingDashboard] = useState(true);
    const [loadingGraph, setLoadingGraph] = useState(true);
    const [announcementModal, setAnnouncementModal] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [rankData, setRankData] = useState([]);
    const [allRank, setAllRank] = useState([]);
    const [filterGraph, setFilterGraph] = useState('week')
    const [loadingRank, setLoadingRank] = useState(false);
    const [tab, setTab] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [dataOption, setDataOption] = useState(options);
    const [data, setData] = useState([]);
    const [productPerPage, setProductPerpage] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [announcementList, setAnnouncementList] = useState([]);
    const [loadingAnnouncement, setLoadingAnnouncement] = useState(true);
    const [rankList, setRankList] = useState([]);
    const [rankInfoModal, setRankInfoModal] = useState(false)

    useEffect(() => {
        if (refresh) {
            getDashboard();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        setLoadingGraph(true);
        getDashboard()
    }, [filterGraph, props.auth.auth.access_token])

    useEffect(() => {
        if (allRank.length > 0) {
            if (props.auth.profile.rank_name === 'SSIB') {
                setRankData(allRank);
            } else {
                setRankData(allRank.filter(item => item.status === 1));
            }
        }
    }, [props.auth.profile.rank_name, allRank, props.auth.auth.access_token]);

    useEffect(() => {
        setLoadingRank(true);
        fetch(api.get_rank, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingRank(false);
                }, 1000);
                if (responseJson.status === 'success') {
                    setRankList(responseJson.data)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }, [])

    useEffect(() => {
        getAnnouncement();
    }, [props.lang]);

    const getDashboard = () => {
        let url = api.get_dashboard;
        if (filterGraph) {
            url += `?type=${filterGraph}`;
        }

        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingDashboard(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setAllRank(responseJson.data.ranks)
                    setDashboardInfo(responseJson.data);
                    // let initialAccounts = [
                    //     { mt5_group_id: 1, mt5_login_id: '', mt5_login_password: '', customer_mt5_id: null, },
                    //     { mt5_group_id: 2, mt5_login_id: '', mt5_login_password: '', customer_mt5_id: null },
                    //     { mt5_group_id: 3, mt5_login_id: '', mt5_login_password: '', customer_mt5_id: null },
                    //     { mt5_group_id: 4, mt5_login_id: '', mt5_login_password: '', customer_mt5_id: null },
                    // ];

                    // setDashboardInfo({
                    //     ...responseJson.data,
                    //     mt5_accounts: initialAccounts
                    // });

                    // responseJson.data.mt5_accounts.forEach(account => {
                    //     const index = account.mt5_group_id - 1;
                    //     initialAccounts[index] = account;
                    // });

                    generateGraph(responseJson.data.group_sales_statistic)
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getAnnouncement = (page = pageNumber) => {
        let url = `${api.customer_get_announcements}?page=${page}&limit=${productPerPage}&type=announcement`;

        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingAnnouncement(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setAnnouncementList(responseJson.data.results);
                    var pageCount = Math.ceil(responseJson.data.total_results / productPerPage)
                    setPageCount(pageCount);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const generateGraph = (data) => {
        let time = [];
        if (filterGraph === 'day') {

        } else if (filterGraph === 'week') {
            const datesOfCurrentWeek = getCurrentWeekDates();
            datesOfCurrentWeek.forEach(date => time.push(moment(date).format('YYYY-MM-DD')));
        } else { //month
            const datesOfCurrentMonth = getCurrentMonthDates();
            datesOfCurrentMonth.forEach(date => time.push(moment(date).format('YYYY-MM-DD')));
        }
        let chart = {
            labels: time,
            datasets: [{
                label: translate(props.lang, "Sales"),
                data: [],
                fill: true,
                backgroundColor: "transparent",
                pointStyle: 'line',
                borderColor: getLinearGradient("#00FFBE", "#00FFBE"),
            }]
        }

        for (var j = 0; j < time.length; j++) {
            chart.datasets.map((x, y) => {
                if (data.length > 0) {
                    x.data.push(data[j].value ? data[j].value : 0)
                } else {
                    x.data.push(0)
                }

            })
        }
        setData(chart);
        var options = {
            legend: {
                labels: {
                    usePointStyle: true,
                    fontColor: "#fff"
                },
            },
            scales: {
                yAxes: [{
                    ticks: data.length > 0 ? ({
                        fontColor: "#fff",
                        fontWeight: '400',
                        min: 0,
                        // max: '',
                        // stepSize: 10000, // <----- This prop sets the stepSize
                        callback: function (value) {
                            return Number(value).toLocaleString('en-US');
                        }
                    }) : ({
                        fontColor: "#fff",
                        min: 0,
                        max: 10000,
                        stepSize: 1000, // <----- This prop sets the stepSize
                        callback: function (value) {
                            return Number(value).toLocaleString('en-US');
                        }
                    })

                }],
                xAxes: [{
                    ticks: {
                        fontColor: "#fff",
                        fontWeight: '400',
                    }

                }]
            }
        };
        setDataOption(options);
        setLoadingGraph(false);
    }

    const downloadImage = () => {
        const svg = document.getElementById("DepositDashboardQR");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");

            const downloadLink = document.createElement("a");
            downloadLink.download = "DepositDashboardQR";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };

        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }

    const getLinearGradient = (startColor, endColor) => {
        const ctx = document.createElement('canvas').getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, startColor);
        gradient.addColorStop(1, endColor);
        return gradient;
    };

    function openAnnouncementModal(item) {
        setSelectedAnnouncement(item);
        setAnnouncementModal(true);
    }

    return (
        <>
            <div className='dashboard pb-5'>
                <Row className="justify-content-start px-0 mx-0 mt-3">
                    <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
                        <Wallet
                            boxCurrency={`USD`}
                            walletBalance={`${dashboardInfo.wallet_balance ? Number(dashboardInfo.wallet_balance).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loadingDashboard}
                            setRefresh={setRefresh}
                        />
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
                        <InfoBoxV2
                            icon={require('../../../images/icon2/mt5Balance.svg').default}
                            boxLabel={translate(props.lang, "MT5 Balance")}
                            boxCurrency={`USD`}
                            boxValue={`${dashboardInfo.mt5_balance ? Number(dashboardInfo.mt5_balance).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loadingDashboard}
                        />
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
                        <InfoBoxV2
                            icon={require('../../../images/icon2/hedgePosition.svg').default}
                            boxLabel={translate(props.lang, "Hedged Position")}
                            boxCurrency={`USD`}
                            boxValue={`${dashboardInfo.hedge_balance ? Number(dashboardInfo.hedge_balance).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loadingDashboard}
                        />
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
                        <InfoBoxV2
                            icon={require('../../../images/icon2/totalProfitLoss.svg').default}
                            boxLabel={translate(props.lang, "Total Profit/Loss")}
                            boxCurrency={`USD`}
                            boxValue={`${dashboardInfo.total_trade_profit ? Number(dashboardInfo.total_trade_profit).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loadingDashboard}
                        />
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
                        <InfoBoxV2
                            icon={require('../../../images/icon2/totalBonus.svg').default}
                            boxLabel={translate(props.lang, "Total Bonus")}
                            boxCurrency={`USD`}
                            boxValue={`${dashboardInfo.total_commission_profit ? Number(dashboardInfo.total_commission_profit).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loadingDashboard}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-start px-0 mx-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <span className="font-weight-600 font-22 text-white">{translate(props.lang, "Ranking")}</span>
                            <div className="copyBox py-2 text-center d-inline-block px-3" onClick={() => setRankInfoModal(true)}>
                                <div className="d-flex align-items-center">
                                    <span className="text-black font-weight-500 font-16">{translate(props.lang, "Details")}</span>
                                    <img src={require('../../../images/icon2/arrow-black.svg').default} alt="icon-wallet" className="ms-1 img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className='w-100 my-3 borderLine'></div>
                        <div className="mb-md-3">
                            <RankingInfo loading={loadingRank} rankList={rankData} lg={3} md={4} ownRank={props.auth.profile.rank_name ? props.auth.profile.rank_name : ''} />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-stretch px-0 mx-0 mt-3">
                    <Col lg={6} md={12} sm={12} xs={12} className="mb-3">
                        <div className="tableBgContainer px-md-4 px-3 py-3 py-md-4 h-100">
                            <div className='d-flex align-items-center'>
                                <div className="me-2">
                                    <img src={require('../../../images/icon2/mt5Login.svg').default} alt="icon-wallet" className="img-fluid" />
                                </div>
                                <span className="font-weight-600 font-22 text-white">{translate(props.lang, "My MT5 Info")}</span>
                            </div>
                            <div className='w-100 my-3 borderLine'></div>
                            <div>
                                <Row className="align-items-center justify-content-center mt5tab">
                                    {
                                        Array.from({ length: 4 }, (_, i) => i).map((item, i) => (
                                            <Col lg={3} md={3} sm={6} xs={6} key={i} className="mb-1 mb-md-3">
                                                <div className={`text-center py-2 px-2 ${tab === item ? 'activeTab' : 'inactiveTab'}`} onClick={() => setTab(item)}>
                                                    <span className={`font-weight-400 font-16 line-height-18 d-block text-capitalize ${tab === item ? 'active' : 'inactive'}`}>{translate(props.lang, "MT5 Account")} {item + 1}</span>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <Row className="mt-3 text-center">
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <div className="mb-2">
                                            <span className="d-block font-18 font-weight-400 text-capitalize text-white op8 lh-base">{translate(props.lang, "MT5 Login ID")}</span>
                                            <span className="d-block font-20 font-weight-600 text-white lh-base mt-2">
                                                {dashboardInfo.mt5_accounts.length > 0 ? (
                                                    dashboardInfo.mt5_accounts.filter(item => item.mt5_group_id === (tab+1)).length > 0 ? (
                                                        dashboardInfo.mt5_accounts.filter(item => item.mt5_group_id === (tab+1)).map((item, i)=>(
                                                            <span className="d-block" key={i}>{item.mt5_login_id}</span>
                                                        ))
                                                    ) : '-'
                                                ) : '-'}
                                                {/* {dashboardInfo.mt5_accounts[tab] && dashboardInfo.mt5_accounts[tab].mt5_login_id ? dashboardInfo.mt5_accounts[tab].mt5_login_id : '-'} */}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <div>
                                            <span className="d-block font-18 font-weight-400 text-capitalize text-white op8 d-block text-capitalize">{translate(props.lang, "MT5 Password")}</span>
                                            <span className="d-block font-20 font-weight-600 text-white lh-base mt-2">
                                                {/* {dashboardInfo.mt5_accounts[tab] && dashboardInfo.mt5_accounts[tab].mt5_login_password ? dashboardInfo.mt5_accounts[tab].mt5_login_password : '-'} */}
                                                {dashboardInfo.mt5_accounts.length > 0 ? (
                                                    dashboardInfo.mt5_accounts.filter(item => item.mt5_group_id === (tab+1)).length > 0 ? (
                                                        dashboardInfo.mt5_accounts.filter(item => item.mt5_group_id === (tab+1)).map((item, i)=>(
                                                            <span className="d-block" key={i}>{item.mt5_login_password}</span>
                                                        ))
                                                    ) : '-'
                                                ) : '-'}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="mb-3 mt-3 mt-md-0">
                        <div className="tableBgContainer px-md-4 px-3 py-3 py-md-4 h-100">
                            <Row className="px-0 mx-0 align-items-center">
                                <Col lg={9} md={9} sm={12} xs={12} className="px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="me-2">
                                            <img src={require('../../../images/icon2/qrcode.svg').default} alt="icon-wallet" className="img-fluid" />
                                        </div>
                                        <span className="font-weight-600 font-22 text-white line-height-20 text-capitalize">{translate(props.lang, "My QR Referral Code")}</span>
                                    </div>
                                    <div className="py-3 w-100">
                                        <div className="borderLine"></div>
                                    </div>
                                    <div className="referral-qr-container w-50 mx-auto mb-3 h-100 p-2 d-block d-md-none" onClick={() => downloadImage()}>
                                        <QRCode
                                            id="referralQR"
                                            size={128}
                                            style={{ height: "auto", width: "100%" }}
                                            value={window.location.origin + '/register?referral=' + props.auth.profile.referral_code}
                                            viewBox={`0 0 128 128`}
                                            bgColor="#ffffff"
                                        />
                                    </div>
                                    <div>
                                        <Row className="align-items-center justify-content-center">
                                            <Col lg={12} md={12} sm={8} xs={8} className="mb-md-3">
                                                <div>
                                                    <span className="font-weight-600 font-20 text-center text-white break-all-word lh-base overflow-wrap-anywhere">{props.auth.profile.referral_code ? window.location.origin + '/register?referral=' + props.auth.profile.referral_code : ''}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={4} xs={4}>
                                                <div className="d-flex align-items-center justify-content-end justify-content-md-start">
                                                    <div className="copyBox py-2 text-center d-inline-block px-3" onClick={() => copyText(props.auth.profile.referral_code ? window.location.origin + '/register?referral=' + props.auth.profile.referral_code : '', props.lang)}>
                                                        <div className="d-flex align-items-center">
                                                            <span className="text-black font-weight-500 font-16">{translate(props.lang, "Copy")}</span>
                                                            <img src={require('../../../images/icon2/arrow-black.svg').default} alt="icon-wallet" className="ms-1 img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </Col>
                                <Col lg={3} md={3} sm={3} xs={3} className="pe-0 d-none d-md-block">
                                    <div className="referral-qr-container w-100 h-100 p-2" onClick={() => downloadImage()}>
                                        <QRCode
                                            id="referralQR"
                                            size={128}
                                            style={{ height: "auto", width: "100%" }}
                                            value={window.location.origin + '/register?referral=' + props.auth.profile.referral_code}
                                            viewBox={`0 0 128 128`}
                                            bgColor="#ffffff"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-start px-0 mx-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <div className="tableBgContainer h-100">
                            <div className='px-md-4 px-3 py-3 py-md-4'>
                                <Row className='align-items-center mx-0 px-0'>
                                    <Col lg={8} md={8} sm={8} xs={8} className='px-0'>
                                        <div>
                                            <img src={require('../../../images/icon2/announcement.svg').default} className="img-fluid iconSize" alt="wallet-balance" />
                                            <span className="font-weight-600 font-22 text-white ms-3 ms-md-4">{translate(props.lang, "Announcement")}</span>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={4} xs={4} className='pe-0'>
                                        <div className="d-flex justify-content-end w-100">
                                            <div className="cursor-pointer transferBox py-2 px-2 d-inline-block text-center" onClick={() => history.push('/announcement')}>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className="text-black font-weight-500 font-16">{translate(props.lang, "More")}</span>
                                                    <img src={require('../../../images/icon2/arrow-black.svg').default} alt="icon-wallet" className="ms-1 img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='w-100 mt-3 borderLine'></div>
                                <div className='w-100 mt-3'>
                                    {props.loading ? (
                                        <LoadingV3 theme='light' />
                                    ) :
                                        <>
                                            {announcementList.length > 0 ? announcementList.map((item, i) => (
                                                <div key={i} className={`${i !== announcementList.length - 1 ? 'border-bottom-grey py-2' : 'pt-2'}`}>
                                                    <Row className='mx-0 px-0' onClick={() => openAnnouncementModal(item)}>
                                                        <Col lg={3} md={3} sm={4} xs={4} className="ps-0">
                                                            <div>
                                                                <span className="text-white op6 font-13 font-weight-400 d-block d-md-inline-block lh-base">{item.date_added ? item.date_added.split(' ')[0] : ''}</span>
                                                                <span className="text-white op6 font-13 font-weight-400 d-block d-md-inline-block lh-base ps-md-2">{item.date_added ? item.date_added.split(' ')[1] : ''}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={9} md={9} sm={8} xs={8} className="pe-0 ps-0 ps-lg-2">
                                                            <span className="font-weight-600 font-13 text-white d-block lh-base">{item.title ? item.title.length > 100 ? item.title.substring(0, 100) + '...' : item.title : ''}</span>
                                                            <div className="mt-1">
                                                                <span className="font-weight-400 font-13 text-white op8 lh-sm d-block d-md-none">{item.description.length > 30 ? props.lang === 'en' ? item.description.substring(0, 50) + '...' : item.description.substring(0, 30) + '...' : item.description}</span>
                                                                <span className="font-weight-400 font-13 text-white op8 lh-sm d-none d-md-block">{item.description.length > 30 ? props.lang === 'en' ? item.description.substring(0, 100) + '...' : item.description.substring(0, 30) + '...' : item.description}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )) :
                                                <div className='h-100 w-100 d-flex justify-content-start align-items-center'>
                                                    <div className='text-start'>
                                                        <span className='d-block text-white font-weight-400 font-13'>{translate(props.lang, "No Announcement For Now")}</span>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-start px-0 mx-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="graphBgContainer px-md-4 px-3 py-3 py-md-4">
                            <Row className='mx-0 px-0 align-items-center'>
                                <Col lg={6} md={6} sm={12} xs={12} className='mb-2 px-0'>
                                    <div className='text-start text-md-start'>
                                        <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Group Sales Performance")}</span>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={12} xs={12} className='mb-2 px-0'></Col>
                                <Col lg={3} md={3} sm={12} xs={12} className="mb-2 px-0">
                                    <div className={`groupLabel-select select-placeholder-center w-100 py-1 py-md-0 px-2`}>
                                        <Select
                                            options={filterType.length > 0 ? filterType.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                            placeholder={translate(props.lang, "Select Filter Type")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={customSelectStyles}
                                            value={
                                                filterType.filter(item => item.value === filterGraph).length > 0 ?
                                                    (filterType.filter(item => item.value === filterGraph).map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })))
                                                    : null
                                            }
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={(e) => setFilterGraph(e ? e.value : '')}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                    {loadingGraph ? (
                                        <LoadingV3 theme='light' />
                                    ) :
                                        <Line data={data} options={dataOption} height={window.innerWidth > 667 ? 'auto' : 400} />
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Modal className="fade modalColorBg" size="lg" show={announcementModal} centered>
                    <Modal.Header className="border-0 pt-4 px-md-4 px-3">
                        <div className="modalHeader d-flex w-100 align-items-center">
                            <div className="w-75">
                            </div>
                            <div className="w-25 text-end cursor-pointer" onClick={() => setAnnouncementModal(false)} >
                                <img src={require('../../../images/icon2/modal-close.svg').default} alt="close" />
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="border-0 px-md-4 px-3 pt-0">
                        <Row className='justify-content-center align-items-center mx-0 px-0'>
                            {/* <Col lg={12} md={12} xs={12} sm={12} className="mb-5 text-center">
                                <span className="font-weight-700 font-30 text-white lh-base">{translate(props.lang, "Announcement")}</span>
                            </Col> */}
                            <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                <span className="font-weight-700 font-26 lh-base text-white break-all-word d-block">
                                    {selectedAnnouncement && selectedAnnouncement.title ? selectedAnnouncement.title : '-'}
                                </span>
                            </Col>
                            <Col lg={12} md={12} xs={12} sm={12}>
                                <span className="font-weight-400 font-14 lh-base text-white break-all-word d-block">
                                    {translate(props.lang, "Posted at")} {selectedAnnouncement && selectedAnnouncement.date_added ? selectedAnnouncement.date_added : ''}
                                </span>
                            </Col>
                            <Col lg={12} md={12} xs={12} sm={12}>
                                <hr className='announcementPopupLine my-3' />
                            </Col>
                            <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                {selectedAnnouncement ? (
                                    <span className="font-weight-400 font-18 line-height-30 text-white d-block wordBreak break-white-space">
                                        {selectedAnnouncement.description ? selectedAnnouncement.description : ''}
                                    </span>
                                ) : null}
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal className="fade modalInfoBg" size="lg" show={rankInfoModal} centered>
                    <Modal.Header className="border-0 py-4">
                        <div className="modalHeader d-flex w-100 align-items-center">
                            <div className="w-75">
                            </div>
                            <div className="w-25 text-end cursor-pointer" onClick={() => setRankInfoModal(false)} >
                                <img src={require('../../../images/icon2/modal-close.svg').default} alt="close" />
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="border-0 px-4 pt-0">
                        <Table responsive className="w-100 table-rankinfo text-center position-relative">
                            <thead>
                                <tr>
                                    <th className={`font-weight-600 font-18 text-gold-gradient text-center`}>{translate(props.lang, "Rank")}</th>
                                    <th className={`font-weight-600 font-18 text-gold-gradient text-center`}>{translate(props.lang, "Lot Rebate Bonus")}(USD)</th>
                                    <th className={`font-weight-600 font-18 text-gold-gradient text-center`}>{translate(props.lang, "Contract Commission")}</th>
                                    <th className={`font-weight-600 font-18 text-gold-gradient text-center`}>{translate(props.lang, "Same Rank Bonus")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rankList.map((item, i) => (
                                        item.rank_name !== 'SSIB' ? (
                                            <tr key={i}>
                                                <td className={`font-weight-500 font-18 lh-base`}>{item.rank_name}</td>
                                                <td className={`font-weight-500 font-18 lh-base`}>{item.commission_rate ? item.commission_rate : '-'}</td>
                                                <td className={`font-weight-500 font-18 lh-base`}>{item.contract_commission ? item.contract_commission + '%' : '-'}</td>
                                                <td className={`font-weight-500 font-18 lh-base`}>{item.same_rank_rate ? item.same_rank_rate + '%' : '-'}</td>
                                            </tr>
                                        ) : null
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Dashboard);