/** global version **/
const region = "global";
// const url = "https://api-rcfx.dothouz.com"; //testnet
const url = "https://api.rc-global.co"; //production

/** indo version **/
// const region = "indo";
// const url = "https://api-rcglobalint.dothouz.com"; //testnet
// const url = "https://api.rc-globalint.co"; //production

const api = {
    register: url + '/register',
    upload: url + '/upload',
    withdrawInfo: url + "/wallet/walletWithdrawInfo",
    
    getNotificationList: url + '/profile/getNotificationList',
    updateNotification: url + '/profile/updateNotification',
    closeAndOpenTrading: url + '/profile/closeAndOpenCopyTradeByMt5Account',
    getBankName: url + '/account/bankNameList',
    getBankCountry: url + '/account/bankCountryList',
    addWithdrawInfo : url + '/wallet/addWalletBankInfo',
    getWithdrawInfo : url + '/wallet/walletWithdrawInfo',
    deleteWithdrawInfo : url + '/wallet/deleteWalletBankInfo',
    generateOrderNo : url + '/topPay/generateOrderNo',
    changeProfile : url +'/profile/changeProfile',
    calculateWithdrawal : url + '/wallet/walletWithdrawalCalculate',
    topPayAddFund : url + '/topPay/addFund',
    topPayAddFundInfo: url  + '/topPay/addFundInfo',
    // getFundMethodInfo: url  + '/surepay/fundMethodInfo',
    // addFundInfo: url  + '/surepay/addFundInfo',
    // addFund: url  + '/surepay/addFund',
    // getTeamReferralLink: url + "/team/getReferralLink",
    // paymentGateway : url + "/fundtransfer",

    login: url + '/customer/login',
    register: url + '/customer/register',
    get_dashboard: url + '/customer/get_dashboard',
    teamOverview: url + '/team/overview',
    get_transactions: url + '/wallet/get_transactions',
    get_profile: url + '/customer/get_profile',
    get_rank: url + '/setting/get_value?key=rcfx_rank',
    add_bank: url + '/customer/add_bank',
    get_banks: url + '/customer/get_banks',
    get_bank_details: url + '/customer/get_bank',
    add_crypto_address: url + '/customer/add_crypto_address',
    get_crypto_addresses: url + '/customer/get_crypto_addresses',
    get_crypto_addresses_details: url + '/customer/get_crypto_address',
    crypto_deposit: url + '/wallet/crypto_deposit',
    get_deposit_crypto_address: url + '/customer/get_deposit_crypto_address',
    wallet : url + '/wallet',
    get_package: url + '/setting/get_value?key=rcfx_package',
    transfer: url + '/wallet/transfer',
    get_network_fee: url + '/setting/get_value?key=config_network_fee',
    get_wallet_balance: url + '/wallet/get_wallet_balance',
    request_email_otp: url + '/customer/request_email_otp',
    withdraw: url + "/wallet/withdraw",
    get_lock_period: url + '/setting/get_value?key=rcfx_lock_period',
    get_assets: url + '/customer/get_assets',
    edit_password: url + '/customer/edit_password',
    edit_transaction_password: url + '/customer/edit_transaction_password',
    performance: url + '/team/performance',
    team_detail: url + '/team/detail',
    submit_kyc: url + '/customer/submit_kyc',
    withdraw_mt5: url + '/customer/withdraw_mt5',
    forget_password: url + '/customer/forget_password',
    get_mt5_deal_details: url + '/customer/get_mt5_deal_details',
    edit_profile: url + '/customer/edit_profile',
    customer_get_announcements: url + '/announcement/customer_get_announcements',
    customer_get_announcement_details: url + '/announcement/customer_get_announcement',
    get_special_accounts: url + '/customer/get_special_accounts',
    transfer_fund: url + '/wallet/transfer_fund',
    renew_mt5_lock_period: url + '/customer/renew_mt5_lock_period',
}

export { url, api, region };
