const ZH_TW = {
    "Welcome Back": "歡迎回來",
    "Don’t have an account yet?": "還沒有帳戶？",
    "Sign up now": "立即註冊",
    "Email Address": "郵箱",
    "Enter Email": "請輸入郵箱",
    "Password": "密碼",
    "Enter Transaction Password": "輸入交易密碼",
    "Remember me": "記住賬號",
    "Forgot Password?": "重置登錄密碼？",
    "Login": "登錄",
    "This field is required.": "必填項。",
    "Forgot Password": "重置登錄密碼",
    "Already have an account?": "已有帳戶？",
    "Back To Login": "登錄",
    "Verification Code": "郵箱驗證碼",
    "Your Verification Code": "輸入郵箱驗證碼",
    "Send": "發送",
    "Confirm Password": "確認密碼",
    "Confirm Your Password": "請輸入登錄密碼",
    "Submit": "提交",
    "By clicking 'Submit' button, you agree to our Privacy and Terms of Use.": "單擊“提交”按鈕，即表示您同意我們的隱私和使用條款。",
    "Invalid Email Format": "郵箱格式錯誤",
    "Password must at least 6 characters": "登錄密碼必须至少为6個字符",
    "Confirm password need to same with password": "確認密碼需要與密碼相同",
    "Verification code has sent to": "驗證碼已發送至您的 $email 郵箱。請檢查您的郵箱或垃圾郵箱",
    "Create Personal Account": "請準確填寫您的註冊信息",
    "Login now": "登錄",
    "Full Name": "姓名",
    "Your Full Name": "請輸入身份證姓名/護照姓名",
    "Country": "國家",
    "Select Country": "選擇國家",
    "No countries available": "沒國家可選",
    "Transaction Password": "交易密碼",
    "Your Transaction Password": "輸入交易密碼",
    "Referral Code (optional)": "邀請代碼（可選）",
    "Create Account Now": "註冊",
    "Register Successful": "您已完成註冊",
    "Success": "成功",
    "Error": "錯誤",
    "Your Referral Code": "請輸入邀請碼",
    "Dashboard": "首頁",
    "Investment": "資產",
    "Wallet": "錢包",
    "Wallet Overview": "財務記錄",
    "Deposit": "存款",
    "Withdrawal": "提款",
    "Bank Info": "提款信息",
    "Transaction History": "存取記錄",
    "Bonus History Details": "返佣記錄",
    "Team": "團隊",
    "Team Overview": "晉級詳情",
    "Team Investment": "團隊詳情",
    "Performance": "數據總覽",
    "Referral": "邀請鏈接",
    "Profile": "我的信息",
    "Settings": "設置",
    "KYC": "身份認證",
    "Security": "密碼",
    "Fund Your Wallet": "賬戶存款",
    "Change Password": "修改登錄密碼",
    "Forget Transaction Password": "忘記交易密碼",
    "Notification": "通知",
    "Hi,": "你好，",
    "Stop Trading Account": "停止交易MT5賬號",
    "Attention": "請注意!",
    "Are you sure you want to stop trading?": "您確定要停止交易嗎？",
    "Yes, I am sure!": "是的，我確定！",
    "Cancel": "取消",
    "New password must at least 6 characters": "新登錄密碼必須至少包含6個字符",
    "Confirm password does not match the new password": "確認密碼與新登錄密碼不一致",
    "New login password change successful": "已修改新登錄密码",
    "Back": "返回",
    "Old Password": "原密碼",
    "New Password": "新登錄密碼",
    "Your Old Password": "輸入原密碼",
    "Your New Password": "輸入新登錄密碼",
    "Confirm New Password": "確認新登錄密碼",
    "Confirm Your New Password": "輸入新登錄密碼",
    "Update": "更新",
    "New Transaction Password": "新交易密碼",
    "Your new transaction password": "輸入新交易密碼",
    "Confirm New Transaction Password": "確認新交易密碼",
    "Confirm your new confirm transaction password": "輸入新交易密碼",
    "New transaction password change successful": "已修改新交易密碼",
    "Transaction password must at least 6 characters": "交易密碼必須至少為6個字符",
    "Confirm password does not match the new transaction password": "确认密碼与新交易密碼不一致",
    "Logout": "退出登錄",
    "Referral Code": "邀請碼",
    "Referral Link": "邀請鏈接",
    "Close": "關閉",
    "Copied": "複製成功",
    "Oops! No data found...": "未找到數據",
    "Loading Data": "加載數據中",
    "Male": "男性",
    "Female": "女性",
    // "Not Verified": "未審核",
    "Only Numbers are allowed in this field": "此項只允許輸入數字",
    "Image size cannot exceed 2mb": "圖片大小不能超過2MB",
    "Only allow format jpg, png or jpeg": "只允許 jpg, png, jpeg 格式",
    "Gender": "性別",
    "Select Gender": "選擇性別",
    "IC / passport no.": "身份證/護照號碼",
    "Your IC / Passport No.": "輸入您的身份證/護照號碼",
    "Date of Birth": "生日",
    "Your Date of Birth": "輸入您的生日",
    "Phone Number": "手機號",
    "Your phone number": "輸入您的手機號",
    "Address": "地址",
    "Your address": "輸入您的地址",
    "Joining Date": "註冊時間",
    "KYC Status": "認證狀態",
    "Remark": "備註",
    "Upload IC/ Passport front": "上傳身份證/護照正面",
    "Upload IC/ Passport Back": "上傳身份證/護照背面",
    "Upload Photo With Holding IC/Passport": "上傳本人手持身份證/護照的照片",
    "APPROVED": "已批准",
    "REVIEW": "審核中",
    "REJECTED": "已被拒",
    "PENDING": "待審核",
    "Congratulations, your account has been successfully created.": "恭喜，您的帳戶已成功創建。",
    "Continue": "繼續",
    "Profile successful updated": "個人資料已成功更新",
    "ID": "ID",
    "Upline": "我的上屬",
    "My Referral Code": "我的邀請碼",
    "Edit": "更改",
    "MT5 Username": "MT5 賬號",
    "MT5 Password": "MT5密碼",
    "share my QR code": "分享我的邀請鏈接",
    "My Rank": "我的等級",
    "Active Group Member": "活躍人數",
    "Total Group Member": "累計人數",
    "Ranking Bonus": "平級獎",
    "Lot Rebate Bonus": "點差返佣",
    "Profit Sharing Bonus": "盈利分潤",
    "Direct Referral Members": "我的直屬人數",
    "Direct Referral Investment Amount": "我的直屬業績",
    "Total Group Investment Amount": "我的團隊業績",
    "Personal Sales": "我的直推業績",
    "Group Sales": "我的團隊業績",
    "Self Deposit": "我的MT5餘額",
    "Ranking": "等級晉級方式",
    "Wallet Balance:": "錢包餘額：",
    "Total Member": "累計人數",
    "Total Active Member": "活躍人數",
    "Total Sales": "團隊業績",
    "Personal Deposit": "MT5餘額",
    "Type Of Bonus": "佣金類型",
    "Amount": "金額",
    "Date & Time": "時間",
    "View More": "查看更多",
    "Wallet Transaction History": "財務記錄",
    "Description": "描述",
    "Type": "資產類型",
    "Action": "類型",
    "Transaction Hash": "交易明細",
    "TXID": "TXID",
    "Status": "狀態",
    "MT5 Balance": "MT5 餘額",
    "Crypto Deposit": "加密貨幣存款",
    "Seamlessly Add Cryptocurrency To Your Account And Start Trading Effortlessly.": "為了您的資金安全，請認真核對充值地址信息。",
    "Fiat Deposit": "法幣存款",
    "Conveniently Deposit Funds Using Your Preferred Fiat Currency, Ensuring A Smooth And Hassle-Free Experience.": "為了您的資金安全，請認真核對轉款信息",
    "Coin": "幣種",
    "Network": "網絡",
    "Select Network": "選擇網絡",
    "Deposit Address": "充值地址",
    "Wallet Address": "錢包地址",
    "Minimum Deposit": "最少充幣數量",
    "Please Select Network": "請選擇網絡",
    "Total Invested": "我的總資產",
    "Total Profit/Loss": "累計交易收益",
    "Investment Details": "訂單詳情",
    "Order ID": "訂單號",
    "Price": "價格",
    "Trading Pair": "產品名稱",
    "Profit/Loss %": "盈利/虧損",
    "Lot Size": "手數",
    "Price Enter": "入場價格",
    "Price Close": "出場價格",
    "Announcement": "公告",
    "No Announcement For Now": "暫無公告",
    "Group Sales Performance": "我的團隊業績",
    "Sales": "業績",
    "Read More": "了解更多",
    "My MT5 Info": "我的MT5登錄",
    "Wallet Total Balance": "錢包",
    "Trading Profit": "交易收益",
    "Total Investment": "MT5餘額",
    "Total Bonus": "累計返佣收益",
    "My QR Referral Code": "我的二維碼&邀請鏈接",
    "Transfer": "劃轉",
    "From": "從",
    "To": "至",
    "Transfer Amount": "劃轉金額",
    "Balance Available:": "可轉金額：",
    "Your Transfer Amount": "輸入劃轉金額",
    "Successful": "成功",
    "Unsuccessful": "未成功",
    "Insufficient balance": "餘額不足",
    "Add New Withdrawal Method": "添加新的提款方式",
    "Withdrawal Method Available": "可用提款方式",
    "Invalid email or password": "無效的郵箱或密碼",
    "Fiat Withdrawal": "法幣提款",
    "Crypto Withdrawal": "加密貨幣提款",
    "Bank Country": "銀行所在國家",
    "Bank Account Holder Name": "銀行賬戶持有人姓名",
    "Bank Name": "銀行名稱",
    "Bank Account Number": "銀行賬號",
    "Bank Address": "銀行地址",
    "Bank Swift Code": "銀行Swift碼",
    "Select Your Bank country": "選擇您的銀行所在國家/地區",
    "Select Your Bank Name": "選擇您的銀行名稱",
    "Your Bank Account Holder Name": "輸入姓名",
    "Your Bank Account Number": "輸入銀行賬號",
    "Your Bank Address": "輸入銀行地址",
    "Your Bank Swift Code": "輸入銀行Swift碼",
    "Your Bank Name": "輸入銀行名稱",
    "Your remark": "輸入備註",
    "Your Wallet Address": "輸入您的錢包地址",
    "Select Your Network": "選擇您的網絡",
    "Save": "保存",
    "Select Bank Name": "選擇銀行名稱",
    "No network available": "沒網絡可選",
    "Withdrawal From Wallet": "可提款餘額",
    "Email Verification Code": "郵箱驗證碼",
    "Your Email Verification Code": "輸入郵箱驗證碼",
    "Withdraw Amount": "提款金額",
    "Your Withdraw Amount": "輸入提款金額",
    "Select Type": "選擇類型",
    "No option available": "沒有選項可選",
    "Select bank transfer": "選擇銀行轉賬",
    "Great News! You have the option to resume trading. Do you want to resume trading?": "好消息！您可以選擇恢復交易。",
    "Click here": "點擊這裡",
    "to reopen trading activity.": "重新恢復交易。",
    "Receive Amount": "到账金額",
    "Network Fee:": "網絡費用：",
    "Exchange Rate:": "匯率：",
    "Charge Fee:": "收費：",
    "Merchant": "商家",
    "Select Merchant": "選擇商家",
    "Order Number": "訂單號",
    "Your Order Number": "輸入訂單號",
    "Payment Currency": "支付貨幣",
    "Select Payment Currency": "選擇支付貨幣",
    "No currency available": "沒貨幣可選",
    "Deposit Amount": "存款金額",
    "Your Deposit Amount": "輸入存款金額",
    "Minimum to maximum transaction is 1000-4000 USD": "最低至最高存款金額為1000至4000 USD",
    "Remove Withdrawal Method": "刪除提款方式",
    "Withdrawal Type": "提款類型",
    "fiat withdraw": "法幣提款",
    "crypto withdraw": "加密貨幣提款",
    "Delete": "刪除",
    "Balance Available": "可轉餘額",
    "Amount must greater than 0.": "數額必須大於0。",
    "Remark:": "備註：",
    "Amount must only within 1000 - 4000": "數額必須在 1,000 - 4,000 之間",
    "View Details": "查看詳情",
    "Details": "詳情",
    "Investment Package": "跟單策略",
    "Select Your Package": "選擇跟單策略",
    "Monthly Return": "交易收益(月)",
    "Minimum investment amount is": "最低投資額為",
    "Maximum investment amount is": "最高投資金額為",
    "Purchase Package Details": "仓位详情",
    "Purchase Datetime": "購買時間",
    "Cumulative Investment Days": "累計跟單天數",
    "Investment Allocation": "仓位金額",
    "Surrender": "終止",
    "Successfully surrender": "成功終止配套",
    "active": "已激活",
    "Account Type": "提款方式",
    "Select Account Type": "選擇提款方式",
    "No account type available": "沒類別可選",
    "Days": "天",
    "Processing Fee": "手續費",
    "Invalid withdraw amount": "提款數額無效",
    "Withdraw": "提款",
    "MT5 Account": "MT5賬號",
    "Select MT5 Account": "選擇MT5賬號",
    "All MT5 Account": "全部MT5賬號",
    "Account": "賬號",
    "MT5 Account 1 Username": "MT5賬號 1",
    "MT5 Account 2 Username": "MT5賬號 2",
    "MT5 Account 3 Username": "MT5賬號 3",
    "MT5 Account 4 Username": "MT5賬號 4",
    "MT5 Account 1 Password": "MT5賬號 1 密碼",
    "MT5 Account 2 Password": "MT5賬號 2 密碼",
    "MT5 Account 3 Password": "MT5賬號 3 密碼",
    "MT5 Account 4 Password": "MT5賬號 4 密碼",
    "Payment Amount": "付款金額",
    "Your Payment Amount": "您的付款數額",
    "Convert": "兌換",
    "Exchange Rate": "匯率：",
    "Your IP does not support the operation": "你的所在IP不支持操作",
    "to": "至",
    "inactive": "不活躍",
    "Account withdrawal type": "提款方式",
    "Select withdrawal type": "選擇提款方式",
    "MT5 Login ID": "MT5賬號",
    "completed": "完成",
    "Transfer1": "劃轉",
    "Copy": "複製",
    "Confirm": "確認",
    "MT5 Login ID1": "MT5登錄",
    "Select Bank Country": "選擇銀行所在國家",
    "Change Transaction Password": "修改交易密碼",
    "Login Password": "登錄密碼",
    "Enter Login Password": "請輸入登錄密碼",
    "Confirm Login Password": "確認登錄密碼",
    "Enter Verification Code": "請輸入郵箱驗證碼",
    "Enter Transaction Password": "請輸入交易密碼",
    "Confirm login password and login password are not same": "確認密碼與登錄密碼不一致",
    "Assets Type": "資產類型",
    "Trend Trading Strtegies": "趨勢交易策略",
    "Trading Details": "交易詳情",
    "Prudent": "穩健",
    "Short Trade": "短線",
    "Runtime": "運行天數",
    "Total Copiers": "累計跟單人數",
    "AUM (USD)": "跟單規模（USD）",
    "Profit/Loss (Month)": "收益率（月）",
    "Minimum Copy Amount (USD)": "最低跟單金額（USD）",
    "Follow Orders": "跟單",
    "Support Resistance Strategies": "支撐阻力交易策略",
    "Candlestick Patterns Strategies": "蠟燭圖形態交易策略",
    "Trend trading is very obvious, which is to seize the trend of the day and create profits as much as possible. The short-term trend trading strategy is very simple. If you see a foreign exchange currency pair rebounding three times on the same trend line, you can predict a strong trend and find the direction to trade in that trend.": "趨勢交易很明顯，就是抓住當天的趨勢，然後盡可能的創造盈利。短線趨勢交易策略很簡單，如果看到外匯貨幣對在同一個趨勢線回彈了3次，那麼可以預測將出現較強趨勢，並尋找在該趨勢的方向進行交易。",
    "Win Trades": "總盈利筆數",
    "Lose Trades": "總虧損筆數",
    "Max Drawdown": "最大回撤",
    "Trading Pairs": "交易對",
    "Support/resistance trading is the best short-term trading method, with a focus on identifying key price points on the chart and then conducting breakthrough trading. The risk management of this strategy is also very direct, which is to set stop loss at key price points.": "支撐位／阻力位交易是最佳的短線交易方法，重點就是識別圖表上的關鍵價位，然後進行突破交易。這種策略的風險管理也很直接，就是在關鍵價位上下設置止損。",
    "It is traded in the form of a candle chart, with time frames locked to 1-minute, 5-minute, and 15 minute charts. We need to find the inverted candle pattern on the chart as a signal to trade. Similarly, every stop loss is very important, and a good stop loss point is to reverse the candlestick on the opposite side of your trade.": "它是通過蠟燭圖形態來交易，時間框我們鎖定1分鐘、5分鐘和15分鐘圖。我們需要在圖表上尋找反轉蠟燭圖形態，以此作為信號去交易。同樣的，每一次止損都非常重要，好的止損點就是反轉燭台與你交易相反的那一側。",
    "Ranking1": "晉级方式",
    "Wallet Deposit": "存款",
    "Enter Verification Code1": "請輸入郵箱驗證碼",
    "Your Full Name1": "輸入身份證姓名/護照姓名",
    "Enter New Login Password": "請輸入新登錄密碼",
    "New Login Password": "新登錄密碼",
    "Confirm New Login Password": "確認新登錄密碼",
    "MT5 PROFIT": "MT5盈利",
    "In": "入",
    "Lot Rebate": "手數返佣",
    "2.50 from test0@gmail.com": "2.50 從 test0@gmail.com",
    "Hedged Position": "對沖倉",
    "Start": "開始",
    "End": "截止",
    "Source from MT5 account 1": "來源 MT5 帳號 1",
    "Rebate User": "返傭用戶",
    "Details": "詳情",
    "Old Transaction Password": "旧交易密码",
    "Enter Old Transaction Password": "输入旧交易密码",
    "Coming Soon": "即将推出",
    "Active": "待處理",
    "Pending Verify": "審核中",
    "Rejected": "已拒絕",
    "Verified": "已認證",
    "Please Try Again": "请重试",
    "My MT5 Balance": "我的MT5餘額",
    "Contract Commission": '合約佣金',
    "Not Verified": "未認證",
    "Select Transaction Type": "選擇交易類型",
    "Bonus": '返傭',
    "Withdraw Method": "提款方式",
    "crypto": "加密貨幣",
    "fiat" : "法币",
    "Pending1": "待审核",
    "Approved": "已批准",
    "Rejected": "已拒絕",
    "Active1": "活躍",
    "wallet_active": "成功",
    "wallet_pending": "待處理",
    "wallet_approved": "已批准",
    "wallet_rejected": "已拒絕",
    "WITHDRAW": "提款",
    "DEPOSIT_CRM": "钱包充值",
    "DEPOSIT_MT5": "MT5充值",
    "Request wallet withdraw": '錢包提款',
    "Submit Amount": '提款金額',
    "Fee": "手續費",
    "Receive Amount": "到帳金額",
    "Doc No.": "訂單號",
    "Result Per Page": "每頁結果",
    "Contract Period": "合約期",
    "Select Contract Period": '選擇合約期',
    "Topup": "加仓",
    "Topup Amount": '加仓金額',
    "Select Topup Amount": '选择加仓金額',
    "Enter Topup Amount": "输入加仓金額",
    "Extra Profit": '額外盈利',
    "Old password must at least 6 characters": "登錄密碼必須至少包含6個字符",
    "New password must at least 6 characters": "新登錄密碼必須至少包含6個字符",
    "Old transaction password must at least 6 characters": "交易密碼必須至少包含6個字符",
    "The page you were looking for is not found!": "找不到您要找的頁面！",
    "Back To Login": "返回登錄",
    "Select Bonus Type": "選擇佣金類型",
    "Same Rank Bonus": "平級獎",
    "Early Bird Bonus": "早鳥收益",
    "VIP Bonus": "VIP收益",
    "Early Bird Bonus (Purchaser 1.25%)": "早鳥收益（購買者 1.25%）",
    "Early Bird Bonus (Referral 1.25%)": "早鳥收益 （分享者 1.25%）",
    "TRANSFER_FROM_MT5": "MT5提款",
    "Penalty Fee": "違約金",
    "Capital withdrawal only allowed on Saturdays and Sundays.": "本金提款只開放在星期六下午3點至星期日晚上11點59分。",
    "Notice": "通知",
    "Period Extra Profit": "合約期收益",
    "Password has reset successfully": "已重置新登入密碼",
    "Adjust": "調整",
    "week": "每週",
    "month": "每月",
    "My Total Assets": "我的總資產",
    "Sales": "業績",
    "More": "更多",
    "Login ID": "賬號",
    "Let’s get you all set up so you can start your first onboarding experience !": "讓我們為您完成所有設置，以便您可以開始您的首次入職體驗！",
    "Log in to your account so you can continue building & editing your onboarding flows !": "登錄到您的帳戶，以便您可以繼續構建和編輯您的入職流程！",
    "Posted at": "發布於",
    "Rank": "級別",
    "VIP Bonus (Purchaser 0.75%)": "VIP收益 （購買者 0.75%）",
    "VIP Bonus (Referral 0.75%)": "VIP收益 （分享者 0.75%）",
    "Hide Password": "隱藏密碼",
    "Show Password": "顯示密碼",
    "January": "一月",
    "February": "二月",
    "March": "三月",
    "April": "四月",
    "May": "五月",
    "June": "六月",
    "July": "七月",
    "August": "八月",
    "September": "九月",
    "October": "十月",
    "November": "十一月",
    "December": "十二月",
    "Sun": "日",
    "Mon": "一",
    "Tue": "二",
    "Wed": "三",
    "Thu": "四",
    "Fri": "五",
    "Sat": "六",
    "Wallet Withdraw": "錢包提款",
    "Start Date": "開始日期",
    "Period Extra Profit": "合約期收益",
    "Early Bird": "早鳥配套",
    "VIP": "VIP配套",
    "Early Bird/VIP": "早鳥配套/VIP配套",
    "Package": "配套",
    "Total Earning Bonus": "總收益"  ,
    "Remaining Period": "剩餘週期",
    "deleted": "已停止",
    "Internal Transfer": "轉帳",
    "Wallet Balance": "錢包餘額",
    "TRANSFER": "轉帳",
    "MT5 Account $index Username": "MT5賬號 $index",
    "MT5 Account $index Password": "MT5賬號 $index 密碼",
    "Renew Contract": "續約",
    "New Contract Period": "新合約期",
    "Please note": "請注意",
    "The minimum deposit amount is 15 USDT. If the deposit is less than 15 USDT, System wallet will not be able to received funds or display the balance. Please make sure minimum deposit 15 USDT.": "最低充值金額為<span class='font-weight-600 text-red'>15 USDT</span>。如果充值金額低於<span class='font-weight-600 text-red'>15 USDT</span>，系統錢包將無法成功入賬或顯示餘額，請務必按照最低<span class='font-weight-600 text-red'>15 USDT</span>進行充值。",
}

export default ZH_TW;