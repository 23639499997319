import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import { logout } from "../../../store/actions/AuthActions";
import { api, region } from "../../../utils/api";
import { copyText } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from "../../components/Loading/LoadingV3";
import customSelectStyles from "../SelectStyle";
import { depositNetwork } from "../../../utils/config";

const CryptoDeposit = (props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [networkOption, setNetworkOption] = useState([]); 
    // const [networkOption, setNetworkOption] = useState([{ value: "", label: "TRC20" }]); //testnet
    // const [networkOption, setNetworkOption] = useState([{value: "TGBKJ21BZVUBkdJ2ApnRvfZfxmjXMS3pLC", label : "TRC20"}]); //live

    useEffect(()=>{
        // if(region === 'global'){
        //     setNetworkOption(depositNetwork.filter(item => item.value !== "BSC"));
        // } else {
            setNetworkOption(depositNetwork)
        // }
    },[region])

    useEffect(() => {
        if (selectedNetwork) {
            getCryptoDeposit();
        }
    }, [props.auth.auth.access_token, selectedNetwork]);

    const getCryptoDeposit = () => {
        // setLoading(true);
        // fetch(api.get_deposit_crypto_address + `?network=${selectedNetwork}`, {
        //     method: "GET",
        //     headers: new Headers({
        //         "Content-Type": "application/json",
        //         "Accept-Language": props.lang,
        //         "Authorization": "Bearer " + props.auth.auth.access_token
        //     }),
        // })
        //     .then(response => response.json())
        //     .then(responseJson => {
        //         setTimeout(() => {
        //             setLoading(false);
        //         }, 1000);
        //         if (responseJson.status === "success") {
        //             setWalletAddress(responseJson.data.base58_address);
        //             // setNetworkOption([{ value: responseJson.data.base58_address, label: "TRC20" }]);
        //         } else {
        //             if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
        //                 dispatch(logout(props.history, 'login'));
        //             }
        //             console.log('error', responseJson.message)
        //         }
        //     }).catch(error => {
        //         console.error("error", error);
        //     });
    }

    const handleOptionSelected = async (e) => {
        const { value } = e;
        setSelectedNetwork(value);
    }

    const downloadImage = (index) => {
        const svg = document.getElementById("DepositQR" + index.toString());
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");

            const downloadLink = document.createElement("a");
            downloadLink.download = "DepositQR" + index.toString();
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="tableBgContainer px-md-4 px-2 py-4 py-md-5">
                            <Row className="align-items-center px-0 mx-0 justify-content-md-between">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex align-items-center w-100 mb-4">
                                        <Link to="/wallet/deposit" className="cursor-pointer">
                                            <div className="d-flex align-items-center">
                                                <img src={require('../../../images/icon2/backBtn.svg').default} className="img-fluid back-btn" alt="back" />
                                            </div>
                                        </Link>
                                        <div className="ms-3">
                                            <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Crypto Deposit")}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupDisabled-input py-2 mb-1`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Coin")} <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type="select"
                                                    name="chainType"
                                                    value='1'
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    disabled={true}
                                                >
                                                    <option value="1" disabled="disabled">{translate(props.lang, "USDT | TetherUS")}</option>
                                                </Input>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-select py-2 px-md-4 px-3 mb-1`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Network")} <span className="errorTextRed">*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={networkOption.length > 0 ? networkOption.map((item, i) => ({ value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No network available") }]}
                                                    placeholder={translate(props.lang, "Select Network")}
                                                    className="input-transparent w-100 text-capitalize"
                                                    styles={customSelectStyles}
                                                    isSearchable={false}
                                                    value={
                                                        selectedNetwork ? networkOption.filter(item => item.value === selectedNetwork).map((item) => ({ value: item.value, label: item.label })) : ''
                                                    }
                                                    name="network"
                                                    onChange={(e) => handleOptionSelected(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start deposit_address_bg py-2 px-md-4 px-3">
                                        <div className={`py-1 mb-1`}>
                                            <Row className="justify-content-center w-100 align-items-center mx-0 px-0">
                                                <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                    <span className={`font-weight-500 font-13 text-grey-8 d-block mt-2`}>{translate(props.lang, "Deposit Address")}</span>
                                                </Col>
                                                {loading ? (
                                                    <>
                                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-3 px-0">
                                                            <div className="px-3 py-4 px-lg-5 py-lg-5 h-100">
                                                                <LoadingV3 theme="dark" />
                                                            </div>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    selectedNetwork ? (
                                                        walletAddress ? (
                                                            <Col lg={12} md={12} sm={12} xs={12} className="px-0 mt-3">
                                                                <div className="d-flex flex-column flex-md-row align-items-center justify-content-start mb-3">
                                                                    <div className="px-0 mb-md-0 mb-3">
                                                                        <div className="deposit-qr-container p-md-3 p-3 text-center d-inline-block" onClick={() => downloadImage(1)} >
                                                                            <QRCode
                                                                                id={"DepositQR1"}
                                                                                size={128}
                                                                                value={walletAddress}
                                                                                viewBox={`0 0 256 256`}
                                                                                bgColor="#ffffff"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-0">
                                                                        <div className="text-start px-md-3">
                                                                            <span className="font-weight-400 font-16 line-height-18 text-grey-1 d-block">{translate(props.lang, "Wallet Address")}</span>
                                                                            <div className="d-flex mt-2 align-items-center w-100">
                                                                                <span className="font-weight-600 font-18 line-height-18 d-block break-all-word me-3 text-black overflow-wrap-anywhere">{walletAddress}</span>
                                                                                <div className="cursor-pointer" onClick={() => copyText(walletAddress, props.lang)} >
                                                                                    <div className="copyBox py-2 px-4 text-center word-keep-all">
                                                                                        <span className="text-black font-weight-500">{translate(props.lang, "Copy")}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Row className="justify-content-center w-100 align-items-center px-0 mx-0">
                                                                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                                        {/* <div className="d-flex w-100 justify-content-between justify-content-md-start">
                                                                            <div>
                                                                                <span className="font-weight-400 font-16 line-height-18 text-grey-1">{translate(props.lang, "Minimum Deposit")}</span>
                                                                            </div>
                                                                            <div className="ms-md-3">
                                                                                <span className="font-weight-400 font-16 line-height-18 text-grey-1">0.00000001 USDT</span>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="mt-4">
                                                                            <div>
                                                                                <span className="font-weight-600 font-18 line-height-18 text-black">{translate(props.lang, "Please note")} : </span>
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <span className="font-weight-500 font-18 line-height-18 text-black" dangerouslySetInnerHTML={{ __html: translate(props.lang, "The minimum deposit amount is 15 USDT. If the deposit is less than 15 USDT, System wallet will not be able to received funds or display the balance. Please make sure minimum deposit 15 USDT.") }}></span>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>) : null
                                                    )
                                                        : (
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <Row className="align-items-center justify-content-center px-0 mx-0 pt-4">
                                                                    <Col lg={2} md={2} sm={6} xs={6}>
                                                                        <img src={require('../../../images/icon2/noResultGrey.png').default} className="img-fluid" alt="no-data" />
                                                                    </Col>
                                                                </Row>
                                                                <div className="text-center">
                                                                    <span className="d-block text-black">
                                                                        {translate(props.lang, "Please Select Network")}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        )
                                                    )
                                                }
                                            </Row >
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(CryptoDeposit);