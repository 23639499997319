import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import "../../../css/team.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from '../../../utils/function';
import { translate } from "../../../utils/translate";
import LoadingV3 from '../../components/Loading/LoadingV3';
import InfoBox from '../Common/InfoBox';
import InfoBoxV2 from '../Common/InfoBoxV2';

const Performance = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [teamPerformanceInfo, setTeamPerformanceInfo] = useState({
        rank: '',
        total_lot_rebate_bonus: 0,
        total_same_rank_bonus: 0,
        total_contract_bonus: 0,
        total_downline: 0,
        total_personal_sale: 0,
        total_group_sales: 0,
        total_early_bird_purchaser: 0,
        total_early_bird_referral: 0,
        total_vip_purchaser: 0,
        total_vip_referral: 0,
    });

    useEffect(() => {
        getTeamPerformance();
    }, [props.auth.auth.access_token]);

    const getTeamPerformance = () => {
        setLoading(true);
        fetch(api.performance, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setTeamPerformanceInfo(responseJson.data);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className='pb-5 teams'>
                <Row className='justify-content-center align-items-center px-0 mx-0 mt-3'>
                    <Col lg={12} md={12} sm={12} xs={12} className='mb-4'>
                        <div className="tableBgContainer p-3 h-100">
                            <Row className='px-0 mx-0 align-items-center justify-content-center'>
                                <Col lg={11} md={11} sm={9} xs={9} className='px-0'>
                                    <Row className="align-items-center mx-0 px-0 h-100">
                                        <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                            <div className="d-flex align-items-center w-100">
                                                <div className="me-2">
                                                    <img src={require('../../../images/icon2/myRank.svg').default} alt="icon-rank" className="img-fluid" />
                                                </div>
                                                <span className="font-weight-600 font-22 text-white line-height-20 text-capitalize">{translate(props.lang, "My Rank")}</span>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className="px-0 py-3">
                                            <div className="borderLine"></div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                            <span className="font-weight-600 font-32 text-center text-white break-all-word lh-base overflow-wrap-anywhere">{props.auth.profile.rank_name ? props.auth.profile.rank_name : '-'}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={1} md={1} sm={3} xs={3} className='text-end pe-0'>
                                    {!loading ? (
                                        <img src={props.auth.profile.rank_image ? props.auth.profile.rank_image : require('../../../images/avatar/profileSmall.svg').default} className="img-fluid bigRankIconSize" alt="profile-img" />
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/lotRebateBonus.svg').default}
                            boxLabel={translate(props.lang, "Lot Rebate Bonus")}
                            boxCurrency={`USD`}
                            // style={'v2'}
                            boxValue={`${teamPerformanceInfo && teamPerformanceInfo.total_lot_rebate_bonus ? Number(teamPerformanceInfo.total_lot_rebate_bonus).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/sameRankBonus.svg').default}
                            boxLabel={translate(props.lang, "Ranking Bonus")}
                            boxCurrency={`USD`}
                            // style={'v2'}
                            boxValue={`${teamPerformanceInfo && teamPerformanceInfo.total_same_rank_bonus ? Number(teamPerformanceInfo.total_same_rank_bonus).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/contractCommission.svg').default}
                            boxLabel={translate(props.lang, "Contract Commission")}
                            boxCurrency={`USD`}
                            // style={'v2'}
                            boxValue={`${teamPerformanceInfo && teamPerformanceInfo.total_contract_bonus ? Number(teamPerformanceInfo.total_contract_bonus).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/periodExtraBonus.svg').default}
                            boxLabel={translate(props.lang, "Period Extra Profit")}
                            boxCurrency={`USD`}
                            // style={'v2'}
                            boxValue={`${teamPerformanceInfo && teamPerformanceInfo.total_period_extra_profit ? Number(teamPerformanceInfo.total_period_extra_profit).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/earlyBirdPurchaser.svg').default}
                            boxLabel={translate(props.lang, "Early Bird Bonus (Purchaser 1.25%)")}
                            boxCurrency={`USD`}
                            boxValue={`${teamPerformanceInfo && teamPerformanceInfo.total_early_bird_purchaser ? Number(teamPerformanceInfo.total_early_bird_purchaser).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/earlyBirdReferral.svg').default}
                            boxLabel={translate(props.lang, "Early Bird Bonus (Referral 1.25%)")}
                            boxCurrency={`USD`}
                            boxValue={`${teamPerformanceInfo && teamPerformanceInfo.total_early_bird_referral ? Number(teamPerformanceInfo.total_early_bird_referral).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/vip_purchaser1.svg').default}
                            boxLabel={translate(props.lang, "VIP Bonus (Purchaser 0.75%)")}
                            boxCurrency={`USD`}
                            boxValue={`${teamPerformanceInfo && teamPerformanceInfo.total_vip_purchaser ? Number(teamPerformanceInfo.total_vip_purchaser).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mb-4'>
                        <InfoBoxV2
                            icon={require('../../../images/icon2/vip_referral1.svg').default}
                            boxLabel={translate(props.lang, "VIP Bonus (Referral 0.75%)")}
                            boxCurrency={`USD`}
                            boxValue={`${teamPerformanceInfo && teamPerformanceInfo.total_vip_referral ? Number(teamPerformanceInfo.total_vip_referral).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                        />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="tableBgContainer px-2 py-4 h-100">
                            <Row className='px-0 mx-0 align-items-center justify-content-between'>
                                <Col lg={1} md={12} sm={12} xs={12} className='mb-4 mb-lg-0 text-center text-lg-start'>
                                    <img src={require('../../../images/icon2/directReferral.svg').default} className="img-fluid iconSize" alt="Direct Referral Members" />
                                </Col>
                                <Col lg={11} md={12} sm={12} xs={12} className='text-center text-md-start'>
                                    <Row className='px-0 mx-0 justify-content-lg-center justify-content-start'>
                                        <Col lg={4} md={12} sm={12} xs={12} className='mb-3 mb-md-2 mb-lg-0 py-3 py-lg-0'>
                                            <div className='text-center'>
                                                <span className="font-weight-600 font-22 text-white">{translate(props.lang, "Direct Referral Members")}</span>
                                            </div>
                                            <div className='w-100 mt-4'>
                                                {loading ? (
                                                    <LoadingV3 theme='light' />
                                                ) :
                                                    <div className="text-center">
                                                        <span className="font-weight-600 font-32 text-white">{teamPerformanceInfo && teamPerformanceInfo.total_downline ? Number(teamPerformanceInfo.total_downline).toLocaleString('en-US') : '-'}</span>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={4} md={12} sm={12} xs={12} className='mb-3 mb-md-2 mb-lg-0 py-3 py-lg-0'>
                                            <div className='text-center'>
                                                <span className="font-weight-600 font-22 text-white">{translate(props.lang, "Direct Referral Investment Amount")}</span>
                                            </div>
                                            <div className='w-100 mt-4'>
                                                {loading ? (
                                                    <LoadingV3 theme='light' />
                                                ) :
                                                    <div className="text-center">
                                                        <span className="font-weight-600 font-32 text-white">{`USD ${teamPerformanceInfo && teamPerformanceInfo.total_personal_sale ? Number(teamPerformanceInfo.total_personal_sale).toLocaleString('en-US', digitFormat) : '0.00'}`}</span>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={4} md={12} sm={12} xs={12} className='py-3 py-lg-0'>
                                            <div className='text-center'>
                                                <span className="font-weight-600 font-22 text-white">{translate(props.lang, "Total Group Investment Amount")}</span>
                                            </div>
                                            <div className='w-100 mt-4'>
                                                {loading ? (
                                                    <LoadingV3 theme='light' />
                                                ) :
                                                    <div className="text-center">
                                                        <span className="font-weight-600 font-32 text-white">{`USD ${teamPerformanceInfo && teamPerformanceInfo.total_group_sales ? Number(teamPerformanceInfo.total_group_sales).toLocaleString('en-US', digitFormat) : '0.00'}`}</span>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>


                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Performance);