const EN = {
    "Welcome Back": "Welcome Back",
    "Don’t have an account yet?": "Don’t have an account yet?",
    "Sign up now": "Sign up now",
    "Email Address": "Email",
    "Enter Email": "Enter Email",
    "Password": "Password",
    "Enter Transaction Password": "Enter Transaction Password",
    "Remember me": "Remember me",
    "Forgot Password?": "Reset Login Password?",
    "Login": "Login",
    "This field is required.": "This field is required.",
    "Forgot Password": 'Reset Login Password',
    "Already have an account?": 'Already have an account?',
    "Back To Login": 'Back To Login',
    "Verification Code": 'Email Verification Code',
    "Your Verification Code": "Enter Email Verification Code",
    "Send": "Send",
    "Confirm Password": "Confirm Password",
    "Confirm Your Password": "Enter Login Password",
    "Submit": "Submit",
    "By clicking 'Submit' button, you agree to our Privacy and Terms of Use.": "By clicking 'Submit' button, you agree to our Privacy and Terms of Use.",
    "Invalid Email Format": "Invalid Email Format",
    "Password must at least 6 characters": "Password must at least 6 characters",
    "Confirm password need to same with password": "Confirm password need to same with password",
    "Verification code has sent to": "A verification code has been sent to your $email mailbox. Please check your mailbox or junk mailbox",
    "Create Personal Account": "Fill In Your Accurate Information",
    "Login now": "Login now",
    "Full Name": "Full Name",
    "Your Full Name": "Enter Full Name As Per Identity Card/Passport",
    "Country": "Country",
    "Select Country": "Select Country",
    "No countries available": "No countries available",
    "Transaction Password" : "Transaction Password",
    "Your Transaction Password" : "Enter Transaction Password",
    "Referral Code (optional)": "Referral Code (optional)",
    "Create Account Now": "Create",
    "Register Successful": "Register Successful",
    "Success": "Success",
    "Error": "Please Try Again",
    "Your Referral Code": "Enter Referral Code" ,
    "Dashboard": "Dashboard",
    "Investment": "Assets",
    "Wallet": "Wallet",
    "Wallet Overview": "Overview",
    "Deposit": "Deposit",
    "Withdrawal": "Withdrawal",
    "Bank Info": "Withdrawal Method",
    "Transaction History": "Wallet History",
    "Bonus History Details": "Bonus History",
    "Team": "Team",
    "Team Overview": "Overview",
    "Team Investment": "User Details",
    "Performance": "Data Statistics",
    "Referral": "Referral",
    "Profile": "Profile",
    "Settings": "Settings",
    "KYC": "Identity Verification",
    "Security": "Password",
    "Wallet Withdrawal": "Wallet Withdrawal",
    "Change Password": 'Change Login Password',
    "Forget Transaction Password": 'Change Transaction Password',
    "Notification": "Notification",
    "Hi,": "Hi,",
    "Stop Trading Account": 'Stop Trading \nMT5 Account',
    "Attention": "Attention",
    "Are you sure you want to stop trading?": "Are you sure you want to stop trading?",
    "Yes, I am sure!": "Yes, I am sure!",
    "Cancel": "Cancel",
    "New password must at least 6 characters": 'New password must at least 6 characters',
    "Confirm password does not match the new password": "Confirm password does not match the new password",
    "New login password change successful": 'New login password change successful',
    'Back': "Back",
    "Old Password": "Old Password",
    "New Password": "New Login Password",
    "Your Old Password": "Enter Old Password",
    "Your New Password": "Enter New Login Password",
    "Confirm New Password": "Confirm New Login Password",
    "Confirm Your New Password": "Enter New Login Password",
    "Update": "Update",
    "New Transaction Password": "New Transaction Password",
    "Your new transaction password": 'Enter New Transaction Password',
    "Confirm New Transaction Password": 'Confirm New Transaction Password',
    "Confirm your new confirm transaction password": "Enter New Transaction Password",
    "New transaction password change successful": 'New transaction password change successful',
    "Transaction password must at least 6 characters": 'Transaction password must at least 6 characters',
    "Confirm password does not match the new transaction password": "Confirm password does not match the new transaction password",
    "Logout": "Log Out",
    "Referral Code": "Referral Code",
    "Referral Link": "Referral Link",
    "Close": "Close",
    "Copied": "Copied",
    "Oops! No data found...": "No Data Found",
    "Loading Data": "Loading Data",

    "Male" : "Male",
    "Female" : "Female",
    "Not Verified": "Not Verified",
    "Only Numbers are allowed in this field": 'Only Numbers are allowed in this field',
    "Image size cannot exceed 2mb": 'Image size cannot exceed 2mb',
    "Only allow format jpg, png or jpeg": "Only allow format jpg, png or jpeg",
    "Gender": "Gender",
    "Select Gender": "Select Gender",
    "IC / passport no.": "Identity card / passport no.",
    "Your IC / Passport No.": "Enter Identity card / Passport No.",
    "Date of Birth": 'Date of Birth',
    "Your Date of Birth": 'Enter Date of Birth',
    "Phone Number": 'Phone Number',
    "Your phone number": 'Enter phone number',
    "Address": "Address",
    "Your address": 'Enter address',
    "Joining Date": 'Register Date',
    "KYC Status": 'KYC Status',
    "Remark": "Remarks",
    "Upload IC/ Passport front": "Upload IC Front/ Passport",
    "Upload IC/ Passport Back": "Upload IC Back/ Passport",
    "Upload Photo With Holding IC/Passport": "Upload Photo With Holding IC/Passport",
    "APPROVED": "APPROVED",
    "REVIEW": "REVIEW",
    "REJECTED": "REJECTED",
    "PENDING": "PENDING",
    "Congratulations, your account has been successfully created.": "Congratulations, your account has been successfully created.",
    "Continue": 'Continue',
    "Profile successful updated": "Profile successful updated",
    "ID": "ID",
    "Upline": 'Referral',
    "My Referral Code": "My Referral Code",
    "Edit" : "Edit",
    "MT5 Username": 'MT5 Username',
    "MT5 Password": "MT5 Password",
    "share my QR code": 'Copy Referral Link To Friends',
    "My Rank": "My Rank",
    "Active Group Member": "Active User",
    "Total Group Member": "Total User",
    "Ranking Bonus": "Same Rank Bonus",
    "Lot Rebate Bonus": "Lot Rebate Bonus",
    "Profit Sharing Bonus": "Profit Sharing Bonus",
    "Direct Referral Members": "Direct Referral",
    "Direct Referral Investment Amount": "Personal Sales",
    "Total Group Investment Amount": "Group Sales",
    "Personal Sales": "Personal Sales",
    "Group Sales": "Group Sales",
    "Self Deposit": "My MT5 Balance",
    "Ranking": "Ranking",
    "Wallet Balance:": "Wallet Balance:",
    "Total Member": "Total User",
    "Total Active Member": "Active User",
    "Total Sales": "Total Sales",
    "Personal Deposit": "MT5 Balance",
    "Type Of Bonus": "Bonus Type",
    "Amount": 'Amount',
    "Date & Time": "Time",
    "View More": "View More",
    "Wallet Transaction History": "Wallet History",
    "Description": "Description",
    "Type": "Type",
    "Action": "Action",
    "Transaction Hash" : "Transaction Hash",
    "TXID": 'TXID',
    "Status": "Status",
    "MT5 Balance": "MT5 Balance",
    "Crypto Deposit": 'Crypto Deposit',
    "Seamlessly Add Cryptocurrency To Your Account And Start Trading Effortlessly.": 'For Safety Of Your Funds, Please Verify And Check The Deposit Address.',
    "Fiat Deposit": 'Fiat Deposit',
    "Conveniently Deposit Funds Using Your Preferred Fiat Currency, Ensuring A Smooth And Hassle-Free Experience.": "For Safety Of Your Funds, Please Verify And Check the Transfer Details.",
    "Coin": "Coin",
    "Network": 'Chain',
    "Select Network": "Select Chain",
    'Deposit Address': "Deposit Address",
    "Wallet Address": "Wallet Address",
    "Minimum Deposit": "Minimum Deposit",
    "Please Select Network": "Please Select Chain !",
    "Total Invested": "My Total Assets",
    "Total Profit/Loss": "Total Profit/Loss",
    "Investment Details": "Order Details",
    "Order ID": "Order ID",
    "Price": "Price",
    "Trading Pair": "Pair",
    "Profit/Loss %": "Profit/Loss",
    "Lot Size": "Lot Size",
    "Price Enter": "Enter Price",
    "Price Close": "Close Price",
    "Announcement": "Announcement",
    "No Announcement For Now": "No Announcement For Now",
    "Group Sales Performance": "My Total Sales",
    "Sales": "Sales",
    "Read More": "Read More",
    "My MT5 Info": "My MT5 Info",
    "Wallet Total Balance": "Wallet",
    "Trading Profit": "Trading Profit",
    "Total Investment": "MT5 Balance",
    "Total Bonus": "Total Bonus",
    "My QR Referral Code": "My QR Referral Code",
    "Transfer": "Transfer",
    "From": "From",
    "To": "To",
    "Transfer Amount": "Transfer Amount",
    "Balance Available:": "Available :",
    "Your Transfer Amount": 'Enter Transfer Amount',
    "Successful": "Successful",
    "Unsuccessful": "Unsuccessful",
    "Insufficient balance": "Insufficient balance",
    "Add New Withdrawal Method" : "Add New Withdrawal Method",
    "Withdrawal Method Available" : "Withdrawal Method Available",
    "Invalid email or password": 'Invalid email or password',
    "Fiat Withdrawal": 'Fiat Withdrawal',
    "Crypto Withdrawal": "Crypto Withdrawal",
    "Bank Country": "Bank Country",
    "Bank Name": "Bank Name",
    "Bank Account Holder Name": "Bank Account Holder Name",
    "Bank Account Number": "Bank Account Number",
    "Bank Address": "Bank Address",
    "Bank Swift Code": "Bank Swift Code",
    "Select Your Bank country": "Select Your Bank country",
    "Select Your Bank Name": "Select Your Bank Name",
    "Your Bank Account Holder Name": "Enter Bank Account Holder Name",
    "Your Bank Account Number": "Enter Bank Account Number",
    "Your Bank Address": "Enter Bank Address",
    "Your Bank Swift Code": "Enter Bank Swift Code",
    "Your Bank Name": "Enter Bank Name",
    "Your remark": "Enter Remarks",
    "Your Wallet Address": "Enter Wallet Address",
    "Select Your Network" : "Select Chain",
    "Save": 'Save',
    "Select Bank Name": 'Select Bank Name',
    "No network available": 'No network available',
    "Withdrawal From Wallet": "Available Withdrawal",
    "Email Verification Code": 'Email Verification Code',
    "Your Email Verification Code": "Enter Email Verification Code",
    "Withdraw Amount": "Withdrawal Amount",
    "Your Withdraw Amount": "Enter Withdrawal Amount",
    "Select Type": "Select Type",
    "No option available": 'No option available',
    "Select bank transfer": 'Select bank transfer',
    "Great News! You have the option to resume trading. Do you want to resume trading?": "Great News! You have the option to resume trading. Do you want to resume trading?",
    "Click here": "Click here",
    "to reopen trading activity.": "to reopen trading activity.",
    "Receive Amount" : "Receive Amount",
    "Network Fee:" : "Network Fee : ",
    "Exchange Rate:" : "Exchange Rate : ",
    "Charge Fee:" : "Charge Fee : ",
    "Merchant " : "Merchant ",
    "Select Merchant" : "Select Merchant",
    "Order Number": "Order Number",
    "Your Order Number": "Enter Order Number",
    "Payment Currency ": "Payment Currency ",
    "Select Payment Currency": "Select Payment Currency",
    "No currency available": "No currency available",
    "Deposit Amount": "Deposit Amount",
    "Your Deposit Amount": "Enter Deposit Amount",
    "Minimum to maximum transaction is 1000-4000 USD": "Minimum To Maximum is 1000-4000 USD",
    "Remove Withdrawal Method": "Remove Withdrawal Method",
    "Withdrawal Type": 'Withdrawal Method',
    "fiat Withdraw": "fiat withdraw",
    "crypto Withdraw": "crypto withdraw",
    "Delete" : "Delete",
    "Balance Available": "Balance Available",
    "Amount must greater than 0.": 'Amount must greater than 0.',
    "Remark:": "Remark:",
    "Amount must only within 1000 - 4000": 'Amount must only within 1,000 - 4,000',
    "View Details": 'View Details',
    "Details": 'Details',
    "Investment Package": "Copy Strategies",
    "Select Your Package": "Select Copy Strategies",
    "Monthly Return": "Trading Profit (Month)",
    "Minimum investment amount is": 'Minimum investment amount is',
    "Maximum investment amount is": "Maximum investment amount is",
    "Purchase Package Details": "Account Details",
    "Purchase Datetime": "Start Time",
    "Cumulative Investment Days": 'Copy Days',
    "Investment Allocation": "Amount",
    "Surrender": "Surrender",
    "Successfully surrender": "Successfully surrender",
    "active": "active",
    "Account Type": "Account Type",
    "Select Account Type": "Select Account Type",
    "No account type available": "No account type available",
    "Days": "Days",
    "Processing Fee": "Processing Fee",
    "Invalid withdraw amount": 'Invalid withdraw amount',
    "Withdraw": "Withdraw",
    "MT5 Account": "MT5 Account",
    "Select MT5 Account": "Select MT5 Account",
    "All MT5 Account": 'All MT5 Account',
    "Account": 'Account',
    "MT5 Account 1 Username": 'MT5 Login (Account 1)',
    "MT5 Account 2 Username": "MT5 Login (Account 2)",
    "MT5 Account 3 Username": "MT5 Login (Account 3)",
    "MT5 Account 4 Username": "MT5 Login (Account 4)",
    "MT5 Account 1 Password": 'MT5 Password (Account 1)',
    "MT5 Account 2 Password": 'MT5 Password (Account 2)',
    "MT5 Account 3 Password": 'MT5 Password (Account 3)',
    "MT5 Account 4 Password": 'MT5 Password (Account 4)',
    "Payment Amount": 'Payment Amount',
    "Your Payment Amount": 'Your Payment Amount',
    "Convert": "Convert",
    "Exchange Rate": "Exchange Rate : ",
    "Your IP does not support the operation": "Your IP does not support the operation",
    "to": "to",
    "inactive": "inactive",
    "Account withdrawal type": "Withdrawal Method",
    "Select withdrawal type": "Select withdrawal method",
    "MT5 Login ID": "MT5 Login ID",
    "completed": "completed",
    "Transfer1" : "Transfer",
    "Copy" : "Copy",
    "Confirm" : "Confirm",
    "MT5 Login ID1": "MT5 Login ID",
    "Change Transaction Password" : "Change Transaction Password",
    "Confirm login password and login password are not same" : "Confirm login password and login password are not same",
    "Assets Type" : "Assets Type",
    "Select Bank Country" : "Select Bank Country",
    "Enter Verification Code" : "Enter Email Verification Code",
    "Trend Trading Strtegies" : "Trend Trading Strtegies",
    "Trading Details" : "Trading Details",
    "Prudent" : "Prudent",
    "Short Trade" : "Short Trade",
    "Runtime" : "Runtime",
    "Total Copiers" : "Total Copiers",
    "AUM (USD)" : "AUM (USD)",
    "Profit/Loss (Month)" : "Profit/Loss (Month)",
    "Minimum Copy Amount (USD)" : "Minimum Copy Amount (USD)",
    "Follow Orders" : "Follow Orders",
    "Support Resistance Strategies" : "Support Resistance Strategies",
    "Candlestick Patterns Strategies" : "Candlestick Patterns Strategies",
    "Trend trading is very obvious, which is to seize the trend of the day and create profits as much as possible. The short-term trend trading strategy is very simple. If you see a foreign exchange currency pair rebounding three times on the same trend line, you can predict a strong trend and find the direction to trade in that trend." : "Trend trading is very obvious, which is to seize the trend of the day and create profits as much as possible. The short-term trend trading strategy is very simple. If you see a foreign exchange currency pair rebounding three times on the same trend line, you can predict a strong trend and find the direction to trade in that trend.",
    "Win Trades" : "Win Trades",
    "Lose Trades" : "Lose Trades",
    "Max Drawdown" : "Max Drawdown",
    "Trading Pairs" : "Trading Pairs",
    "Support/resistance trading is the best short-term trading method, with a focus on identifying key price points on the chart and then conducting breakthrough trading. The risk management of this strategy is also very direct, which is to set stop loss at key price points." : "Support/resistance trading is the best short-term trading method, with a focus on identifying key price points on the chart and then conducting breakthrough trading. The risk management of this strategy is also very direct, which is to set stop loss at key price points.",
    "It is traded in the form of a candle chart, with time frames locked to 1-minute, 5-minute, and 15 minute charts. We need to find the inverted candle pattern on the chart as a signal to trade. Similarly, every stop loss is very important, and a good stop loss point is to reverse the candlestick on the opposite side of your trade." : "It is traded in the form of a candle chart, with time frames locked to 1-minute, 5-minute, and 15 minute charts. We need to find the inverted candle pattern on the chart as a signal to trade. Similarly, every stop loss is very important, and a good stop loss point is to reverse the candlestick on the opposite side of your trade.",
    "Ranking1" : "Ranking",
    "Wallet Deposit" : "Wallet Deposit",
    "Enter Verification Code1" : "Enter Verification Code",
    "Your Full Name1": "Enter Full Name As Identity Card/Passport",
    "Enter New Login Password" : "Enter New Login Password",
    "New Login Password" : "New Login Password",
    "Confirm New Login Password" : "Confirm New Login Password",
    "MT5 PROFIT" : "MT5 Profit",
    "In" : "In",
    "Lot Rebate" : "Lot Rebate",
    "2.50 from test0@gmail.com" : "2.50 from test0@gmail.com",
    "Hedged Position" : "Hedged Account",
    "Start" : "Start",
    "End" : "End",
    "Source from MT5 account 1": "From MT5 Account 1",
    "Rebate User" : "Rebate User",
    "Details" : "Details",
    "Old Transaction Password" : "Old Transaction Password",
    "Enter Old Transaction Password" : "Enter Old Transaction Password",
    "Coming Soon" : "Coming Soon",
    "Enter Total Commission" : "Enter Total Commission",
    "Active" : "Pending",
    "Pending Verify" : "Review",
    "Rejected" : "Rejected",
    "Verified" : "Verified",
    "Please Try Again": "Please Try Again",
    "Not Verified": "Not Verified",
    "My MT5 Balance": 'My MT5 Balance',
    "Contract Commission": 'Contract Commission',
    "Select Transaction Type": "Select Transaction Type",
    "Bonus": "Bonus",
    "Withdraw Method": "Withdraw Method",
    "crypto": "crypto",
    "fiat" : "fiat",
    "Pending1" : "Pending",
    "Approved" : 'Approve',
    "Rejected" : "Rejected",
    "Active1" : "Active",

    "wallet_active": "Success",
    "wallet_pending": "Pending",
    "wallet_approved": "Approved",
    "wallet_rejected": "Rejected",
    "WITHDRAW": "Withdraw",
    "DEPOSIT_CRM": "Deposit Wallet",
    "DEPOSIT_MT5": "Deposit MT5",
    "Request wallet withdraw": 'Request wallet withdraw',
    "Submit Amount": 'Submit Amount',
    "Fee": "Fee",
    "Receive Amount": "Receive Amount",
    "Result Per Page": "Result Per Page",
    "Contract Period": "Contract Period",
    "Select Contract Period": 'Select Contract Period',
    "Topup": "Top Up",
    "Topup Amount": 'Top Up Amount',
    "Select Topup Amount": "Select Top Up Amount",
    "Enter Topup Amount": "Enter Top Up Amount",
    "Extra Profit": 'Extra Profit',
    "Old password must at least 6 characters": "Old password must at least 6 characters",
    "New password must at least 6 characters": "New password must at least 6 characters",
    "Old transaction password must at least 6 characters": "Old transaction password must at least 6 characters",
    "The page you were looking for is not found!": "The page you were looking for is not found!",
    "Back To Login": "Back To Login",
    "Select Bonus Type": "Select Bonus Type",
    "Same Rank Bonus": "Same Rank Bonus",
    "Early Bird Bonus": "Early Bird Bonus",
    "VIP Bonus": "VIP Bonus",
    "Early Bird Bonus (Purchaser 1.25%)": "Early Bird Bonus (Purchaser 1.25%)",
    "Early Bird Bonus (Referral 1.25%)": "Early Bird Bonus (Referral 1.25%)",
    "TRANSFER_FROM_MT5": "Withdraw MT5",
    "Withdraw MT5": "Withdraw MT5",
    "Penalty Fee": "Penalty Fee",
    "Capital withdrawal only allowed on Saturdays and Sundays.": "Capital withdrawal only allowed on Saturdays 3pm until Sundays 11.59pm.",
    "Notice": "Notice",
    "Period Extra Profit": "Period Extra Profit",
    "Password has reset successfully": "Password has reset successfully",
    "Adjust": "Adjust",
    "week": "Week",
    "month": "Month",
    "My Total Assets": "My Total Assets",
    "Sales": "Sales",
    "More": "More",
    "Login ID": "Login ID",
    "Let’s get you all set up so you can start your first onboarding experience !": "Let’s get you all set up so you can start your first onboarding experience !",
    "Sign Up": "Sign Up",
    "Log in to your account so you can continue building & editing your onboarding flows !": 'Log in to your account so you can continue building & editing your onboarding flows !',
    "Posted at": "Posted at",
    "MT5 Profit": "MT5 Profit",
    "Rank": "Rank",
    "VIP Bonus (Purchaser 0.75%)": "VIP Bonus (Purchaser 0.75%)",
    "VIP Bonus (Referral 0.75%)": "VIP Bonus (Referral 0.75%)",
    "Hide Password": "Hide Password",
    "Show Password": "Show Password",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "Sun": "Sun",
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    "Wallet Withdraw": "Withdraw",
    "Start Date": "Start Date",
    "Period Extra Profit": 'Period Extra Profit',
    "Early Bird": "Early Bird",
    "VIP": "VIP",
    "Early Bird/VIP": "Early Bird/VIP",
    "Package": "Package",
    "Total Earning Bonus": "Total Earning Bonus"  ,
    "Remaining Period": "Remaining Period",
    "deleted": "deleted",
    "Txn Hash": "Txn Hash",
    "Internal Transfer": "Transfer",
    "Wallet Balance": "Wallet Balance",
    "TRANSFER": "Transfer",
    "MT5 Account $index Username": "MT5 Account $index Username",
    "MT5 Account $index Password": "MT5 Account $index Password",
    "Renew Contract": "Renew Contract",
    "New Contract Period": "New Contract Period",
    "Please note": "Please note",
    "The minimum deposit amount is 15 USDT. If the deposit is less than 15 USDT, System wallet will not be able to received funds or display the balance. Please make sure minimum deposit 15 USDT.": "The minimum deposit amount is <span class='font-weight-600 text-red'>15 USDT</span>. If the deposit is less than <span class='font-weight-600 text-red'>15 USDT</span>, System wallet will not be able to received funds or display the balance. Please make sure minimum deposit <span class='font-weight-600 text-red'>15 USDT</span>.",
}

export default EN;