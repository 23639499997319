import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
import { logout } from "../../../store/actions/AuthActions";
import "../../../css/wallet.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LoadingV3 from "../../components/Loading/LoadingV3";
import { FaSpinner } from "react-icons/fa";
import NotFound from "../Common/NotFound";

const BankInfo = (props) => {
    const dispatch = useDispatch();
    const productPerpage = window.innerWidth > 991 ? 10 : 5;
    const [removeBankInfoModal, setRemoveBankInfoModal] = useState(false);
    const [result, setResult] = useState([]);
    const [bankResult, setBankResult] = useState([]);
    const [cryptoResult, setCryptoResult] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [bankInfoDetail, setBankInfoDetail] = useState(null);

    useEffect(() => {
        getFiat();
        getCrypto()
        return () => {};
    }, [props.auth.auth.access_token]);

    const getFiat = () => {
        // setLoadingData(true);
        fetch(api.get_banks, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingData(false);
                if (responseJson.status=== "success") {
                    setBankResult(responseJson.data)
                } else{
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getCrypto = () => {
        // setLoadingData(true);
        fetch(api.get_crypto_addresses, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingData(false);
                if (responseJson.status=== "success") {
                    setCryptoResult(responseJson.data)
                } else{
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getWithdrawInfo = () => {
        // setLoadingData(true);
        fetch(api.getWithdrawInfo, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingData(false);
                if (responseJson.status=== "success") {
                    setResult(responseJson.data.bankInfo)
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    console.log('error get withdraw', responseJson.message)
                    // swal(translate(props.lang, "Error"), responseJson.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const removeSelectedBank = (item) => {
        setRemoveBankInfoModal(true);
        setBankInfoDetail(item)
    }

    const closeBankInfoModal = () => {
        setRemoveBankInfoModal(false);
        setBankInfoDetail(null)
    }

    const confirmRemoveBank = () => {
        setLoadingDelete(true);
        fetch(api.deleteWithdrawInfo + `?id=${bankInfoDetail.id}`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingDelete(false);
                if (responseJson.status=== "success") {
                    swal(translate(props.lang, 'Success'), "", "success");
                    closeBankInfoModal();
                    getWithdrawInfo();
                } else if (responseJson.status === "error") {
                    //dispatch(logout(props.history, 'login'));                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <div className="mt-3">
                    <Row className="align-items-center justify-content-center mx-0 px-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <Link to="/wallet/bankInfo/withdraw-method">
                                <div className="whiteContainer py-md-4 d-block px-md-4 px-2 py-3 h-100">
                                    <Row className="align-items-center px-0 mx-0">
                                        <Col lg={10} md={10} sm={11} xs={11} className="px-0">
                                            <Row className="align-items-center justify-content-center px-0 mx-0">
                                                <Col lg={1} md={2} sm={3} xs={3} className="px-0 px-md-1">
                                                    <img src={require('../../../images/wallet/withdrawMethod.svg').default} alt="icon-wallet" className="img-fluid" />
                                                </Col>
                                                <Col lg={11} md={10} sm={9} xs={9}  className="px-1">
                                                    <span className="font-weight-500 font-20 white-container-title break-all-word line-height-110percent">+ {translate(props.lang, "Add New Withdrawal Method")}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={2} md={2} sm={1} xs={1} className="text-end px-0">
                                            <img src={require('../../../images/icon/blue-arrow.svg').default} className="img-fluid" alt="icon-wallet" />
                                        </Col>
                                    </Row>
                                </div>
                            </Link>
                        </Col>

                        <Col lg={12} md={12} sm={12} xs={12} className="">
                            <div className="whiteContainer py-4 d-block px-4 h-100">
                                <Row className="justify-content-center align-items-center text-center text-md-start mx-0 px-0">
                                    <Col lg={12} md={12} sm={12} xs={12} className="my-0 px-0">
                                        <span className="font-weight-600 font-18 line-height-26 text-black-1">{translate(props.lang, "Withdrawal Method Available")} ({result.length}/10)</span>
                                    </Col>
                                </Row>
                                {loadingData ? (
                                    <LoadingV3 theme="light" />
                                ) : (
                                    result.length > 0 ? (
                                        result.map((item, i) => (
                                            <div className="bankInfoContainer py-md-4 d-block px-md-4 p-3 h-100 mt-4 border-white" key={i}>
                                                <Row className="align-items-center px-0 mx-0">
                                                    <Col lg={1} md={1} sm={2} xs={2} className="d-none d-md-block px-0 px-md-1">
                                                        {item.withdrawType === 'FIAT_WITHDRAW' ? (
                                                            <img src={require('../../../images/wallet/fiatwithdraw.svg').default} className="img-fluid" alt={item.withdrawType} />
                                                        ) : (<img src={require('../../../images/wallet/cryptowithdraw.svg').default} className="img-fluid" alt={item.withdrawType} />)}
                                                    </Col>
                                                    <Col lg={10} md={10} sm={10} xs={10} className="px-0 px-md-4">
                                                        <div className="">
                                                            {item.withdrawType ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Withdrawal Type")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">{translate(props.lang, item.withdrawType.toLowerCase().replace('_', ' '))}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            {item.walletAddress ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Coin")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">USDT | TetherUS</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            {item.walletAddress ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Wallet Address")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">{item.walletAddress}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            {item.bankCountry ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Bank Country")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">{item.bankCountry}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            {item.bankName ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Bank Name")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">{item.bankName}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            {item.accHolderName ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Bank Account Holder Name")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">{item.accHolderName}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            {item.accNumber ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Bank Account Number")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">{item.accNumber}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            {item.bankAddress ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Bank Address")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">{item.bankAddress}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            {item.bankSwiftCode ? (
                                                                <Row className="px-0 mx-0 justify-content-start align-items-center mb-1">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Bank Swift Code")}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                        <div>
                                                                            <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-bsae">{item.bankSwiftCode}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                            <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                                <Col lg={5} md={5} sm={12} xs={12} className="mb-1 px-0">
                                                                    <div>
                                                                        <span className="font-weight-600 font-18 white-container-title break-all-word line-height-110percent text-capitalize">{translate(props.lang, "Remark")}</span>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={7} md={7} sm={12} xs={12} className="mb-1 px-0">
                                                                    <div>
                                                                        <span className="font-weight-400 font-16 text-black break-all-word text-capitalize overflow-wrap-anywhere lh-base">{item.remark ? item.remark : '-'}</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col lg={1} md={1} sm={2} xs={2} className="px-0 px-md-1">
                                                        <div className="d-flex h-100 justify-content-end">
                                                            <div className="w-50 arrow-icon-container py-3 text-end text-md-end" onClick={() => removeSelectedBank(item)}>
                                                                <img src={require('../../../images/icon/deleteIcon.svg').default} className="img-fluid" alt="icon-wallet" />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="py-5 mt-3 text-center whiteContainer">
                                            <NotFound />
                                        </div>
                                    )
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Modal className="fade modalColorBg" show={removeBankInfoModal} centered>
                        <Modal.Header className="border-0 pt-4 pb-2">
                            <div className="d-flex w-100 align-items-center">
                                <div className="w-75"></div>
                                <div className="w-25 text-end cursor-pointer" onClick={() => closeBankInfoModal()} >
                                    <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                        <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                    </svg>
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="border-0 px-4">
                            <div className='modalBody'>
                                <div>
                                    <div className="text-center mb-5">
                                        <span className="font-weight-700 font-22 text-black">{translate(props.lang, "Remove Withdrawal Method")}</span>
                                    </div>
                                    {bankInfoDetail ? (
                                        <Row className="mx-0 px-0">
                                            {bankInfoDetail.withdrawType ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Withdrawal Type")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 line-height-26 text-black text-capitalize break-all-word">{translate(props.lang, bankInfoDetail.withdrawType.toLowerCase().replace('_', ' '))}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            {bankInfoDetail.bankCountry ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Bank Country")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 line-height-26 text-black text-capitalize break-all-word">{bankInfoDetail.bankCountry}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            {bankInfoDetail.bankName ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Bank Name")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 line-height-26 text-black break-all-word">{bankInfoDetail.bankName}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            {bankInfoDetail.accHolderName ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Bank Account Holder Name")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 line-height-26 text-black break-all-word">{bankInfoDetail.accHolderName}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            {bankInfoDetail.accNumber ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Bank Account Number")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 line-height-26 text-black break-all-word">{bankInfoDetail.accNumber}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            {bankInfoDetail.bankAddress ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Bank Address")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 line-height-26 text-black break-all-word">{bankInfoDetail.bankAddress}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            {bankInfoDetail.bankSwiftCode ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Bank Swift Code")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 line-height-26 text-black break-all-word">{bankInfoDetail.bankSwiftCode}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            {bankInfoDetail.walletAddress ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Coin")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 line-height-26 text-black break-all-word overflow-wrap-anywhere">USDT | TetherUS</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            {bankInfoDetail.walletAddress ? (
                                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                    <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Wallet Address")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                            <div>
                                                                <span className="font-weight-400 font-18 lh-base text-black break-all-word overflow-wrap-anywhere">{bankInfoDetail.walletAddress}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}

                                            <Col lg={12} md={12} xs={12} sm={12} className="mb-3 px-0">
                                                <Row className="px-0 mx-0 justify-content-start align-items-center">
                                                    <Col lg={4} md={4} sm={12} xs={12} className="mb-1">
                                                        <div>
                                                            <span className="font-weight-600 font-18 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Remark")}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={8} md={8} sm={12} xs={12} className="mb-1">
                                                        <div>
                                                            <span className="font-weight-400 font-18 line-height-26 text-black break-all-word">{bankInfoDetail.remark}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} md={12} xs={12} sm={12}>
                                                <Row className="mx-0 px-0 pt-4 align-items-center justify-content-between">
                                                    <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                                        <div className="cancel-btn px-4 py-3 text-center mx-auto w-100" onClick={() => closeBankInfoModal()}>
                                                            <span className="font-weight-500 font-18 line-height-26 text-white d-block">{translate(props.lang, "Cancel")}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                                        <button className="delete-btn px-4 py-3 text-center mx-auto w-100 float-end" disabled={loadingDelete} onClick={() => confirmRemoveBank()}>
                                                            <span className="font-weight-600 font-18 line-height-26 text-white d-block">{loadingDelete ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Delete")}</span>
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(BankInfo);