import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { Col, Row, Table } from "reactstrap";
import "../../../css/wallet.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { bonusTypeList, shownItem, transactionTypeList } from "../../../utils/config";
import { digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from "../../components/Loading/LoadingV3";
import customSelectStyles from "../SelectStyle";
import InfoBoxV2 from "../Common/InfoBoxV2";
import NotFound from "../Common/NotFound";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";

const { RangePicker } = DatePicker;

const WalletOverview = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingBonusHistory, setLoadingBonusHistory] = useState(false);
    const [loadingWalletHistory, setLoadingWalletHistory] = useState(false);
    const [transactionType, setTransactionType] = useState('');
    const [bonusTransactionType, setBonusTransactionType] = useState('');
    const dispatch = useDispatch();
    const [bonusHistoryList, setBonusHistoryList] = useState([]);
    const [localeSetting, setLocaleSetting] = useState({});
    const [walletInfo, setWalletInfo] = useState({
        wallet_balance: '',
        mt5_balance: '',
        hedge_balance: '',
        total_trade_profit: '',
        total_commission_profit: '',
    })
    const [result, setResult] = useState({
        depositWallet: 0,
        bonusWallet: 0,
    })
    const [productPerpage, setProductPerpage] = useState(12);
    const [bonusProductPerpage, setBonusProductPerpage] = useState(12);
    const [trxInfoModal, setTrxInfoModal] = useState(false);
    const [trxDetail, setTrxDetail] = useState(null);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [bonusFrom, setBonusFrom] = useState("");
    const [bonusTo, setBonusTo] = useState("");
    const timerRef = useRef(null);
    const timerTrxRef = useRef(null);

    useEffect(() => {
        getStatement();
    }, [props.auth.auth.access_token, props.lang]);

    useEffect(() => {
        getBonusTransaction();
    }, [props.auth.auth.access_token, bonusFrom, bonusTo, props.lang, bonusProductPerpage, bonusTransactionType]);

    useEffect(() => {
        getTransactionList();
    }, [props.auth.auth.access_token, transactionType, from, to, props.lang, productPerpage])

    useEffect(() => {
        setLocaleSetting({
            applyLabel: translate(props.lang, 'Confirm'), // Change "Apply" button text to "Confirm"
            cancelLabel: translate(props.lang, 'Cancel'),
            monthNames:
                [
                    translate(props.lang, 'January'),
                    translate(props.lang, 'February'),
                    translate(props.lang, 'March'),
                    translate(props.lang, 'April'),
                    translate(props.lang, 'May'),
                    translate(props.lang, 'June'),
                    translate(props.lang, 'July'),
                    translate(props.lang, 'August'),
                    translate(props.lang, 'September'),
                    translate(props.lang, 'October'),
                    translate(props.lang, 'November'),
                    translate(props.lang, 'December')
                ],
            daysOfWeek: [
                translate(props.lang, 'Sun'),
                translate(props.lang, 'Mon'),
                translate(props.lang, 'Tue'),
                translate(props.lang, 'Wed'),
                translate(props.lang, 'Thu'),
                translate(props.lang, 'Fri'),
                translate(props.lang, 'Sat'),
            ]
        })
    }, [props.lang])

    const getStatement = () => {
        setLoading(true);
        fetch(api.get_dashboard, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.access_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setWalletInfo(responseJson.data);
                } else {
                    if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                        dispatch(logout(props.history, 'login'));
                    }
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getTransactionList = () => {
        setLoadingWalletHistory(true);
        clearTimeout(timerTrxRef.current);
        timerTrxRef.current = setTimeout(() => {
            let url = api.get_transactions + `?page=${1}&limit=${productPerpage}`;

            if (from && to) {
                url += `&date_start=${from}&date_end=${to}`
            }
            if (transactionType) {
                url += `&transaction_types=${transactionType}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang,
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoadingWalletHistory(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.results);
                    } else {
                        if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        console.log("error", responseJson.message);
                    }

                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const handleTimeChange = (e, type) => {
        const formattedFromDate = e ? dayjs(e[0]).format("YYYY-MM-DD") : '';
        const formattedToDate = e ? dayjs(e[1]).format("YYYY-MM-DD") : '';
        if (type === 'bonus') {
            setBonusFrom(formattedFromDate);
            setBonusTo(formattedToDate);
        } else {
            setFrom(formattedFromDate);
            setTo(formattedToDate);
        }
    }

    const getBonusTransaction = () => {
        setLoadingBonusHistory(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_transactions + `?page=${1}&limit=${bonusProductPerpage}`;

            if (bonusFrom && bonusTo) {
                url += `&date_start=${bonusFrom}&date_end=${bonusTo}`
            }
            if (bonusTransactionType) {
                url += `&transaction_types=${bonusTransactionType}`
            } else {
                let allBonus = bonusTypeList.filter(item => item.label === 'All');
                if (allBonus.length > 0) {
                    url += `&transaction_types=${allBonus[0].value}`
                }
            }

            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.access_token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoadingBonusHistory(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setBonusHistoryList(responseJson.data.results);
                    } else {
                        if (responseJson.status === 'error' && responseJson.message === 'Token Expired') {
                            dispatch(logout(props.history, 'login'));
                        }
                        console.log("error", responseJson.message);
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const openTrxInfoModal = (info) => {
        setTrxDetail(info);
        setTrxInfoModal(true);
    }

    const closeTrxInfoModal = () => {
        setTrxDetail(null);
        setTrxInfoModal(false)
    }

    const handleDateChange = (e, picker) => {
        let startDate = moment(picker.startDate).format('YYYY-MM-DD');
        let endDate = moment(picker.endDate).format('YYYY-MM-DD');
        setFrom(startDate ? startDate : '')
        setTo(endDate ? endDate : '')
    };

    const clearDateSearch = () => {
        setFrom('');
        setTo('')
    }

    const clearBonusDateSearch = () => {
        setBonusFrom('');
        setBonusTo('')
    }

    const handleBonusDateChange = (e, picker) => {
        let startDate = moment(picker.startDate).format('YYYY-MM-DD');
        let endDate = moment(picker.endDate).format('YYYY-MM-DD');
        setBonusFrom(startDate ? startDate : '')
        setBonusTo(endDate ? endDate : '')
    }

    const getTypeText = (text) => {
        let newText = props.lang === 'en' ? text.split('(') : text.split('（');
       
        return (
            <>
             <span className="d-md-inline-block d-block pe-md-1">{newText[0]}</span>
             <span className="d-md-inline-block d-block">{props.lang === 'en' ? "(" : "（"}{newText[1]}</span>
            </>
        )
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <div className="mt-3">
                    <Row className="align-items-center justify-content-start mx-0 px-0">
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                            <InfoBoxV2
                                icon={require('../../../images/icon2/wallet.svg').default}
                                boxLabel={translate(props.lang, "Wallet")}
                                boxCurrency={`USD`}
                                boxValue={`${walletInfo && walletInfo.wallet_balance ? Number(walletInfo.wallet_balance).toLocaleString('en-US', digitFormat) : "0.00"}`}
                                loading={loading}
                            />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                            <InfoBoxV2
                                icon={require('../../../images/icon2/mt5Balance.svg').default}
                                boxLabel={translate(props.lang, "MT5 Balance")}
                                boxCurrency={`USD`}
                                boxValue={`${walletInfo && walletInfo.mt5_balance ? Number(walletInfo.mt5_balance).toLocaleString('en-US', digitFormat) : "0.00"}`}
                                loading={loading}
                            />
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="tableBgContainer py-4 px-md-4 px-2">
                                <Row className="align-items-center px-0 mx-0">
                                    <Col lg={9} md={9} sm={8} xs={8} className=" mb-4">
                                        <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Wallet Transaction History")}</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={4} xs={4} className="text-end mb-4">
                                        <Link to="/wallet/transaction-history">
                                            <span className="view-more-text font-weight-600 font-16 line-height-26">{translate(props.lang, "View More")}</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className="align-items-center px-0 mx-0 justify-content-start">
                                    <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-lg-0">
                                        {/* <RangePicker
                                            className="w-100 mt-md-0 input-transparent"
                                            placeholder={[
                                                translate(props.lang, "Start"),
                                                translate(props.lang, "End"),
                                            ]}
                                            onChange={(e) => handleTimeChange(e, 'wallet')}
                                        /> */}
                                        <div className="groupLabel-input date px-2 py-3 py-md-2">
                                            <DateRangePicker
                                                onApply={handleDateChange}
                                                startDate={from}
                                                endDate={to}
                                                initialSettings={{ locale: localeSetting }}
                                                key={JSON.stringify(localeSetting)}
                                            >
                                                <span className="font-16 font-weight-600 text-black">
                                                    <Row className="align-items-center justify-content-between px-0 mx-0">
                                                        <Col lg={4} md={4} sm={4} xs={4} className="ps-0 text-center">
                                                            <span className={`font-18 font-weight-600 ${from ? "text-black" : "placeholder-color"}`}>{from ? from : translate(props.lang, "Start")}</span>
                                                        </Col>
                                                        <Col lg={2} md={2} sm={2} xs={2} className="px-0 text-center">
                                                            <img src={require('../../../images/icon2/dateArrow.svg').default} className="img-fluid" />

                                                        </Col>
                                                        <Col lg={4} md={4} sm={4} xs={4} className="pe-0 text-center">
                                                            <span className={`font-18 font-weight-600 ${to ? "text-black" : "placeholder-color"}`}>{to ? to : translate(props.lang, "End")}</span>
                                                        </Col>
                                                        <Col lg={2} md={2} sm={2} xs={2} className="text-end pe-0">
                                                            {from && to ? (
                                                                <div className="d-flex w-100 justify-content-end" onClick={() => clearDateSearch()}>
                                                                    <div className="gold-indicatior-container d-flex align-items-center justify-content-end">
                                                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <img src={require('../../../images/icon2/calendar.svg').default} className="calendar-icon" />
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </span>
                                            </DateRangePicker>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12} className="h-100 mb-2 mb-lg-0">
                                        <div className={`groupLabel-select select-placeholder-center w-100 py-1 py-md-0 px-2`}>
                                            <Select
                                                options={transactionTypeList.length > 0 ? transactionTypeList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                                placeholder={translate(props.lang, "Select Transaction Type")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={customSelectStyles}
                                                value={
                                                    transactionTypeList.filter(item => item.value === transactionType).length > 0 ?
                                                        (transactionTypeList.filter(item => item.value === transactionType).map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })))
                                                        : null
                                                }
                                                menuPortalTarget={document.body}
                                                isClearable
                                                isSearchable={false}
                                                onChange={(e) => setTransactionType(e ? e.value : '')}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-lg-0">
                                        <div className={`groupLabel-select select-placeholder-center w-100 py-1 py-md-0 px-2`}>
                                            <Select
                                                options={
                                                    shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                                }
                                                placeholder={translate(props.lang, "Result Per Page")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={customSelectStyles}
                                                // classNamePrefix="customSelect"
                                                menuPortalTarget={document.body}
                                                value={shownItem && shownItem.find(option => option.value === productPerpage)}
                                                name="productPerpage"
                                                isSearchable={false}
                                                onChange={(e) => setProductPerpage(e.value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center px-0 mx-0 justify-content-start mt-4">
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="tableContainer">
                                            <Table responsive className="w-100 table-list text-center">
                                                <thead>
                                                    <tr>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Date & Time")}</th>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Assets Type")}</th>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Action")}</th>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Amount")} (USD)</th>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Status")}</th>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Details")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loadingWalletHistory ? (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <LoadingV3 theme='light' />
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        result.length > 0 ? (
                                                            result.map((item, i) => (
                                                                <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                                     <td className={`font-weight-500 lh-base`} style={{ minWidth: '150px'}}>
                                                                    <span className="d-md-inline-block d-block pe-md-1">{item.date_added.split(" ")[0]}</span>
                                                                    <span className="d-md-inline-block d-block">{item.date_added.split(" ")[1]}</span>
                                                                </td>
                                                                <td className={`font-weight-500 lh-base`}  style={{ minWidth: props.lang === 'en' ? '200px' : '150px'}}>{item.transaction_type === 'EARLY_BIRD_BONUS' || item.transaction_type === "VIP_BONUS" ? getTypeText(item.transaction_type_text) : item.transaction_type_text}</td>
                                                                <td className={`font-weight-500 text-capitalize`}>{item.type_text.toLowerCase()}</td>
                                                                    <td className={`font-weight-500`}>{Number(item.amount).toLocaleString('en-US', digitFormat)}</td>
                                                                    {/* <td className={`font-weight-500 status ${item.status.toLowerCase()}`}>
                                                                        {translate(props.lang, "wallet_" + item.status.toLowerCase())}
                                                                    </td> */}
                                                                    <td className={`font-weight-500`} style={{ minWidth: '100px'}}>
                                                                        <span className={`statusBox px-3 py-1 ${item.status.toLowerCase()}`}> {translate(props.lang, "wallet_" + item.status.toLowerCase())}</span>
                                                                    </td>
                                                                    <td className={`font-weight-500 text-start`} style={{ minWidth: '150px'}}>
                                                                        <div className="mx-auto w-100 text-center">
                                                                            <div onClick={() => openTrxInfoModal(item)} className={`cursor-pointer py-2 px-3 d-inline-block view-btn`}>
                                                                                <span className="font-weight-500 font-16 line-height-26 break-all-word">{translate(props.lang, "View Details")}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    <div className="py-5 text-center bg-transparent">
                                                                        <NotFound />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="tableBgContainer py-4 px-md-4 px-2">
                                <Row className="align-items-center px-3">
                                    <Col lg={9} md={9} sm={8} xs={8} className="mb-4">
                                        <span className="font-weight-600 font-22 line-height-26 text-white">{translate(props.lang, "Bonus History Details")}</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={4} xs={4} className="text-end mb-4">
                                        <Link to="/wallet/bonus-history-details">
                                            <span className="view-more-text font-weight-600 font-16 line-height-26">{translate(props.lang, "View More")}</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className="align-items-center px-0 mx-0 justify-content-start">
                                    <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-lg-0">
                                        {/* <RangePicker
                                            className="w-100 mt-md-0 input-transparent"
                                            placeholder={[
                                                translate(props.lang, "Start"),
                                                translate(props.lang, "End"),
                                            ]}
                                            onChange={(e) => handleTimeChange(e, 'bonus')}
                                        /> */}
                                        <div className="groupLabel-input date px-2 py-3 py-md-2">
                                            <DateRangePicker
                                                onApply={handleBonusDateChange}
                                                startDate={bonusFrom}
                                                endDate={bonusTo}
                                                initialSettings={{ locale: localeSetting }}
                                                key={JSON.stringify(localeSetting)}
                                            >
                                                <span className="font-16 font-weight-600 text-black">
                                                    <Row className="align-items-center justify-content-between px-0 mx-0">
                                                        <Col lg={4} md={4} sm={4} xs={4} className="ps-0 text-center">
                                                            <span className={`font-18 font-weight-600 ${bonusFrom ? "text-black" : "placeholder-color"}`}>{bonusFrom ? bonusFrom : translate(props.lang, "Start")}</span>
                                                        </Col>
                                                        <Col lg={2} md={2} sm={2} xs={2} className="px-0 text-center">
                                                            <img src={require('../../../images/icon2/dateArrow.svg').default} className="img-fluid" />

                                                        </Col>
                                                        <Col lg={4} md={4} sm={4} xs={4} className="pe-0 text-center">
                                                            <span className={`font-18 font-weight-600 ${bonusTo ? "text-black" : "placeholder-color"}`}>{bonusTo ? bonusTo : translate(props.lang, "End")}</span>
                                                        </Col>
                                                        <Col lg={2} md={2} sm={2} xs={2} className="text-end pe-0">
                                                            {bonusFrom && bonusTo ? (
                                                                <div className="d-flex w-100 justify-content-end" onClick={() => clearBonusDateSearch()}>
                                                                    <div className="gold-indicatior-container d-flex align-items-center justify-content-end">
                                                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <img src={require('../../../images/icon2/calendar.svg').default} className="calendar-icon" />
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </span>
                                            </DateRangePicker>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12} className="h-100 mb-2 mb-lg-0">
                                        <div className={`groupLabel-select select-placeholder-center w-100 py-1 py-md-0 px-2`}>
                                            <Select
                                                options={bonusTypeList.length > 0 ? bonusTypeList.filter(item => item.label !== 'All').map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                                placeholder={translate(props.lang, "Select Bonus Type")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={customSelectStyles}
                                                value={
                                                    bonusTypeList.filter(item => item.value === bonusTransactionType).length > 0 ?
                                                        (bonusTypeList.filter(item => item.value === bonusTransactionType).map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })))
                                                        : null
                                                }
                                                isClearable
                                                isSearchable={false}
                                                menuPortalTarget={document.body}
                                                onChange={(e) => setBonusTransactionType(e ? e.value : '')}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12} className="mb-2 mb-lg-0">
                                        <div className={`groupLabel-select select-placeholder-center w-100 py-1 py-md-0 px-2`}>
                                            <Select
                                                options={
                                                    shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                                }
                                                placeholder={translate(props.lang, "Result Per Page")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={customSelectStyles}
                                                isSearchable={false}
                                                menuPortalTarget={document.body}
                                                value={shownItem && shownItem.find(option => option.value === bonusProductPerpage)}
                                                name="bonusProductPerpage"
                                                onChange={(e) => setBonusProductPerpage(e.value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center px-0 mx-0 justify-content-start mt-4">
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="tableContainer">
                                            <Table responsive className="w-100 table-list text-center position-relative">
                                                <thead>
                                                    <tr>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Date & Time")}</th>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Type Of Bonus")}</th>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Rebate User")}</th>
                                                        <th className={`font-weight-600`}>{translate(props.lang, "Amount")} (USD)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loadingBonusHistory ? (
                                                        <tr role="row" className="odd">
                                                            <td colSpan={4}>
                                                                <LoadingV3 theme='light' />
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        bonusHistoryList.length > 0 ?
                                                            bonusHistoryList.map((item, i) => (
                                                                <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                                     <td className={`font-weight-500 lh-base`} style={{ minWidth: '150px'}}>
                                                                    <span className="d-md-inline-block d-block pe-md-1">{item.date_added.split(" ")[0]}</span>
                                                                    <span className="d-md-inline-block d-block">{item.date_added.split(" ")[1]}</span>
                                                                </td>
                                                                <td className={`font-weight-500 lh-base`}  style={{ minWidth: props.lang === 'en' ? '200px' : '150px'}}>{item.transaction_type === 'EARLY_BIRD_BONUS' || item.transaction_type === "VIP_BONUS" ? getTypeText(item.transaction_type_text) : item.transaction_type_text}</td>
                                                                    <td className={`font-weight-500 px-1 description lh-base`}>
                                                                        <div className="py-2">
                                                                            {item.remark_arr ? JSON.parse(item.remark_arr).length > 1 ? JSON.parse(item.remark_arr)[1] : '-' : '-'}
                                                                        </div>
                                                                    </td>
                                                                    <td className={`font-weight-500`}>{item.amount ? Number(item.amount).toLocaleString('en-US', digitFormat) : '-'}</td>
                                                                </tr>
                                                            )) :
                                                            <tr className="py-5 text-center bg-transparent">
                                                                <td colSpan={4}>
                                                                    <NotFound />
                                                                </td>
                                                            </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal className="fade modalColorBg" show={trxInfoModal} centered>
                <Modal.Header className="border-0 py-4">
                    <div className="d-flex w-100 align-items-center">
                        <div className="w-75"></div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeTrxInfoModal()} >
                            <img src={require('../../../images/icon2/modal-close.svg').default} alt="close" />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-3 pt-0">
                    <div className='modalBody'>
                        <div>
                            <div className="text-center mb-4">
                                <span className="font-weight-600 font-30 text-white">{translate(props.lang, "Details")}</span>
                            </div>
                            {trxDetail ? (
                                <Row className="mx-0 px-0">
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-400 font-16 line-height-26 text-white op6 break-all-word">{translate(props.lang, "Date & Time")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-600 font-18 line-height-26 text-white text-capitalize break-all-word">{trxDetail.date_added}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-400 font-16 line-height-26 text-white op6 break-all-word">{translate(props.lang, "Assets Type")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-600 font-18 line-height-26 text-white break-all-word">{trxDetail.transaction_type_text}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-400 font-16 line-height-26 text-white op6 break-all-word">{translate(props.lang, "Action")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-600 font-18 line-height-26 text-white break-all-word text-capitalize">{trxDetail.type_text.toLowerCase()}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-400 font-16 line-height-26 text-white op6 break-all-word">{translate(props.lang, "Amount")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-600 font-18 line-height-26 text-white break-all-word">{Number(trxDetail.amount).toLocaleString('en-US', digitFormat)}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-400 font-16 line-height-26 text-white op6 break-all-word">{translate(props.lang, "Status")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className={`font-weight-600 font-18 line-height-26 break-all-word statusBox px-2 py-1 d-inline-block ${trxDetail.status.toLowerCase()}`}> {translate(props.lang, "wallet_" + trxDetail.status.toLowerCase())}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-400 font-16 line-height-26 text-white op6 break-all-word">{translate(props.lang, "Description")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    {trxDetail.transaction_type === 'WITHDRAW' ? (
                                                        <>
                                                            <span className="mb-1 d-block font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{translate(props.lang, "Request wallet withdraw")}</span>
                                                            <table>
                                                                <tr>
                                                                    <td><span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{translate(props.lang, "Doc No.")}</span></td>
                                                                    <td>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere px-2">:</span>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{trxDetail.remark[0] ? trxDetail.remark[0] : '-'}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{translate(props.lang, "Submit Amount")}</span></td>
                                                                    <td>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere px-2">:</span>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{trxDetail.remark[1] ? trxDetail.remark[1] : '-'}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{translate(props.lang, "Fee")}</span></td>
                                                                    <td>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere px-2">:</span>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{trxDetail.remark[2] ? trxDetail.remark[2] : '-'}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{translate(props.lang, "Receive Amount")}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere px-2">:</span>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{trxDetail.remark[3] ? trxDetail.remark[3] : '-'}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{translate(props.lang, "Remark")}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere px-2">:</span>
                                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{trxDetail.order_remark ? trxDetail.order_remark : '-'}</span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </>
                                                    ) : (
                                                        <span className="font-weight-600 font-18 line-height-26 text-white break-all-word break-white-space lh-base overflow-text-anywhere">{trxDetail.remark}</span>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {trxDetail.transaction_type === 'WITHDRAW' ? (
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                            <Row className="px-0 mx-0 justify-content-start align-items-start">
                                                <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                    {trxDetail.withdrawal_remark.map((item, i) => (
                                                    <div key={i}>
                                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                                <div>
                                                                    <span className="font-weight-400 font-16 line-height-26 text-white op6 break-all-word">{translate(props.lang, "Txn Hash")}</span>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <div>
                                                                    <span className={`font-weight-600 font-18 line-height-26 text-white break-all-word`}><a className="link-hash" href={item.transaction_url} target="_blank">{item.transaction_hash}</a></span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    ))}
                                                </Col>
                                            </Row>
                                        </Col>
                                    ) : null}
                                </Row>
                            ) : null}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(WalletOverview);