const JA = {
    "Welcome Back": "お帰りなさい",
    "Don’t have an account yet?": "まだアカウントを持っていませんか？",
    "Sign up now": "今すぐ登録",
    "Email Address": "メールアドレス",
    "Enter Email": "メールアドレスを入力してください",
    "Password": "パスワード",
    "Your Password": "取引パスワードを入力してください",
    "Remember me": "アカウントを記憶する",
    "Forgot Password?": "パスワードを再設定する",
    "Login": "ログイン",
    "This field is required.": "必須項目です。",
    "Forgot Password": "ログインパスワードを忘れた",
    "Already have an account?": "既にアカウントをお持ちですか？",
    "Back To Login": "ログイン",
    "Verification Code": "メール確認コード",
    "Your Verification Code": "メール確認コードを入力してください",
    "Send": "送信",
    "Confirm Password": "パスワード確認",
    "Confirm Your Password": "ログインパスワードを入力してください",
    "Submit": "送信",
    "By clicking 'Submit' button, you agree to our Privacy and Terms of Use.": "「送信」ボタンをクリックすると、当社のプライバシーと利用規約に同意したことになります。",
    "Invalid Email Format": "無効なメールアドレス",
    "Password must at least 6 characters": "パスワードは6文字以上でなければなりません",
    "Confirm password need to same with password": "確認パスワードはパスワードと一致する必要があります",
    "Verification code has sent to": "確認コードが $email に送信されました。メールまたは迷惑メールフォルダを確認してください",
    "Create Personal Account": "登録情報を正確に入力してください",
    "Login now": "ログイン",
    "Full Name": "名前",
    "Your Full Name": "IDカードまたはパスポートの名前を入力してください",
    "Country": "国",
    "Select Country": "国を選択",
    "No countries available": "選択可能な国がありません",
    "Transaction Password": "取引パスワード",
    "Your Transaction Password": "取引パスワードを入力してください",
    "Referral Code (optional)": "招待コード（任意）",
    "Create Account Now": "登録",
    "Register Successful": "登録が完了しました",
    "Success": "成功",
    "Error": "エラー",
    "Your Referral Code": "招待コードを入力してください",
    "Dashboard": "ホーム",
    "Investment": "資産",
    "Wallet": "ウォレット",
    "Wallet Overview": "財務記録",
    "Deposit": "入金",
    "Withdrawal": "出金",
    "Bank Info": "出金情報",
    "Transaction History": "入出金記録",
    "Bonus History Details": "リベート記録",
    "Team": "チーム",
    "Team Overview": "進級詳細",
    "Team Investment": "チーム詳細",
    "Performance": "データ概要",
    "Referral": "招待リンク",
    "Profile": "マイ情報",
    "Settings": "設定",
    "KYC": "身分証明",
    "Security": "パスワード",
    "Fund Your Wallet": "アカウント入金",
    "Change Password": "ログインパスワードの変更",
    "Forget Transaction Password": "取引パスワードを忘れる",
    "Notification": "通知",
    "Hi,": "こんにちは、",
    "Stop Trading Account": "MT5アカウントの取引を停止",
    "Attention": "ご注意ください！",
    "Are you sure you want to stop trading?": "本当に取引を停止しますか？",
    "Yes, I am sure!": "はい、確かに！",
    "Cancel": "キャンセル",
    "New password must at least 6 characters": "新しいパスワードは6文字以上でなければなりません",
    "Confirm password need to same with new password": "新しいパスワードと確認パスワードが一致する必要があります",
    "New login password change successful": "パスワードが正常に更新されました",
    "Back": "戻る",
    "Old Password": "古いパスワード",
    "New Password": "新しいログインパスワード",
    "Your Old Password": "古いパスワードを入力してください",
    "Your New Password": "新しいログインパスワードを入力してください",
    "Confirm New Password": "新しいログインパスワードの確認",
    "Confirm Your New Password": "新しいログインパスワードを入力してください",
    "Update": "更新",
    "New Transaction Password": "新しい取引パスワード",
    "Your new transaction password": "新しい取引パスワードを入力してください",
    "Confirm New Transaction Password": "新しい取引パスワードの確認",
    "Confirm your new confirm transaction password": "新しい取引パスワードを入力してください",
    "New transaction password change successful": "取引パスワードが正常に更新されました",
    "Transaction password must at least 6 characters": "取引パスワードは6文字以上でなければなりません",
    "Logout": "ログアウト",
    "Referral Code": "招待コード",
    "Referral Link": "招待リンク",
    "Close": "閉じる",
    "Copied": "コピー成功",
    "Oops! No data found...": "データが見つかりません",
    "Loading Data": "データを読み込み中",
    "Male": "男性",
    "Female": "女性",
    "Not Verified": "未確認",
    "Only Numbers are allowed in this field": "この項目には数字のみを入力できます",
    "Image size cannot exceed 2mb": "画像サイズは2MBを超えてはいけません",
    "Only allow format jpg, png or jpeg": "許可される形式はjpg、png、jpegのみです",
    "Gender": "性別",
    "Select Gender": "性別を選択",
    "IC / passport no.": "IDカード/パスポート番号",
    "Your IC / Passport No.": "IDカード/パスポート番号を入力してください",
    "Date of Birth": "生年月日",
    "Your Date of Birth": "生年月日を入力してください",
    "Phone Number": "電話番号",
    "Your phone number": "電話番号を入力してください",
    "Address": "住所",
    "Your address": "住所を入力してください",
    "Joining Date": "登録日",
    "KYC Status": "認証状態",
    "Remark": "備考",
    "Upload IC/ Passport front": "IDカード/パスポートの表面をアップロード",
    "Upload IC/ Passport Back": "IDカード/パスポートの裏面をアップロード",
    "Upload Photo With Holding IC/Passport": "本人がIDカード/パスポートを持っている写真をアップロード",
    "APPROVED": "承認済み",
    "REVIEW": "審査中",
    "REJECTED": "拒否されました",
    "PENDING": "保留中",
    "Congratulations, your account has been successfully created.": "おめでとうございます。アカウントが正常に作成されました。",
    "Continue": "続ける",
    "Profile successful updated": "プロフィールが正常に更新されました",
    "ID": "ID",
    "Upline": "アップライン",
    "My Referral Code": "私の招待コード",
    "Edit": "編集",
    "MT5 Username": "MT5 アカウント",
    "MT5 Password": "MT5パスワード",
    "share my QR code": "私の招待リンクを共有",
    "My Rank": "私のランク",
    "Active Group Member": "アクティブメンバー",
    "Total Group Member": "総メンバー",
    "Ranking Bonus": "ランクボーナス",
    "Lot Rebate Bonus": "スプレッドリベート",
    "Profit Sharing Bonus": "利益分配ボーナス",
    "Direct Referral Members": "直接紹介メンバー",
    "Direct Referral Investment Amount": "直接紹介の投資額",
    "Total Group Investment Amount": "私のチームの投資額",
    "Personal Sales": "直接紹介の業績",
    "Group Sales": "私のチームの業績",
    "Self Deposit": "私のMT5残高",
    "Ranking": "ランクアップ方法",
    "Wallet Balance:": "ウォレット残高：",
    "Total Member": "総メンバー数",
    "Total Active Member": "アクティブメンバー数",
    "Total Sales": "チーム業績",
    "Personal Deposit": "MT5残高",
    "Type Of Bonus": "資産タイプ",
    "Amount": "金額",
    "Date & Time": "日時",
    "View More": "もっと見る",
    "Wallet Transaction History": "財務記録",
    "Description": "説明",
    "Type": "資産タイプ",
    "Action": "タイプ",
    "Transaction Hash": "取引明細",
    "TXID": "TXID",
    "Status": "ステータス",
    "MT5 Balance": "MT5 残高",
    "Crypto Deposit": "暗号通貨の預金",
    "Seamlessly Add Cryptocurrency To Your Account And Start Trading Effortlessly.": "あなたの資金の安全のために、リチャージアドレス情報を慎重に確認してください。",
    "Fiat Deposit": "法定通貨の預金",
    "Conveniently Deposit Funds Using Your Preferred Fiat Currency, Ensuring A Smooth And Hassle-Free Experience.": "あなたの資金の安全のために、送金情報を慎重に確認してください。",
    "Coin": "コイン",
    "Network": "ネットワーク",
    "Select Network": "ネットワークを選択",
    "Deposit Address": "入金アドレス",
    "Wallet Address": "ウォレットアドレス",
    "Minimum Deposit": "最低入金額",
    "Please Select Network": "ネットワークを選択してください",
    "Total Invested": "私の総資産",
    "Total Profit/Loss": "累計取引利益",
    "Investment Details": "注文の詳細",
    "Order ID": "注文番号",
    "Price": "価格",
    "Trading Pair": "商品名",
    "Profit/Loss %": "利益/損失",
    "Lot Size": "ロット数",
    "Price Enter": "入場価格",
    "Price Close": "出場価格",
    "Announcement": "お知らせ",
    "No Announcement For Now": "お知らせはありません",
    "Group Sales Performance": "私のチームの業績",
    "Sales": "業績",
    "Read More": "さらに詳しく",
    "My MT5 Info": "私のMT5ログイン",
    "Wallet Total Balance": "ウォレット",
    "Trading Profit": "取引利益",
    "Total Investment": "MT5残高",
    "Total Bonus": "累計キャッシュバック利益",
    "My QR Referral Code": "私のQRコード＆招待リンク",
    "Transfer": "振替",
    "From": "から",
    "To": "まで",
    "Transfer Amount": "振替金額",
    "Balance Available:": "振替可能残高：",
    "Your Transfer Amount": "振替金額を入力してください",
    "Successful": "成功",
    "Unsuccessful": "未成功",
    "Insufficient balance": "残高不足",
    "Add New Withdrawal Method": "新しい出金方法を追加",
    "Withdrawal Method Available": "利用可能な出金方法",
    "Invalid email or password": "無効なメールアドレスまたはパスワード",
    "Fiat Withdrawal": "法定通貨の出金",
    "Crypto Withdrawal": "暗号通貨の出金",
    "Bank Country": "銀行の所在国",
    "Bank Account Holder Name": "銀行口座名義人の名前",
    "Bank Name": "銀行名",
    "Bank Account Number": "銀行口座番号",
    "Bank Address": "銀行住所",
    "Bank Swift Code": "銀行のSwiftコード",
    "Select Your Bank country": "銀行の所在国を選択",
    "Select Your Bank Name": "銀行名を選択",
    "Your Bank Account Holder Name": "名義人の名前を入力",
    "Your Bank Account Number": "銀行口座番号を入力",
    "Your Bank Address": "銀行住所を入力",
    "Your Bank Swift Code": "銀行のSwiftコードを入力",
    "Your Bank Name": "銀行名を入力",
    "Your remark": "備考を入力",
    "Your Wallet Address": "ウォレットアドレスを入力",
    "Select Your Network": "ネットワークを選択",
    "Save": "保存",
    "Select Bank Name": "銀行名を選択",
    "No network available": "利用可能なネットワークがありません",
    "Withdrawal From Wallet": "出金可能残高",
    "Email Verification Code": "メール確認コード",
    "Your Email Verification Code": "メール確認コードを入力",
    "Withdraw Amount": "出金金額",
    "Your Withdraw Amount": "出金金額を入力",
    "Select Type": "タイプを選択",
    "No option available": "利用可能なオプションはありません",
    "Select bank transfer": "銀行振込を選択",
    "Great News! You have the option to resume trading. Do you want to resume trading?": "朗報です！取引を再開するオプションがあります。取引を再開しますか？",
    "Click here": "ここをクリック",
    "to reopen trading activity.": "取引を再開する。",
    "Receive Amount": "受取額",
    "Network Fee:": "ネットワーク料金：",
    "Exchange Rate:": "為替レート：",
    "Charge Fee:": "手数料：",
    "Merchant": "商人",
    "Select Merchant": "商人を選択",
    "Order Number": "注文番号",
    "Your Order Number": "注文番号を入力",
    "Payment Currency": "支払い通貨",
    "Select Payment Currency": "支払い通貨を選択",
    "No currency available": "利用可能な通貨がありません",
    "Deposit Amount": "入金金額",
    "Your Deposit Amount": "入金金額を入力",
    "Minimum to maximum transaction is 1000-4000 USD": "最低から最高の取引金額は1000から4000 USD",
    "Remove Withdrawal Method": "出金方法を削除",
    "Withdrawal Type": "出金タイプ",
    "fiat withdraw": "法定通貨の出金",
    "crypto withdraw": "暗号通貨の出金",
    "Delete": "削除",
    "Balance Available": "利用可能な残高",
    "Amount must greater than 0.": "金額は0より大きくなければなりません。",
    "Remark:": "備考：",
    "Amount must only within 1000 - 4000": "金額は1000から4000の範囲内でなければなりません",
    "View Details": "詳細を見る",
    "Details": "詳細",
    "Investment Package": "コピー取引戦略",
    "Select Your Package": "コピー取引戦略を選択",
    "Monthly Return": "月間取引利益",
    "Minimum investment amount is": "最低投資金額は",
    "Maximum investment amount is": "最高投資金額は",
    "Purchase Package Details": "コピー取引戦略の詳細",
    "Purchase Datetime": "開始日時",
    "Cumulative Investment Days": "累計取引日数",
    "Investment Allocation": "取引金額",
    "Surrender": "終了",
    "Successfully surrender": "パッケージの終了に成功しました",
    "active": "アクティブ",
    "Account Type": "出金方法",
    "Select Account Type": "出金方法を選択",
    "No account type available": "利用可能なアカウントタイプはありません",
    "Days": "日",
    "Processing Fee": "手数料",
    "Invalid withdraw amount": "無効な出金金額",
    "Withdraw": "出金",
    "MT5 Account": "MT5口座",
    "Select MT5 Account": "MT5口座を選択",
    "All MT5 Account": "すべてのMT5口座",
    "Account": "口座",
    "MT5 Account 1 Username": "MT5口座 1",
    "MT5 Account 2 Username": "MT5口座 2",
    "MT5 Account 3 Username": "MT5口座 3",
    "MT5 Account 4 Username": "MT5口座 4",
    "MT5 Account 1 Password": "MT5口座 1 パスワード",
    "MT5 Account 2 Password": "MT5口座 2 パスワード",
    "MT5 Account 3 Password": "MT5口座 3 パスワード",
    "MT5 Account 4 Password": "MT5口座 4 パスワード",
    "Payment Amount": "支払い金額",
    "Your Payment Amount": "支払い金額を入力",
    "Convert": "変換",
    "Exchange Rate": "為替レート：",
    "Your IP does not support the operation": "あなたのIPは操作をサポートしていません",
    "to": "まで",
    "inactive": "非アクティブ",
    "Account withdrawal type": "出金方法",
    "Select withdrawal type": "出金方法を選択",
    "MT5 Login ID": "MT5口座ID",
    "completed": "完了",
    "Transfer1": "振替",
    "Copy": "コピー",
    "Confirm": "確認",
    "MT5 Login ID1": "MT5ログイン",
    "Select Bank Country": "銀行の所在国を選択",
    "Change Transaction Password": "取引パスワードを変更",
    "Login Password": "ログインパスワード",
    "Enter Login Password": "ログインパスワードを入力してください",
    "Confirm Login Password": "ログインパスワードを確認",
    "Enter Verification Code": "メール確認コードを入力してください",
    "Enter Transaction Password": "取引パスワードを入力してください",
    "Confirm login password and login password are not same": "確認パスワードとログインパスワードが一致しません",
    "Assets Type": "資産タイプ",
    "Trend Trading Strtegies": "トレンド取引戦略",
    "Trading Details": "取引の詳細",
    "Prudent": "慎重",
    "Short Trade": "短期取引",
    "Runtime": "稼働日数",
    "Total Copiers": "累計コピー数",
    "AUM (USD)": "コピー取引の規模（USD）",
    "Profit/Loss (Month)": "利益/損失（月）",
    "Minimum Copy Amount (USD)": "最低コピー金額（USD）",
    "Follow Orders": "コピー取引",
    "Support Resistance Strategies": "サポートとレジスタンスの取引戦略",
    "Candlestick Patterns Strategies": "ローソク足パターンの取引戦略",
    "Trend trading is very obvious, which is to seize the trend of the day and create profits as much as possible. The short-term trend trading strategy is very simple. If you see a foreign exchange currency pair rebounding three times on the same trend line, you can predict a strong trend and find the direction to trade in that trend.": "トレンド取引は非常に明確で、その日のトレンドを捉え、できるだけ多くの利益を生み出すことです。短期トレンド取引戦略は非常に簡単です。同じトレンドラインで3回反発する外国為替通貨ペアを見た場合、強いトレンドを予測し、そのトレンドに従って取引の方向を見つけることができます。",
    "Win Trades": "総利益取引数",
    "Lose Trades": "総損失取引数",
    "Max Drawdown": "最大ドローダウン",
    "Trading Pairs": "取引ペア",
    "Support/resistance trading is the best short-term trading method, with a focus on identifying key price points on the chart and then conducting breakthrough trading. The risk management of this strategy is also very direct, which is to set stop loss at key price points.": "サポート/レジスタンス取引は最良の短期取引方法であり、チャート上の重要な価格ポイントを特定し、その後、ブレイクスルー取引を行うことに焦点を当てています。この戦略のリスク管理も非常に直接的で、重要な価格ポイントでストップロスを設定することです。",
    "It is traded in the form of a candle chart, with time frames locked to 1-minute, 5-minute, and 15 minute charts. We need to find the inverted candle pattern on the chart as a signal to trade. Similarly, every stop loss is very important, and a good stop loss point is to reverse the candlestick on the opposite side of your trade.": "ローソク足チャートの形で取引され、時間枠は1分、5分、15分のチャートに固定されています。取引のシグナルとして、チャート上で逆転ローソク足パターンを見つける必要があります。同様に、すべてのストップロスが非常に重要であり、良いストップロスポイントは、取引の反対側のローソク足を反転させることです。",
    "Ranking1" : "ランクアップ方法",
    "Wallet Deposit": "入金",
    "Enter Verification Code1" : "メール確認コードを入力してください",
    "Your Full Name1": "Dカードまたはパスポートの名前を入力してください",
    "Enter New Login Password": "新しいログインパスワードを入力してください",
    "New Login Password": "新しいログインパスワード",
    "Confirm New Login Password": "新しいログインパスワードを確認してください",
    "MT5 PROFIT" : "MT5 利益",
    "In" : "入",
    "Lot Rebate" : "ロットリベート",
    "2.50 from test0@gmail.com" : "2.50 は test0@gmail.com から",
    "Hedged Position" : "ヘッジポジション",
    "Start" : "開始",
    "End" : "終了",
    "Source from MT5 account 1" : "MT5 アカウント 1 からのソース",
    "Rebate User" : "リベートユーザー",
    "Details" : "詳細",
     "Old Transaction Password": "旧取引パスワード",
    "Enter Old Transaction Password": "旧取引パスワードを入力してください",
    "Coming Soon" : "近日公開",
    "My MT5 Balance": '私のMT5残高',
    "Contract Commission": '契約手数料',



}

export default JA;